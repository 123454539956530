import React, {useState, useEffect} from 'react';
import {empty} from "../../../../helpers/helper";
import {useSelector, useDispatch} from "react-redux";
import { constructor3dSelectors, constructor3dOperations } from "../../../../redux/constructor";
import Base from "./../../../model/components/Base";
import './style.scss'

const BaseClass = new Base();

const DetailConnections = ({detail, editConnection, removeConnection}) => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {

	}, [detail.connections]);


	const edit = connectionId => {
		editConnection(connectionId)
	}

	const remove = connectionId => {
		removeConnection(connectionId)
	}

	if(empty(detail.connections)) return null;

	return (
		<div className="detail-connections d-flex flex-column">
			<div className="detail-connections__header d-flex flex-justify-beetwen">
				<span>connections: {detail.connections.length}</span>
				<a className={`panel__heade-control ${isOpen ? 'hide' : 'show'}`}
					onClick={e => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</a>
			</div>
			<div className={`detail-connections__body ${isOpen ? '' : 'hidden'}`}>
				{detail.connections.map((el, i) => {
					const detail = BaseClass.getDetailById(el.detail)
					return (
						<div key={i} className="d-flex flex-justify-beetwen detail-connections__item">
							<div className="detail-connections__item-text">{`side: ${el.currentFace} to ${detail.name}->${el.targetFace}`}</div>
							<a className="add-button" onClick={e => edit(el.connectionId)}> &#9998;</a>
							<a className="remove-button" onClick={e => remove(el.connectionId)}> &times;</a>
						</div>)
				})}
			</div>
		</div>
	)


}

export default DetailConnections;