import React, {useEffect, useRef, useState} from "react";
import icon from "../../media/user.svg";
import API from "api/API";
import {useDispatch, useSelector} from 'react-redux'
import {userSelectors} from "redux/user";
import "./style.scss";
import {Link} from "react-router-dom";
import Errors from "../../models/Errors";
import logo2 from "../../media/logo-dark.svg";
import Languages from "../../translation/Languages";
import {ReactComponent as Arrow} from '../../media/angle-down-purpur.svg';
import DefaultBtn from "../UI/Buttons/DefaultBtn";
import {errorMessageOperations} from "redux/errors";
import {empty} from "../../helpers/helper";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";

const _errors = new Errors([])

const Auth = () => {
    const user = useSelector(userSelectors.User);
    const [isOpen, setIsOpen] = useState(false)
    const [allFilial, setAllFilial] = useState([])
    const [currentFilial, setCurrentFilial] = useState(user?.filial_agency === null
        ? `${Languages.getTranslation('default-filial', true)} `
        : `${user?.filial.name}`)
    const [isOpenFilial, setIsOpenFilial] = useState(false);
    useUpdateTranslations();

    const clickRef = useRef(null)
    const handleClick = e => {
        if (clickRef.current && !clickRef.current.contains(e.target) && isOpen) {
            setIsOpen(prevState => !prevState);
        }
    };

    const dispatch = useDispatch()

    useEffect(() => {
        API.Auth.getFilialAgency()
            .then(response => {
                setAllFilial(response?.data)
            })
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClick);
        }
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [isOpen])

    const logout = async () => {
        await API.Auth.logout()
            .catch(error => {
                _errors.setError(`${error.message}`, 'error', true)
            });
    };

    const handleChangeFilial = async (item) => {
        await API.Auth.setFilialAgency(item.id)
            .then(response => {
                dispatch(errorMessageOperations.switchStateError(
                    {message: Languages.getTranslation("filial-changed", true), type: 'success', show: true}))
                document.location.href = '/details'
                setCurrentFilial(item.name)
            })
    }
    return (
        <>
            <div className="auth">
                {/*<a href="#" onClick={() => setProject()} className="auth__link">*/}
                {/*    Особистий кабінет*/}
                {/*</a>*/}
                <div className="user" ref={clickRef}>
                    {/*{user ? (*/}{/*onClick={() => logout()}*/}
                    {/*  <span className="name">{`${user.firstName} ${user.lastName}`}</span>*/}
                    {/*) : (*/}
                    {/*  ""*/}
                    {/*)}*/}
                    {user ? <img
                        id='user-tooltip'
                        onClick={() => {
                            setIsOpen(prevState => !prevState)
                        }}
                        src={icon} alt="logo" className="auth-img"/> : ""}
                    {isOpen &&
                        <div className='user_tooltip'>
                            <div className='user_wrapper'>
                                <div className='user_wrapper_userName'>
                                    <img src={icon} alt="logo" className="auth-img"/>
                                    <div className='user_wrapper_userName_colum'>
                                        <div
                                            className='user_wrapper_userName_colum_first'>{`${user.firstName} ${user.lastName}`}</div>
                                        <div>{`+${user.phone}`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='filia_wrapper'>
                                <p className='user_affiliate'>{Languages.getTranslation("Affiliate:", true)}</p>
                            </div>
                            <div className='user_wrapper'>
                                <div className='user_wrapper_userName jc-center' onClick={() =>
                                    setIsOpenFilial(prevState => !prevState)}>
                                    <div>
                                        {currentFilial}
                                        <Arrow
                                            className={`user_wrapper_userName_arrow ${isOpenFilial ? 'rotate' : 'rotateBack'}`}/>
                                        {(isOpenFilial && !empty(allFilial)) &&
                                            <div className='filial_tooltip'>
                                                <div className='sub-wrapper'>
                                                    {allFilial.map((item, index) => (
                                                        <div onClick={() => handleChangeFilial(item)}
                                                             className='filial_tooltip_item'
                                                             key={index}>{item.name}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='user_btn_exit'>
                                <DefaultBtn title={Languages.getTranslation('exit', true)} handler={logout}/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Auth;
