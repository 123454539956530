import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import AppContainer from "components/AppContainer";
import AppHeader from "components/AppHeader";
import Modal from "components/UI/Modal";
import DetailProps from "components/UI/Modal/DetailProps";
import DetailInfo from "components/UI/Modal/DetailInfo";
import { NotificationContainer } from "react-notifications";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/UI/Loader";
import { ToastContainer, toast } from "react-toastify";
import Toast from "components/Toast";
import DisplayHandler from '../components/Toast/DisplayHandler'

const PrivateTemplate = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("processes") == -1) {
      dispatch(
        modalsOperations.showStateSelectedTreatments({
          show: false,
        })
      );
      dispatch(
        modalsOperations.showStateDetailInfo({
          show: false,
        })
      );
    }
  }, [location]);

  return (
    <AppContainer>
      {/*<NotificationContainer />*/}
      {/* <ToastContainer /> */}
      {/*  <DisplayHandler />*/}
      {/*  <Toast />*/}
       <Modal />
       {/*<SelectedTreatments />*/}
       <DetailProps />
       <DetailInfo />

       <AppHeader />
       {children}
    </AppContainer>
  );
};
export default PrivateTemplate;
