import Languages from "../../../../../translation/Languages";
import {useEffect, useState} from "react";
import {holesParamsForEdgeSide, holesParamsForFace} from "../config";

const selectOptionsForHoles = (type) => {
  if (['front', 'back'].includes(type)) {
    return holesParamsForFace
  } else {
    return holesParamsForEdgeSide
  }
}

const GroupChangeDiamHolesItem = ({hole}) => {
  const [currentHole, setCurrentHole] = useState(hole)

  const changeHandler = (e) => {
     setCurrentHole({
        side: hole.side,
        diam: hole.diam,
        neededDiam: Number(e.target.value),
     })
  }

  const changeMaterialProps = () => {
    hole.neededDiam = currentHole.neededDiam
  }

  useEffect(() => {
    changeMaterialProps()
  }, [currentHole]);

  return <div className='groupChangeDiamHolesItem'>
    <div className="groupChangeDiamHolesItem__section">
      <span className="groupChangeDiamHolesItem__label">{Languages.getTranslation('current-diameter', true)}</span>
      <div className="groupChangeDiamHolesItem__value">{hole.diam}</div>
    </div>
    <div className="groupChangeDiamHolesItem__section">
      <span className="groupChangeDiamHolesItem__label">{Languages.getTranslation('change-the-diameter', true)}</span>
      <select name="groupChangeDiamHolesItem__select" className="groupChangeDiamHolesItem__select"
              onChange={(e) => changeHandler(e)} value={currentHole.neededDiam}>
        {selectOptionsForHoles(hole.side).map((el, index) => <option key={index} value={el.value}>{el.key}</option>)}
      </select>
    </div>
  </div>
}

export default GroupChangeDiamHolesItem