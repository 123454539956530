const getCDetails = ({ constructor3d }) => {
  return constructor3d.cDetails;
};

const getScene = ({constructor3d}) => {
  return constructor3d.scene
}

const getSelectedDetails = ({constructor3d}) => {
  return constructor3d.selectedCDetails
}

const getEditedObject = ({constructor3d}) => {
  return constructor3d.editedObject
}

// const getProducts = ({constructor3d}) => {
//   return constructor3d.products
// }

const getProducts = ({ project }) => {
  return project.project.construction && project.project.construction.payload
    ? project.project.construction.payload.construction._products
    : [];
}

const getSelectedObjects = ({constructor3d}) => {
  return constructor3d.selectedObjects
}

const getHistory = ({constructor3d}) => {
  return constructor3d.history
}

const showedProcessing = ({constructor3d}) => {
  return constructor3d.showProcessing
}

const getConnections = ({constructor3d}) => {
  return constructor3d.connections
}

const getConnectValues = ({constructor3d}) => {
  return constructor3d.connectValues
}

const getConnectionOptions = ({constructor3d}) => {
  return constructor3d.connectOptions
}

const getGroups = ({constructor3d}) => {
  return constructor3d.group
}

const getDetailById = (detailCId) => {
  return ({constructor3d}) => {
    return constructor3d.cDetails.find(detail => detail.detailCId === detailCId)
  }
}

const leftPanelOpened = ({constructor3d}) => {
  return constructor3d.interface.leftPanelOpened
}

const rightPanelOpened = ({constructor3d}) => {
  return constructor3d.interface.rightPanelOpened
}

const getCurrentCamera = ({constructor3d}) => {
  return constructor3d.interface.currentCamera
}

const getTopPanelItems = ({constructor3d}) => {
  return constructor3d.interface.topPanelItems
}

const connectModalOpened = ({constructor3d}) => {
  return constructor3d.interface.connectModalOpened
}

export default {
  getCDetails,
  getScene,
  getSelectedDetails,
  getEditedObject,
  getProducts,
  getSelectedObjects,
  getHistory,
  showedProcessing,
  getConnections,
  getConnectValues,
  getConnectionOptions,
  getGroups,
  getDetailById,
  leftPanelOpened,
  rightPanelOpened,
  getCurrentCamera,
  getTopPanelItems,
  connectModalOpened
};
