import './style.scss'
import ahtung from "../../../../media/ahtuung.png";
import CloseBtn from "../../Buttons/CloseBtn";
import {modalsOperations, modalsSelectors} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {useEffect, useState} from "react";
import DeleteProductTableItem from "./DeleteProductTableItem";
import {projectSelectors} from "redux/project";
import {toastError} from "../../../../helpers/toasts";

const DeleteProduct = () => {
  const dispatch = useDispatch();
  const construction = useSelector(projectSelectors.getConstruction);
  const productId = useSelector(modalsSelectors.getModalIsEdit);
  const [details, setDetails] = useState([])
  const [product, setProduct] = useState({
    name: 'test',
    id: 0
  })

  useEffect(() => {
    const neededProduct = construction.products.find(p => p.id === productId)
    if (neededProduct) {
      setProduct(neededProduct)
      setDetails([...construction.details.filter(el => Number(el.productId) === Number(productId))])
    }
  }, []);

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const submitHandler = () => {
    construction.deleteDetails(details.map(el => el.id))
      .then(() => {
        return construction.deleteProduct(product.id)
      })
      .then(() => close())
      .catch(err => toastError(Languages.getTranslation('defaultError', true)));
  }

  return <div className='deleteProduct'>
    <div className="deleteProduct__header">
      <CloseBtn handler={close}/>
    </div>


    <div className="deleteProduct__ahtungSection">
      <div className="deleteProduct__ahtungSection-container">
        <img src={ahtung} alt="ahtung"/>
        <div className="ahtungModal__body-decription-title">
          {Languages.getTranslation('warning', true)}</div>
        <div className="deleteProduct__ahtungSection-text">
          {Languages.getTranslation('deleteProduct-ahtung-text', true)}
        </div>
        <div className="deleteProduct__ahtungSection-text">
          {Languages.getTranslation('deleteProduct-ahtung-text-two', true)}
        </div>
      </div>
    </div>

    <div className="deleteProduct__table">
      <div className="deleteProduct__table-header">
        <div className="deleteProduct__table-number">№</div>
        <div className="deleteProduct__table-name">{Languages.getTranslation('detailName', true)}</div>
        <div className="deleteProduct__table-data">
          <div className="deleteProduct__table-dataItem">{Languages.getTranslation('height', true)}</div>
          <div className="deleteProduct__table-dataItem">{Languages.getTranslation('width', true)}</div>
          <div className="deleteProduct__table-dataItem">{Languages.getTranslation('amount', true)}</div>
        </div>
      </div>

      <div className="deleteProduct__table-body">

        {details.map((detail, index) => <DeleteProductTableItem key={detail.id} detail={detail} index={index}/>)}

      </div>
    </div>

    <div className="deleteProduct__productSection">
      <div className="deleteProduct__productSection-title">
        {Languages.getTranslation('tied-product', true)}
      </div>
      <div className="deleteProduct__productSection-container">
        <div className="deleteProduct__productSection-text">{product.id}</div>
        <div className="deleteProduct__productSection-text">{product.name}</div>
      </div>
    </div>

    <div className="deleteProduct__footer">
      <div className="deleteProduct__footer-container">
        <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
        <DefaultBtn title={Languages.getTranslation('delete', true)} handler={submitHandler}/>
      </div>
    </div>

  </div>
}

export default DeleteProduct;