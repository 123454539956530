import Languages from "../../../translation/Languages";
import "./styles.scss";
import {useDispatch, useSelector} from "react-redux";
import {langOperations, langSelectors} from "redux/lang";

export const Langs = () => {
    const curLang = useSelector(langSelectors.getLangState);
    const dispatch = useDispatch();
    const changeLangHandler = (lang) => {
        dispatch(
            langOperations.changeLang({lang})
        );
        Languages._locale = lang;
    }

    return (<div className="lang">
        <div className="lang--current">
            {Languages.checkUkLang(curLang)}
        </div>
        <div className="lang-list">
            {Languages.langs.map((lang) => (
                <button
                    key={lang}
                    type="button"
                    className={`lang-btn ${curLang === lang ? "active" : ""}`}
                    onClick={() => changeLangHandler(lang)}
                >
                    {Languages.checkUkLang(lang)}
                </button>
            ))}
        </div>
    </div>)
}