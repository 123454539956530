import keyMirror from "keymirror";

const actionType = {
  Construction: null,
  Detail: null,
  IsArrowMaterial: null,
  IsArrowEdge: null,
  sceneModel: null,
  Treatment: null,
  ErrorProject: null
};

export default keyMirror(actionType);
