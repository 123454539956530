import React, { useEffect, useRef } from "react";
import { SVG } from "@svgdotjs/svg.js";
import Helpers from "../../../models/3D/Helpers";
import {empty} from "../../../helpers/helper";

const ContourSVGComponent = ({ detail, rects = []}) => {
    const svgRef = useRef();

    const { contour3d } = detail;
    useEffect(() => {

        const draw = SVG().addTo(svgRef.current)
            .size('100%', '100%')
            .viewbox(0, 0, detail.l, detail.h)


        const strokeWidth = Math.sqrt(detail.l * detail.h) * 0.03;

        const contour = detail.createContour()?.contour?.contour
        contour?.forEach(object => {
            if (object?.type === "line") {
                draw.line(object.x1, object.y1, object.x2, object.y2).stroke({ color: '#A14389', width: strokeWidth });
            } else {
                const sweepFlag = object?.dir ? "0" : "1";
                let pathString = `M ${object?.x1}, ${object?.y1} A ${object?.r},${object?.r} 0 0,${sweepFlag} ${object?.x2}, ${object?.y2}`;
                draw.path(pathString).fill('none').stroke({ color: '#A14389', width: strokeWidth });
            }
        });
        rects?.forEach((rect, i) => {
            let pointsRect = []

            rect.elements.forEach((element, index) => {
                let rectCoordinates = {}
                if(!empty(rect)){
                    rectCoordinates = Helpers.fixUShapePoints(rect)
                }

                if (element?.type === "arc") {
                    const coordinate = rectCoordinates[index];
                    element.xc += coordinate.xc;
                    element.yc += coordinate.yc;


                    const endAngleRad = Math.abs(element.endAngleRad);
                    const startAngleRad = Math.abs(element.startAngleRad);
                    const {x1, y1, x2, y2} = coordinate;
                    const largeArcFlag = (endAngleRad - startAngleRad <= Math.PI) ? "0" : "1";
                    const path = `M ${x1} ${y1} A ${element.r} ${element.r} 0 ${largeArcFlag} 0 ${x2} ${y2}`;

                    draw.path(path).stroke({color: '#A14389', width: 4}).fill('#A14389');
                }
                else{
                    let shift = rectCoordinates[index];
                    element.x1 = shift.x1;
                    element.y1 = shift.y1;
                    element.x2 = shift.x2;
                    element.y2 = shift.y2;

                    // For lines, use the updated element's (x1, y1) and (x2, y2) as the line's start and end points.
                    let x1 = element.x1;
                    let y1 = element.y1;
                    let x2 = element.x2;
                    let y2 = element.y2;

                    pointsRect.push(`${x1},${y1} ${x2},${y2}`);
                    draw.polygon(pointsRect.join(" ")).fill('#A14389');
                    draw.line(x1, y1, x2, y2)
                        .stroke({ color: '#A14389', width: 4 }).fill('#A14389');
                }
            });
        });

    }, []);

    return (
        <div ref={svgRef} style={{ width: '50px', height: '50px', transform: 'rotateX(180deg)' }}></div>
    );
};

export default React.memo(ContourSVGComponent);
