import Base from "./Base";
import {empty} from "../../../helpers/helper";
import _Constructor from "../../../db/_Constructor";

export default class HistoryClass extends Base {
	historyLength = 20;

	constructor() {
    super();
  }

	getHistory() {
		return this.getState('history');
	}

	getCurrentStep() {
		const history = this.getHistory();
		return history.findIndex(el => el.isCurrent);

	}

	prepareDetailsFromHistory(steps=null) {
		const details = {};
		const revertedSteps = steps ?? this.getHistory();
		revertedSteps.forEach(el => {
			details[el.detail.detailCId] = el.changes;
		})
		return details;
	}

	restoreDetailsFromHistory(details, restore = false) {
		for (const [detailCId, changes] of Object.entries(details)) {
			if(restore ) {
				if(Array.isArray(changes)) {
					changes.forEach(ch => this.getDetailFromState({detailCId}).applyHistoryState(ch))
				} else {
					this.getDetailFromState({detailCId}).applyHistoryState(changes);
				}
			} else {
				if(Array.isArray(changes)) {
					changes.forEach(ch => this.getDetailFromState({detailCId}).restoreHistoryState(ch))
				} else {
					this.getDetailFromState({detailCId}).restoreHistoryState(changes);
				}


			}
		}
	}

	backToState(index, restore=false) {
		const history = this.getHistory();

		const revertedSteps = [];
		const current = this.getCurrentStep();
		if(index < current) {
			history.forEach((el, i) => {
				if(i <= current && i >= index && el.changes.type !== 'created') {
					revertedSteps.push(el);
				}
			})
			revertedSteps.reverse()
		} else if(index > current) {
			history.forEach((el, i) => {
				if(i > current && i <= index && el.changes.type !== 'created') {
					revertedSteps.push(el);
				}
			})
		} else {
			revertedSteps.push(history.find((el, i) => i === index))
		}
		this.restoreDetailsFromHistory(this.prepareDetailsFromHistory(revertedSteps), restore);
		this.updateState({
			type: 'HISTORY_SET_CURRENT',
			payload: restore ? index :index - 1
		})
	}

	addState(data) {
		const history = [...this.getHistory()];
		const actHistory = history.slice(0, this.getCurrentStep() + 1);
    if (actHistory.length >= this.historyLength) {
	    actHistory.splice(0, 1);
    }
		actHistory.push(data);
		this.updateState({
			type: 'SET_HISTORY_STATE',
			payload: actHistory
		})
		this.updateState({
			type: 'HISTORY_SET_CURRENT',
			payload: actHistory.length - 1
		})

	}
}