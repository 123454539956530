import React, { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { modalsSelectors } from "redux/modals";
import { inputsSelectors } from "redux/inputs";

import ImportProject from "components/UI/Modal/Types/importProject";
import SaveProject from "./SaveProject/index";
import AddFolder from "./AddFolder/index";
import QuickInputTable from "./QuickInputTable";
import AddDetail from "./AddDetail";
import CuttingParams from "./CuttingParams";
import AddEdjes from "./AddEdges";
import AddMaterialAndEdges from "./AddMaterialAndEdges";
import TransportCut from "./TransportCut";
import NewProject from "./NewProject";
import KronasMaterials from "./KronasMaterials";
import ReplaceMaterial from "./ReplaceMaterial";
import CustomerWarehouse from "./CustomerWarehouse";
import ReplaceMaterialProject from "./ReplaceMaterialProject";
import ImportErrorModal from "./ImportErrorModal";
import ReplaceEdgesProject from "./ReplaceEdgesProject";
import MapProject from "./MapProject";
import MultiplicityModel from "./MultiplicityModel";
import ReplaceEdge from "./ReplaceEdge";
import SaveToOwnerProfile from "./SaveToOwnerProfile";
import ChooseOwner from "./ChooseOwner";
import RemoveEdges from "./RemoveEdges";
import MaterialsResidues from "./MaterialsResidues/MaterialsResidues";
import NewProjectOrLoad from "./NewProjectOrLoad";
import Products from "./Products";
import DeleteProduct from "./DeleteProduct";
import ChangeProductOnDetail from "./ChangeProductOnDetail";
import GroupChangeDiamHoles from "./GroupChangeDiamHoles";
import {useUpdateTranslations} from "../../../hooks/useUpdateTranslations";

const Modal = () => {
	const show = useSelector(modalsSelectors.getModalShowState);
	const type = useSelector(modalsSelectors.getModalTypeState);
	const file = useSelector(inputsSelectors.getFileProjectState);
	const modalRef = useRef(null);
	useUpdateTranslations();

	useEffect(() => {
	}, [type, show, file]);

	return (
		<>
			<CSSTransition
				in={show && type != "PROCESSING"}
				nodeRef={modalRef}
				timeout={500}
				classNames="modal modal-state"
				unmountOnExit
			>
				<div ref={modalRef}>
					{(() => {
						switch (type) {
							case "IMPORT_PROJECT_1":
								return <ImportProject file={file} />;
							case "ADD_MATERIAL":
								return <AddMaterialAndEdges />;
							case "TRANSPORT_CUT":
								return <TransportCut />;
							case "LINK_MATERIAL":
								return <AddMaterialAndEdges />;
							case "NEW_PROJECT":
								return <NewProject />;
							case "REPLACE_MATERIAL_PROJECT":
								return <ReplaceMaterialProject />;
							case "REPLACE_EDGES_PROJECT":
								return <ReplaceEdgesProject />;
							case "REPLACE_MATERIAL":
								return <ReplaceMaterial />;
							case "REPLACE_EDGE":
								return <ReplaceEdge />;
							case "REMOVE_EDGES":
								return <RemoveEdges />;
							case "CUTTING_PARAMS":
								return <CuttingParams />;
							case "CUSTOMER_WAREHOUSE":
								return <CustomerWarehouse />;
							case "KRONAS_MATERIALS":
								return <KronasMaterials />;
							case "CHOOSE_OWNER":
								return <ChooseOwner />;
							case "SAVE_PROJECT":
								return <SaveProject />;
							case "SAVE_TO_OWNER_PROFILE":
								return <SaveToOwnerProfile />;
							case "MAP_PROJECT":
								return <MapProject />;
							case "MULTIPLICITY":
								return <MultiplicityModel />;
							case "IMPORT_ERROR_MODAL":
								return <ImportErrorModal />;
							case "ADD_FOLDER":
								return <AddFolder />;
							case "GROUP_CHANGE_DIAM_HOLES":
								return <GroupChangeDiamHoles />;
							case "MATERIALS_RESIDUES":
								return <MaterialsResidues />;
							case "QUICK_INPUT_TABLE":
								return <QuickInputTable />;
							case "ADD_DETAIL":
								return <AddDetail />;
							case "ADD_EDJES":
								return <AddEdjes />;
							case "DELETE_PRODUCT":
								return <DeleteProduct />;
							case "PRODUCTS":
								return <Products />;
							case "CHANGE_PRODUCT_ON_DETAIL":
								return <ChangeProductOnDetail />;
							default:
								return "";
						}
					})()}
				</div>
			</CSSTransition>
		</>
	);
};

export default Modal;
