import React from "react";
import {edgeTitle, materialTitle} from "../../../../helpers/helper";
import Languages from "../../../../translation/Languages";
import './style.scss';
import img from "../../../../media/material-test.png";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";

const EdgesHelper = ({detail}) => {
	const construction = useSelector(projectSelectors.getConstruction);

	const edgeTitleForHelper = (edgeId) => {
		if (edgeId !== null) {
			const edge = construction.edges.find((el) => el.index === edgeId)
			return edgeTitle(edge)
		}
		return Languages.getTranslation('none', true)
	}

	const materialTitleChange = () => {
		if (isNaN(detail.material)) {
			return materialTitle(detail.material)
		}
		const material = construction.materials.find((el) => el.index === detail.material)
		return materialTitle(material)
	}

	return <div className="edgesHelper">
		<div className="edgesHelper__material-container">
			<div className="edgesHelper__material-header">
				<div className="edgesHelper__text edgesHelper__material-title" >{Languages.getTranslation('material', true)}:</div>
				<span className="edgesHelper__material-title edgesHelper__text ">№{detail.material.index + 1 || detail.material + 1}</span>
			</div>
			<div className="edgesHelper__material-name">
				<img src={img} alt="material"/>
				<span className="edgesHelper__text2">{`Матеріал: ${materialTitleChange()}`}</span>
			</div>
		</div>
		<div className="edgesHelper__edges">
			<div className="edgesHelper__edge">
				<div className="edgesHelper__edge-container">
					<span className="edgesHelper__edge-container-img">
						<i className=" icon__triangle-up"></i>
					</span>
				{/*<span className="edgesHelper__text">Крайка знизу:</span>*/}
				<span className="edgesHelper__text2">{edgeTitleForHelper(detail.edges.top)}</span>
				</div>
			</div>
			<div className="edgesHelper__edge">
				<div className="edgesHelper__edge-container">
					<span className="edgesHelper__edge-container-img">
						<i className="icon__triangle-down"></i>
					</span>
				{/*<span className="edgesHelper__text">Крайка зверху:</span>*/}
				<span className="edgesHelper__text2">{edgeTitleForHelper(detail.edges.bottom)}</span>
				</div>
			</div>
			<div className="edgesHelper__edge">
				<div className="edgesHelper__edge-container">
					<span className="edgesHelper__edge-container-img">
						<i className="icon__triangle-left"></i>
					</span>
				{/*<span className="edgesHelper__text">Крайка справа:</span>*/}
				<span className="edgesHelper__text2">{edgeTitleForHelper(detail.edges.left)}</span>
				</div>
			</div>
			<div className="edgesHelper__edge">
				<div className="edgesHelper__edge-container">
					<span className="edgesHelper__edge-container-img">
						<i className="icon__triangle-right"></i>
					</span>
				{/*<span className="edgesHelper__text">Крайка зліва:</span>*/}
				<span className="edgesHelper__text2">{edgeTitleForHelper(detail.edges.right)}</span>
				</div>
			</div>
		</div>
	</div>
}

export default EdgesHelper