import React from "react";
import {useSelector} from "react-redux";
import { constructor3dSelectors } from "../../../../redux/constructor";

const Connection = ({connection, editConnection, deleteConnection}) => {
	const mainDetail = useSelector(constructor3dSelectors.getDetailById(connection.mainDetail))
	const targetDetail = useSelector(constructor3dSelectors.getDetailById(connection.targetDetail))

	return(
		<div className="block connections-item">
			<div className="block__header connections-item__header">
				<span className="block__header-title connections-item__header-title">{`${targetDetail.name}->${connection.targetObject} to ${mainDetail.name}->${connection.mainObject}`}</span>
				<a className="block__header-control add-button" onClick={e => editConnection(connection.connectionId)}> &#9998;</a>
				<a className="block__header-control remove-button" onClick={e => deleteConnection(connection.connectionId)}> &times;</a>
			</div>
		</div>
	)

}

export default Connection;