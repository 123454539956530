import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import PrivateTemplate from "views/PrivateTemplate";
import Requests from "api/API";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { modalsOperations, modalsSelectors } from "redux/modals";
import { useSelector } from "react-redux";
import Project from "models/Project";
import { useNavigate } from "react-router-dom";

import Languages from "translation/Languages";
import Loader from "../../components/UI/Loader";
import {panelOperations} from "redux/panel";
import { errorMessageOperations } from '../../redux/errors'
import { toast } from 'react-toastify'
import SubmitModal from "../../components/UI/Modal/SubmitModal";
import {empty} from "../../helpers/helper";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";


const Projects = () => {
  const [folders, setFolders] = useState([]);
  const [idValue, setIdValue] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const navigate = useNavigate();
  const [activeFolder, setActiveFolder] = useState({});
  const [loader, setLoader] = useState(false)
  const [projectId, setProjectId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch();
  const show = useSelector(modalsSelectors.getModalShowState);
  const [prevIdValue, setPrevIdValue] = useState(null);
  const [prevOrderNumber, setPrevOrderNumber] = useState(null);
  useUpdateTranslations();

  useEffect(() => {
    toast.dismiss()
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: "",
      })
    );
    dispatch(
      panelOperations.switchStateForPanel({
        show: false
      })
    )
  }, []);

  const getList = async () => {
    setLoader(true)
    const data = await Requests.Projects.getProjectList().catch((error) =>
        dispatch(errorMessageOperations.switchStateError({ message: error.message, type: 'warn', show: true })));

    if (data.length) {
      setActiveFolder({ projects: data, type: "new" });
    } else {
      setActiveFolder({ projects: [], type: "new" });
    }
    setLoader(false)
  };

  const getProjectById = async (id) => {
    const request = await Requests.Projects.getProjectById(id).catch((error) =>
        dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('project-not-found', true), type: 'warn', show: true })));

    if (!empty(request) && idValue !== "") {
      setActiveFolder({
        projects: [request],
        type: "new",
      });
    }else if(empty(idValue)) {
      getList()
    }
  };
  const getProjectByOrderNumber = async (order) => {
    const request = await Requests.Projects.getProjectByOrderNumber(order).catch((error) =>
        dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('project-not-found', true), type: 'warn', show: true })));

    if (!empty(request) && orderNumber !== "") {
      setActiveFolder({
        projects: [request],
        type: "new",
      });
    }else if(empty(orderNumber)) {
      getList()
    }
  };
  const getListFolders = async () => {
    setLoader(true)
    const data = await Requests.Projects.getUserFolders().catch((error) => {
      dispatch(errorMessageOperations.switchStateError(
          { message: error.message, type: 'warn', show: true }))
    });

    if (data && data.data.length) {
      setFolders(data.data);

      if (activeFolder.type) {
        getList();
        return;
      }

      if (activeFolder) {
        data.data.forEach((folder) => {
          if (folder.id == activeFolder.id) {
            setActiveFolder(folder);
          }
        });
      }
    }
    setLoader(false)
  };

  const addFolder = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: true,
        type: "ADD_FOLDER",
      })
    );
  };

  const loadProject = async (id) => {
    setLoader(true)
    Project.loadProject(id, dispatch)
      .then(() => Project.validateProjectDetails())
      .then(() => {
          dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
        setTimeout(()=> {
          setLoader(false)
        }, 500)
          navigate("/details")
      })
      .catch(e => console.log(e));

  };

  const removeHandler = (id) => {
    setProjectId(id)
    setShowModal(true)
  }

  const remove = async (id) => {
    const data = await Requests.Projects.deleteProject(id).then(() => {
          getListFolders()
          dispatch(errorMessageOperations.switchStateError(
                { message: Languages.getTranslation('project-delete', true), type: 'success', show: true }))
        }).catch((error) => {
          dispatch(errorMessageOperations.switchStateError(
              { message: error.message, type: 'warn', show: true }))
        });
    //getList()
  };

  const removeFolder = async (id) => {
    const data = await Requests.Projects.deleteFolder(id).then(() => {
      getListFolders()
      dispatch(errorMessageOperations.switchStateError(
            { message: Languages.getTranslation('folder-delete', true), type: 'success', show: true }))
    }).catch((error) => {
      dispatch(errorMessageOperations.switchStateError(
          { message: error.message, type: 'warn', show: true }))
    })
  };

  const loadProjectByFolder = async (folder) => {
    setLoader(true)
    const data = await Requests.Projects.getUserFoldersById(folder.id).catch((error) => {
      dispatch(errorMessageOperations.switchStateError(
          { message: error.message, type: 'warn', show: true }))
    });
    setActiveFolder({ projects: data.data, id: folder.id });
    setLoader(false)
  };

  useEffect(() => {
    if (!show) {
      getList();
      getListFolders();
    }
  }, [show]);

  const changeHandler = (e) => {
    setIdValue(e.target.value);
  };
  const changeHandlerOrderNumber = (e) => {
    setOrderNumber(e.target.value);
  };

  const handleKeyDown = (e, type) => {
    if (e.key === "Enter") {
      if (type === 'id' && idValue !== prevIdValue) {
        setPrevIdValue(idValue);
        getProjectById(idValue);
      } else if (type !== 'id' && orderNumber !== prevOrderNumber) {
        setPrevOrderNumber(orderNumber);
        getProjectByOrderNumber(orderNumber);
      }
    }
  };
  return (
    <PrivateTemplate>
      <Layout.Content>
        {loader ? <div className="projects" style={{height: 'calc(100vh - 80px)'}}>
          <div className='projects__center'><Loader /></div></div> : <div className="projects">
          <div className="project-type">
            <h2> {Languages.getTranslation("my-projects", true)}</h2>
            <div className="project-actions">
              <div className="project-actions__search">
                  <span className="project-actions__input-title">
                    {`ID ${Languages.getTranslation("of-the-project", true)}`}
                  </span>
                <input
                    type="number"
                    className="project-actions__input"
                    value={idValue}
                    onChange={(e) => changeHandler(e)}
                    onKeyDown={(e) => handleKeyDown(e, 'id')}
                />
              </div>
              <div  className="project-actions__search">
                  <span className="project-actions__input-title">
                    {Languages.getTranslation("order-number-project", true)}
                  </span>
                <input
                    type="number"
                    className="project-actions__input"
                    value={orderNumber}
                    onChange={(e) => changeHandlerOrderNumber(e)}
                    onKeyDown={(e) => handleKeyDown(e, 'order')}
                />
              </div>

            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="projects-list">
            <div className="projects-params">
              <div className="name">Папки</div>

              <div className="items">
                <div
                  onClick={() => getList()}
                  className={activeFolder.type === "new" ? "active" : ""}
                >
                  {Languages.getTranslation("new-projects", true)}
                </div>
                {folders.length
                  ? folders.map((folder, key) => {
                    return (
                      <div
                        key={key}
                        onClick={() => loadProjectByFolder(folder)}
                        className={
                          activeFolder.id == folder.id ? "active" : ""
                        }
                      >
                        {folder.name}
                        <DeleteOutlined
                          title={Languages.getTranslation("delete", true)}
                          onClick={() => removeFolder(folder.id)}
                        />
                      </div>
                    );
                  })
                  : ""}
              </div>

              <div className="add-project">
                <button onClick={addFolder}>{Languages.getTranslation('add-folder1', true)}</button>
              </div>
            </div>

            <div className="projects-items">
              {activeFolder &&
              Object.keys(activeFolder).length &&
              activeFolder.projects &&
              activeFolder.projects.length ? (
                <table border="0" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>{Languages.getTranslation('order-numberR', true)}</th>
                      <th>{Languages.getTranslation('name', true)}</th>
                      <th>{`ID ${Languages.getTranslation('order', true)}`}</th>
                      <th>{Languages.getTranslation('Date-of-creation', true)}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {activeFolder.projects && activeFolder.projects.length
                      ? activeFolder.projects.map((project, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ fontSize: "12px", textAlign: 'center' }}># {key + 1}</td>
                            <td style={{textAlign: 'center'}}>№ {project.id}</td>
                            <td style={{textAlign: 'center'}}>{project.order_number}</td>
                            <td>{project.name}</td>
                            <td style={{textAlign: 'center'}}>{project.order_id}</td>
                            <td style={{textAlign: 'center'}}>
                              {new Date(project.created_at).toLocaleString()}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                fontSize: "14px",
                                paddingRight: "10px",
                              }}
                            >
                              <DownloadOutlined
                                style={{ paddingRight: "8px" }}
                                onClick={() => loadProject(project.id)}
                                title={Languages.getTranslation('load-project', true)}
                              />
                              <DeleteOutlined
                                title={Languages.getTranslation('delete', true)}
                                onClick={() => removeHandler(project.id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                    </tbody>
                  </table>
                ) : <div className='projects__center'>Немає проєктів...</div>}
            </div>
          </div>
        </div>}
        {showModal && <SubmitModal setShowSubmitModal={setShowModal} submitModalText={Languages.getTranslation('want-to-delete-project', true)} submitAction={() => remove(projectId)} isRemoveProject={true}/>}
      </Layout.Content>
    </PrivateTemplate>
  );
};

export default Projects;
