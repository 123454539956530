import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {projectSelectors} from "./../../../../redux/project";
import {constructor3dSelectors} from "./../../../../redux/constructor";
import Panel from "../../layuot/panel";
import ProjectDetail from "../ProjectDetail";
import {empty, isset} from "../../../../helpers/helper";
import './styles.scss';


const ProjectDetails = () => {

	const projectDetails = useSelector(projectSelectors.getDetails);
	const usedDetails = useSelector(constructor3dSelectors.getCDetails);
	const products = useSelector(constructor3dSelectors.getProducts)
	const productsObject = {};

	// useEffect(() => {
		projectDetails.forEach(detail => {
			if(empty(productsObject[detail.productId])) {
				productsObject[detail.productId] = {
					name: products.find(el => el.id === detail.productId).name,
					details: [detail]
				};
			} else {
				productsObject[detail.productId].details.push(detail)
			}

		})
	// }, [projectDetails]);

	// useEffect(() => {
	// 	console.log(usedDetails)
	// }, [usedDetails])

	if(empty(productsObject)) return null;

	return (
		<Panel className="c-details__project c-details__list p__content" title='Деталі проекту'>
			{ Object.keys(productsObject).map(product => {
				return (
          <div key={product}>
            <div>{productsObject[product].name}</div>
            <div>
              {productsObject[product].details.map(detail => {
                const isUsed = usedDetails.filter(el => el.detailId === detail.detailId);
                detail.disabled = !empty(isUsed) && isUsed.length >= detail.count;
                return (<ProjectDetail
                  key={detail.detailId}
                  detail={detail}
                  used={isUsed.length}
                />)
              })}
            </div>
          </div>
        )
			}) }

		</Panel>
	)
}

export default ProjectDetails;