import React from "react";
import img from "../../../../../media/material-add-img-test.svg";
import Languages from "../../../../../translation/Languages";
import {edgeTitle} from "../../../../../helpers/helper";
const EdgeItem = ({	edge, index }) => {
	const name = edgeTitle(edge)

	return (
		<div className="quickInputTable__info-body-item">
			<div className="quickInputTable__info-body-item-number">{index + 1}</div>
			<img alt={name}
				src={img}
				className="quickInputTable__info-body-item-img"
			/>
			<div className="quickInputTable__info-body-item-name">
				{ name }
			</div>
		</div>
	);
}
export default EdgeItem;