import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import {useNavigate} from "react-router-dom";
import "./index.scss";
import detail_img from "../../../../media/detail_img.svg";
import Languages from "../../../../translation/Languages";
import {empty, isFractional, checkForMilling, checkForAnyHandles, materialTitle} from "../../../../helpers/helper";
import {modalsSelectors} from "redux/modals";
import {errorMessageOperations} from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import {Tooltip} from "react-tooltip";
import {multiplicityArray} from "../../Modal/MultiplicityModel";
import ItemHelper from "../../Modal/QuickInputTable/ItemHelper";
import IntersectionSVG from '../../../../hooks/useIntersectionSVG'
import {toast} from "react-toastify";

const _errors = new Errors([])
import _Details from "../../../../db/_Details";
import drill from "../../../../media/drill.svg";
import saw from "../../../../media/saw.svg";
import cutter from "../../../../media/cutter.svg";
import multiplicityImg from "../../../../media/multiplicity.svg";
import TextureAndCutItem from "../../TextureAndCutItem/TextureAndCutItem"
import img from "../../../../media/material-test.png";

const detailsDb = new _Details();
import imgMaterial from "../../../../media/material-test.png";
import ProductHelper from "../../Modal/ProductHelper";
import Contour from '../../SvgSketch'
import {userSelectors} from "redux/user";
import EdgesHelper from "../../Modal/EdgesHelper";

const TableDetails = ({details, selectedDetails, setSelectedDetails, setDetails, setDeleteDetail, filteredActive}) => {
	const construction = useSelector(projectSelectors.getConstruction);
	const [isAllDetailsSelected, setIsAllDetailsSelected] = useState(false);
	const [sortType, setSortType] = useState('');
	const [currentDetail, setCurrentDetail] = useState({});
	const [showTopButton, setShowTopButton] = useState(false);
	const permissions = useSelector(userSelectors.permissions);

	const modalState = useSelector(modalsSelectors.getModalShowState);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [yPoint, setYPoint] = useState(90);
	const [leftPoint, setLeftPoint] = useState(507);
	const currentEdges = useSelector(projectSelectors.getEdges)
	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const detailCurrent = useSelector(projectSelectors.getDetail);
	const [isOrderConform, setIsOrderConform] = useState(false)
	const [currentDraggedItem, setCurrentDraggedItem] = useState(null);

	useEffect(() => {
		if (modalState) {
			setIsAllDetailsSelected(false)
		}
	}, [modalState])

	useEffect(() => {
		if (!selectedDetails.length) {
			setIsAllDetailsSelected(false);
		}
	}, [selectedDetails]);

	useEffect(() => {
		if (isAllDetailsSelected) {
			setSelectedDetails([...details]);
		} else {
			setSelectedDetails([]);
		}
	}, [isAllDetailsSelected]);

	useEffect(() => {
		const handleScroll = () => {
		  if (window.scrollY > 100) {
			setShowTopButton(true);
		  } else {
			setShowTopButton(false);
		  }
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);

	const selectDetailHandler = (detail) => {
		if (selectedDetails.includes(detail)) {
			const filteredDetails = selectedDetails.filter(
				(el) => el._id !== detail._id
			);
			setSelectedDetails([...filteredDetails]);
		} else {
			setSelectedDetails([...selectedDetails, detail]);
		}
	};

	const setAllDetails = () => {
		if (empty(details)) {
			return
		}
		setIsAllDetailsSelected(!isAllDetailsSelected)
	}

	const activeCheckBox = (type, detail) => {
		if (type === "checkbox") {
			return selectedDetails.includes(detail)
				? "detailsTab__checkbox detailsTab__checkbox_active"
				: "detailsTab__checkbox";
		} else {
			return selectedDetails.includes(detail)
				? "checkbox__icon checkbox__icon_active"
				: "checkbox__icon";
		}
	};
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};
	const setDetailToStore = (detail, key) => {
		toast.dismiss()
		if (!detail.checkGabarites()) {
			_errors.setError(`${Languages.getTranslation("Enter-part", true)}`, 'error', true )
			return;
		}

		if (detail.material === null) {
			_errors.setError(`${Languages.getTranslation("material-to-the-part", true)}`,'error', true)

			return;
		}
		// sceneModel.clear();

		dispatch(
			projectOperations.setDetail({
				detail: detail,
			})
		);
		dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
		const numId =  detail.order || key +1;
		detail.build()
			.then(() => navigate(`/processes/${construction.projectData.id}/${numId}`));
	};

	const remove = (detail) => {
		setDeleteDetail(detail)

	};

	const styleHeader = (type) => {
		if (type === sortType) {
			return `detailsTab__header-item detailsTab__data-item detailsTab__header-item_active`
		}
		return `detailsTab__header-item detailsTab__data-item`
	}

	const sortingDetailsList = (type) => {
		switch (type) {
			case 'count':
				const sortDetailsCount = details.sort((a, b) => b.count - a.count)
				setDetails([...sortDetailsCount])
				setSortType('count')
				break
			case 'height':
				const sortDetailsHeight = details.sort((a, b) => b._l - a._l)
				setDetails([...sortDetailsHeight])
				setSortType('height')
				break
			case 'width':
				const sortDetailsWidth = details.sort((a, b) => b._h - a._h)
				setDetails([...sortDetailsWidth])
				setSortType('width')
				break

			case 'multiplicity':
				const sortDetailsMultiplicity = details.sort((a, b) => b.multiplicity - a.multiplicity)
				setDetails([...sortDetailsMultiplicity])
				setSortType('multiplicity')
				break
			case 'material':
				const sortDetailsMaterial = details.sort((a, b) => b.material - a.material)
				setDetails([...sortDetailsMaterial])
				setSortType('material')
				break
			case 'thickness':
				const sortDetailsThickness = details.sort((a, b) => b.w - a.w)
				setDetails([...sortDetailsThickness])
				setSortType('thickness')
				break
			case 'texture':
				const sortDetailsTexture = details.sort((a, b) => b.isRotateTexture - a.isRotateTexture)
				setDetails([...sortDetailsTexture])
				setSortType('texture')
				break
			case 'products':
				const sortDetailsProducts = details.sort((a, b) => b.productId - a.productId)
				setDetails([...sortDetailsProducts])
				setSortType('products')
				break
			case 'default':
				const defaultSort = details.sort((a, b) => {
					if (!empty(a.order)  && !empty(b.order)) {
						return a.order - b.order;
					} else {
						return a.id - b.id;
					}
				});
				setDetails([...defaultSort])
				setSortType('default')
				break
		}
	}

   // Sorting
	const handleDragStart = (e,detail) => {
		e.dataTransfer.setData('order', detail?.order);
		e.dataTransfer.setData('dragId', detail.id);
		setCurrentDraggedItem(detail);
		e.currentTarget.style.backgroundColor = '#F0E4ED';
		e.currentTarget.style.transform = 'scale(1.12)'
		e.currentTarget.style.transition = 'background-color 0.3s ease, border 0.3s ease, transform 0.3s ease'; // Переход
	}

	const handleDrop = (e, targetOrderId) => {
		e.currentTarget.style.backgroundColor = '';
		e.currentTarget.style.border = '';
		e.currentTarget.style.transform = '';

		e.preventDefault();
		const draggingOrderId = e.dataTransfer.getData('order');
		const draggingItem = details.find(detail => detail.order === Number(draggingOrderId));
		const targetItem = details.find(detail => detail.order === targetOrderId);

		const draggingIndex = details.indexOf(draggingItem);
		const targetIndex = details.indexOf(targetItem);


		if (draggingIndex !== -1 && targetIndex !== -1) {
			const newDetails = [...details];

			const [removed] = newDetails.splice(draggingIndex, 1);
			newDetails.splice(targetIndex, 0, removed);

			newDetails.forEach((item, index) => {
				item.order = index + 1;
			});

			setDetails(newDetails);

			Promise.all(newDetails.map((item, index) =>
				detailsDb.updateDetail(item.id, "order", index + 1)))

		}
	}

	useEffect(() => {
		let orderSet = Array(details.length).fill(0);
		let maxOrder = 0;
		details.forEach((d, index) => {
			if(empty(d.order)){
				d.order = index + 1;
			}
			if(orderSet[d.order - 1] !== 0){
				d.order = orderSet.lastIndexOf(0) + 1;
			}
			orderSet[d.order - 1] = 1;
			maxOrder = Math.max(maxOrder, d.order);
		});
		setDetails([...details.sort((a, b) => a.order - b.order)]);
		setIsOrderConform(true);
	},[modalState]);

	const dragOverHandler = (e) => {
		e.preventDefault()
	}
	const dragEndHandler =(e) => {
		e.preventDefault();
		e.currentTarget.style.backgroundColor = '';
		e.currentTarget.style.border = '';
		e.currentTarget.style.transform = ''; // Сброс масштабирования

		setCurrentDraggedItem(null);
	}
	const materialTitleChange = (neededDetail) => {
		if (isNaN(neededDetail.material)) {
			return materialTitle(neededDetail.material)
		}
		const material = currentMaterials.find((el) => el.index === neededDetail.material)
		return materialTitle(material)
	}

	return (
		<>
			{
				details && details.length > 0 ? <div className="detailsTab">
					<div className="detailsTab__header">

						<div className="detailsTab__info-container" style={{paddingTop: 10, paddingBottom: 7}}>
							<div
								className={
									isAllDetailsSelected
										? "detailsTab__checkbox detailsTab__checkbox_active"
										: "detailsTab__checkbox"
								}
								onClick={setAllDetails}
							>
								<i
									className={
										isAllDetailsSelected ? "checkbox__icon checkbox__icon_active" : ''
									}
								></i>
							</div>
							<div className="detailsTab__header-item detailsTab__number"
								 onClick={() => sortingDetailsList('default')}>№
							</div>
							<div className="detailsTab__header-item detailsTab__img">Ескіз</div>
							<div className="detailsTab__header-item detailsTab__name">
								{Languages.getTranslation("name", true)}
							</div>
						</div>
						<div className="detailsTab__data-container">
							<div className={styleHeader('height')} onClick={() => sortingDetailsList('height')}>
								{Languages.getTranslation("height", true)}
							</div>
							<div className={styleHeader('width')} onClick={() => sortingDetailsList('width')}>
								{Languages.getTranslation("width", true)}
							</div>
							<div className={styleHeader('count')} onClick={() => sortingDetailsList('count')}>
								{Languages.getTranslation("amount", true)}
							</div>
							<div className="detailsTab__header-item detailsTab__data-item">
								{Languages.getTranslation("processes", true)}
							</div>
							<div className={styleHeader('material')} onClick={() => sortingDetailsList('material')}>
								{Languages.getTranslation("material", true)}
								{filteredActive.isMaterialChecked && <span className="filter-icon"/>}
							</div>
							<div className={styleHeader('thickness')} onClick={() => sortingDetailsList('thickness')}>
								{Languages.getTranslation("thickness", true)}
								{filteredActive.isThicknessChecked && <span className="filter-icon"/>}
							</div>
							<div className={styleHeader('products')} onClick={() => sortingDetailsList('products')}>
								{Languages.getTranslation("products", true)}
								{filteredActive.isThicknessChecked && <span className="filter-icon"/>}
							</div>
							<div className={styleHeader('texture')} onClick={() => sortingDetailsList('texture')}>
								{Languages.getTranslation("texture", true)}
								{filteredActive.isTextureChecked && <span className="filter-icon"/>}
							</div>
							{/*<div className={styleHeader('cutting')}>*/}
							{/*	{Languages.getTranslation("cutting", true)}*/}
							{/*</div>*/}
							<div className="detailsTab__header-item detailsTab__data-item">
								{Languages.getTranslation("edge", true)}
								{filteredActive.isEdgesChecked && <span className="filter-icon"/>}
							</div>
						</div>
					</div>
					<div className="detailsTab__body">
						{isOrderConform && details.length ? (
							details.map((detail, key) => {
								const arrErr = detail?.error?.allErrorsMessage
								let type = ''
								if (!empty(arrErr)) {
									const priority = { 'error': 3, 'warning': 2, 'success': 1 };
									let maxPriority = 0;

									arrErr.forEach(el => {
										let currentPriority = priority[el.type] || 0;

										if (currentPriority > maxPriority) {
											type = el.type;
											maxPriority = currentPriority;
										}
									});
									arrErr.sort((a, b) => {
										const types = { 'error': 3, 'warning': 2, 'success': 1 };
										return types[b.type] - types[a.type];
									});
								}


								return (
									<div
										key={detail.id}
										id={key}
										className={`detailsTab__body-item ${detail.name === detailCurrent?.name ? 'lastDetailBackground': 'defaultBackground'} ${detail?.error?.allErrorsMessage?.length > 0 ? 'havError' : ''}`}>
										<div
											className="detailsTab__info-container"

										>
											<div
												className={`${activeCheckBox("checkbox", detail)} detailsTab__checkbox-margin`}												onClick={() => selectDetailHandler(detail)}
											>
												<i className={activeCheckBox("icon", detail)}></i>
											</div>
											<div className="detailsTab__info-container2"
												 draggable
												 onDragStart={(e) => handleDragStart(e, detail)}
												 onDrop={(e) => handleDrop(e, detail?.order, detail.id)}
												 onDragOver={(e) => dragOverHandler(e)}
												 onDragEnd={(e) => dragEndHandler(e)}
											>
												<span className=" detailsTab__number">{key + 1}</span>
												<div className=" detailsTab__img"
													 onClick={() => setDetailToStore(detail, key)}>
													<IntersectionSVG detail={detail}/>
												</div>

												<div className="detailsTab__name">{detail.name}</div>
											</div>
										</div>
										<div className="detailsTab__data-container">
											<div
												className="detailsTab__data-item">{!isFractional(Number(detail.l)) ? Math.round(Number(detail.l)) : Number(detail.l).toFixed(1)}</div>
											<div
												className="detailsTab__data-item">{!isFractional(Number(detail.h)) ? Math.round(Number(detail.h)) : Number(detail.h).toFixed(1)}</div>
											<div className="detailsTab__data-item">{detail.count}</div>

											<div className="detailsTab__data-item">
												{!empty(detail.holes) &&
													<span title='Сверління' className="pointer">
													<img src={drill} alt="Сверління"/>
												</span>
												}
												{(!empty(detail.grooves) || !empty(detail.rabbets)) &&

                                                    <span title={Languages.getTranslation('Grooves-Quarters', true)}
                                                          className="pointer">
														<img src={saw}
                                                             alt={Languages.getTranslation('Grooves-Quarters', true)}/>
												</span>
                                                }
                                                {checkForMilling(detail) &&
                                                    <span title={Languages.getTranslation('Milling', true)}
                                                          className="pointer">
													   <img src={cutter}
                                                            alt={Languages.getTranslation('Milling', true)}/>
										            </span>
                                                }
                                                {!empty(detail.multiplicity) &&
                                                    <span title={Languages.getTranslation('multiplicity', true)}
                                                          className="pointer"
                                                          data-tooltip-id={`${detail.multiplicity !== null && detail.multiplicity?.type > 0 ? `multiplicity-tooltip-${key}` : ''}`}>
														<img src={multiplicityImg}
                                                             alt={Languages.getTranslation('multiplicity', true)}/>
												  </span>
                                                }
                                                {!checkForAnyHandles(detail) &&
                                                    <span title={Languages.getTranslation('dont-have-proc', true)}
                                                          className="pointer">
													   —
										            </span>
                                                }
                                            </div>

											<div className="detailsTab__data-item pointer"
												 data-tooltip-id={`material-tooltip-${key}`}>
												{construction._materials.findIndex(
													(el) => el.index === detail.material
												) + 1}
											</div>
											<div className="detailsTab__data-item">{detail.w}</div>
											<div style={{cursor: 'pointer'}} className="detailsTab__data-item"  data-tooltip-id={`productHelper-tooltip-${key}`}>
												{construction.products.findIndex(el => el.id === detail.productId) + 1}</div>
											<TextureAndCutItem
												materialType={construction.materials.find(el => detail.material === el.index)?.type}
												detailItem={detail.isRotateTexture} id={detail.id} type="texture"/>
											{/*<TextureAndCutItem detailItem={detail.isCutting}  id={detail.id} type="cutting"/>*/}
											<div className="detailsTab__data-item pointer" data-tooltip-id={`edgesHelper-tooltip-${key}`}>
												<div className="detailsTab__edges">
													<i
														className={`detailsTab__edges-top ${!empty(detail.edges.top) && 'detailsTab__edges-top_active'}`}></i>
													<i
														className={`detailsTab__edges-right ${!empty(detail.edges.right) && 'detailsTab__edges-right_active'}`}></i>
													<i
														className={`detailsTab__edges-bottom ${!empty(detail.edges.bottom) && 'detailsTab__edges-bottom_active'}`}></i>
													<i
														className={`detailsTab__edges-left ${!empty(detail.edges.left) && 'detailsTab__edges-left_active'}`}></i>
												</div>
											</div>
										</div>
										{!empty(arrErr) &&
											<div className='detailsTab__icon-error pointer'
												 data-tooltip-id={detail?.error?.allErrorsMessage.length > 0 ? `my-tooltip-${key}` : ''}>
												<svg viewBox="0 0 24 24" width="18px" height="18px"
													 fill={`var(--toastify-icon-color-${type})`}>
													<path
														d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
												</svg>
											</div>}
										<button
											disabled={!permissions.includes("KM_deleteDetail")}
											 className={`btn ${detail?.error?.allErrorsMessage.length > 0
											? 'detailsTab__btn-delete-error' : 'detailsTab__btn-delete'}`}
												onClick={() => remove(detail)}>
											<i className="btn__icon-delete"></i>
										</button>
										<Tooltip
											key={`e-${key}`}
											className='tooltip-error'
											id={`my-tooltip-${key}`}
											place="left-center"
											variant='light'
											zIndex={1000}
											// openOnClick={true}
										>
											<div className='tooltip-error_body'>
												{detail?.error?.allErrorsMessage.map((err, idx) => {
													let color = '';
													if(err.type === 'error'){ color = '#E8C2C2FF'}
													if(err.type === 'warning'){ color = '#EED270'}
													if(err.type === 'success'){ color = '#92CD77' }
													return (<div className='tooltip-error_body_item'
																 style={{border: `2px solid ${color}`}} key={`error-${idx}`}>
														<svg  viewBox="0 0 24 24" width="18px" height="18px"
															 fill={`var(--toastify-icon-color-${err.type})`}>
															<path
																d="M11.983 0a12.206 12.206 0 00-8.51 3.653A11.8 11.8 0 000 12.207 11.779 11.779 0 0011.8 24h.214A12.111 12.111 0 0024 11.791 11.766 11.766 0 0011.983 0zM10.5 16.542a1.476 1.476 0 011.449-1.53h.027a1.527 1.527 0 011.523 1.47 1.475 1.475 0 01-1.449 1.53h-.027a1.529 1.529 0 01-1.523-1.47zM11 12.5v-6a1 1 0 012 0v6a1 1 0 11-2 0z"></path>
														</svg>
														<div className='tooltip-error_body_item_text'>
															{err.message}
														</div>
														<br/>
													</div>)
												})}
											</div>
										</Tooltip>


										<Tooltip
											key={`mat-${key}`}
											className='mat-tooltip'
											id={`material-tooltip-${key}`}
											place="top-center"
											variant='light'
											zIndex={1000}
										>
											<div
												className="itemHelper__material-container detailsTab__materials-tooltip">
												<div className="itemHelper__material-header">
													<div
														className="itemHelper__text itemHelper__material-title">{Languages.getTranslation('material', true)}:
													</div>
													<span
														className="itemHelper__material-title itemHelper__text ">№{detail.material.index + 1 || detail.material + 1}</span>
												</div>
												<div className="itemHelper__material-name">
													<img src={imgMaterial} alt="material"/>
													<span
														className="itemHelper__text2">{`Матеріал: ${materialTitleChange(detail)}`}</span>
												</div>
											</div>
										</Tooltip>

										<Tooltip
											key={`productHelper-${key}`}
											className='productHelper-tooltip'
											id={`productHelper-tooltip-${key}`}
											place="bottom-start"
											variant='dark'
											zIndex={1000}
										>
											<ProductHelper productId={detail.productId}/>
										</Tooltip>

										<Tooltip
											key={`edgesHelper-${key}`}
											className='edgesHelper-tooltip'
											id={`edgesHelper-tooltip-${key}`}
											place="top-start"
											variant='dark'
											zIndex={1000}
										>
											<EdgesHelper detail={detail}/>
										</Tooltip>

										<Tooltip
											key={`m-${key}`}
											className='tooltip-multiplicity'
											id={`multiplicity-tooltip-${key}`}
											place="right-center"
											variant='light'
											// openOnClick={true}
										>
											<div className='tooltip-multiplicity_body'>
												<div className='tooltip-multiplicity_header'>
													{Languages.getTranslation("stitching-type", true)}
												</div>
												{multiplicityArray.sort((a, b) => a.number - b.number).map((i, index) => {
													if (index > 0) {
														return (
															<div key={index}
																 className={`tooltip-multiplicity_body_wrapper ${
																	 detail.multiplicity?.type === i.number ? 'selectedM' : ''}`}>
																<div className="tooltip-multiplicity_body_wrapper_sub">
																	<div
																		className="tooltip-multiplicity_body_wrapper_item">{`${i.number}. `}</div>
																	<div
																		className="tooltip-multiplicity_body_wrapper_item">{i.nameMin}</div>
																</div>
																<img className="tooltip-multiplicity_body_wrapper_img"
																	 src={i.img} alt={i.name}/>
															</div>
														)
													}
												})}
											</div>
										</Tooltip>
									</div>
								);
							})
						) : (
							<div></div>
						)}
					</div>
				</div> : <div></div>
			}
			{showTopButton && (<div className="button-top">
					<div className='button-top_block' onClick={scrollToTop}>
						<div className='button-top_block-angle'></div>
						<span>{Languages.getTranslation("to-the-top", true)}</span>
					</div>
				</div>
			)}
		</>
	);
};

export default TableDetails;
