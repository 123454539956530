import ACTIONS from "./actions";

const changeLang = ({lang}) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.LangStateSwitch(lang));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  changeLang,
};
