import gsap from 'gsap'

export default class Animations {
	static smoothPositionForMesh(mesh = null, data = {}) {
		return gsap.to(mesh.position, data)
	}

	static smoothScaleForMesh(mesh = null, data = {}) {
		return gsap.to(mesh.scale,data)
	}

	static smoothTargetForMesh(mesh=null, data={}) {
		return gsap.to(mesh.target, data)
	}

	static fromToAnimation(mesh, data1, data2) {
		return gsap.fromTo(mesh, data1, data2)
	}

	static gsapAnimationForMesh(mesh, proc=null, data) {
		return gsap.to(proc ? mesh[proc] : mesh, data)
	}
}

