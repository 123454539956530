import './style.scss';
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations, modalsSelectors} from "redux/modals";
import GroupChangeDiamHolesItem from "./GroupChangeDiamHolesItem";
import {useEffect, useState} from "react";
import {empty} from "../../../../helpers/helper";
import {toastSuccess} from "../../../../helpers/toasts";
import {holesParamsForEdgeSide, holesParamsForFace} from "./config";
import Project from "../../../../models/Project";

const GroupChangeDiamHoles = () => {
  const dispatch = useDispatch()
  const details = useSelector(modalsSelectors.getModalIsEdit);
  const [holes, setHoles] = useState([])
  const [holesEdgeSides, setHolesEdgeSides] = useState([])

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const createUniqueArray = (arr, diam = true, side = false) =>
    arr.reduce((acc, current) => {
      const x = acc.find(item => {
        if (diam && side) {
          return item.diam === current.diam && item.side === current.side
        }
        if (side) {
          return item.side === current.side
        }
        return item.diam === current.diam
      });
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);


  useEffect(() => {
    const getClosestFloor = (arr, num) => {
      if (arr.some(item => item.value === num)) {
        return num;
      }

      return arr.reduce((prev, curr) => {
        let prevValue = prev ? prev.value : undefined;
        let currValue = curr ? curr.value : undefined;

        if (prevValue === undefined) {
          return curr;
        } else {
          return (Math.abs(currValue - num) < Math.abs(prevValue - num) ||
            (Math.abs(currValue - num) === Math.abs(prevValue - num) && currValue < prevValue)) ? curr : prev;
        }
      }).value;
    }

    let arrHoles = []
    if (!empty(details)) {
      details.forEach(detail => {
        if (!empty(detail.holes)) {
          detail.holes.forEach(hole => arrHoles.push({
            side: hole.side,
            diam: hole.diam,
            neededDiam: getClosestFloor(!['left', 'right', 'top', 'bottom'].includes(hole.side) ? holesParamsForFace : holesParamsForEdgeSide, hole.diam)
          }))
        }
      })
    }
    if (!empty(arrHoles)) {
      const uniqueObjects = createUniqueArray(arrHoles, true, true);

      setHoles(uniqueObjects.filter(item => ['front', 'back'].includes(item.side)))
      setHolesEdgeSides(uniqueObjects.filter(item => ['left', 'right', "top", 'bottom'].includes(item.side)))
    }
  }, []);

  const updateDetails =  () => {
    return Promise.all(details.map(detail => {
      detail.error.dropErrors()
      return Promise.all(detail.holes.map(hole => {
      if (hole) {
        hole.isErrorText = ''
        let neededHole;
        if (['front', 'back'].includes(hole.side)) {
          neededHole = holes.find(_hole => hole.diam === _hole.diam);
        } else {
          neededHole = holesEdgeSides.find(_hole => hole.diam === _hole.diam);
        }
        if (neededHole) {
          hole.diam = neededHole.neededDiam;
        }
        return hole.updateDb();
      }
    }))}));
  }

  const saveHandler = () => {
    updateDetails()
      .then(() => Project.validateProjectDetails())
      .then(() => {
        toastSuccess(Languages.getTranslation('success-all-holes-changed', true));
        return Promise.resolve();
      })
      .then(() => close());
  };

  return <div className='groupChangeDiamHoles'>
    <div className="groupChangeDiamHoles__header">
      <h3 className="groupChangeDiamHoles__header-title">{Languages.getTranslation('group-change-diamHoles', true)}</h3>
      <CloseBtn handler={close}/>
    </div>
    <div className="groupChangeDiamHoles__main">
      <div className="groupChangeDiamHoles__main-section">
        <h4 className="groupChangeDiamHoles__main-title">{Languages.getTranslation('holes-front-back', true)}</h4>
        {empty(holes) ? <div className="groupChangeDiamHoles__noneHoles">
          <span className="groupChangeDiamHoles__noneHoles-text">{Languages.getTranslation('none-holes', true)}</span>
        </div> : <div className="groupChangeDiamHoles__main-list">
          {createUniqueArray(holes).sort((a, b) => a.diam - b.diam).map(hole =>
            <GroupChangeDiamHolesItem
              key={hole.diam}
              hole={hole}/>)}

        </div>}
      </div>
      <div className="groupChangeDiamHoles__main-section">
        <h4 className="groupChangeDiamHoles__main-title">{Languages.getTranslation('holes-edge', true)}</h4>
        {empty(holesEdgeSides) ? <div className="groupChangeDiamHoles__noneHoles">
          <span className="groupChangeDiamHoles__noneHoles-text">{Languages.getTranslation('none-holes', true)}</span>
        </div> : <div className="groupChangeDiamHoles__main-list">
          {createUniqueArray(holesEdgeSides).sort((a, b) => a.diam - b.diam).map(hole =>
            <GroupChangeDiamHolesItem
              key={hole.diam}
              hole={hole}/>)}

        </div>}
      </div>
    </div>
    <div className="groupChangeDiamHoles__footer">
      <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
      <DefaultBtn title={Languages.getTranslation('save', true)} handler={saveHandler}/>
    </div>
  </div>
}

export default GroupChangeDiamHoles;