import Languages from "../translation/Languages";

const CONSTANTS = {
  // Цвета для кромок
  colors: [
    { color: "green", name: ` 0.6 ${Languages.getTranslation("mm")}`, sign: "<=" },
    { color: "orange", name: ` 0.8 ${Languages.getTranslation("mm")}`, sign: "<=" },
    { color: "lightblue", name: ` 1.0 ${Languages.getTranslation("mm")}`, sign: "<=" },
    { color: "blue", name: ` 2.0 ${Languages.getTranslation("mm")}`, sign: "<" },
    { color: "purple", name: ` 2.0 ${Languages.getTranslation("mm")}`, sign: "=" },
  ],
};

export default CONSTANTS;
