import { createAction } from "@reduxjs/toolkit";
import actionType from './actionType'

const TempDetail = createAction(actionType.tempDetail)
const TempDetails = createAction(actionType.tempDetails)
const TempProject = createAction(actionType.tempProject)

export default {
    TempDetail,
    TempDetails,
    TempProject
}