import './style.scss';
import DefaultBtn from "../Buttons/DefaultBtn";
import Languages from "../../../translation/Languages";
import React from "react";

const ImportBlockModel = ({title, text, formats, uploadFileHandler, importProject, inputRef, selectedType}) => {

    return <div className="importBlockModel">
        <h3 className="importBlockModel__title">{title}</h3>
        <div className="importBlockModel__container">
            <div className="importPage__info-block">
                <DefaultBtn title={Languages.getTranslation('import-project', true)}
                            handler={() => importProject('import_project')}/>
                <div className="project-calc__import-project">
                    <input type="file" id="project-calc__import"
                           className='project-calc__import-project'
                           accept={formats}
                           onChange={(e) => uploadFileHandler((e))}
                           ref={inputRef}
                    />
                    {/*<label htmlFor="project-calc__import">{Languages.getTranslation('import-project', true)}</label>*/}
                </div>
            </div>
            <div className="importBlockModel__info-block">
				<span
                    className="importBlockModel__info-block-text">{text}</span>
            </div>
        </div>
    </div>
}

export default ImportBlockModel