import Db from "../api/Db";

export default class _Details extends Db {
    constructor() {
        super();
    }

    getId() {
        return this.getIndex('details')
    }

    getDetails() {
        return this.getAllData('details')
    }

    getMaterials() {
        return this.getAllData('materials')
    }

    addDetail(detail) {
        if (detail.hasOwnProperty('detailName')){
        delete detail.detailName
        }
        return this.addItem('details', detail)
    }

    updateMillsDb(id, field, value) {
        return Promise.all(value.map((mill) => mill.updateDb()))
    }

    updateDetail(id, field, value) {
        return this.update('details', id, field, value)
    }

    updateDetailData(id, data) {
        const promises = [];
        for (const [field, value] of Object.entries(data)) {
            promises.push(this.updateDetail(id, field, value));
        }
        return Promise.all(promises);
    }

    deleteDetail(id) {
        return this.removeItem('details', id);
    }

    updateDetailsMaterial(oldIndex, newIndex) {
        return this.getDataByKey('details', 'material', oldIndex)
            .then(details => Promise.all(details.map(el => this.updateDetail(el.id, 'material', newIndex))))
    }

    // setDetailsMaterial(details=[], material) {
    //     return Promise.all(details.map(detail => this.updateDetail(detail.id, 'material', material))).then(() => Promise.all(details.map(el => {
    //         if (!empty(el.multiplicity) && typeof el.multiplicity === 'object') {
    //             el.multiplicity.material = material
    //         }
    //         return this.updateDetail(el.id, 'multiplicity', el.multiplicity)
    //     })))
    // }
}