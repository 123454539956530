import RoundCheckBox from "components/UI/Inputs/Input/RoundCheckBox";
import img from "../../../../../media/material-add-img-test.svg";
import Languages from "../../../../../translation/Languages";
import {materialTitle} from "../../../../../helpers/helper";

const changeTitle = (title, material) =>
  title
    .split("(LuxeForm)")
    .join("")
    .split(" ")
    .filter((el) => !el.includes(material.l))
    .join(" ");

const MaterialItem = ({
  material,
  index,
  setSelectedMaterial,
  selectedMaterial,
}) => {
  const setMaterial = (material) => {
    setSelectedMaterial(material);
  };

  return (
    <div className="quickInputTable__info-body-item">
      <RoundCheckBox
        value={material}
        setMaterial={setMaterial}
        selectedMaterial={[selectedMaterial]}
      />
      <div className="quickInputTable__info-body-item-number">{index + 1}</div>
      <img src={img} className="quickInputTable__info-body-item-img" />
      <span className="quickInputTable__info-body-item-name">
        {materialTitle(material)}
      </span>
    </div>
  );
};

export default MaterialItem;
