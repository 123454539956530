import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store, {persistor} from "redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from "./reportWebVitals";
import App from "components/App";
import Authorize from "components/Authorize";
import "styles/main.scss";
import "styles/inputs.scss";
import "react-notifications/lib/notifications.css";
import API from "api/API";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import Db from "./api/Db";
import DisplayHandler from './components/Toast/DisplayHandler'
import Toast from './components/Toast'

const db = new Db();
const root = createRoot(document.getElementById("root"));

API.init()
    .then((id) => {
        if(!id) return Promise.resolve()

        return db.checkUser(id)
    })
    .then(() => {
      root.render(
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
          {/*  <NotificationContainer/>*/}
            <DisplayHandler />
            <Toast />
            {API.getToken()
              ?
              <App />
              :
              process.env.NODE_ENV === 'sandbox' ? <Authorize /> : <App />
            }
          {/* </PersistGate> */}
        </Provider>
      );
    })



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
