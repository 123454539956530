import "./style.scss";


const SupportQuestion = ({ title, answer, isOpen, toggleItem }) => {
    return (
      <div className="accordion">
       <div className={`accordion__item ${isOpen ? 'open' : ''}`}>
        <div className="accordion__title" onClick={toggleItem}>
          <span>{title}</span>
          <span className={`accordion__arrow ${isOpen ? 'open' : ''}`}/>
        </div>
        {isOpen && <div className="accordion__content">{answer}</div>}
       </div>
      </div>

    );
};
export default SupportQuestion;