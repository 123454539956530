import React, {useState, useEffect} from "react";

const Checkbox = ({param, handleChange}) => {
  const [checked, setChecked] = useState(!!param.value)

  const update = (e) => {
    setChecked(e.target.checked)
    param.onChange(e.target.checked)
    handleChange()
  }

  useEffect(() => {
    setChecked(!!param.value)
  }, [param.value]);

  return (
  <>
    <input
        className={`paramIcon__ext`}
      checked={checked}
        onChange={ (e) => {
          update(e);
          handleChange(e)
        }}
      disabled={param.disabled ?? false}
        type='checkbox'
        name={param.name}
        id={`paramIcon__${param.name}`}
    />
      <label htmlFor={`paramIcon__${param.name}`}></label>
  </>

  )
}

export default Checkbox

// new logic
// import React from "react";
// import './../../../Modal/Types/style.scss'

// const Checkbox = ({param, handleChange}) => {

//   const update = (e) => {
//     param.setValue(e.target.checked)
//   }

//   return (
//     <>
//       <div className="newModal__defaultItem">
//         <div 
//           onChange={ (e) => { update(e); handleChange(e) }} 
//           className={'modal-materials-add__checkbox ' + (param.disabled ? 'disabled' : '') + (param.getValue() ? ' modal-materials-add__checkbox-active' : '')}
//         >
//           <i className={param.getValue() ? 'modal-materials-add__checkbox-icon modal-materials-add__checkbox-icon-active' : 'modal-materials-add__checkbox-icon'}></i>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Checkbox