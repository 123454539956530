import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";
import { projectSelectors } from "redux/project";
import {useSelector} from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import {empty} from "../../../../helpers/helper";
import img from "../../../../media/material-add-img-test.svg";
import detail_img from "../../../../media/detail_img.svg";
import imgNotification from "../../../../media/icon-notification.png";
import '../../../../components/CreateProject/Materials/style.scss'
import "./style.scss";
import cloneDeep from "lodash.clonedeep";
import Loader from "../../Loader";

const AddDetailsModal = ({close}) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const { tempProject } = useSelector(state => state.tempDetail.property)
    const [isAllDetailsSelected, setIsAllDetailsSelected] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [translate, setTranslate] = useState({
        header: Languages.getTranslation('details-to-add', true),
        footer: Languages.getTranslation('import-parts', true)})
    const [load, setLoad] = useState(false)
    const [localProject, setLocalProject] = useState([])

    useEffect(() => {
        if(!empty(tempProject)){
            const proj = cloneDeep(tempProject);
            proj.details.forEach(el => {
                if(el.count < 1) { el.count = 1; }
                if(el.count > 50000) { el.count = 50000; }
                if (el.name) {
                    el.name = el.name.replace(/\*/g, '');
                }
            });
            setLocalProject(proj);
        }
    }, [])


    useEffect(() => {
        if (!selectedDetails.length) {
            setIsAllDetailsSelected(false);
        }
    }, [selectedDetails]);

    useEffect(() => {
        if (isAllDetailsSelected) {
            setSelectedDetails([...localProject.details]);
        } else {
            setSelectedDetails([]);
        }
    }, [isAllDetailsSelected]);

    const setAllDetails = () => {
        if (empty(localProject.details)) {
            return
        }
        setIsAllDetailsSelected(!isAllDetailsSelected)
    }

    const activeCheckBox = (type, detail) => {
        if (type === "checkbox") {
            return selectedDetails.includes(detail)
                ? "detailsTab__checkbox detailsTab__checkbox_active"
                : "detailsTab__checkbox";
        } else {
            return selectedDetails.includes(detail)
                ? "checkbox__icon checkbox__icon_active"
                : "checkbox__icon";
        }
    };

    const selectDetailHandler = (detail) => {
        if (selectedDetails.includes(detail)) {
            setSelectedDetails([...selectedDetails.filter(el => el.id !== detail.id)]);
        } else {
            setSelectedDetails([...selectedDetails, detail]);
        }
    };

    const importDetails = () => {
        const emptyObj = {}
        selectedDetails.forEach((el, index) => {
            if(!Array.isArray(emptyObj[`${el.productId}`])){
                emptyObj[`${el.productId}`] = []
            }
            emptyObj[`${el.productId}`].push(index);
        })
        construction.createProductsImport(Object.entries(emptyObj), localProject.products, selectedDetails)
            .then(() => {
                construction.importDetails(selectedDetails.sort((a, b) => a.id - b.id ), localProject)
                    .then(() => {
                        window.location.pathname === '/details'
                            ? window.location.reload()
                            : window.location.href = `${window.location.origin}/details`

                        setLoad(false)
                    })
            })
            .catch(error => console.log(error));
    }

    const handlerImport = () => {
        if (window.location.pathname === '/import-project') {
            setLoad(true)
            construction.startNewProject().then(() => {
                importDetails()
            })
        } else {
            setLoad(true)
            importDetails()
        }
    }

    const showNoti = (detail) => {
        let temp = []
        if(Number(tempProject.materials.find(m => m.index === detail.material)?.article) >= 100018000){
            temp.push(1)
        }
        if((detail.l < 30 && detail.h < 100) ||
            (detail.h < 30 && detail.l < 100) ||
            (detail.h < 30 && detail.l >= 100) ||
            (detail.l < 30 && detail.h >= 100)){
            temp.push(2)
        }
        return temp
    }
    
    const changeCount = (id, event) => {
        let count = Number(event.target.value);
        if (count < 1) {  count = 1; }
        if (count > 50000) {  count = 50000; }
        localProject.details.forEach(el => {
            if(el.id === id){ el.count = count}
        })
    }

    const validInput = (event) => {
        if (Number(event.target.value) < 1) {
            event.target.value = 1;
        }
        if (Number(event.target.value) > 50000) {
            event.target.value = 50000;
        }
    }

    return (
        <div className='modal'>
            <div className="modal-add-details">
                <h2 className="modal-add-details__title">{translate.header}</h2>
                <div className="modal-add-details__header">
                    <div className="modal-add-details__info-container" style={{paddingTop: 10, paddingBottom: 7}}>
                        <div className={
                                isAllDetailsSelected
                                    ? "modal-add-details__checkbox modal-add-details__checkbox_active"
                                    : "modal-add-details__checkbox"}
                            onClick={setAllDetails}>
                            <i className={isAllDetailsSelected && "checkbox__icon checkbox__icon_active"}></i>
                        </div>
                        <div className="modal-add-details__header-item modal-add-details__number">№</div>
                        <div className="modal-add-details__header-item modal-add-details__img">{Languages.getTranslation("Sketch", true)}</div>
                        <div className="modal-add-details__header-item modal-add-details__name">
                            {Languages.getTranslation("name", true)}
                        </div>
                    </div>
                    <div className="modal-add-details__data-container">
                        <div style={{ marginLeft: '20px' }}
                             className='modal-add-details__header-item modal-add-details__data-item'>
                            {Languages.getTranslation("height", true)}
                        </div>
                        <div className='modal-add-details__header-item modal-add-details__data-item'>
                            {Languages.getTranslation("width", true)}
                        </div>
                        <div className='modal-add-details__header-item modal-add-details__data-item'>
                            {Languages.getTranslation("amount", true)}
                        </div>
                        <div className='modal-add-details__header-item modal-add-details__data-item'>
                            {Languages.getTranslation("thickness", true)}
                        </div>
                    </div>
                </div>
                {load ? <div className="modal-add-details_loader" > <Loader /> </div>:
                <div className="modal-add-details__body">
                    {localProject && localProject.details && localProject.details.length > 0 ? (
                        localProject.details.map((detail, key) => {
                            let w;
                            if(typeof detail.multiplicity === 'object' && detail.multiplicity !== null) {
                                w = localProject.materials.find(f => f.index === detail.material)?.thickness +
                                    localProject.materials.find(f => f.index === detail.multiplicity.material)?.thickness
                            }else{
                                const findMaterial = localProject.materials.find(f => f.index === detail.material)
                                w = findMaterial ? findMaterial?.thickness : localProject.materials[detail.material].thickness
                            }
                            return (
                                <div key={key} id={key}
                                    className={`modal-add-details__body-item`}>
                                    <div className="modal-add-details__info-container">
                                        <div
                                            className={activeCheckBox("checkbox", detail)}
                                            onClick={() => selectDetailHandler(detail)}
                                        >
                                            <i className={activeCheckBox("icon", detail)}></i>
                                        </div>
                                        <span className=" modal-add-details__number">{key + 1}</span>
                                        <div className=" modal-add-details__img">
                                            <img src={detail_img} alt="detail img"/>
                                        </div>
                                        <div className="modal-add-details__name">{detail.name.toString().trim() === ""
                                            ? `${Languages.getTranslation('one-detail', true)} ${key + 1}`
                                            : detail.name}</div>
                                    </div>
                                    <div className="modal-add-details__data-container">
                                        <div className="modal-add-details__data-item">{Number(Number(detail.l).toFixed(1))}</div>
                                        <div className="modal-add-details__data-item">{Number(Number(detail.h).toFixed(1))}</div>
                                        {/*<div className="modal-add-details__data-item">{detail.count}</div>*/}
                                        <input className="modal-add-details__imput-item"
                                               defaultValue={detail.count}
                                               min="1" max="10000" type="number"
                                               onBlur={(event) => validInput(event)}
                                               onChange={(event) => changeCount(detail.id, event)}/>
                                        <div className="detailsTab__data-item">{w}</div>
                                        {showNoti(detail).length > 0 &&
                                            <img className="img22" data-tooltip-id={`import-tooltip-${key}`}
                                              src={imgNotification}
                                              alt="imgNotification"/>}
                                    </div>
                                    <Tooltip
                                        className='tooltip'
                                        id={`import-tooltip-${key}`}
                                        place="left"
                                        variant='light'
                                        openOnClick={true}
                                    >
                                        <div className="tooltip">
                                            <p>{ showNoti(detail)[0] === 1 ? `${Languages.getTranslation('after-import-replace', true)}` : ""}
                                            </p>
                                            <p>
                                                {showNoti(detail)[0] === 2 || showNoti(detail)[1] === 2 ? `${Languages.getTranslation('min-100x30', true)}`: ""}
                                            </p>
                                        </div>
                                    </Tooltip>
                                </div>
                            );
                        })
                    ) : ( <div className="modal-add-details__body-no-parts-found" >{Languages.getTranslation('no-parts-found', true)}</div> )}
                </div>}
                <div className='modal-add-details__wraperBtn'>
                    <div className="modal-add-details__btns">
                        <DefaultBtn title={Languages.getTranslation('close-btn', true)} handler={close}/>

                    </div>
                    <div className="modal-add-details__btns">
                        <DefaultBtn disabled={!selectedDetails.length > 0} title={translate.footer} handler={handlerImport}/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDetailsModal;

