import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import Base from "../../../model/components/Base";
import DetailConnections from "../DetailConnections";
import {empty} from "../../../../helpers/helper";

const UsedDetail = ({ detailCId, removeFromGroup }) => {
	const BaseClass = new Base();
	const details = useSelector(constructor3dSelectors.getCDetails)
	const showedProcessing = useSelector(constructor3dSelectors.showedProcessing)
	const detail = details.find(el => el.detailCId === detailCId);
	// const selectedDetails = useSelector(constructor3dSelectors.getSelectedDetails)
	const dispatch = useDispatch();
	// if(!detail) return null;
	const [isSelected, setIsSelected] = useState(false);
	const [isProcessingShowed, setIsProcessingShowed] = useState(false)

	// useEffect(() => {
	//
	// }, [connections, detail.locked]);
	//
	// useEffect(() => {
	// 	setConnections(detail.connections)
	// }, [detail.connections]);

	// useEffect(() => {
	// 	setIsSelected(selectedDetails && selectedDetails.findIndex(el => el.detailCId === detail.detailCId) !== -1)
	// }, [selectedDetails]);

	// const removeConnection = (connectionId) => {
	// 	detail.removeConnection(connectionId)
	// 		.then(() => {
	// 			setConnections(prev => {
	// 				return prev.filter(el => el.connectionId !== connectionId);
	// 			})
	// 		})
	// }

	const removeDetail = () => {
		if(!empty(detail.group?.group_id)) {
			removeFromGroup(detail);
		} else {
			detail.remove()
		}

	}

	const selectDetail = e => {
		const check = e.target;
		if (check.checked) {
			detail.toggleSelectDetail(true);
			setIsSelected(true)
      // dispatch(constructor3dOperations.addSelectedCDetail(detail))
    } else {
			detail.toggleSelectDetail()
			setIsSelected(false)
      // dispatch(constructor3dOperations.removeSelectedCDetail(detail))
    }
	}

	// const editConnection = ( connectionId) => {
	//
	// 	const connection = detail.connections.find(el => el.connectionId === connectionId);
	// 	const mainDetail = details.find(el => el.detailCId === connection.detail);
	// 	const mainObject = BaseClass.getDetailMeshBySide(mainDetail, connection.targetFace);
	// 	const targetObject = BaseClass.getDetailMeshBySide(detail, connection.currentFace);
	//
	// 	dispatch(constructor3dOperations.addSelectedObject({object: mainObject}))
	// 		.then(() => dispatch(constructor3dOperations.addSelectedObject({object: targetObject})))
	// 		.then(() => {
	// 			mainDetail.selectObject(mainObject.uuid);
	// 			detail.selectObject(targetObject.uuid);
	// 			return dispatch(constructor3dOperations.setConnectionValues(connection.offset))
	// 		})
	// 		.then(() => dispatch(constructor3dOperations.setConnectionOptions(true)))
	// }

	const showProcessing = () => {
		setIsProcessingShowed(!isProcessingShowed)
	}

	const lockProcessing = (pId, axis, e) => {
		detail.lockProcessing(pId, axis);
		if(detail.lockedProcessing?.[pId]?.[axis]) {
			e.target.classList.add("active")
		} else {
			e.target.classList.remove("active")
		}
	}

	const getProcessing = () => {
		if(!isProcessingShowed || empty(detail.processing ) || !showedProcessing?.hole) return null;

		return (
			<div className={'block__body'}>
				<div className="block">
					{detail.processing.map(el => {
						return (<div key={el.id} className={'block__header'}
							onMouseLeave={e => detail.hoverProcessing(el.id, false)}
							onMouseOver={e => detail.hoverProcessing(el.id)}>
							<span className={'block__header-title processing-title'}>{el.subType}</span>
							{['x', 'y', 'z'].map(axis => {
								return (
									<button className={`block__header-control button-small ${detail.lockedProcessing[el.id]?.[axis] ? 'active' : ''}`}
										onClick={e => lockProcessing(el.id, axis, e)}>{axis}</button>
								)
							})}
						</div>)
					})}
				</div>

			</div>
		)
	}

	const toggleDetail = e => {
		detail.toggle()
		if(detail.hidden) {
			e.target.classList.add('active')
		} else {
			e.target.classList.remove('active')
		}

	}

	return (
		<div className='used-details__item '>
			<div className={'block'}>
				<div className={'block__header used-details__item-title'}>
					{empty(detail.processing) || !showedProcessing?.hole ? null : <button onClick={showProcessing} className={'block__header-control icon-button-arrow'}></button>}
					<span className="block__header-title used-details__item-title-content" onClick={e => detail.toggleSelectDetail(true)}
							>{detail.name}</span>
					{/*{detail.locked ? <span>&#8654;</span> : null }*/}
					<button onClick={toggleDetail} className={'icon-button-aye'}>hi</button>
					<a className="remove-button used-details__item-control"
						onClick={e => removeDetail(detail.detailCId)}> &times;</a>
				</div>
				{getProcessing()}
			</div>
			{/*{detail.group ? null :*/}
			{/*	<input className="used-details__item-checkbox" type={"checkbox"} onClick={selectDetail} checked={isSelected}/>}*/}


			{/*<DetailConnections detail={detail} editConnection={connectionId => editConnection(connectionId)} removeConnection={connectionId => removeConnection(connectionId)} />*/}
		</div>
	)
}

export default UsedDetail