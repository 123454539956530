import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialProjectState = {
  construction: null,
  detail: null,
  IsArrowMaterial: false,
  IsArrowEdge: false,
  sceneModel: null,
  token: null,
  errors: { message: '', isShow: false },
};

const project = createReducer(initialProjectState, {
  [ACTIONS.ConstructionProject.type]: (state, construction) => {
    return {
      ...state,
      construction: construction,
    };
  },
  [ACTIONS.setDetail.type]: (state, detail) => {
    return {
      ...state,
      detail: detail,
    };
  },
  [ACTIONS.setIsArrowMaterial.type]: (state, IsArrowMaterial) => {
    return {
      ...state,
      IsArrowMaterial: IsArrowMaterial,
    };
  },
  [ACTIONS.setIsArrowEdge.type]: (state, IsArrowEdge) => {
    return {
      ...state,
      IsArrowEdge: IsArrowEdge,
    };
  },
  [ACTIONS.setTreatment.type]: (state, Treatment) => {
    return {
      ...state,
      Treatment: Treatment,
    };
  },
  [ACTIONS.setsceneModel.type]: (state, sceneModel) => {
    return {
      ...state,
      sceneModel,
    };
  },
  [ACTIONS.errorProject.type]: (state, val) => {
    const { message, isShow } = val.payload

    return { ...state, errors: { message, isShow }};
  },
});

export default combineReducers({
  project,
});
