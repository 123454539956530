import React from 'react';
import "./style.scss";
import arrow from '../../../../../media/Arrow.png'
import arrowGor from '../../../../../media/ArrowGor.png'
import {empty} from "../../../../../helpers/helper";
export const Rectangle = ({ x, y, type, selected, valueX, valueY }) => {
    const width = 500;
    const height = 224;
    const centerWidth = width / 2;
    const centerHeight = height / 2;

    const rectangleStyle = {
        cursor: 'pointer',
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        border: selected ? '3px solid #3f3d3d' : '3px solid #C6C6C6',

    };

    let lineStyles = [];


    switch(type){
        case 1:
            lineStyles.push({
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: `${centerWidth + x}px`,
                borderLeft: '2px dashed #C6C6C6',
            });
            break;
        case 2:
            lineStyles.push({
                position: 'absolute',
                left: '0',
                right: '0',
                top: `${centerHeight + y}px`,
                borderTop: '2px dashed #C6C6C6',
            });
            break;
        case 3:
            lineStyles.push({
                position: 'absolute',
                left: '0',
                right: '0',
                top: `${centerHeight + y}px`,
                borderTop: '2px dashed #C6C6C6',
            });
            lineStyles.push({
                position: 'absolute',
                bottom: '0',
                top: `${centerHeight + y}px`,
                left: `${centerWidth + x}px`,
                borderLeft: '2px dashed #C6C6C6',
            });
            break;
        case 4:
            lineStyles.push({
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: `${centerWidth + x}px`,
                borderLeft: '2px dashed #C6C6C6',
            });
            lineStyles.push({
                position: 'absolute',
                left: '0',
                right: `${centerWidth + x}px`,
                top: `${centerHeight + y}px`,
                borderTop: '2px dashed #C6C6C6',
            });
            break;
        default:
            return null;
    }

    return (
        <div style={rectangleStyle}>
            <div className="templateType-multiplicity">
                <div className="templateType-line"></div>
                <div className="templateType-line"></div>
                <div className="templateType-line"></div>
            </div>
            {!empty(valueY) && (
                <div className="templateType-arrowVertical">
                    <img src={arrow} alt="arrow"/>
                    <div className="templateType-titleVer">{valueY}</div>
                </div>
            )}
            {!empty(valueX) && (
                <div className="templateType-arrowGorizontal">
                    <img src={arrowGor} alt="arrow"/>
                    <div className="templateType-title">{valueX}</div>

                </div>
            )}

            {lineStyles.map((style, index) =>
                <div key={index} style={style}/>
            )}
        </div>
    );
};

