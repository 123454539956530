import { combineReducers } from "@reduxjs/toolkit";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from './actions'

const initialState = {
    tempDetail: {
        name: '',
        material: 0,
        height: "",
        heightError: false,
        width: "",
        widthError: false,
        multiplicity: null, //{edge: null, type: null, material: 0},
        amount: 1,
        countError: false,
        isRotateTexture: true,
        isCutting: false,
        productId: 1,
        edges: { left: null, top: null, right: null, bottom: null },
        afterCloseCheckbox: true
    },
    tempDetails: [],
    tempProject: {}
}

const property = createReducer(initialState, {
    [ACTIONS.TempDetail.type]: (state, val) => {
        const { name, property } = val.payload
        if(name === null){
            return initialState
        }
        return { ...state, tempDetail: { ...state.tempDetail, [name]: property } }
    },

    [ACTIONS.TempDetails.type]: (state, val) => {
        return { ...state, tempDetails: val.payload.details }
    },

    [ACTIONS.TempProject.type]: (state, val) => {
        return { ...state, tempProject: val.payload.project }
    }
})

export default combineReducers({
    property
})