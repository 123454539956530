import React, {useState, useEffect, useRef } from "react";
import TableDetails from "components/UI/Tables/Details";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import "./style.scss";
import {modalsOperations, modalsSelectors} from "redux/modals";
import Languages from "translation/Languages";
import _Details from "../../db/_Details";
import {empty} from "../../helpers/helper";
import {toast} from "react-toastify";
import SubmitModal from "../UI/Modal/SubmitModal";
import {errorMessageOperations} from '../../redux/errors'
import Errors from "../../models/Errors";
import {tempDetailOperations} from "redux/tempDetail";
import AhtungModal from "../UI/Modal/AhtungModal";
import Loader from "../UI/Loader";
import AddDetailsModal from "../UI/Modal/AddDetailsModal";
import CloneDetailsModal from "../UI/Modal/CloneDetailsModal";
import cloneDeep from "lodash.clonedeep";
import FilterSidebar from "../UI/FilterSidebar";
import InputModal from "../UI/Modal/InputModal";
import Requests from "../../api/API";
import InputExelModal from "../UI/Modal/InputExelModal";
import EditCount from "../UI/Modal/EditCount";
import {toastError, toastSuccess} from "../../helpers/toasts";
import CustomSelect from "../UI/Select"
const detailsDb = new _Details();
const _errors = new Errors([])
import { checkDetailsWithEdges } from "../../helpers/helper";
import axios from "axios";
import {userSelectors} from "redux/user";
import materialImg from '../../media/selectAction/material-to-detail.png';
import edgeImg from '../../media/selectAction/edge-to-detail.png';
import deleteDetailsImg from '../../media/selectAction/delete-details.png';
import textureImg from '../../media/selectAction/texture.png';
import noTextureImg from '../../media/selectAction/noTexture.png';
import cloneDetailImg from '../../media/selectAction/clone-detail.png';
import countDetailsImg from '../../media/selectAction/count-details.png';
import groupChangeHoles from '../../media/selectAction/groupChangeHoles.png';
import Contour from "../UI/SvgSketch";

const parentStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#ffffff',
        border: 'none',
    })
};
const Details = ({materials}) => {
    const dispatch = useDispatch();
    const construction = useSelector(projectSelectors.getConstruction);
    const { errors } = useSelector(state => state.project.project);
    const modalState = useSelector(modalsSelectors.getModalShowState);
    const detailsList = useSelector(projectSelectors.getDetails);

    // TODO исправить на рефакторе: настроить тригер показ ошибок из redux детали.
    // const errorMessage = useSelector(errorMessageSelectors.getErrorMessageState)

    const tooltipRef = useRef(null)
    const inputRef = useRef()
    const permissions = useSelector(userSelectors.permissions);

    const [selectedDetails, setSelectedDetails] = useState([]);
    const [details, setDetails] = useState([]);
    const [initialDetails, setInitialDetails] = useState([]);
    const [filteredMaterials, setFilteredMaterials] = useState([]);
    const [filteredEdges, setFilteredEdges] = useState([]);
    const [filteredThickness, setFilteredThickness] = useState([]);
    const [haveTexture, setHaveTexture] = useState(false);
    const [isNotTexture, setIsNotTexture] = useState(false);
    const [haveMultiplicity, setHaveMultiplicity] = useState(false);
    const [isNotMultiplicity, setIsNotMultiplicity] = useState(false);

    const [selectedValue, setSelectedValue] = useState('');
    const [showSubmitModal, setShowSubmitModal] = useState(false)
    const [selectType, setSelectType] = useState('')
    const [submitModalText, setSubmitModalText] = useState('')
    const [deleteDetail, setDeleteDetail] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [load, setLoad] = useState(false)
    const [showAhtung, setShowAhtung] = useState(false)
    const [showAddDetails, setShowAddDetails] = useState(false)
    const [showCloneDetail, setShowCloneDetail] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [openInputModal, setOpenInputModal] = useState(false)
    const [openModalGitlab, setOpenModalGitlab] = useState(false)
    const [editCountModal, setEditCountModal] = useState(false)

    const [isMaterialsOpen, setIsMaterialsOpen] = useState(false);
    const [isEdgesOpen, setIsEdgesOpen] = useState(false);
    const [isThicknessOpen, setIsThicknessOpen] = useState(false);
    const [isTextureOpen, setIsTextureOpen] = useState(false);
    const [isMultiplicityOpen, setIsMultiplicityOpen] = useState(false);
    const [edgesForFilter, setEdgesForFilter] = useState([])
    const [materialsForFilter, setMaterialsForFilter] = useState([])
    const [thicknessForFilter, setThicknessForFilter] = useState([]);
    const [filteredActive, setFilteredActive] = useState(({
        isMaterialChecked: false,
        isEdgesChecked: false,
        isThicknessChecked: false,
        isMultiplicityChecked: false,
        isTextureChecked: false,
    }))

    const [currentDetailsToShow, setCurrentDetailsToShow] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // новое состояния для контроля рендеринга

    const lastDetailWithImgPdf = detailsList[detailsList.length - 1]?.imgPdf
    const detailsPerInterval = 10;
    const intervalTime = 700;



    useEffect(() => {
        let timer = null;

        if (empty(lastDetailWithImgPdf)) {
            timer = setTimeout(() => {
                if (currentIndex < construction.details.length) {
                    setIsLoading(true); // начинаем загрузку
                    const nextDetails = construction.details.slice(currentIndex, currentIndex + detailsPerInterval);
                    setCurrentDetailsToShow(nextDetails);
                    setCurrentIndex(prevIndex => prevIndex + detailsPerInterval);
                }
                else if(empty(lastDetailWithImgPdf)){
                    setIsLoading(true); // начинаем загрузку
                    let  objectsWithImgPdfNull = construction.details.filter(detail => detail.imgPdf === null);
                    setCurrentDetailsToShow(objectsWithImgPdfNull);

                }
                else {
                    setIsLoading(false); // если все детали отображены, останавливаем загрузку
                }
            }, intervalTime);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [currentIndex, lastDetailWithImgPdf, detailsList]);





    const FORMATS_BASIS =
        '.kronas, .project, .json, .xml';
    const FORMATS_VP =
        '.kronas, .project, .json, .xml';
    const FORMATS_GIBLAB =
        '.kronas, .project, .json, .xml';
    const FORMATS_PRO100 =
        '.txt';

    const updateOrderAfterRemoval = async () => {
        await Promise.all([...construction._details].map(async (item, index) => {
            const newOrder = index + 1;
            if (newOrder !== item.order) {
                item.order = newOrder;
                await detailsDb.updateDetail(item.id, "order", newOrder);
            }
        }));
    };
    const removeDetails = (id) => {
        detailsDb.deleteDetail(id)
            .then(() => {
                setDetails([]);
                setTimeout(() => {

                    setDetails([...construction._details]);
                }, 100);
            }).then(() => {
            setTimeout(() => {
                    updateOrderAfterRemoval()

                }, 100)

        })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.body.classList.add("sidebar-open");
        } else {
            document.body.classList.remove("sidebar-open");
        }
    }, [isSidebarOpen]);

    const removeDetail = () => {
        setInitialDetails(initialDetails.filter((el) => el !== deleteDetail));
        construction._details = details.filter((el) => el !== deleteDetail);
        detailsDb.deleteDetail(deleteDetail.id)
            .then(() => {
                setDetails([]);
                setTimeout(() => {
                    setDetails([...construction._details]);
                }, 100);
            })
            .catch(err => console.log(err))
    };

    const filterDetailsByEdge = (currentDetails) => {
        const filteredIndexEdges = filteredEdges.map(({ index }) => index);

        const filteredDetailsByEdges = filteredIndexEdges.map(index => checkDetailsWithEdges(currentDetails, index));
        const flatDetails = filteredDetailsByEdges.flat();

        return [...new Set(flatDetails)]
    }
    const filterDetailsByThickness =(currentDetails) => {
        const filteredIndexThickness = filteredThickness.map(({w}) => w);
        return currentDetails.filter((det) => filteredIndexThickness.includes(det.w));
    }
    const filterDetailsByTexture =(currentDetails) => {
        if(haveTexture && isNotTexture){
            return currentDetails
        } else if(haveTexture){
            return currentDetails.filter((det) => det.isRotateTexture);
        } else{
            return currentDetails.filter((det) => !det.isRotateTexture);
        }
    }
    const filterDetailsByMyltiplicity = (currentDetails) => {
        if(haveMultiplicity && isNotMultiplicity){
            return currentDetails
        } else if(haveMultiplicity){
            return currentDetails.filter((det) =>!!det.multiplicity);
        } else{
            return currentDetails.filter((det) => !!det.multiplicity === false);
        }
    }
    const filterDetailsByMaterials =(currentDetails) => {
        const filteredIndexMaterials = filteredMaterials.map(({index}) => index);
        return   currentDetails.filter((det) => filteredIndexMaterials.includes(det.material) || filteredIndexMaterials.includes(det.multiplicity?.material));
    }
    const checkFilteredProps = () => {
        const isMaterialChecked = materialsForFilter.some(({ isChecked }) => isChecked)
        const isEdgesChecked = edgesForFilter.some(({ isChecked }) => isChecked)
        const isThicknessChecked = thicknessForFilter.some(({ isChecked }) => isChecked)
        const isMultiplicityChecked = haveMultiplicity || isNotMultiplicity
        const isTextureChecked = haveTexture || isNotTexture

        setFilteredActive({
            isMaterialChecked,
            isEdgesChecked,
            isThicknessChecked,
            isMultiplicityChecked,
            isTextureChecked,
        });

    }
    const filterDetails = () => {
        const isFilterExist = filteredEdges.length || filteredThickness.length || filteredMaterials.length || haveTexture  || isNotTexture || haveMultiplicity || isNotMultiplicity;
        if(isFilterExist){
        const materialItems = filteredMaterials.length ? filterDetailsByMaterials(initialDetails) : initialDetails
        const edgesItems = filteredEdges.length ? filterDetailsByEdge(materialItems) : materialItems
        const thicknessItems = filteredThickness.length ? filterDetailsByThickness(edgesItems) : edgesItems
        const textureItems = haveTexture || isNotTexture ? filterDetailsByTexture(thicknessItems) : thicknessItems
        const myltiplicityItems = haveMultiplicity || isNotMultiplicity ? filterDetailsByMyltiplicity(textureItems) : textureItems

        setDetails(myltiplicityItems)
        }else{
            setDetails(initialDetails)
        }
        checkFilteredProps()
    }
    useEffect(() => {
        if (!errors.isShow) {
            detailsList.every(item => {
                if (item.error.allErrorsMessage.length > 0) {
                    const message = Languages.getTranslation('erroneous-parts-found-in-the-project', true)

                    toast.error(`${message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        toastId: `${Languages.getTranslation('erroneous-parts-found-in-the-project', true)}`,
                    })

                    dispatch(projectOperations.errorProject(message, true))
                    return false
                }
                return true
            })
        }
    }, [errors]);

    useEffect(() => {
        if (deleteDetail) {
            setSubmitModalText(Languages.getTranslation('delete-detail-accept', true) + deleteDetail.name + "?")
            setSelectType('deleteDetail')
            setShowSubmitModal(true)
        }
    }, [deleteDetail])

    useEffect(() => {
        setDetails([...detailsList]);
    }, [detailsList]);

    useEffect(() => {
        const filteredEdges = construction.edges.map((item) => ({...item, isChecked: false}))
        setEdgesForFilter(filteredEdges)
    }, [modalState, materials, construction.edges])

    useEffect(() => {
        const filteredMaterials = construction.materials.map((item) => ({...item, isChecked: false}))
        setMaterialsForFilter(filteredMaterials)
        const thicknessArrFilter = initialDetails.map(({w}) => ({w, isChecked:false}));
        const uniqueArrayThickness = thicknessArrFilter.filter((obj, index, arr) => {
            return arr.findIndex((item) => item.w === obj.w) === index;
        });
        setThicknessForFilter(uniqueArrayThickness)
        if (!modalState) {
            setDetails([...detailsList.sort((a, b) => {
                if (!empty(a.order)  && !empty(b.order)) {
                    return a.order - b.order;
                } else {
                    return a.id - b.id;
                }
            })]);
            setInitialDetails([...detailsList])
        }
    }, [modalState, materials]);

    const updateOrderAfterRemovalOnce = (deletedItem) => {
        // slicing to create a new copy
        const newDetails = [...details].filter(detail => detail.id !== deletedItem.id);
        newDetails.forEach((item, index) => {
            item.order = index + 1;
        });
        setDetails(newDetails);

        // updating database
        Promise.all(newDetails.map((item, index) =>
            detailsDb.updateDetail(item.id, "order", index + 1)))
    }
    useEffect(() => {
        if (selectedValue === 'selectRemoveDetails') {
            selectedDetails.forEach((sd) => {
                removeDetails(sd._id)
            })
            construction.details = details.filter((detail) => !selectedDetails.includes(detail))
            setInitialDetails(initialDetails.filter((detail) => !selectedDetails.includes(detail)));

            setSelectedValue('')
            setSelectedDetails([])
        }
        if (selectedValue === "deleteDetail") {
            updateOrderAfterRemovalOnce(deleteDetail)
            removeDetail()
            setSelectType('')
            setSelectedValue('')
        }
        if (selectedValue === "import_project" && !empty(inputRef.current)) {
            inputRef.current.click()
            setSelectedValue('')
        }
        if (selectedValue === "import_project_id") {
            setOpenInputModal(true)
            setSelectedValue('')
        }
        if (selectedValue === "transferToReverseSide") {
            updateHolesOnSelectedDetails()
            setSelectedValue('')
            setSelectedDetails([])
        }
        if (selectedValue === "import_project_gitlab") {
            setOpenModalGitlab(true)
            setSelectedValue('')
        }
        if (selectedValue === "import_project_pro100") {
            inputRef.current.click()
        }
    }, [selectedValue, inputRef])

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOut)
        }
        return () => {
            document.removeEventListener('click', handleClickOut);
        };
    }, [isOpen])

    const updateHolesOnSelectedDetails =  () => {
        let isSuccess = false
         selectedDetails.map(detail => Promise.all(detail.holes.map(hole => {
             if (hole) {
                 isSuccess = true
                return hole.reverseSide()
             }
        })))
        if (isSuccess) toastSuccess(Languages.getTranslation('reverse-holes-to-side-success', true))
    }

    const handleClickOut = e => {
        if (tooltipRef.current && !tooltipRef.current.contains(e.target) && isOpen) {
            setIsOpen(prevState => !prevState);
        }
    };

    const closeFilterSidebar =() => {
        setIsSidebarOpen(false);
    }

    const changeAction = (event) => {
        const  value  = event

        if (empty(selectedDetails)) {
            _errors.setError(`${Languages.getTranslation('choose-one-detail', true)}`, 'error', true)
            return
        }

        if (value === 'selectMaterial') {
            dispatch(modalsOperations.switchStateForModal({
                show: true,
                type: 'REPLACE_MATERIAL_PROJECT',
                isEdit: selectedDetails,
                isReplacedMaterialForProject: false,
            }))
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'changeProductOnDetail') {
            dispatch(modalsOperations.switchStateForModal({
                show: true,
                type: 'CHANGE_PRODUCT_ON_DETAIL',
                isEdit: selectedDetails,
            }))
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'selectEdge') {
            if (empty(construction.edges)) {
                dispatch(errorMessageOperations.switchStateError({
                    message: Languages.getTranslation('edge-to-detail-error', true),
                    type: 'error',
                    show: true
                }))
                setSelectedValue('')
                setSelectedDetails([])
                return
            }
            dispatch(modalsOperations.switchStateForModal({
                show: true,
                type: 'REPLACE_EDGE',
                isEdit: selectedDetails
            }))
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'groupChangeDiamHoles') {
            dispatch(modalsOperations.switchStateForModal({
                show: true,
                type: 'GROUP_CHANGE_DIAM_HOLES',
                isEdit: selectedDetails
            }))
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'removeDetails') {
            setSubmitModalText(Languages.getTranslation('submit-removeDetails', true))
            setSelectType('selectRemoveDetails')
            setShowSubmitModal(true)
            setSelectedValue('')
        }

        if (value === 'selectTexture') {
            selectedDetails.forEach((sd) => {
                if(!['OSB'].includes(construction.materials.find(mat => mat.index === sd.material).type)){
                    detailsDb.updateDetail(sd.id, "isRotateTexture", true)
                }
            })
            construction.details.forEach((el) => {
                if (selectedDetails.includes(el)  && !['OSB'].includes(construction.materials.find(mat => mat.index === el.material).type)) {
                    el.isRotateTexture = true
                }
            })
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'removeTexture') {
            selectedDetails.forEach((sd) => {
                if(!['Постформінг', 'Постформинг', 'OSB'].includes(construction.materials.find(mat => mat.index === sd.material).type)) {
                    detailsDb.updateDetail(sd.id, "isRotateTexture", false);
                }
            })
            construction.details.forEach((el) => {
                if (selectedDetails.includes(el)) {
                    if(!['Постформінг', 'Постформинг', 'OSB'].includes(construction.materials.find(mat => mat.index === el.material).type)){
                        el.isRotateTexture = false
                    }
                }
            })
            setSelectedValue('')
            setSelectedDetails([])
        }

        if (value === 'updateCountDetails') {
            setEditCountModal(true)
            setSelectedValue('')
        }

        if (value === 'transferToReverseSide') {
            setSelectedValue('transferToReverseSide')
        }

        if(value === 'cloneDetail'){
            const tempDetails = cloneDeep(selectedDetails)
            tempDetails.forEach((item, index) => {
                item.name += Languages.getTranslation('copy', true)
            })
            dispatch(tempDetailOperations.setTempDetails(tempDetails))
            setShowCloneDetail(true)
        }
    };

    const createDetail = async () => {
        if (!construction._materials.length) {
            toastError(Languages.getTranslation('dont-have-materials-project', true))
            return;
        }
        if (empty(construction.products)) {
           await construction.createProduct({name: Languages.getTranslation('single-product', true)})
        }
        dispatch(
            modalsOperations.switchStateForModal({
                show: true,
                type: "ADD_DETAIL",
            })
        );
    };

    const quickInputTableHandler = async () => {

        if (!construction._materials.length) {
            toastError(Languages.getTranslation('dont-have-materials-project', true))
            return;
        }
        if (empty(construction.products)) {
            await construction.createProduct({name: Languages.getTranslation('single-product', true)})
        }
        dispatch(
            modalsOperations.switchStateForModal({
                show: true,
                type: "QUICK_INPUT_TABLE",
            })
        );
    };

    const productsHandler = () => {
        if (!construction._materials.length) {
            toastError(Languages.getTranslation('dont-have-materials-project', true))
            return;
        }
        dispatch(
          modalsOperations.switchStateForModal({
              show: true,
              type: "PRODUCTS",
          })
        );
    };

    const uploadFileHandler = async (event) => {
        setIsOpen(prevState => !prevState)
        setLoad(true)

        const formData = new FormData()
        formData.append('file', event.target.files[0]);

        await axios.post(`${process.env.CVRT_KRONAS}/api/${selectedValue === 'import_project_pro100' ? 'convert-file-pro100' : 'convert-file'}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data', }
        })
            .then(data => {
            setIsOpen(prevState => !prevState)
            dispatch(tempDetailOperations.setTempProject(data.data.data))
            setLoad(false)
            setShowAddDetails(true)
        })
            .catch(err => console.log(err))
    }

    const handleGetId = (id) => {
        Requests.Projects.getProjectById(id)
            .then(response => {
                // TODO JSON.parse(response.file) RangeError: Maximum call stack size exceeded
                // dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
                dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
                setOpenInputModal(false)
                setShowAddDetails(true)
            })
            .catch(error => console.log(error))
    }

    const handleOpenCLone = () => {
        setOpenModalGitlab(false)
        setShowAddDetails(true)
    }

    const closeEditCount = () => {
        setSelectedDetails([])
        setEditCountModal(false)
    }

    useEffect(() => {
        setDetails(construction.details.sort((a, b) => {
                if (!empty(a.order)  && !empty(b.order)) {
                    return a.order - b.order;
                } else {
                    return a.id - b.id;
                }
            })
        )
    }, [editCountModal]);

    const createDetailButton = () => {
        if(!permissions.includes('KM_createDetail')) return null;

        return (
            <div className="details__options-add">
                <button
                    onClick={createDetail}
                    className="btn"
                    style={{marginLeft: "23px"}}
                    title={Languages.getTranslation('add-detail', true)}
                >
                    <i className="btn__icon-add"></i>
                </button>
                <span className="details__options-title">{Languages.getTranslation("details", true)}</span>
            </div>
        )
    }
    return (
        <>
            {load ? <div className='mT-10'><Loader/></div> :
                <div className="details">
                    <div className="details__options">
                        {createDetailButton()}
                        {/*<div className="details__options-add">*/}
                        {/*    <button*/}
                        {/*        className="btn"*/}
                        {/*        style={{marginLeft: "23px"}}*/}
                        {/*        title={Languages.getTranslation('add-detail', true)}*/}
                        {/*    >*/}
                        {/*        <i className="btn__icon-add"></i>*/}
                        {/*    </button>*/}
                        {/*    <span className="details__options-title">Фурнитура</span>*/}
                        {/*</div>*/}
                        {permissions.includes('KM_TSW') && (
                            <button
                                className="details__quick-input-table"
                                onClick={quickInputTableHandler}
                                title={Languages.getTranslation('open-qit', true)}
                            >
                                {Languages.getTranslation("quick-input-table", true)}
                            </button>
                        )}
                        {permissions.includes('KM_importDetail') && (
                            <button
                                ref={tooltipRef}
                                className="details__quick-input-table"
                                title={Languages.getTranslation("import-parts", true)}

                                onClick={() => setIsOpen(prevState => !prevState)}
                            >
                                <i className="icon-link"></i>
                                {isOpen &&
                                    <div className='details__quick-input-table_tooltip'>
                                        <div className='details__quick-input-table_tooltip-item-wrapper'>
                                            <div
                                                onClick={() => {
                                                    setShowAhtung(prevState => !prevState)
                                                    setSelectType('import_project')
                                                }}
                                                className='details__quick-input-table_tooltip-item-wrapper_type'>
                                                {Languages.getTranslation("from-Basis-Meblyar", true)}</div>
                                            <div
                                                onClick={(event) => {
                                                    setShowAhtung(prevState => !prevState)
                                                    setSelectType('import_project_id')
                                                }}
                                                className='details__quick-input-table_tooltip-item-wrapper_type'>
                                             <span className="project-actions__input-title">
                                                {`${Languages.getTranslation("import-saved-project", true)}`}
                                              </span>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setShowAhtung(prevState => !prevState)
                                                    setSelectType('import_project')
                                                }}
                                                className='details__quick-input-table_tooltip-item-wrapper_type'>
                                                {Languages.getTranslation("from-GibLab", true)}</div>
                                            <div
                                                onClick={(event) => {
                                                    setShowAhtung(prevState => !prevState)
                                                    setSelectType('import_project_gitlab')
                                                }}
                                                className='details__quick-input-table_tooltip-item-wrapper_type'>
                                                {Languages.getTranslation("from-Xsl", true)}</div>
                                            <div
                                                onClick={() => {
                                                    setShowAhtung(prevState => !prevState)
                                                    setSelectType('import_project_pro100')
                                                }}
                                                className='details__quick-input-table_tooltip-item-wrapper_type'>
                                                {Languages.getTranslation("importPro100", true)}</div>
                                        </div>
                                    </div>
                                }
                            </button>
                        )}
                        {permissions.includes('KM_TSW') && (
                            <button
                                className="details__products"
                                onClick={productsHandler}
                                title={Languages.getTranslation('open-productsModal', true)}
                            >
                                <i className="details__products-icon"></i>
                                {Languages.getTranslation("products", true)}
                            </button>
                        )}
                        {permissions.includes("KM_detailsSelect") && (
                            <div className="details__select">
                                <CustomSelect
                                    value={selectedValue}
                                    customStyles={parentStyles}
                                    placeholder={Languages.getTranslation("select-action", true)}
                                    options={[
                                        {
                                            value: 'selectMaterial',
                                            label: Languages.getTranslation("material-to-detail", true),
                                            img: materialImg
                                        },
                                        {
                                            value: 'changeProductOnDetail',
                                            label: Languages.getTranslation("change-product-on-detail", true),
                                            img: materialImg
                                        },
                                        {
                                            value: 'selectEdge',
                                            label: Languages.getTranslation("edge-to-detail", true),
                                            img: edgeImg
                                        },
                                        {
                                            value: 'removeDetails',
                                            label: Languages.getTranslation("delete-selected-details", true),
                                            img: deleteDetailsImg
                                        },
                                        {
                                            value: 'selectTexture',
                                            label: Languages.getTranslation("select-texture-on-detail", true),
                                            img: textureImg
                                        },
                                        {
                                            value: 'removeTexture',
                                            label: Languages.getTranslation("remove-texture-on-detail", true),
                                            img: noTextureImg
                                        },
                                        {
                                            value: 'cloneDetail',
                                            label: Languages.getTranslation(`clone-detail${selectedDetails.length > 1 ? 's' : ''}`, true),
                                            img: cloneDetailImg
                                        },
                                        {
                                            value: 'updateCountDetails',
                                            label: Languages.getTranslation(`update-count-details`, true),
                                            img: countDetailsImg
                                        },
                                        {
                                            value: 'transferToReverseSide',
                                            label: Languages.getTranslation(`reverse-holes-to-side`, true),
                                            img: countDetailsImg
                                        },
                                        {
                                            value: 'groupChangeDiamHoles',
                                            label: Languages.getTranslation(`group-change-diamHoles`, true),
                                            img: groupChangeHoles
                                        },
                                    ]}
                                    disabled={selectedDetails.length === 0}
                                    withImages={true}
                                    handleChange={(selectedOption) => {
                                        setSelectedValue(selectedOption);
                                        changeAction(selectedOption && selectedOption.value ? selectedOption.value : "");
                                    }}
                                />
                            </div>
                        )}
                        <div className="details__filter" onClick={() => setIsSidebarOpen(true)}>
                            <span className="details__filter-text">
                                {Languages.getTranslation('filter', true)}
                            </span>
                            <div className="details__icon"/>
                        </div>
                    </div>
                    <input type="file" id="project-calc__import"
                           className='import-project'
                           accept={selectedValue === 'import_project_pro100' ? FORMATS_PRO100 : FORMATS_BASIS}
                           onChange={(e) => uploadFileHandler((e))}
                           ref={inputRef}
                    />
                    {details.length ? (
                        <TableDetails
                            details={details}
                            removeDetail={removeDetail}
                            setSelectedDetails={setSelectedDetails}
                            selectedDetails={selectedDetails}
                            filteredActive={filteredActive}
                            setDetails={setDetails}
                            setDeleteDetail={setDeleteDetail}/>
                    ) : null}
                    {showSubmitModal && <SubmitModal
                        setShowSubmitModal={setShowSubmitModal}
                        setSelectedValue={setSelectedValue}
                        selectType={selectType}
                        submitModalText={submitModalText}
                        setDeleteDetail={setDeleteDetail}/>}
                    {showAhtung && <AhtungModal
                        setShowSubmitModal={setShowAhtung}
                        submitModalText={Languages.getTranslation('import-project-accept', true)}
                        setSelectedValue={setSelectedValue} selectType={selectType}/>}
                    {showAddDetails && <AddDetailsModal close={() => {
                        dispatch(tempDetailOperations.setTempProject({}))
                        setShowAddDetails(false)
                    }}/>}
                    {showCloneDetail && <CloneDetailsModal close={() => {
                        dispatch(tempDetailOperations.setTempDetails([]))
                        setShowCloneDetail(false)
                    }}/>}
                    {openInputModal && <InputModal
                        close={() => setOpenInputModal(false)}
                        getId={handleGetId}/>}
                    {openModalGitlab && <InputExelModal
                        close={() => setOpenModalGitlab(false)}
                        openCLone={handleOpenCLone}/>}
                </div>}
            {editCountModal && <EditCount close={closeEditCount} selectedDetails={selectedDetails}/>}
            {isSidebarOpen &&
                <FilterSidebar
                    materials={materialsForFilter}
                    edges={edgesForFilter}
                    haveTexture={haveTexture}
                    isNotTexture={isNotTexture}
                    isNotMultiplicity={isNotMultiplicity}
                    haveMultiplicity={haveMultiplicity}
                    setFilteredEdges={setFilteredEdges}
                    setHaveTexture={setHaveTexture}
                    setIsNotTexture={setIsNotTexture}
                    setIsNotMultiplicity={setIsNotMultiplicity}
                    setHaveMultiplicity={setHaveMultiplicity}
                    setFilteredThickness={setFilteredThickness}
                    setFilteredMaterials={setFilteredMaterials}
                    setIsMaterialsOpen={setIsMaterialsOpen}
                    isMaterialsOpen={isMaterialsOpen}
                    setIsEdgesOpen={setIsEdgesOpen}
                    isEdgesOpen={isEdgesOpen}
                    setIsThicknessOpen={setIsThicknessOpen}
                    isThicknessOpen={isThicknessOpen}
                    setIsMultiplicityOpen={setIsMultiplicityOpen}
                    isMultiplicityOpen={isMultiplicityOpen}
                    setIsTextureOpen={setIsTextureOpen}
                    setMaterialsForFilter={setMaterialsForFilter}
                    setEdgesForFilter={setEdgesForFilter}
                    setThickness={setThicknessForFilter}
                    thickness={thicknessForFilter}
                    isTextureOpen={isTextureOpen}
                    filterDetails={filterDetails}
                    close={closeFilterSidebar}/>
            }

            <div>
                {!empty(currentDetailsToShow) && empty(lastDetailWithImgPdf) && isLoading && currentDetailsToShow.map(detail =>
                    <Contour
                        key={detail.id}
                        detail={detail}
                        rects={detail.getCutoutsData()}
                        holes={detail.holes}
                    />
                )}
            </div>
        </>
    );
};

export default Details;
