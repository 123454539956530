import React, { useState, useEffect } from "react";
import "./style.scss";
import { projectSelectors } from "redux/project";
import { useSelector } from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import {validationInput} from "../../../../helpers/helper";

const EditCount = ({close, selectedDetails}) => {
  const construction = useSelector(projectSelectors.getConstruction);

  const [value, setValue] = useState('');


  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const submit = () => {
    construction.updateDetailCount(selectedDetails, value).then((data) => close())
  };

  return (
    <>
      <div className='modal'>
        <div className="editCount">
          <h2 className="modal-save-project__title">{Languages.getTranslation('update-count-details', true)}</h2>
          <div className="modal-save-project__body">
            <div className="modal-save-project__form-control">
              <label
                htmlFor="name"
                className="modal-save-project__form-control-label"
              >
                {Languages.getTranslation('update-count-which-value', true)}
              </label>
              <input
                id="name"
                onChange={handleChange}
                name="value"
                value={value}
                type="text"
                maxLength="21"
                className="modal-save-project__form-control-input"
                onInput={(e) => validationInput(e)}
              />
            </div>
          </div>
          <div className="modal-save-project__btns">
            <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
            <DefaultBtn title={Languages.getTranslation('save', true)} handler={submit}/>
          </div>
        </div>
      </div>

    </>
  );
};

export default EditCount;
