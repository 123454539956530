import ACTIONS from "./actions";

const addCDetail = (detail) => {

  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addCDetail(detail));
    } catch (e) {
      console.log(e);
    }
  };
};

const removeCDetail = (detail) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeCDetail(detail))
    } catch (e) {
      console.log(e);
    }
  }
}

const addSelectedCDetail = (selectedDetail) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addSelectedCDetail(selectedDetail));
    } catch (e) {
      console.log(e);
    }
  };
}



const removeSelectedCDetail = (removedDetail) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeSelectedCDetail(removedDetail));
    } catch (e) {
      console.log(e);
    }
  };
}

const setScene = (scene) => {

  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setScene(scene));
    } catch (e) {
      console.log(e);
    }
  };
}

const setEditedObject = (object) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setEditedObject(object));
    } catch (e) {
      console.log(e);
    }
  };
}

const addSelectedObject = (object) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addSelectedObject(object));
    } catch (e) {
      console.log(e);
    }
  };
}

const removeSelectedObject = (object) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeSelectedObject(object));
    } catch (e) {
      console.log(e);
    }
  };
}

const setShowProcessing = object => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setShowedProcessing(object));
    } catch (e) {
      console.log(e);
    }
  };
}

const setConnectionValues = object => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setConnectValues(object));
    } catch (e) {
      console.log(e);
    }
  };
}

const setConnectionOptions = val => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.showConnectOptions(val));
    } catch (e) {
      console.log(e);
    }
  };
}

const removeConnection = connection => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeConnection(connection));
    } catch (e) {
      console.log(e);
    }
  };
}

const dropConnectValues = connectValues => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.dropConnectValues(connectValues));
    } catch (e) {
      console.log(e);
    }
  };
}

const addGroup = group => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addGroup(group));
    } catch (e) {
      console.log(e);
    }
  };
}

const removeGroup = group_id => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeGroup(group_id));
    } catch (e) {
      console.log(e);
    }
  };
}

const addDetailGroup = ({detailCId, group}) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addDetailGroup({detailCId, group}));
    } catch (e) {
      console.log(e);
    }
  };
}

const removeDetailGroup = (detailCId) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeDetailGroup(detailCId));
    } catch (e) {
      console.log(e);
    }
  };
}

const switchLeftPanel = (opened) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.leftPanelOpened(opened));
    } catch (e) {
      console.log(e);
    }
  };
}

const switchRightPanel = () => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.rightPanelOpened());
    } catch (e) {
      console.log(e);
    }
  };
}

const setCurrentCamera = (type) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setCurrentCamera(type));
    } catch (e) {
      console.log(e);
    }
  };
}

const addTopPanelItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.addTopPanelItem(item));
    } catch (e) {
      console.log(e);
    }
  };
}

const removeTopPanelItem = (index) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.removeTopPanelItem(index));
    } catch (e) {
      console.log(e);
    }
  };
}

const toggleConnectModal = () => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.toggleConnectModal());
    } catch (e) {
      console.log(e);
    }
  };
}


export default {
  addCDetail,
  addSelectedCDetail,
  setScene,
  setEditedObject,
  addSelectedObject,
  removeSelectedObject,
  setShowProcessing,
  setConnectionValues,
  setConnectionOptions,
  removeSelectedCDetail,
  removeConnection,
  dropConnectValues,
  addGroup,
  removeGroup,
  addDetailGroup,
  removeDetailGroup,
  switchLeftPanel,
  switchRightPanel,
  setCurrentCamera,
  addTopPanelItem,
  removeTopPanelItem,
  toggleConnectModal
};
