import "../Checkbox/style.scss";
import {useEffect, useState} from "react";

const DefaultCheckbox = (props) => {
    const { checked, changeProp, disabled = false } = props;

    const [isChecked, setIsChecked] = useState(checked);

    useEffect(() => {
        if (props.checked) {
            setIsChecked(props.checked);
        }
    }, [props.checked]);

    const activeCheckbox = isChecked ? "detailsTab__checkbox detailsTab__checkbox_active" : "detailsTab__checkbox";
    const activeIcon = isChecked && "checkbox__icon checkbox__icon_active"
    const disabledClass = disabled ? "disabled" : "";
    const handleChange = () => {
        if(!disabled){
            setIsChecked(previousState => !previousState);
            changeProp();
        }

    }

    return (
        <div className={`${activeCheckbox} ${disabledClass}`} onClick={handleChange}>
            <i className={activeIcon}></i>
        </div>
    );
};

export default DefaultCheckbox;