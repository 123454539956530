import './style.scss';
export const formatPrice = (price) => new Intl.NumberFormat("ua", {}).format(price).replace(",", ".");

// TODO какая валюта для локализации
const Price = ({ price, type  }) => {
    return (
        <div className="nomenclature-data__price">
            <h3 className="nomenclature-data__price-text">
                {type} :
                <span className="nomenclature-data__price-total">   {formatPrice(price)}</span>
                <span className="uah"> ₴</span>
            </h3>
        </div>
    );
};
export default Price