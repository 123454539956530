import Helpers from "./3D/Helpers";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {empty, prepareObject} from "../helpers/helper";
import Languages from "../translation/Languages";
import {data} from "browserslist";

export default class RectangleAndCircle extends Processing {
  _subType ;
  _isActive = false;
  _width;
  _height;
  _fullDepth = true;
  _side = 'front';
  _id;

  constructor({
                detail,
                dataForConstructor = {},
                subType = "rectangle",
                r = subType === 'rectangle' ? 10 : 50,
                x = subType === 'rectangle' ? 160 : detail.l / 2 -r,
                y = subType === 'rectangle' ? 100 : detail.h / 2 -r,
                fullDepth = true,
                comment = "",
                height = subType === 'rectangle' ? 100 : 100,
                width = subType === 'rectangle' ? 100 : 100,
                x_axis =  dataForConstructor?.x_axis ?? "left",
                y_axis =  dataForConstructor?.y_axis ?? "bottom",
                id,
                isErrorText = '',
                additionalInfo = '',
                isInit = false,
                isTemplate = null
              }) {
    super({x, y, r, detail, x_axis, y_axis, id, comment, isErrorText, additionalInfo, isInit, isTemplate});
    this._width = width;
    this._height = height;
    this._depth = detail.w;
    this._subType = subType;
    this._fullDepth = fullDepth
    this._dataForConstructor = dataForConstructor

    if(!empty(dataForConstructor.x_axis)) {
      this._x_axis = dataForConstructor.x_axis;
    } else {
      this._x_axis = 'left';
    }

    if(!empty(dataForConstructor.x)) {
      this._x = dataForConstructor.x
    } else {
      this._x = subType === 'rectangle'  ? x : x + r
    }

    if(!empty(dataForConstructor.y_axis)) {
      this._y_axis = dataForConstructor.y_axis;
    } else {
      this._y_axis = 'bottom';
    }

    if(!empty(dataForConstructor.y)) {
      this._y = dataForConstructor.y
    } else {
      this._y = subType === 'rectangle' ? y : y + r
    }
    this.initRequiredFields();
    this.addParams();
  }

  initRequiredFields () {
    const calcMaxWidth = () => {
      return this.detail.l <= this.x ? this.detail.l : this.detail.l - this.x
    }

    const calcMaxHeight = () => {
      return this.detail.h <= this.y ? this.detail.h : this.detail.h - this.y
    }

    const calcMaxX = () => {
      return this.detail.l <= this.width ? 0 : this.detail.l - this.width
    }

    this.requiredFields = {
      x: {
        max: this.isRectangle ? calcMaxX() : this.detail.l - this.r,
        min:  this.getMinByMaterial(this.isRectangle ? 0 : this.r, 'x'),
      },
      y: {
        max: this.isRectangle ? calcMaxHeight() : this.detail.h - this.r,
        min:  this.getMinByMaterial(this.isRectangle ? 0 : this.r, 'y'),
      },
      width: {
        min: this.isRectangle ? this.r * 2 : 0,
        max: this.isRectangle ? calcMaxWidth() : Math.min(this.detail.l, this.detail.h)
      },
      height: {
        min: this.isRectangle ? this.r * 2 : 0,
        max: this.isRectangle ? this.detail.h - this.y : Math.min(this.detail.l, this.detail.h)
      },
      r: {
        min: this.getMinByMaterial(5, 'r'),
        max: this.isRectangle ? Math.min(this.width, this.height) / 2 : Math.min(this.detail.l, this.detail.h) / 2
      },
      subType: true
    }
  }

  getMinByMaterial(defaultValue, dimension) {
    const detailMaterial = this.findMaterialOnDetail();
    let result = defaultValue;

    switch(dimension) {
      case 'r':
        if(['Compact-плита'].includes(detailMaterial.type)) {
          result = 5;
        }
        break;
      case 'y': case 'x':
        if(['Постформінг', 'Постформинг', 'Compact-плита'].includes(detailMaterial.type)) {
          result = dimension === 'y' ? 30 : 0;
        }
        break;
    }

    return result;
  }

  set subType(subType){
    this._subType = subType

    this.setFormField({
      name: 'subType', value: this.subType
    })
    this.x = this.isRectangle ? 160 : this.detail.l / 2;
    this.y = this.isRectangle ? 100 : this.detail.h / 2;
    this.r = this.isRectangle ? 10 : 50;
    switch (this.subType) {
      case 'rectangle':
        this.setFormField({name: 'width', value: this.width, type: 'number', label: Languages.getTranslation('width', true), visible: true})
        this.setFormField({name: 'height', value: this.height, type: 'number', label: Languages.getTranslation('height', true), visible: true})
        break;
      case 'circle':
        this.setFormField({name: 'width', visible: false});
        this.setFormField({name: 'height', visible: false});
    }


    // this.buildDetail()
    //     .then(() => this.renderDetail());
    this.initRequiredFields()
    this.renderDetail()
  }

  get subType(){
    return this._subType
  }
  addParams() {
    let f_value = ''
    // if(this.isInit) return;
    this.setFormField({
      name: 'subType', value: this.subType, label: Languages.getTranslation('cut-type', true), type: 'select', variables: [
        {key: "circle", value: Languages.getTranslation('circle-proc', true)},
        {key: "rectangle", value: Languages.getTranslation('rectangle-proc', true)},
      ],
    })
    this.setFormField({name: 'fullDepth', value: !this.fullDepth, type: 'checkbox-orig', label: Languages.getTranslation('non-through-cutout', true), visible: true,});

    f_value = this._dataForConstructor.hasOwnProperty('f_y') ? this._dataForConstructor['f_y'] : '';
    this.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_y', label: `${Languages.getTranslation('center-on-axis', true)} Y`, callback: () => {
            this.setCenterY();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_x') ? this._dataForConstructor['f_x'] : '';
    this.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
            this.setCenterX();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_width') ? this._dataForConstructor['f_width'] : '';
    this.setFormField({name: 'width', value: this.width, type: 'number', label: `${Languages.getTranslation('width', true)} (pw)`, visible: this.subType === "rectangle", additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_height') ? this._dataForConstructor['f_height'] : '';
    this.setFormField({name: 'height', value: this.height, type: 'number', label: `${Languages.getTranslation('height', true)} (ph)`, visible: this.subType === "rectangle", additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})

    this.setFormField({name:'x_axis', value: this.x_axis, type: 'select', label: `${Languages.getTranslation("side-to-exist", true)} X`, variables: [
        { key: "left", value: Languages.getTranslation('left2', true)},
        { key: "right", value: Languages.getTranslation('right2', true) },
      ], visible: true})
    this.setFormField({name:'y_axis', value: this.y_axis, type: 'select', label: `${Languages.getTranslation("side-to-exist", true)} Y`, variables: [
        { key: "top", value: Languages.getTranslation('top2', true) },
        { key: "bottom", value: Languages.getTranslation('bottom2', true) },
      ], visible: true})
    this.setFormField({name: 'depth', visible: false});
    this.setFormField({name: 'r', value: this.r, type: 'number', label: Languages.getTranslation('radius', true), additionalProcessing: {
        name: 'removeRadius', label: '', callback: () => {
          this.removeRadius()
        }
      }})
  }

  get y(){
    return this._y;
  }

  set y(y){
    if(typeof y === 'number'){
      if(!this.checkRequiredField('y', y)) {
        this._y = y;
        this.setFormField({name: 'y', value: this.y})
        this.updateDataForConstructor({name: 'f_y', value: ''})
      } else {
        this.showErrors();
      }
    }else{
      if(!empty(y)){
        if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('py')
            && y.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('py')
            && y.includes('pw')) {
          this.showToastError('refer-each-other')
        }else if (!empty(this._dataForConstructor['f_height'])
            && this._dataForConstructor['f_height'].includes('py')
            && y.includes('ph')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(y))) {
          const calculation = Number(Number(this.formulaCalculation(y)).toFixed(1));
          if(!this.checkRequiredField('y', calculation)) {
            this._y = calculation;
            this.setFormField({name: 'y', value: this.y})
            this.updateDataForConstructor({name: 'f_y', value: y})
          }else{
            this.showErrors()
          }
        }
      }else {
        this.updateDataForConstructor({name: 'f_y', value: ''})
      }
    }
    this.updateDataForConstructor({name: 'y', value: this.y})
    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('py')){
      this.x = this._dataForConstructor['f_x']
    }
    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('py')){
      this.width = this._dataForConstructor['f_width']
    }
    if(!empty(this._dataForConstructor['f_height']) && this._dataForConstructor['f_height'].includes('py')){
      this.height = this._dataForConstructor['f_height']
    }
    this.renderDetail()
    this.initRequiredFields()
  }

  get x(){
    return this._x;
  }

  set x (x){
    if(typeof x === 'number'){
      if(!this.checkRequiredField('x', x)) {
        this._x = x;
        this.setFormField({name: 'x', value: this.x})
        this.updateDataForConstructor({name: 'f_x', value: ''})
      } else {
        this.showErrors();
      }
    }else{
      if(!empty(x)){
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('px')
            && x.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('px')
            && x.includes('pw')) {
          this.showToastError('refer-each-other')
        }else if (!empty(this._dataForConstructor['f_height'])
            && this._dataForConstructor['f_height'].includes('px')
            && x.includes('ph')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(x))) {
          const calculation = Number(Number(this.formulaCalculation(x)).toFixed(1));
          if(!this.checkRequiredField('x', calculation)) {
            this._x = calculation;
            this.setFormField({
              name: 'x', value: this.x, type: 'number', label: 'x (px)', additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: x},
                {
                  name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
                    this.setCenterX();
                  }
                }
              ]
            })
            this.updateDataForConstructor({name: 'f_x', value: x})
          }else{
            this.showErrors()
          }
        }
      } else{
        this.updateDataForConstructor({name: 'f_x', value: ''})
      }

    }
    this.updateDataForConstructor({name: 'x', value: this.x})
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('px')){
      this.y = this._dataForConstructor['f_y']
    }
    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('px')){
      this.width = this._dataForConstructor['f_width']
    }
    if(!empty(this._dataForConstructor['f_height']) && this._dataForConstructor['f_height'].includes('px')){
      this.height = this._dataForConstructor['f_height']
    }
    this.initRequiredFields()
    this.renderDetail();
  }

  removeRadius() {
    this.r = 3;
    this.setFormField({name: 'r', value: 3})
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    this.renderDetail()
  }


  get dataForValidate() {
    const shape = prepareObject(this.realData);
    return {
      ...shape,
      edge: this.getEdgeArrayIndex(this.edge)
    };
  }

  get cutOut() {
    const width = this.isRectangle ? this.width : this.r * 2;
    const height = this.isRectangle ? this.height : this.r * 2;
    this.fixMultiplicity();
    const cutout = {
      x: this.xCalc,
      y: this.yCalc,
      z: this.z,
      side: this.side,
      type: 'cutout',
      subType: this.subType,
      depth: this.depth,
      multiplicity: this.multiplicity,
      fullDepth: this.fullDepth,
      edge: null,
      width,
      height,
      color: this.getMeshColor(),
      elements: []
    }
    switch (this.subType) {
      case 'rectangle':
        cutout.elements.push(Helpers.createLine(0, this.r, 0, height - this.r));
        cutout.elements.push(Helpers.createArc(0, height - this.r, this.r, height, this.r, this.r, height - this.r));
        cutout.elements.push(Helpers.createLine(this.r, height, width - this.r, height));
        cutout.elements.push(Helpers.createArc(width - this.r, height, width, height - this.r, this.r, width - this.r, height - this.r));
        cutout.elements.push(Helpers.createLine(width, height - this.r, width, this.r));
        cutout.elements.push(Helpers.createArc(width, this.r, width - this.r, 0, this.r, width - this.r, this.r));
        cutout.elements.push(Helpers.createLine(width - this.r, 0, this.r, 0));
        cutout.elements.push(Helpers.createArc(this.r, 0, 0, this.r, this.r, this.r, this.r));
        break;
      case 'circle':
        cutout.elements.push(Helpers.createArc(width - this.r, 0, width - this.r, height, this.r, width - this.r, height - this.r));
        cutout.elements.push(Helpers.createArc(width - this.r, height, width - this.r, 0, this.r, width - this.r, height - this.r));

    }


    return cutout;
  }
  get realData() {
    this.updateDataForConstructor({name: 'x', value: this.x})
    this.updateDataForConstructor({name: 'y', value: this.y})
    this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
    this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})

    return {
      side: this.side,
      x: this.xCalc,
      y: this.yCalc,
      z: this.z,
      width: this.isRectangle ? this.width : this.r * 2,
      height: this.isRectangle ? this.height : this.r * 2,
      depth: this.depth,
      fullDepth: this.fullDepth,
      x_axis: this.x_axis,
      y_axis: this.y_axis,
      type: this.isRectangle ? "Rectangle" : "Circle",
      subType: this.subType,
      id: this.id,
      r: this.r,
      edge: null,
      ext: false,
      isErrorText: this.isErrorText,
      isTemplate: this.isTemplate
    };
  }

  addSizesArrows() {
    if(!this.isActive) return false;
    const pos = this.x_axis + '_' + this.y_axis;
    return SizeArrow.getElementSizeArrow(
      this.x,
      this.y,
      null,
      pos,
      this.detail
    )
  }

  set fullDepth(fullDepth) {
    this.setFormField({name: 'fullDepth', value: fullDepth})
    this.updateDataForConstructor({name: 'fullDepth', value: fullDepth});
    this._fullDepth = !fullDepth;
    this.renderDetail()
  }

  get fullDepth() {
    return this._fullDepth;
  }

  setCenterX() {
    this.x = this.isRectangle ? this.detail.l / 2 - this.width / 2 : this.detail.l / 2;
    // this.setFormField({name: 'x', value: this.x});
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    // this.renderDetail()
  }

  get height() {
    return this._height;
  }

  set height(height) {
    if(typeof height === 'number'){
      if(!this.checkRequiredField('height', height)) {
        this._height = Number(height);
        this.setFormField({name: 'height', value: this.height})
        this.updateDataForConstructor({name: 'f_height', value: ''})
      } else {
        this.showErrors();
      }
    }else{
      if(!empty(height)) {
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('ph')
            && height.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('ph')
            && height.includes('pw')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('ph')
            && height.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(height))) {
          const calculation = Number(Number(this.formulaCalculation(height)).toFixed(1));
          if(!this.checkRequiredField('height', calculation)) {
            this._height = calculation;
            this.setFormField({
              name: 'height',
              value: this.height})
            this.updateDataForConstructor({name: 'f_height', value: height})
          }else{
            this.showErrors()
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_height', value: ''})
      }
    }

    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('ph')){
      this.x = this._dataForConstructor['f_x']
    }
    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('ph')){
      this.width = this._dataForConstructor['f_width']
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('ph')){
      this.y = this._dataForConstructor['f_y']
    }
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    this.renderDetail()
    this.initRequiredFields()
  }

  get width() {
    return this._width;
  }

  set width(width) {
    if(typeof width === 'number'){
      if(!this.checkRequiredField('width', width)) {
        this._width = Number(width);
        this.setFormField({name: 'width', value: this.width})
        this.updateDataForConstructor({name: 'f_width', value: ''})
      } else {
        this.showErrors();
      }
    }else {
      if(!empty(width)) {
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('pw')
            && width.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('pw')
            && width.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_height'])
            && this._dataForConstructor['f_height'].includes('pw')
            && width.includes('ph')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(width))) {
          const calculation = Number(Number(this.formulaCalculation(width)).toFixed(1));
          if(!this.checkRequiredField('width', calculation)) {
            this._width = calculation;
            this.setFormField({
              name: 'width',
              value: this.width,
              additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: width}]
            })
            this.updateDataForConstructor({name: 'f_width', value: width})
          }else{
            this.showErrors();
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_width', value: ''})
      }
    }

    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('pw')){
      this.x = this._dataForConstructor['f_x']
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('pw')){
      this.y = this._dataForConstructor['f_y']
    }
    if(!empty(this._dataForConstructor['f_height']) && this._dataForConstructor['f_height'].includes('pw')){
      this.height = this._dataForConstructor['f_height']
    }

    // this.buildDetail()
    //   .then(() => this.renderDetail());
    this.renderDetail()
    this.initRequiredFields()
  }

  setCenterY() {
    this.y = this.isRectangle ? this.detail.h / 2 - this.height / 2 : this.detail.h / 2;
    // this.setFormField({name: 'y', value: this.y});
    // this.buildDetail()
    //   .then(() => this.renderDetail());
    // this.renderDetail()
  }

  get xCalc() {
    if (this.x_axis === "right") {
      return this.isRectangle ? this.detail.l - this.x - this.width : this.detail.l - this.x - this.r;
    } else {
      return this.isRectangle ? this.x : this.x - this.r;
    }
  }

  get yCalc() {
    if (this.y_axis === "top") {
      return this.isRectangle ? this.detail.h - this.y - this.height : this.detail.h - this.y - this.r;
    } else {
      return this.isRectangle ? this.y : this.y - this.r;
    }
  }

  get shape() {
    return this.realData
  }

  get paramsSorting() {
    const sortingArr = ['x_axis', 'y_axis', 'x', 'y', 'height', 'width', 'r', 'fullDepth', 'comment']

    return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  validate() {
    // TODO I've disabled validation for now because of trimming to size
    // if(this.detail.l < this.xCalc + this.width) {
    //   this.detail.error.setError(`${Languages.getTranslation('change-the-X-value', true)}`, 'error', true);
    //   return Promise.reject('change-the-X-value')
    // }
    // if(this.detail.h < this.yCalc + this.height) {
    //   this.detail.error.setError(`${Languages.getTranslation('change-the-Y-value', true)}`, 'error', true);
    //   return Promise.reject('change-the-Y-value')
    // }
    // if(this.height >= this.detail.h ) {
    //   this.detail.error.setError(`${Languages.getTranslation('rectangular-cutout-cannot-be-the-full-height', true)}`, 'error', true);
    //   return Promise.reject('rectangular-cutout-cannot-be-the-full-height')
    // }
    // if(this.width >= this.detail.l) {
    //   this.detail.error.setError(`${Languages.getTranslation('rectangular-cutout-cannot-be-the-full-width', true)}`, 'error', true);
    //   return Promise.reject('rectangular-cutout-cannot-be-the-full-width')
    // }
    return Promise.resolve()
  }

  mirror(axis, data, detailH, detailL) {

    if (axis === 'ver') {
      switch (data.x_axis) {
        case 'left' :
          data.x = detailL - data.x - data.width
          data.x_axis = 'left'
          if(data.subType === 'circle') {
            data.y = data.y + data.r;
            data.x = data.x + data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
          }

          break
        case 'right' :
          data.x_axis = 'right'
          if(data.subType === 'circle') {
            data.y = data.y - data.r;
            data.x = data.x + data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
          }
          break
      }
      switch (data.y_axis) {
        case 'top' :
          data.y = this.y
          if(data.subType === 'circle') {
            data.x = data.x + data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
            y: data.y
          }
          break
        case 'bottom' :
          break
      }
    }

    if (axis === 'hor') {
      switch (data.y_axis) {
        case 'bottom' :
          data.y = detailH - data.y - data.height;
          data.y_axis = 'bottom'
          if(data.subType === 'circle') {
            data.x = data.x + data.r;
            data.y = data.y + data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
          }
          break
        case 'top' :
          data.y_axis = 'top';
          if(data.subType === 'circle') {
            data.x = data.x + data.r;
            data.y = data.y - data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
          }
          break
      }
      switch (data.x_axis) {
        case 'right' :
          data.x = this.x
          if(data.subType === 'circle') {
            data.x = data.x + data.r;
            data.y = data.y - data.r;
          }
          data.dataForConstructor = {
            x_axis: data.x_axis,
            y_axis: data.y_axis,
            x: data.x
          }
          break
        case 'left' :
          break
      }
    }
  }

  mirrorWithReverse(axis) {
    if (axis.ver) {
      switch (this.x_axis) {
        case 'left' :
          this.x_axis = 'right'
          break
        case 'right' :
          this.x_axis = 'left'
          break
      }
    }

    if (axis.hor) {
      switch (this.y_axis) {
        case 'bottom' :
          this.y_axis = 'top'
          break
        case 'top' :
          this.y_axis = 'bottom'
          break
      }
    }
  }

  get isRectangle() {
    return this.subType === 'rectangle';
  }

  rotateDetail(direction) {
    let x, y, x_axis, y_axis, height, width;
    [x , y] = [this.y, this.x];
    [height, width] = [this.width, this.height]

    if(direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
    }
    this.initRequiredFields();
    this.x_axis = x_axis;
    this.y_axis = y_axis;
    this.x = x;
    this.y = y;

    if(this.isRectangle) {
      this.height = height;
      this.width = width;
      this.updateDataForConstructor({name: 'height', value: this.height})
      this.updateDataForConstructor({name: 'width', value: this.width})
    }
    this.updateDataForConstructor({name: 'x', value: this.x})
    this.updateDataForConstructor({name: 'y', value: this.y})
    this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
    this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
    return this.updateDb();
  }
}
