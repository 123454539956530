import Detail from "./Detail";
import Requests from "api/API";
import _Details from "../db/_Details";
import _Materials from "../db/_Materials";
import _Edges from "../db/_Edges";
import {empty, isset, resetMaterialArticle} from "../helpers/helper";
import _Project from "../db/_Project";
import Languages from "../translation/Languages";
import DB from "../api/Db";
import cloneDeep from "lodash.clonedeep";
import Helpers from "./3D/Helpers";
import {v4 as uuidv4} from "uuid";
import {isArray} from "lodash";
import {toastError} from "../helpers/toasts";
import store from "../redux/store";
import _Products from "../db/_Products";

const detailsDb = new _Details();
const materialsDb = new _Materials();
const edgesDb = new _Edges();
const projectDb = new _Project()
const productsDb = new _Products()
const db = new DB()

/** Construction */
export default class Construction {
  _materials = [];
  _furnitures = [];
  _details = [];
  _edges = [];
  _products = [];
  _projectData = {
    name: null
  };
  user = null
  constructor(data, dispatch) {
    // if(empty(data)) {
    //  this.startNewProject()
    // }
    projectDb.getProjectData()
        .then(_data => {
          this.projectData = _data
        })


    this.init(data);
    this.dispatch = dispatch;
  }

  init(data) {
    // if(empty(data)) {
    //   this.startNewProject()
    // } else {

    this.materials = [];
    this.furnitures = [];
    this.details = [];
    this.edges = [];

  }

  get edges() {
    return this._edges;
  }

  set edges(edges) {
    this._edges = edges;
  }

  get furnitures() {
    return this._furnitures;
  }

  set furnitures(furnitures) {
    this._furnitures = furnitures;
  }

  get details() {
    return this._details;
  }

  set details(details) {
    this._details = details;
  }

  get products() {
    return this._products;
  }

  set products(products) {
    this._products = products;
  }

  get materials() {
    return this._materials;
  }

  get chosenDetails() {
    return this._choosenDetails;
  }

  set chosenDetails(chosenDetails) {
    return (this._choosenDetails = chosenDetails);
  }

  set projectData(data) {
    this._projectData = data;
  }

  get projectData() {
    return this._projectData;
  }

  getEmptyDetailData() {
    return {
      productId: null,
      id: null,
      detailName: '',
      name: '',
      detailDescription: '',
      imgBase: '',
      imgPdf: '',
      multiplicity: null,
      leftCut: 0,
      rightCut: 0,
      topCut: 0,
      bottomCut: 0,
      count: 1,
      material: null,
      h: 0,
      ch: 0,
      l: 0,
      cl: 0,
      isRotateTexture: null,
      isCutting: false,
      edges: {
        left: null,
        right: null,
        top: null,
        bottom: null
      },
      preCutting: {
        left: null,
        right: null,
        top: null,
        bottom: null
      },
      holes: [],
      corners: [],
      rects: [],
      arcs: [],
      smiles: [],
      contour: [],
      cutouts: [],
      mills: [],
      detailId: null

      // Обработки
      // "grooves": detail.grooves.map(groove=>groove.data),
      // "rabbets": detail.rabbets.map(rabbet=>rabbet.data),
      // "rectangles": detail.rectangles.map(rectangle=>rectangle.data),
      // "circles": detail.circles.map(circle=>circle.data),
      // "uShapes": detail.uShapes.map(shape=>shape.data)
    };
  }
  async getMaterialCur(articleNeeded){
    let materials = await materialsDb.getMaterials();
    return  materials.find(({article}) => article === articleNeeded);
  }
  getDataDetail(id) {
    const detail = this.details.find((el) => el.id === id);
    if (!empty(detail)) {
	    return {
        productId: detail.productId,
        id: detail.id,
        detailName: detail.name,
        name: detail.name,
        detailDescription: detail.detailDescription,
        imgBase: detail.imgBase,
        imgPdf: detail.imgPdf,
        multiplicity: detail.multiplicity,
        leftCut: detail.leftCut,
        rightCut: detail.rightCut,
        topCut: detail.topCut,
        bottomCut: detail.bottomCut,
        count: detail.count,
        material: detail.material,
        h: detail.h,
        ch: detail.ch,
        l: detail.l,
        cl: detail.cl,
        w: detail.w,
        isRotateTexture: detail.isRotateTexture,
        isCutting: detail.isCutting,
        edges: detail.edges,
        holes: detail.holes.map((hole) => hole.data),
        corners: detail.corners.map((corner) => corner.data),
        rects: detail.getRectsData(),
        arcs: [],
        smiles: [],
        contour: [],
        cutouts: [],
	      mills: [],
	      soft: detail.soft,
        preCutting: detail.preCutting,
	      isPostForming: detail.isPostForming,
        detailId: detail.detailId

        // Обработки
        // "grooves": detail.grooves.map(groove=>groove.data),
        // "rabbets": detail.rabbets.map(rabbet=>rabbet.data),
        // "rectangles": detail.rectangles.map(rectangle=>rectangle.data),
        // "circles": detail.circles.map(circle=>circle.data),
        // "uShapes": detail.uShapes.map(shape=>shape.data)
      };
    }
    return null;
  }
  get getDataDetails() {
    const getData = [];

    this.details.forEach((detail) => {
      const data = {
        productId: detail.productId || 1,
        id: detail.id,
        detailName: detail.name,
        name: detail.name,
        detailDescription: detail.detailDescription,
        multiplicity: detail.multiplicity,
        leftCut: detail.leftCut,
        rightCut: detail.rightCut,
        topCut: detail.topCut,
        bottomCut: detail.bottomCut,
        count: detail.count,
        material: detail.material,
        h: detail.h,
        ch: detail.ch,
        l: detail.l,
        cl: detail.cl,
        isRotateTexture: detail.isRotateTexture,
        isCutting: detail.isCutting,
        edges: detail.edges,
        holes: detail.holes.map((hole) => hole.data),
        corners: detail.corners.map((corner) => corner.data),
        rects: detail.getRectsData(),
        arcs: [],
        smiles: [],
        contour: [],
        cutouts: [],
        mills: [],
        preCutting: detail.preCutting,
        detailId: detail.detailId

        // Обработки
        // "grooves": detail.grooves.map(groove=>groove.data),
        // "rabbets": detail.rabbets.map(rabbet=>rabbet.data),
        // "rectangles": detail.rectangles.map(rectangle=>rectangle.data),
        // "circles": detail.circles.map(circle=>circle.data),
        // "uShapes": detail.uShapes.map(shape=>shape.data)
      };
      getData.push(data);
    });

    return getData;
  }

  set materials(materials) {
    this._materials = materials;
  }

  setProjectMaterials(materials, filialId = null) {
    const self = this;
    const _materials = [];
    const filial = empty(filialId) ? store?.getState()?.user?.user?.user?.payload?.user?.filial?.ttid : filialId
    return new Promise((resolve, reject) => {
    function addMaterial(_material) {
        if (!empty(_material)) {
          const materialInSelf = self.materials.find((el) =>
            resetMaterialArticle(el.article) === resetMaterialArticle(_material.article))
          const duplicatedMaterial = self.materials.find((el) =>
            el.article === _material.article)
          const neededMaterialInSelection = _materials.find((el) =>
            resetMaterialArticle(el.article) === resetMaterialArticle(_material.article))

          if (duplicatedMaterial) {
            return addMaterial(materials.shift())
          }
          if (!_material.hasOwnProperty('sawdust') && Object.isExtensible(_material)) {
            self.updateMaterial(resetMaterialArticle(_material.article), 'sawdust', _material.sawdust = (_material?.typename !== "OSB"))
                .then(() => addMaterial(materials.shift()))
                .catch(err => reject(err))
          }
          if (!_material.hasOwnProperty('CsComplexBand') && Object.isExtensible(_material)) {
            self.updateMaterial(resetMaterialArticle(_material.article), 'CsComplexBand', _material.CsComplexBand = false)
                .then(() => addMaterial(materials.shift()))
                .catch(err => reject(err))
          }
            if (!_material.hasOwnProperty('CsDirectCut') && Object.isExtensible(_material)) {
                self.updateMaterial(resetMaterialArticle(_material.article), 'CsDirectCut', _material.CsDirectCut = null)
                    .then(() => addMaterial(materials.shift()))
                    .catch(err => reject(err))
            }
            if (!_material.hasOwnProperty('CsSortBands') && Object.isExtensible(_material)) {
                self.updateMaterial(resetMaterialArticle(_material.article), 'CsSortBands', _material.CsSortBands = null)
                    .then(() => addMaterial(materials.shift()))
                    .catch(err => reject(err))
            }

          if (materialInSelf) {
            if (materialInSelf.article === _material.article) {
              return addMaterial(materials.shift())
            }
            let newParts
            if(empty(materialInSelf.parts)){
            materialInSelf.parts = []
            }
            if (materialInSelf.parts.some((el) => el.article === _material.article && !_material.hasOwnProperty('typeParts'))){
              newParts = [...materialInSelf.parts]
            } else {
              newParts = [...materialInSelf.parts, _material]
            }
            self.updateMaterial(materialInSelf.index, 'parts', newParts)
              .then(() => addMaterial(materials.shift()))
              .catch(err => reject(err))
          } else if (neededMaterialInSelection) {
            if(!empty(neededMaterialInSelection.parts) && isArray(neededMaterialInSelection.parts)){
              neededMaterialInSelection.parts = [...neededMaterialInSelection.parts, _material]
              materialsDb.updateMaterial(neededMaterialInSelection.index, 'parts', neededMaterialInSelection.parts)
                .then(() => addMaterial(materials.shift()))
                .catch(err => reject({Error: err}))
            } else {
              materialsDb
                .getIndex("materials")
                .then((index) => {
                  _material.index = index;
                  if (!empty(_material.languages)) {
                    return Promise.resolve(_material)
                  } else {
                    return Promise.resolve(_material)
                  }
                })
                .then((_material) => {
                  _materials.push(_material);
                  return materialsDb.addMaterial(_material);
                })
                .then(() => addMaterial(materials.shift()))
                .catch((err) => reject(err));
            }
          } else {
            Requests.Materials.getMaterialsCurrent(resetMaterialArticle(_material.article), filial)
              .then((materialFromApi) => {
                if (!empty(materialFromApi)) {
                  const materialFromApiInProject = self.materials.find((el) =>
                      el.article === materialFromApi[0].article)
                  if (!materialFromApiInProject && !_material.hasOwnProperty('mc_id') && !_material.hasOwnProperty('typeParts')) {
                    return materialsDb
                      .getIndex('materials')
                      .then((index) => {
                        materialFromApi[0].index = index
                        if (!_material.hasOwnProperty('sawdust')) {
                          materialFromApi[0].sawdust = (materialFromApi[0].typename !== "OSB");
                        }else{
                          materialFromApi[0].sawdust =  _material.sawdust
                        }

                          if (!_material.hasOwnProperty('CsComplexBand')) {
                              materialFromApi[0].CsComplexBand = false;
                              materialFromApi[0].CsDirectCut = null;
                              materialFromApi[0].CsSortBands = null;
                          }else{
                              materialFromApi[0].CsComplexBand =  _material.CsComplexBand
                              materialFromApi[0].CsDirectCut =  _material.CsDirectCut
                              materialFromApi[0].CsSortBands =  _material.CsSortBands
                          }

                        materialFromApi[0].parts = _material.parts

                        delete materialFromApi[0].edges
                        return Promise.resolve(materialFromApi[0])
                      })
                      .then((_material) => {
                        _materials.push(_material);
                        return materialsDb.addMaterial(_material);
                      })
                      .then(() => addMaterial(materials.shift()))
                  }
                  return materialsDb
                    .getIndex("materials")
                    .then((index) => {
                      materialFromApi[0].index = index;
                      if (!_material.hasOwnProperty('sawdust')) {
                        materialFromApi[0].sawdust = (materialFromApi[0].typename !== "OSB");
                      }else{
                        materialFromApi[0].sawdust =  _material.sawdust
                      }
                      if (!_material.hasOwnProperty('CsComplexBand')) {
                        materialFromApi[0].CsComplexBand = false;
                        materialFromApi[0].CsDirectCut = null;
                        materialFromApi[0].CsSortBands = null;
                      }else{
                        materialFromApi[0].CsComplexBand =  _material.CsComplexBand
                        materialFromApi[0].CsDirectCut =  _material.CsDirectCut
                        materialFromApi[0].CsSortBands =  _material.CsSortBands
                      }

                      materialFromApi[0].parts = [_material]
                      delete materialFromApi[0].edges
                      return Promise.resolve(materialFromApi[0])
                    })
                    .then((material) => {
                      _materials.push(material);
                      return materialsDb.addMaterial(material);
                    })
                    .then(() => addMaterial(materials.shift()))
                } else if (Number(resetMaterialArticle(_material.article)) > 100_000_000 && _material.hasOwnProperty('client_id') && _material.article.split('_').length === 2) { //For CustomerWarehouse materials
                  materialsDb.getIndex('materials')
                    .then((index) => {
                      _material.parts = [{
                        ..._material,
                      }]
                      _material.index = index
                      _material.article = resetMaterialArticle(_material.article)

                      delete _material.mc_id

                      return Promise.resolve(_material)
                    })
                    .then((_material) => {
                      _materials.push(_material);
                      return materialsDb.addMaterial(_material);
                    })
                    .then(() => addMaterial(materials.shift()))
                } else {
                  materialsDb
                    .getIndex("materials")
                    .then((index) => {
                      _material.index = index;
                      if (!empty(_material.languages)) {
                        return Promise.resolve(_material)
                      } else {
                        return Promise.resolve(_material)
                      }
                    })
                    .then((_material) => {
                      _materials.push(_material);
                      return materialsDb.addMaterial(_material);
                    })
                    .then(() => addMaterial(materials.shift()))
                    .catch((err) => reject(err));
                }
              })
          }
        } else {
          self.materials = [...self.materials, ..._materials];
          resolve(..._materials);
        }
      }
      addMaterial(materials.shift());
    });
  }
  setProjectEdges(edges) {
    const self = this;
    const _edges = [];

    return new Promise((resolve, reject) => {
      function addEdge(_edge) {
        if (!empty(_edge)) {
          _edge.thickness = parseFloat(_edge.thickness);
          _edge.width = parseFloat(_edge.width);
          _edge.height = parseFloat(_edge.height);
          edgesDb
            .getIndex('edges')
            .then((index) => {
              _edge.index = index;
              _edges.push(_edge);
              return edgesDb.addEdge(_edge)
             })
            .then(() => addEdge(edges.shift()))
            .catch((err) => reject(err));
        } else {
          self.edges = [...self.edges, ..._edges];
          resolve(..._edges);
        }
      }

      addEdge(edges.shift());
    });
  }

  /**
   * Clones the provided details.
   *
   * @param {Array} details - The details to clone.
   * @returns {Promise} - A promise that resolves when the cloning process is complete.
   */
  cloneDetails(details){
    return new Promise((resolve, reject) => {
      const clone = (detail) => {
        if(!empty(detail)){
          const name = detail[!empty(detail.newName) ? 'newName' : 'name']
          db.getItemById('details', detail.id)
              .then(data => {
                data.detailId = uuidv4()
                Helpers.calcDetailPreCutting(data, true)
                return this.addProjectDetails([{...data, name}])
                    .then(() => clone(details.shift()))
              })
        }else{
          return resolve()
        }
      }
      clone(details.shift())
    })
  }

  /**
   * Clones a detail object and adds it to the project details.
   * @param {object} detail - The detail object to clone and add.
   * @returns {Promise} A Promise that resolves with no value upon successful cloning and addition, or rejects with an error if there is an error during the process.
   */
  cloneDetail(detail){
    return new Promise((resolve, reject) => {
      const name = detail[!empty(detail.newName) ? 'newName' : 'name']
      Helpers.calcDetailPreCutting(detail, true)
      detail.detailId = uuidv4()
      this.addProjectDetails([{...detail, name}])
          .then(() => resolve())
          .catch((error) => reject(error))
    })
  }

  /**
   * Retrieves details from the database based on the given ID.
   *
   * @param {string} id - The identifier of the item to retrieve details for.
   * @returns {Promise} A promise that resolves with the retrieved details or rejects with an error.
   */
  getDetailFromDB(id){
    return new Promise((resolve, reject) => {
      db.getItemById('details', id)
          .then(data => resolve(data))
          .catch(error => reject(error))
    })
  }

  addProjectDetails(details) {
    const self = this;
    let detailNumber = 1;
    return new Promise((resolve, reject) => {
      const _details = [];
      function addDetail(detail) {
        if (!empty(detail)) {
          detailsDb
            .getId()
            .then((id) => {
              detail.id = id;
              detail.productId = Number(detail.productId);
              detail.number = detailNumber++;
              return materialsDb.getMaterials();
            })
            .then((materials) => {
              if (!empty(detail.material) && !empty(materials)) {
                const usedMaterial = materials.find(
                  (el, i) => el.index === Number(detail.material)
                );
                if (!empty(usedMaterial)) {
                  detail.material = usedMaterial.index;
                  detail.w = usedMaterial.thickness || usedMaterial.t;
                  if(!empty(detail.multiplicity) && typeof detail.multiplicity !== 'object') {
                    if(detail.multiplicity > 1) {
                      detail.multiplicity = {type: 1, material: detail.material, edge: null}
                    } else {
                      detail.multiplicity = null;
                    }
                  }
                  if(empty(detail?.isCutting)){
                    detail.isCutting = false
                  }

                  if (['Постформінг', 'Постформинг'].includes(usedMaterial.type)) {
                    detail.isPostForming = true

                  }
                  const _detail = Helpers.prepareDetails(detail);
                  _details.push(_detail);
                  return detailsDb.addDetail(_detail);
                } else {
                  reject({ Error: "cant find material" });
                }
              } else {
                reject({ Error: "cant find material" });
              }
            })
            .then(() => {
              addDetail(details.shift());
            });
        } else {
          _details.forEach((item) => {
            self.addDetail(item);
          });
          resolve();
        }
      }
      addDetail(details.shift());
    });
  }

  /**
   * Adds details to a project.
   *
   * @param {Object} tmp_detail - The detail to be added.
   * @param {string|null} [tmp_detail.id=null] - Optional ID of the detail.
   * @param {Array|Object} tmp_detail.holes - Array or object representing holes in the detail.
   * @param {Array|Object} tmp_detail.rects - Array or object representing rectangles in the detail.
   * @param {Array|Object} tmp_detail.mills - Array or object representing mills in the detail.
   * @param {Array|Object} tmp_detail.bevels - Array or object representing bevels in the detail.
   * @param {Array|Object} tmp_detail.corners - Array or object representing corners in the detail.
   * @param {Object} tmp_detail.material - The material of the detail.
   * @param {number|null} tmp_detail.multiplicity - The multiplicity of the detail.
   * @param {Object} detail.holes[0] - The first hole in the detail.
   * @param {string|null} [detail.holes[0].id=null] - Optional ID of the hole.
   * @param {Object} detail.rects[0] - The first rectangle in the detail.
   * @param {string|null} [detail.rects[0].id=null] - Optional ID of the rectangle.
   * @param {Object} detail.mills[0] - The first mill in the detail.
   * @param {string|null} [detail.mills[0].id=null] - Optional ID of the mill.
   * @param {Object} detail.bevels[0] - The first bevel in the detail.
   * @param {string|null} [detail.bevels[0].id=null] - Optional ID of the bevel.
   * @param {Object} detail.corners[0] - The first corner in the detail.
   * @param {string|null} [detail.corners[0].id=null] - Optional ID of the corner.
   * @param {number|Object} detail.material - The material of the detail.
   * @param {number|Object|null} detail.multiplicity - The multiplicity of the detail.
   * @returns {Promise} - A promise that resolves when the detail is added, or rejects with an error.
   */
  importDetails(selectedDetails, tempProject) {
    const sides = ['top', 'left', 'right', 'bottom'];
    const processes = ['corners', 'rects', 'mills', 'contour'];
    let millionIncMaterial = 0, millionIncEdge = 0

    this.materials.forEach(mat => {
      const numArticle = Number(mat.article);
      if(numArticle >= 100018000 && millionIncMaterial < numArticle % 10){
        millionIncMaterial = numArticle % 10 }
    })
    this.edges.forEach(edge => {
      const numArticle = Number(edge.article);
      if(numArticle >= 100018000 && millionIncMaterial < numArticle % 10){
        millionIncMaterial = numArticle % 10 }
    })
    tempProject.materials.forEach(mat => {
      const numArticle = Number(mat.article);
      if(numArticle >= 100018000 && millionIncMaterial > 0) {
        mat.article = `${(numArticle - numArticle % 10) + ++millionIncMaterial}`.toString()
      }
    })
    tempProject.edges.forEach(edge => {
      const numArticle = Number(edge.article)
      if(numArticle >= 100115000 && millionIncEdge > 0) {
        edge.article += `${(numArticle - numArticle % 10) + ++millionIncEdge}`.toString()
      }
    })

    /**
     * Finds an item in an array based on the article number and thickness.
     * @param {object} obj - The object to be found.
     * @param {string} type - The type of items to search in.
     * @returns {Object|undefined} - The found item or undefined if not found.
     */
    const findExists = (obj, type) => {
      let exists = this[type].find(m => Number(m.article) === Number(obj.article));
      if(!empty(exists)){
        if(exists.thickness === Number(obj.thickness)){
          return exists
        }else if(exists.thickness === Number(obj.thickness) && tempProject.hasOwnProperty('creator') && tempProject.creator.name === 'Bazis'){
          return exists
        }
        else{
          return undefined
        }
      } else { return exists }
    }

    /**
     * Retrieves the material at the specified array index.
     *
     * @param {number} arrayIndex - The index of the material in the array.
     * @returns {Promise} A promise that resolves with the index of the found material, or rejects with an error object if the material cannot be found.
     */
    const getMaterial = (arrayIndex) => {
      return new Promise((res, rej) => {
        // const existsMat = this.materials.find(m => m.article === tempProject.materials[arrayIndex].article);
        const existsMat = findExists(tempProject.materials[arrayIndex], 'materials');
        if(!empty(existsMat)) {
          res(existsMat.index);
        } else {
          this.setProjectMaterials([cloneDeep(tempProject.materials[arrayIndex])])
            .then(() => {
              const tmp_mat = this.materials.find(m => Number(m.article) === Number(tempProject.materials[arrayIndex].article));
              if(!empty(tmp_mat)) {
                res(tmp_mat.index);
              } else {
                rej({error: 'cant find added material'})
              }
            })
            .catch(e => rej(e))
        }
      })
    }

    /**
     * Retrieves an edge from the tempProject object based on the given arrayIndex.
     *
     * @param {number} arrayIndex - The index of the edge in the array.
     * @param {string|null} [type=null] - Optional type parameter.
     * @param {number|null} [i=null] - Optional i parameter.
     * @returns {Promise} - A promise that resolves with the retrieved edge or rejects with an error message.
     */
    const getEdge = (arrayIndex, type = null, i = null) => {
      return new Promise((res, rej) => {
        if(!empty(arrayIndex)) {
          const old = cloneDeep(tempProject.edges[arrayIndex]);
          // const existsEdge = this.edges.find(e => e.article === old.article);
          const existsEdge = findExists(old, 'edges');
          if(!empty(existsEdge)) {
            const result = (!empty(type) && !empty(i)) ? {edge: existsEdge.index, type, i} : existsEdge.index;
            res(result)
          } else {
            this.setProjectEdges([old])
              .then(() => {
                const tmp_edge = this.edges.find(e => e.article === old.article);
                if(!empty(tmp_edge)) {
                  const result = (!empty(type) && !empty(i)) ? {edge: tmp_edge.index, type, i} : tmp_edge.index;
                  res(result)
                } else {
                  rej({error: 'cant find added edge'})
                }
              })
              .catch(err => rej(err, 'getEdge'))
          }
        } else {
          const result = !empty(type) || !empty(i) ? {edge: null, type, i} : null;
          res(result)
        }
      })
    }

    /**
     * Retrieves a product based on its ID.
     *
     * @param {number} id - The ID of the product to retrieve.
     * @returns {Promise<number>} A promise that resolves with the product ID if found, or rejects with an error if not found.
     */
    const getProduct = (id) => {
      return new Promise((resolve, reject) => {
        const existProduct = this.products.find(pro => Number(pro.id) === Number(id));
        if(!empty(existProduct)){
          resolve(existProduct.id);
        } else {
          this.createProduct({...tempProject.products[`${id}`]})
              .then((id) => {
                resolve(id)
              })
              .catch(error => reject(error));
        }
      })
    }

    return new Promise((resolve, reject) => {
      /**
       * Adds details to a project.
       *
       * @param {Object} tmp_detail - The detail to be added.
       * @returns {Promise} - A promise that resolves when the detail is added, or rejects with an error.
       */
      const addDetail = tmp_detail => {
        if (!empty(tmp_detail)) {
          const detail = cloneDeep(tmp_detail);
          delete detail.id;
          detail.holes.forEach(hole => {
            if (empty(hole.id)) {
              hole.id = uuidv4()
            }
          })
          detail.rects.forEach(rect => {
            if (empty(rect.id)) {
              rect.id = uuidv4()
            }
          })
          Helpers.createMillFromSmiles(detail)
          if(!empty(detail.mills)){
            detail.mills.forEach(mill => {
              if (!['partial', 'closed'].includes(mill.type)) {
                mill.elements = []
              }
              if (empty(mill.id)) {
                mill.id = uuidv4()
              }
            })
          }
          if (!empty(detail.bevels)) {
            detail.bevels.forEach(bevel => {
              if (empty(bevel.id)) {
                bevel.id = uuidv4()
              }
            })
          }
          detail.corners.forEach(corner => {
              corner.contourId = !empty(corner?.quant) ? corner.id : null
            if (empty(corner.id)) {
              corner.id = uuidv4()
            }
          })
          getMaterial(detail.material)
            .then(index => {
              detail.material = index;
              return new Promise((resolve, reject) => {
                const tempEdges = cloneDeep(sides), edges = []
                const addSideEdge = (side) => {
                  if(!empty(side)) {
                    getEdge(detail.edges[side])
                        .then(ed => edges.push(ed))
                        .then(() => addSideEdge(tempEdges.shift()))
                        .catch(err => reject(err))
                  }else {
                    resolve(edges)
                  }
                }
                addSideEdge(tempEdges.shift())
              })
            })
            .then(edges => {
              sides.forEach((side, i) => detail.edges[side] = edges[i]);
              if (!empty(detail.multiplicity)) {
                if(typeof detail.multiplicity === "number"){
                    if(detail.multiplicity > 1){
                      detail.multiplicity = {type: 1}
                      return Promise.all([Promise.resolve(detail.material), Promise.resolve(null)])
                    }else{
                      return Promise.resolve(null);
                    }
                }
                return Promise.all([getMaterial(detail.multiplicity.material), getEdge(detail.multiplicity.edge)])
              } else {
                return Promise.resolve(null);
              }
            })
            .then(data => {
              if (!empty(data)) {
                detail.multiplicity = {...detail.multiplicity, material: data[0], edge: data[1]}
              }
              const promises = [];
              processes.forEach(type => {
                if(!empty(detail[type])){
                  detail[type].forEach((proc, i) => {
                    if (!empty(proc.edge)) {
                      promises.push({edge: proc.edge, type, i})
                    }
                  })
                }
              })
              return new Promise((resolve, reject) => {
                const edges = [], tempProcesses = cloneDeep(promises)
                const addProcessesEdges = (process) => {
                  if(!empty(process)){
                    getEdge(process.edge, process.type, process.i)
                        .then(ed => edges.push(ed))
                        .then(() => addProcessesEdges(tempProcesses.shift()))
                        .catch(err => reject(err))
                  }else {
                    resolve(edges)
                  }
                }
                addProcessesEdges(tempProcesses.shift())
              })
              // return Promise.all(promises)
            })
            .then(data => {
              data.forEach(el => {
                detail[el.type][el.i].edge = el.edge
              })

              if(!detail.hasOwnProperty('productId')){
                detail.productId = 1;
              }else {
                getProduct(detail.productId)
                    .then((id) => { detail.productId = id })
                    .catch(error => reject(error));
              }
              // Helpers.calcDetailPreCutting(detail)
              return this.addProjectDetails([detail])
            })
            .then(() => addDetail(selectedDetails.shift()))
            .catch(e => reject(e))
        } else {
          resolve()
        }
      }
      addDetail(selectedDetails.shift())
    })
  }

  /**
   * Updates the detail data for a given id.
   *
   * @param {string} id - The id of the detail to update.
   * @param {object} data - The new data to update the detail with.
   * @property {array} data.corners - An array of corner objects to update.
   * @property {number} data.corners[].edge - The index of the edge to assign to the corner.
   * @returns {Promise<object>} - A promise that resolves with the updated detail object.
   */
  updateDetailData(id, data){
    return detailsDb.updateDetailData(id, data)
        .then(() => {
          const detail = this.details.find((el) => el.id === id)
          for (const [field, value] of Object.entries(data)) {
            if (field === 'corners'){
              const newArrWithEdges = value.map((element) => {
                const neededEdge = this.edges.find((edge) => edge.index === element.edge)
                element.edge = neededEdge
                return element
              })
              detail[field] = newArrWithEdges
            } else {
              detail[field] = value;
            }
          }
          return Promise.resolve(detail)
        })
  }

  updateDetail(id, field, value) {
    const self = this;
    if (field === 'mills') {
      return detailsDb.updateMillsDb(id, field, value).then(() => {
        const detail = self.details.find((el) => el.id === id);
          detail[field] = value;
      });
    }
    return detailsDb.updateDetail(id, field, value).then(() => {
      const detail = self.details.find((el) => el.id === id);
      if (field === 'corners'){
        const newArrWithEdges = value.map((element) => {
          const neededEdge = this.edges.find((edge) => edge.index === element.edge?.index)
          element.edge = neededEdge
          return element
        })
        detail[field] = newArrWithEdges
      } else {
      detail[field] = value;
      }
    });
  }

  updateDetailsMaterial(oldId, newId) {
    return new Promise((resolve, reject) => {
      detailsDb
        .updateDetailsMaterial(oldId, newId)
        .then(() => {
          this.details.forEach((detail) => {
            detail.material = newId;
          });
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  deleteMaterial(index) {
    const self = this;
    return materialsDb.deleteMaterial(index)
      .then(() => {
      self.materials = self.materials.filter(
        (material) => material.index !== index
      );
      return Promise.resolve();
    })
      .catch((error) => console.log(error))
  }

  deleteEdge(index) {
    const self = this;
    return edgesDb.deleteEdge(index).then(() => {
      self.edges = self.edges.filter(
        (edge) => edge.index !== index
      );
      return Promise.resolve();
    });
  }

  startNewProject(dataFromCreated = null) {
    return detailsDb.dropAllData()
      .then(() => projectDb.addProject(null, dataFromCreated))
      .then((projectData) => {
        this.projectData = projectData;
        this.materials = [];
        this.edges = [];
        this.details = [];
        this.products = [];
      });
  }

  updateMaterial(index, field, value) {
    return materialsDb.updateMaterial(index, field, value).then(() => {
      const neededMaterial = this.materials.find((material) => material.index === index)
      neededMaterial[field] = value
    })
  }

  deleteDetails(ids) {
    const self = this;
    return Promise.all(ids.map(id => detailsDb.deleteDetail(id)))
      .then(() => {
        self.details = self.details.filter((detail) =>!ids.includes(detail.id));
        self.details.forEach((detail, i) => {
          detail.number = i + 1
        })
        return Promise.all(self.details.map(detail => detailsDb.updateDetail(detail.id, 'number', detail.number)));
      })
  }

  deleteDetail(id) {
    const self = this;
    return detailsDb.deleteDetail(id)
        .then(() => {
          const details = self.details.filter(el => el.id !== id);
          details.forEach((detail, i) => {
            detail.number = i + 1;
          })
          self.details = details;
          //!TODO must change when 400 details
          // return new Promise((resolve, reject) => {
          //   function delDetail(detail){
          //     if(!empty(detail)){
          //       detailsDb.updateDetail(detail.id, 'number', detail.number)
          //           .then(() => delDetail(details.shift()))
          //           .catch(err => reject(err))
          //     }else {
          //       return resolve()
          //     }
          //   }
          //   delDetail(details.shift())
          // })
          return Promise.all(details.map(detail => detailsDb.updateDetail(detail.id, 'number', detail.number)))
        }).catch(err => console.log(err))
  }

  // Добавление Детали
  addDetail(data = null) {
    if (data === null) {
      data = {
        id: this._details.length
          ? this._details[this._details.length].id + 1
          : 1,
      };
    }
    data.parent = this;
    // data.id = empty(data.id) ? (this._details.length ? this._details[this._details.length - 1].id + 1 : 1) : data.id;
    const detailData = new Detail(data);
    detailData.parent = this;
    this.details = [...this.details, detailData];
    return this._details[this._details.length - 1];
  }

  setDetailsMaterial(details, oldIndex, newIndex) {
    details.forEach(el => {
      const oldMaterial = this.materials.find((material) => material.index === oldIndex);
      const nedMaterial = this.materials.find((material) => material.index === newIndex);
      if (el.material === oldIndex) {
        el.material = newIndex;
      }
      if (['Постформінг', 'Постформинг'].includes(oldMaterial.type) && !['Постформінг', 'Постформинг'].includes(nedMaterial.type)) {
        el.isPostForming = false
      }
      if (!empty(el.multiplicity) && typeof el.multiplicity === 'object') {
        if (el.multiplicity?.material === oldIndex) {
          el.multiplicity.material = newIndex
        }
      }
      this.updateDetail(el.id, 'material', el.material).then(() => this.updateDetail(el.id, 'multiplicity', el.multiplicity)).then(() => this.updateDetail(el.id, 'isPostForming', el.isPostForming))
    })
    return Promise.resolve();
  }

  replaceDetailsMaterial(details, newIndex) {
        details.forEach(el => {
          el.material = newIndex;
          const nedMaterial = this.materials.find((material) => material.index === newIndex);
          if (!['Постформінг', 'Постформинг'].includes(nedMaterial.type)) {
            el.isPostForming = false
          }
          this.updateDetail(el.id, 'material', el.material)
            .then(() => this.updateDetail(el.id, 'isPostForming', el.isPostForming))
        })
        return Promise.resolve();
  }

  updateDetailEdge(detail, oldEdge, newEdge) {
      const edges = {
        top: null,
        bottom: null,
        right: null,
        left: null,
      };
      for(let edge in detail.edges) {
        if(detail.edges[edge] === oldEdge) {
          edges[edge] = newEdge
        } else {
          edges[edge] = detail.edges[edge]
        }
      }

      const corners = detail.corners.map(corner => {
        if(!empty(corner.edge) && corner.edge.index === oldEdge) {
          corner.edge = newEdge
        }
          return corner
      })

      const contour = detail.contour.map(counter => {
        if(!empty(counter.edge) && counter.edge === oldEdge) {
          counter.edge = newEdge
        }
          return counter
      })
    function updateElementEdgeIfNotEmpty(elements, oldEdge, newEdge) {
      elements.forEach((el) => {
        if (!empty(el.edge) && el.edge === oldEdge) {
          el.edge = newEdge;
        }
      });
    }

    const mills = detail.mills.map(mill => {
      if (!empty(mill.edge) && mill.edge === oldEdge) {
        mill.edge = newEdge;
        // Update edges for element collections without repeating code
        if (!empty(mill.mill.elements)) {
          updateElementEdgeIfNotEmpty(mill.mill.elements, oldEdge, newEdge);
        }

        if (!empty(mill.elements)) {
          updateElementEdgeIfNotEmpty(mill.elements, oldEdge, newEdge);
        }

        if (!empty(mill.mill.overallElements)) {
          updateElementEdgeIfNotEmpty(mill.mill.overallElements, oldEdge, newEdge);
        }

        if (!empty(mill.overallElements)) {
          updateElementEdgeIfNotEmpty(mill.overallElements, oldEdge, newEdge);
        }
      }
      return mill
    })

    if (!empty(detail.multiplicity) && typeof detail.multiplicity === 'object') {
      if (detail.multiplicity?.edge === oldEdge) {
        detail.multiplicity.edge = newEdge
      }
    }

      return Promise.all([
        this.updateDetail(detail.id, 'edges', edges),
        this.updateDetail(detail.id, 'corners', corners),
        this.updateDetail(detail.id, 'contour', contour),
        this.updateDetail(detail.id, 'mills', mills),
        this.updateDetail(detail.id, 'multiplicity', detail.multiplicity)])
          .then(() => detail.createContour().contour)
          .then((contour) => detail.updateDbContour(contour.contour))
  }

  updateDetailsEdges(oldEdge, newEdge, details = null) {
    const neededDetails = details ? details : this.details
    const detailsWithOldEdge = neededDetails.filter((detail) =>
      Object.values(detail.edges).some(el => el === oldEdge.index)
	    || detail.corners.some(corner => corner.edge?.index === oldEdge.index || corner.edge === oldEdge.index)
      || detail.contour.some(counter => counter.edge === oldEdge.index)
      || detail.mills.some(mill => mill.edge === oldEdge.index)
      || detail.multiplicity?.edge === oldEdge.index)

    if(detailsWithOldEdge.some(detail => newEdge.width - 3 <= detail.realW)){
      return Promise.reject(Languages.getTranslation('edges-3mm-error', true))
    }
    return new Promise((resolve, reject) => {
      Promise.all(detailsWithOldEdge.map(el => this.updateDetailEdge(el, oldEdge.index, newEdge.index)))
        .then(data => resolve())
        .catch(err => reject({Error: err}))
    })
  }

  updateDetailSideEdge(detail, side, edge) {
    const _edges = cloneDeep(detail.edges);
    _edges[side] = edge;

    return this.updateDetail(detail.id, 'edges', _edges)
  }

  updateDetailGlue(id, glue) {

    projectDb.updateProject(id, 'glue', glue)
        .then(() => {this.projectData = {...this.projectData, glue: glue}})
  }

  updateDetailCount(selectedDetails, newCount) {
    return new Promise((resolve, reject) => {
      Promise.all(selectedDetails.map(detail =>  {
        const newValue = detail.count * newCount
      return this.updateDetail(detail.id, 'count', newValue)
      }))
        .then(data => resolve(this.details))
        .catch(err => {
          toastError(Languages.getTranslation('defaultError', true))
          return reject({Error: err})
        })
    })
  }

  reserveMaterials(id) {
    let ids = []
    this.materials.forEach(el => {
      if (!empty(el.parts)) {
        el.parts.forEach(part => {
          if (!empty(part.id) && part.typeParts === 'kronasElement') {
            ids.push(part.id)
          }
        })
      }
    })
    if (!empty(ids)) {
      return new Promise((resolve, reject) => {
        const bodyRequest = {
          "project_id": id,
          "ids": ids
        }
        Requests.Materials.reservingMaterials(bodyRequest)
          .then(() => Promise.all(this.materials.map(el => {
            if (!empty(el.parts)) {
              const parts = cloneDeep(el.parts)
              parts.forEach( part => {
                if (!empty(part.id) && part.typeParts === 'kronasElement' && part.active === 'act') {
                  part.active = 'res'
                }
              })
              return materialsDb.updateMaterial(el.index, 'parts', parts).then(() => Promise.resolve(parts))
            } else {
              return  Promise.resolve(null)
            }
          })))
          .then((data) => {
            // data.forEach((el, i) => {
            //   if (!empty(el)) {
            //     this.materials[i] = {...this.materials[i], parts: el}
            //   }
            // })
            resolve()
          })
          .catch(err => {
            toastError(Languages.getTranslation('defaultError', true))
            return reject({Error: err})
          })
      })
    }
    return Promise.resolve()
  }

  updateMaterialPartForResidues(id) {

  }

  deleteReservingMaterials(id, ids) {
    const bodyRequest = {
      "project_id": id,
      "status": "act",
      "ids": [ids]
    }
    return new Promise((resolve, reject) => {
      Requests.Materials.deleteReservingMaterials(bodyRequest)
        .then(() => resolve())
        .catch(err => {
          toastError(Languages.getTranslation('defaultError', true))
          return reject({Error: err})
        })
    })
  }

   checkCustomerWarehouseParts(parts) {
    if (!empty(parts)) {
      return Promise.all(parts.map(part =>{
        return Requests.Materials.checkKronasOneElement(part.id)
          .then((res) => {
            if(!empty(res?.data)) {
              if(res.data.active === 'res') {
                part.active = 'res'
              }
            }
            return Promise.resolve(part)
          })
      }))
    }
    return Promise.resolve()
  }

  async checkReservingMaterials(id) {
    let ids = []
    let needNotification = false
    let needNotification2 = false
    const bodyRequest = {
        "project_id": id
    }
      await Requests.Materials.checkKronasElement(bodyRequest)
        .then(data => {
          if (!empty(data?.data)) {
            data?.data.forEach(material =>{
              if (!empty(material.id) && material.active === 'res') {
                ids.push(material.id)
              }
            })
          }
          return Promise.resolve()
        })
        .then(() => {
          return Promise.all(this.materials.map(async (el) => {
            if (!empty(el.parts)) {
              await this.checkCustomerWarehouseParts(el.parts.filter(part => part.hasOwnProperty('placeName')));
              el.parts.forEach(part => {
                if (part.active === 'res'&& !ids.includes(part.id) && part.hasOwnProperty('placeName')) {
                  needNotification2 = true;
                }
                if (part.active === 'res' && !ids.includes(part.id) && !part.hasOwnProperty('placeName')){
                  needNotification = true;
                }
              });
              const newParts = el.parts.filter(part => (part.active === 'act' || (part.active === 'res' && ids.includes(part.id)) || (part.hasOwnProperty('placeName') && part.active !== 'res')));
              return this.updateMaterial(el.index, 'parts', newParts)
                .catch(err => {
                  toastError(Languages.getTranslation('defaultError', true));
                  throw err;
                });
            }
          }));
        })
        .then(() => {
          if (needNotification) {
            toastError(Languages.getTranslation('text-delete-part-kronasElement2', true))
          }
          if (needNotification2) {
            toastError(Languages.getTranslation('text-delete-part-customerWarehouse', true))
          }
        })
        .catch(err => {toastError(Languages.getTranslation('defaultError', true))
          return Promise.reject(err)
        })
      return Promise.resolve()
  }

  /**
   * Creates a products import using the given array of product entries, the product details and selected details.
   *
   * @param {Array} arr - The array of product entries.
   * @param {Object} products - The product details.
   * @param {Object} selectedDetails - The selected details.
   * @returns {Promise} A promise that resolves when the products import is created.
   */
  createProductsImport(arr, products, selectedDetails){
    const self = this;
    return new Promise((resolve, reject) => {
      function addProduct(pro){
        if(!empty(pro)){
          const [proId, proValues] = pro;
          const tempProduct = {...products[proId - 1]};
          if(!tempProduct.hasOwnProperty('name') || tempProduct.name === ''){
            tempProduct.name = 'product'
          }
          delete tempProduct.id;

          self.createProduct(tempProduct)
              .then(id => {
                proValues.forEach(el => {
                  selectedDetails[el].productId = id
                })
              })
              .then(() => addProduct(arr.shift()))
              .catch(error => reject(error))
        }else{
          return resolve()
        }
      }
      addProduct(arr.shift());
    })
  }

  /**
   * Creates a new product and adds it to the list of products.
   *
   * @param {*} product - The product to be created.
   * @returns {Promise<number>} - A promise that resolves with the id of the created product.
   * @throws {Error} - If there is an error creating the product.
   */
  createProduct(product) {
    return productsDb.addProduct(product)
      .then((item) => {
        this.products.push(item)
        return Promise.resolve(item.id)
      })
      .catch(err => {
        toastError(Languages.getTranslation('defaultError', true))
        return Promise.reject(err)
      })
  }

  deleteProduct(id) {
    return productsDb.deleteProduct(id)
      .then(() => {
        this.products = this.products.filter(product => product.id !== id)
        return Promise.resolve()
      })
      .catch(err => {
        toastError(Languages.getTranslation('defaultError', true))
        return Promise.reject(err)
      })
  }

  updateProduct(id, field, value) {
    return productsDb.updateProduct(id, field, value).then(() => {
      this.products.forEach(product => {
        if (product.id === id) {
          product[field] = value
        }
      })
      return Promise.resolve()
    })
      .catch(err => {
        toastError(Languages.getTranslation('defaultError', true))
        return Promise.reject(err)
      })
  }



}


