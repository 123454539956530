import ACTIONS from "./actions";

const switchStateForHideAll = ({ show }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.HideAllStateSwitch({ show: show }));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  switchStateForHideAll,
};
