import API from "../config/api";

export default class YoutubeV3API {
  constructor(axios) {
    this.axios = axios;
  }
  
  async fetchVideoFromPlaylist() {
    const PLAYLIST_ID = "PL4on7YuYKzf_PQwJ3zBqPwAROAm9Ufi1H";
    return this.axios.get(API.ROUTE.fetchYoutubeVideos(PLAYLIST_ID)).then(response => {
      if (response.data && response.status === 200) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject()
      }
    })
  }
}