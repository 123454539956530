import React from 'react'
import PrivateTemplate from "../../views/PrivateTemplate";
import './style.scss';
import CostTable from "../../components/UI/Tables/CostTable";
import Price from '../../components/UI/Price/Price'
import Languages from "../../translation/Languages";
const EstimatedCost = () => {
    const data = JSON.parse(localStorage.getItem('data'));
    const materials = data?.data.filter(({typeNomenclature}) => typeNomenclature === "material")
    const services = data?.data.filter(({typeNomenclature}) => typeNomenclature === "service")
    const cost = data?.data.filter(({typeNomenclature}) => typeNomenclature === "ordersum")
    const costMaterials = materials.reduce((acc, material) => acc + material.sum, 0)
    const servicesMaterials = services.reduce((acc, material) => acc + material.sum, 0)
    return <div>
        <section className='costPage'>
            <div className='costPage__title-main'>
                <span className="costPage__title-main-one">{Languages.getTranslation('project-calculation', true)} : {data?.projectId}</span>
               <span>
                <span className="costPage__title-main-item">
                   <span style={{fontWeight: 700, marginRight: '10px'}}> {Languages.getTranslation('client', true)} :</span>
                    {data?.user?.name},</span>
                <span className="costPage__title-main-item">{data?.user?.phone},</span>
                <span className="costPage__title-main-item">{data?.user?.email}</span>
                </span>

            </div>

            <div>
                <h2 className='costPage__title'>{Languages.getTranslation('materials', true)}</h2>
                {materials.length && <CostTable data={materials}/>}
                <Price price={costMaterials} type={Languages.getTranslation('material-general', true)}/>
            </div>
            <div>
                <h2 className='costPage__title'>{Languages.getTranslation('services', true)}</h2>
                {services.length && <CostTable data={services} service={true}/>}
                <Price price={servicesMaterials} type={Languages.getTranslation('services-general', true)}/>
            </div>
            <div>
                <Price price={cost[0].ordersum} type={Languages.getTranslation('in-general', true)}/>
            </div>
        </section>
    </div>
}
export default EstimatedCost
