import Languages from "../translation/Languages";
import cloneDeep from "lodash.clonedeep";

export function isset(val) {
	let res = true;
	if (typeof val === 'undefined' || val === null) {
		res = false;
	}
	if (res && (typeof val === 'number' && isNaN(val))) {
		res = false
	}
	return res
}

export function isDefined(val) {
	return typeof val !== "undefined"
}

export function empty(val) {
	try {
		if (!isset(val)) {
			return true;
		} else if (typeof val === 'object' && ((Array.isArray(val) && val.length === 0) || Object.keys(val).length === 0)) {
			return true;
		} else {
			return val.length === 0
		}
	} catch (e) {
		return true;
	}
}

export function isNumber(val) {
	return typeof val === 'number' && !isNaN(val);
}

export const resetMaterialArticle = (article) => {
	const newArticle = article.split('_')
	return newArticle[0]
}



export function prepareObject(data) {
	const newArr = [];
	if (!Array.isArray(data)) {
		return data;
	}
	data.forEach(el => {
		if (typeof el === "object") {
			if (Array.isArray(el)) {
				const _newArray = [];
				el.forEach(_el => {
					if (typeof _el !== "object") {
						_newArray.push(_el);
					}
				})
				newArr.push(_newArray);
			} else {
				const newObj = {}
				for (const index in el) {
					const name = (index.indexOf('_') === 0) ? index.substring(1) : index;
					if ([
						'comment',
						'side',
						'direction',
						'x_axis',
						'y_axis',
						'additionalInfo',
						'r',
						'width',
						'height',
						'depth',
						'edge',
						'ext',
						'x',
						'y',
						'diam',
						'type',
						'z',
						'overallElements',
						"projectId",
						'angle',
						"facade",
						'dataForConstructor',
						"compactLockType",
						'elements',
						'subType',
						"quant",
						'doubleSmiles',
						'edgeSide',
						'start',
						'alpha',
						'fullDepth',
						'additionalSide',
						'additionalTreats',
						'additionalCutter',
						"contourId",
						'offset',
						'templateData',
						'preCutting',
						'id',
						'detailId',
						'templateId',
						'touchedSides',
						'typeR',
						'templateType',
						'isTemplate',
						'x1', 'x2','y1','y2','xc', 'yc','type','edge','holes', 'length','startAngle','endAngle','startAngleRad','endAngleRad','color','dir'
					].indexOf(name) !== -1) {
						newObj[name] = el[index]
					}
				}
				newArr.push({...newObj})
			}
		} else {
			newArr.push(el)
		}
	})
	return newArr
}

export function edgeTitle(edge) {
	if (edge){
	if (+edge.article >= 100_115_000) {
		return edge.name
			? `${edge.name} ${edge.width}x${edge.thickness}`
			: `${Languages.getAvaliableTranslation(edge.languages)} ${edge.thickness}x${edge.width}`
	}
	return edge.name
		? edge.name
		: Languages.getAvaliableTranslation(edge.languages)
	}
	return null
}

export const createMaterialTitle = (material) => {
	return material.hasOwnProperty('client_id') ? material.name : materialTitle(material)
}

export function materialTitle(material) {
	try{
		if ( !empty(material) && Number(material.article) >= 100_018_000 || material.hasOwnProperty('mc_id')) {
			return material.name
				? `${material.name} ${material.height}x${material.width}x${material.thickness}`
				: `${Languages.getAvaliableTranslation(material.languages)} ${material.height}x${material.width}x${material.thickness}`
		}
		if (material.languages && !empty(Languages.getAvaliableTranslation(material.languages))) {
			return Languages.getAvaliableTranslation(material.languages)
		}
		return material.name
			? material.name
			: Languages.getAvaliableTranslation(material.languages)
	}catch (error){
		console.log(error)
	}
}

/**
 * Validates the details of a value based on given parameters.
 * @param {string} value - The value to be validated.
 * @param {object} material - The material object containing details.
 * @param {string} name - The name of the detail being validated.
 * @param {number|null} [bottom=null] - Optional bottom value.
 * @param {number|null} [top=null] - Optional top value.
 * @returns {boolean} - True if the value passes the validation, false otherwise.
 */
export const validateDetail = (value, material, name, bottom = null, top = null) => {
	const newName = name === 'height' ? 'width' : 'height'
	if (value.length >= 3) {
		if(['Постформинг', 'Постформінг'].includes(material.type )) {
			if(bottom) { value += 20}
			if(top) { value += 20}
			return ((+value > material[newName]) || (material[newName] < +value));
		}
		return ((+value > material[newName]) || (material[newName] - 16 < +value))
	}
}

export 	const validationInput = (el) => {
	el.target.value = el.target.value.replace(/[^0-9]+/g, "");
	if (el.target.min && el.target.max) {
		el.target.value = Number(el.target.value) < Number(el.target.min) ? null : Number(el.target.value) > Number(el.target.max) ? el.target.max : el.target.value
	}
};

export const checkDetailsWithEdges = (details, edge) =>{
	return details.filter((detail) =>
		Object.values(detail.edges).some(el => el === edge)
		|| detail.corners.some(corner => corner.edge?.index === edge || corner.edge === edge)
		|| detail.contour.some(counter => counter.edge === edge)
		|| detail.mills.some(mill => mill.edge === edge)
		|| detail.multiplicity?.edge === edge)
}

/**
 * Checks if a detail has any milling operations.
 *
 * @param {Object} detail - The detail object to check.
 * @return {boolean} - Returns true if the detail has any milling operations, otherwise false.
 */
export const checkForMilling = (detail) => {
	return !empty(detail.corners) || !empty(detail.bevels) || !empty(detail.cutouts) || !empty(detail.mills) || !empty(detail.rectangles) || !empty(detail.circles)
}
/**
 * Checks if there are any handles present in the given detail.
 *
 * @param {Object} detail - The detail object to check for handles.
 * @returns {boolean} - Returns true if there are any handles, otherwise false.
 */
export const checkForAnyHandles = (detail) => {
	return checkForMilling(detail) || !empty(detail.holes) || !empty(detail.grooves) || !empty(detail.rabbets) || !empty(detail.multiplicity)
}
export function isFractional(num) {
	return num % 1 !== 0;
}


const interObject = {
	'dy': 'this.detail.h', // h detail
	'dx': 'this.detail.l', // l detailD
	'dz': 'this.detail.w', // w detailD
	'hh': 'this.depth', // depth processing
	'pr': 'this.r', // radius processing
	'pw': 'this.width', // width processing
	'ph': 'this.height', // height processing
	'po': 'this.offset', // offset processing
	'px': 'this.x', // x processing
	'py': 'this.y', // y processing
}

/**
 * Interprets a formula by replacing keywords with their corresponding values from an interObject.
 * @param {string} formula - The formula to be interpreted.
 * @returns {string} - The interpreted formula with replaced keywords.
 */
export const interpreter = (formula) => {
	let result = formula
	Object.keys(interObject)
		.sort((a, b) => b.length - a.length)
		.forEach(key => {
			result = result.replace(new RegExp('\\b' + key + '\\b', 'g'), interObject[key]);
		});
	return result;
}

export function sortArray(arr, key, value) {
	return arr.sort((a, b) => {
		if (a[key] === value && b[key] !== value) {
			return -1; // a имеет приоритет перед b
		} else if (a[key]!== value && b[key] === value) {
			return 1; // b имеет приоритет перед a
		}
		return 0; // Иначе, не менять порядок
	});
}

export function sortKeys(obj, key) {
	const entries = Object.entries(obj);
	entries.sort(([keyA], [keyB]) => {
		if (keyA === 'null') return -1;
		if (keyB === 'null') return 1;
		return 0;
	});
	return Object.fromEntries(entries);
}
export function filterPreCutting(preCutting, side) {
	const opposingSides = {
		top: 'bottom',
		bottom: 'top',
		left: 'right',
		right: 'left'
	};

	const oppositeSide = opposingSides[side];

	let newPreCutting = {};

	for (let key in preCutting) {
		newPreCutting[key] = null;
	}


	if (oppositeSide) {
		newPreCutting[oppositeSide] = preCutting[oppositeSide];
	}

	return newPreCutting;
}

export function sortByType(array, type) {
	return array.sort((a, b) => {
		if (a.type === type && b.type !== type) {
			return -1;
		}

		if (b.type === type && a.type !== type) {
			return 1;
		}

		return 0;
	})
}

/**
 * Maps array of detail objects to cache objects based on subType
 * @param {Array} detailArray - Array of detail objects
 * @returns {Array} - Array of cache objects
 */
const mapDetailToCache = detailArray => detailArray.map((el) => {
		switch(el.subType){
			case 'groove':
				return { width: el.width, height: el.height, x: el.x, y: el.y, side: el.side, direction: el.direction };
			case 'corner':
				return { width: el.width, height: el.height, x: el.x, y: el.y, angle: el.angle, r: el.r };
			case 'circle':
				return { width: el.width, height: el.height, x: el.x, y: el.y, r: el.r, x_axis: el.x_axis, y_axis: el.y_axis, fullDepth: el.fullDepth.toString() };
			case 'rectangle':
				return { width: el.width, height: el.height, x: el.x, y: el.y, r: el.r, x_axis: el.x_axis, y_axis: el.y_axis, fullDepth: el.fullDepth.toString() };
			case 'rabbet':
				return {
					width: el.width,
					height: el.height,
					x: el.x, y: el.y, side: el.side,
					edgeSide: el.edgeSide,
					r: el.r, depth:
					el.depth, ext: el.ext };
			case 'mill':
				switch (el.type){
					case 'gEdge':
						return { width: el.width, height: el.height, x: el.x, y: el.y, angle: el.angle, r: el.r };
					case 'uShape':
						return { width: el.width, height: el.height, x: el.x, y: el.y, edgeSide: el.edgeSide, r: el.r };
					case 'smile':
						return { width: el.width, height: el.height, x: el.x, y: el.y, edgeSide: el.edgeSide};
					case 'radiusEdge':
						return { width: el.width, height: el.height, x: el.x, y: el.y, angle: el.angle, r: el.r };
					case 'partial':
						if(el.hasOwnProperty('touchedSides')){
							return { width: el.width, height: el.height, x: el.x, y: el.y, touchedSides: el.mill.touchedSides, r: el.r };
						}else{
							return { width: el.width, height: el.height, x: el.x, y: el.y, r: el.r };
						}
					default:
						return { width: el.width, height: el.height, x: el.x, y: el.y };
				}
			default:
				return { width: el.width, height: el.height, x: el.x, y: el.y };
		} }),
	processes = ['corners', 'grooves', 'mills', 'circles', 'rectangles', 'rabbets', 'cutouts'];

const cacheKey = (detail, h, l) => JSON.stringify({
		l, h, preCutting: detail.preCutting, id: detail.id, name: detail.name,
		length: processes.map(el => detail[el].length ? detail[el].length : ''),
		corners: mapDetailToCache(detail.corners),
		grooves: mapDetailToCache(detail.grooves),
		mills: mapDetailToCache(detail.mills),
		circles: mapDetailToCache(detail.circles),
		rectangles: mapDetailToCache(detail.rectangles),
		rabbets: mapDetailToCache(detail.rabbets) }),
	_l_h = {
		largeX: 0,
		largeY: 0,
		x: null,
		y: null,
		width: null,
		height: null,
		angle: null,
		edgeSide: null,
		touchedSides: []},
	cache = {},
	preCutting = {
		left: null,
		right: null,
		top: null,
		bottom: null },
	someArr = [];

/**
 * Sets the values of `preCutting` object based on the provided `detail` object.
 *
 * @param {Object} detail - The detail object containing width, height, touchedSides, angle, or edgeSide.
 *
 * @return {Object} - The `preCutting` object with updated values.
 */
export function toAddSide (detail) {
	if(!empty(_l_h.touchedSides)){
		if (!empty(_l_h.width)) {
			if (_l_h.touchedSides.includes('right') && !_l_h.touchedSides.includes('left')) {
				preCutting.right = null;
				preCutting.left = _l_h.width;
			}else if (_l_h.touchedSides.includes('left') && !_l_h.touchedSides.includes('right')) {
				preCutting.left = null;
				preCutting.right = _l_h.width;
			}
		}
		if (!empty(_l_h.height)) {
			if (_l_h.touchedSides.includes('top') && !_l_h.touchedSides.includes('bottom')) {
				preCutting.top = null;
				preCutting.bottom = _l_h.height;
			}else if (_l_h.touchedSides.includes('bottom') && !_l_h.touchedSides.includes('top')) {
				preCutting.bottom = null;
				preCutting.top = _l_h.height;
			}
		}
	}
	else if(!empty(_l_h.angle)){
		if (!empty(_l_h.width)) {
			if (_l_h.angle.includes('right')) {
				preCutting.right = null;
				preCutting.left = _l_h.width;
			}else if (_l_h.angle.includes('left')) {
				preCutting.left = null;
				preCutting.right = _l_h.width;
			}
		}
		if (!empty(_l_h.height)) {
			if (_l_h.angle.includes('top')) {
				preCutting.top = null;
				preCutting.bottom = _l_h.height;
			}else if (_l_h.angle.includes('bottom')) {
				preCutting.bottom = null;
				preCutting.top = _l_h.height;
			}
		}
	}
	else if(!empty(_l_h.edgeSide)){
		if (!empty(_l_h.width)) {
			if (_l_h.edgeSide === 'right') {
				preCutting.right = null;
				preCutting.left = _l_h.width;
			}else if (_l_h.edgeSide === 'left') {
				preCutting.left = null;
				preCutting.right = _l_h.width;
			}
		}
		if (!empty(_l_h.height)) {
			if (_l_h.edgeSide === 'top') {
				preCutting.top = null;
				preCutting.bottom = _l_h.height;
			}else if (_l_h.edgeSide === 'bottom') {
				preCutting.bottom = null;
				preCutting.top = _l_h.height;
			}
		}
	}
	else {
		if (!empty(_l_h.width)) {
			if (!detail.checkProcessing('right')) {
				preCutting.left = null;
				preCutting.right = _l_h.width;
			} else if (!detail.checkProcessing('left')) {
				preCutting.right = null;
				preCutting.left = _l_h.width;
			}
			if (detail.checkProcessing('right')) {
				preCutting.right = null;
			}
			if (detail.checkProcessing('left')) {
				preCutting.left = null;
			}
			if(detail.checkProcessing('right') && detail.checkProcessing('left')){
				detail.parent.updateDetail(detail.id, 'increase', false);
			}
		}
		if (!empty(_l_h.height)) {
			if (!detail.checkProcessing('bottom')) {
				preCutting.top = null;
				preCutting.bottom = _l_h.height;
			} else if (!detail.checkProcessing('top')) {
				preCutting.bottom = null;
				preCutting.top = _l_h.height;
			}
			if (detail.checkProcessing('top')) {
				preCutting.top = null;
			}
			if (detail.checkProcessing('bottom')) {
				preCutting.bottom = null;
			}
			if(detail.checkProcessing('bottom') && detail.checkProcessing('top')){
				detail.parent.updateDetail(detail.id, 'increase', false);
			}
		}
	}
	if(empty(_l_h.width)){
		preCutting.right = preCutting.left = null
	}
	if(empty(_l_h.height)){
		preCutting.top = preCutting.bottom = null
	}
	if(!empty(detail.facade && !empty(detail.templates))){
        let tempHandles =  detail.templates.find(({templateType}) => templateType === 'handles')
        if(!empty(tempHandles)){
		let result = filterPreCutting(preCutting, tempHandles.edgeSide);
		Object.assign(preCutting, result);

		}
	}
	_l_h.touchedSides = [];
	return preCutting;
}

/**
 * Creates an array of points representing the angles of a given angle object.
 * @param {Object} angle - The angle object with properties 'x', 'y', 'r', 'detail', and 'type'.
 * @param {number} angle.x - The x-coordinate of the angle.
 * @param {number} angle.y - The y-coordinate of the angle.
 * @param {number} angle.r - The radius of the angle.
 * @param {Object} angle.detail - The detail object with properties 'h' and 'l'.
 * @param {number} angle.detail.h - The height of the angle.
 * @param {number} angle.detail.l - The length of the angle.
 * @param {string} angle.angle - The type of angle ('left_top', 'left_bottom', 'right_top', 'right_bottom').
 * @param {string} angle.type - The type of angle ('line', 'circle').
 * @return {Array<Object>} - An array of point objects representing the angles.
 */
function createAngles(angle){
	if(!empty(angle.detail?.h) && !empty(angle.detail?.l)){
		const { x, y, r } = angle, { h, l } = angle.detail;
		switch (angle.angle){
			case "left_top":
				return angle.type === 'line'
					? [{x: 0 , y: 0}, {x: x, y: 0}, {x: 0, y: y}]
					: [{x: 0 , y: 0}, {x: r, y: 0}, {x: 0, y: r}];
			case "left_bottom":
				return angle.type === 'line'
					? [{x: 0 , y: h}, {x: x, y: h}, {x: 0, y: h - y}]
					: [{x: 0 , y: h}, {x: r, y: h}, {x: 0, y: r}];
			case "right_top":
				return angle.type === 'line'
					? [{x: l , y: 0}, {x: l, y: y}, {x: l - x, y: 0}]
					: [{x: l , y: 0}, {x: l, y: r}, {x: l - x, y: 0}];
			case "right_bottom":
				return angle.type === 'line'
					? [{x: l , y: h}, {x: l - x, y: h}, {x: l, y: h - y}]
					: [{x: l , y: h}, {x: l - r, y: h}, {x: l, y: h - r}];
			default:
				break;
		}
	}
}

/**
 * Creates vertices based on the mill elements.
 *
 * @param {Object} mill - The mill object containing elements.
 * @property {Array} mill.elements - The array of mill elements.
 * @property {string} mill.elements[].type - The type of mill element ("line" or "arc").
 * @property {number} mill.elements[].x1 - The x-coordinate of the starting point.
 * @property {number} mill.elements[].y1 - The y-coordinate of the starting point.
 * @property {number} mill.elements[].x2 - The x-coordinate of the ending point.
 * @property {number} mill.elements[].y2 - The y-coordinate of the ending point.
 * @returns {Array} The array of created vertices.
 */
function createVertices(mill) {
	let lines = mill.elements;
	let vertices = [];
	lines.forEach(el => {
		if (el.type === "line") {
			vertices.push({x: Math.round(el.x1), y: Math.round(el.y1)});
		} else if (el.type === "arc") {
			vertices.push({x: Math.round(el.x1), y: Math.round(el.y1)});
		}
	});
	const lastEl = lines[lines.length - 1];
	vertices.push({x: lastEl?.x2, y: lastEl?.y2})
	return vertices;
}

/**
 * Calculates the cross product of two vectors represented in 2D space.
 *
 * @param {number} ax - The x-component of the first vector.
 * @param {number} ay - The y-component of the first vector.
 * @param {number} bx - The x-component of the second vector.
 * @param {number} by - The y-component of the second vector.
 * @returns {number} The cross product of the two vectors.
 */
function crossProduct(ax, ay, bx, by) {
	return ax * by - ay * bx;
}

/**
 * Determines if a point is on a line segment defined by two endpoints.
 *
 * @param {number} ax - x-coordinate of the first endpoint.
 * @param {number} ay - y-coordinate of the first endpoint.
 * @param {number} bx - x-coordinate of the second endpoint.
 * @param {number} by - y-coordinate of the second endpoint.
 * @param {number} px - x-coordinate of the point to be checked.
 * @param {number} py - y-coordinate of the point to be checked.
 * @returns {boolean} True if the point is on the line segment, false otherwise.
 */
function isPointOnLine(ax, ay, bx, by, px, py) {
	const EPSILON = 1e-14;
	let cp1 = crossProduct(bx - ax, by - ay, px - ax, py - ay);
	if (Math.abs(cp1) > EPSILON) {
		return false;
	}
	let dotp = (bx - ax) * (px - ax) + (by - ay) * (py - ay);
	if (dotp < 0) {
		return false;
	}
	let squaredLengthBA = Math.pow(bx - ax, 2) + Math.pow(by - ay, 2);
	if (dotp > squaredLengthBA) {
		return false;
	}
	return true;
}

/**
 * Determines whether a given point is inside a polygon.
 *
 * @param {Array} polyPoints - The array of polygon points.
 * @param {Object} point - The point to check.
 * @param {number} point.x - The x-coordinate of the point.
 * @param {number} point.y - The y-coordinate of the point.
 *
 * @return {boolean} Returns `true` if the point is inside the polygon, otherwise `false`.
 */
function isPointInsidePoly(polyPoints, point) {
	let inside = false;
	let j = polyPoints.length - 1;
	for (let i = 0; i < polyPoints.length; j = i++) {
		let xi = polyPoints[i].x, yi = polyPoints[i].y;
		let xj = polyPoints[j].x, yj = polyPoints[j].y;
		let intersect = ((yi > point.y) !== (yj > point.y)) && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
		if (intersect) {
			inside = !inside;
		}
		if (isPointOnLine(xi, yi, xj, yj, point.x, point.y)) {
			return true;
		}
	}
	return inside;
}

/**
 * Modifies a 2D array by marking cells inside a given polygon with a specified name.
 *
 * @param {Array<Array<string>>} array - The 2D array to be modified.
 * @param {Array<{x: number, y: number}>} polygon - The polygon defining the boundary.
 * @param {string} name - The name to mark the cells inside the polygon.
 *
 * @returns {Array<Array<string>>} - The modified 2D array with cells inside the polygon marked with the specified name.
 */
function modifyArrayByPolygon(array, polygon, name) {
	if(!empty(polygon) && polygon?.length > 0){
		for (let i = 0; i < array.length; i++) {
			for (let j = 0; j < array[i].length; j++) {
				if (isPointInsidePoly(polygon, {x: j, y: i}, name) && array[i][j] !== '1') {
					array[i][j] = '1';
				}
			}
		}
	}
	return array;
}

/**
 * Represents the detail of a variable.
 *
 * @typedef {Object} Detail*/
export function findEmptySpace( /**
								 * Represents the detail of a variable.
								 *
								 * @typedef {Object} Detail
								 * @property {string} name - The name of the variable.
								 * @property {string} type - The type of the variable.
								 * @property {string} description - The description of the variable.
								 * @property {boolean} isRequired - Indicates if the variable is required or optional.
								 */
								detail, /**
								 * Represents the height of an object.
								 *
								 * @typedef {number} Height
								 */
							   h, /**
								 * Represents a variable `l`.
								 *
								 * @type {any}
								 * @description This variable is used to store an arbitrary value.
								 *
								 * @example
	 *
	 * // Initializing `l` with a number
	 * let l = 123;
	 *
	 * // Updating `l` with a string value
	 * l = 'example';
	 *
	 * // Assigning an object to `l`
	 * l = { foo: 'bar' };
	 *
	 * // Accessing the value stored in `l`
	 * console.log(l); // Output: { foo: 'bar' }
								 *
								 * @since 1.0.0
								 */
							   l, /**
								 * Indicates whether something is the first occurrence or not.
								 *
								 * @type {boolean}
								 */
							   first = true){
	const key = cacheKey(detail, h, l), arrPosition = [];
	if(cache[key]) {
		_l_h.width = cache[key].temp_l_h.width
		_l_h.height = cache[key].temp_l_h.height
		if(!empty(cache[key].temp_l_h.touchedSides)) { _l_h.touchedSides = cache[key].temp_l_h.touchedSides }
		cache[key].arrPosition.forEach(el => someArr.push(el));
		return cache[key];
	}

	if (arrPosition.length === 2){
		arrPosition.forEach(el => someArr.push(el))
		return null
	}

	const PADDING = 10;
	const tempDetail = cloneDeep(detail),
		rectangle_A = { width: 155 + PADDING, height: 100 + PADDING },
		rectangle_B = { width: 155 + PADDING, height: 100 + PADDING },
		rectangle_A_rev = { width: 100 + PADDING, height: 155 + PADDING },
		rectangle_B_rev = { width: 100 + PADDING, height: 155 + PADDING };
	let {_l, _h} = tempDetail, largesEmpty;
	let virtualArray = Array.from({ length: Math.floor(_h) }, () => new Array(Math.floor(_l)).fill(0));
	if(_l === l){ _l_h.width = null }
	if(_h === h){ _l_h.height = null }

	processes.forEach(el => {
		if(!empty(tempDetail[el])){
			tempDetail[el].forEach(proc => {
				if(!empty(proc.subType)) {
					const { x, y, width, height, r } = proc;
					let forI, forJ, top = Math.round(_h - y - height - 1)
					switch (proc.subType) {
						case 'corner':
							virtualArray = modifyArrayByPolygon(virtualArray, createAngles(proc), detail.name)
							break;
						case 'groove':
							const newY = _h - y - height
							for (let i = 0; i < virtualArray.length; i++) {
								for (let j = 0; j < virtualArray[i].length; j++) {
									if (proc._side === 'back') {
										if (proc.direction === 'hor') {
											if (i >= newY && i < newY + height && j <= width && j >= x) {
												try{
													virtualArray[i][j] = '1';

												}catch (e){
													// Catch the error but do nothing with it
												}											}
										}
										if (proc.direction === 'ver') {
											if (i >= newY - width && i <= newY && j >= x - 1 && j < x + height) {
												try{
													virtualArray[i][j] = '1';

												}catch (e){
													// Catch the error but do nothing with it
												}
											}
										}
									}
									if (proc._side === 'front') {
										if (proc.direction === 'hor') {
											if (i >= newY - 1 && i < newY + height && j <= width && j >= x && virtualArray[i][j] === 0) {
												try{
													virtualArray[i][j] = 'f'

												}catch (e){
													// Catch the error but do nothing with it
												}											}
										}
										if (proc.direction === 'ver') {
											if (i >= newY - width && i <= newY && j >= x - 1 && j < x + height && virtualArray[i][j] === 0) {
												try{
													virtualArray[i][j] = 'f'

												}catch (e){
													// Catch the error but do nothing with it
												}
											}
										}
									}
								}
							}
							break;
						case 'mill':
							switch (proc.type) {
								case 'gEdge':
									top = _h - height - y
									for (let i = 0; i < virtualArray.length; i++) {
										for (let j = 0; j < virtualArray[i].length; j++) {
											switch (proc._angle) {
												case 'left_top':
													if (j <= width && i <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'left_bottom':
													if (virtualArray.length - height <= i && j <= width) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'right_top':
													if (virtualArray[i].length - width <= j && i <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'right_bottom':
													if (virtualArray.length - height <= i && j >= proc.x) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
											}
										}
									}
									break;
								case 'uShape':
									for (let i = 0; i < virtualArray.length; i++) {
										for (let j = 0; j < virtualArray[i].length; j++) {
											switch (proc.edgeSide) {
												case 'left':
													if (i >= virtualArray.length - y - width && i <= virtualArray.length - y && j <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}
													}
													break;
												case 'right':
													const newOffset = _h - proc.offset - width - 1
													if (i >= newOffset && i <= width + newOffset && j >= l - height - 1 && j <= virtualArray[i].length) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'top':
													if (i <= height && j >= x && j <= width + x) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'bottom':
													if (i >= _h - y - height - 1 && j <= width + x && j >= x) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
											}
										}
									}
									break;
								case 'smile':
									for (let i = 0; i < virtualArray.length; i++) {
										for (let j = 0; j < virtualArray[i].length; j++) {
											switch (proc.edgeSide) {
												case 'left':
													if (i >= virtualArray.length - y - width && i <= virtualArray.length - y && j <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'right':
													const newOffset = _h - proc.offset - width - 1
													if (i >= newOffset && i <= width + newOffset && j >= virtualArray[i].length - height - 1) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'top':
													if (i > virtualArray.length - y - height && i <= virtualArray.length - y && j <= width && j >= x) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'bottom':
													if (i >= virtualArray.length - y - height - 1 && j <= width + x && j >= x) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}
													}
													break;
											}
										}
									}
									break;
								case 'radiusEdge':
									top = _h - height - y
									for (let i = 0; i < virtualArray.length; i++) {
										for (let j = 0; j < virtualArray[i].length; j++) {
											switch (proc._angle) {
												case 'left_top':
													if (j <= width && i <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}
													}
													break;
												case 'left_bottom':
													if (virtualArray.length - height <= i && j <= width) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'right_top':
													if (virtualArray[i].length - width <= j && i <= height) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
												case 'right_bottom':
													if (virtualArray.length - height <= i && virtualArray[i].length - width <= j) {
														try{
															virtualArray[i][j] = '1';

														}catch (e){
															// Catch the error but do nothing with it
														}													}
													break;
											}
										}
									}
									break;
								case 'partial':
									if(proc.elements.length === 0){
										break;
									}
									if((h > 170 && l > 350) || (l > 170 && h > 350)){
										break;
									}
									virtualArray = modifyArrayByPolygon(virtualArray, createVertices(proc), detail.name)
									if(proc.mill.hasOwnProperty('touchedSides')){
										_l_h.touchedSides = proc.mill.touchedSides;
									}
							}
							break;
						case 'circle':
							forI = Math.round(top + height); forJ = Math.round(x - r + width)
							for (let i = top; i < forI; i++) {
								for (let j = Math.round(x - r - 1); j < forJ; j++) {
									if(i > 0 && i <= h && j > 0 && j <= l) {
										try{
										  virtualArray[i][j] = proc.fullDepth ? '1' : 'f';
										}catch (e){
											// Catch the error but do nothing with it
										}
									}
								}
							}
							break;
						case 'rectangle':
							forI = Math.round(top + height);
							forJ = Math.round(x + width)

							for (let i = top; i < forI; i++) {
								for (let j = Math.round(x - 1); j < forJ; j++) {
									if(i > 0 && i <= h && j > 0 && j <= l){
                                           try{
											   virtualArray[i][j] = proc.fullDepth ? '1' : 'f';

										   }catch (e){
											   // Catch the error but do nothing with it
										   }
									}
								}
							}
							break;
						case 'rabbet':
							const tempWidth = _h - y - width, tempHeight = _h - y - height;
							for (let i = 0; i < virtualArray.length; i++) {
								for (let j = 0; j < virtualArray[i].length; j++) {
									switch (proc.edgeSide) {
										case 'left':
											if (i >= tempWidth && i <= _h - y && j < height) {
												try{
													virtualArray[i][j] = proc.side === 'front' ? 'f' : '1';

												}catch (e){
													// Catch the error but do nothing with it
												}
											}
											break;
										case 'top':
											if (i < height && j < x + width && j >= x) {
												try{
													virtualArray[i][j] = proc.side === 'front' ? 'f' : '1';
												}catch (e){
													// Catch the error but do nothing with it
												}
											}
											break;
										case 'right':
											if (i <= h - y && i >= h - y - height && j >= x - 1) {
												try{
													virtualArray[i][j] =  proc.side === 'front' ? 'f' : '1';
												}catch (e){
													// Catch the error but do nothing with it
												}
											}
											break;
										case 'bottom':
											if (i >= tempHeight && i < _h - y && j < x + width && j >= x - 1) {
												try{
													virtualArray[i][j] = proc.side === 'front' ? 'f' : '1';
												}catch (e){
													// Catch the error but do nothing with it
												}
											}
											break;
									}
								}
							}
							break;
						default:
							break;
					}
				}
			})
		}
	})

	if(!empty(detail.cutouts)){
		detail.cutouts.forEach(proc => {
			let symbol = '1';
			const heightC = Math.floor(proc.height), widthC = Math.floor(proc.width), xC = Math.floor(proc.x), yC = Math.floor(_h) - Math.floor(proc.y) - Math.floor(proc.height);
			if(proc.depth < detail.w && proc.side === 'front'){ symbol = 'f'; }

			for (let i = 0; i < virtualArray.length; i++) {
				for (let j = 0; j < virtualArray[i].length; j++) {
					if (i < heightC + yC && i >= yC - 1
						&& j < widthC + xC && j >= xC - 1 && virtualArray[i][j] !== '1') {
						virtualArray[i][j] = symbol;
					}
				}
			}
		})
	}

	const findLargestEmptyRectangle = () => {
		let maxSize = 0, startCoordinates = {x: -1, y: -1}, endCoordinates = {x: -1, y: -1}, runCount = [], stack = [];

		for (let x = 0; x < virtualArray[0].length; x++) {
			runCount[x] = virtualArray[0][x] === 0 || virtualArray[0][x] === 'f' ? 1 : 0;
			if (runCount[x] > 0) {
				stack.push(x);
			}
		}

		for (let y = 1; y < virtualArray.length; y++) {
			let currentX = 0;

			while (currentX < virtualArray[0].length) {
				if (virtualArray[y][currentX] === 0 || virtualArray[y][currentX] === 'f') {
					runCount[currentX]++;
				} else {
					runCount[currentX] = 0;
				}

				if (stack.length > 0) {
					if (runCount[currentX] > runCount[stack[stack.length - 1]]) {
						stack.push(currentX);
					} else if (runCount[currentX] < runCount[stack[stack.length - 1]]) {
						let poppedX = 0;
						while (stack.length > 0 && runCount[currentX] < runCount[stack[stack.length - 1]]) {
							poppedX = stack.pop();
							let rectSize = runCount[poppedX] * (currentX - poppedX);

							if (rectSize > maxSize) {
								maxSize = rectSize;
								startCoordinates = {x: poppedX, y: y - runCount[poppedX] + 1};
								endCoordinates = {x: currentX - 1, y: y};
							}
						}
						runCount[poppedX] = runCount[currentX];
						if (stack.length === 0 || poppedX !== stack[stack.length - 1]) {
							stack.push(poppedX);
						}
					}
				} else {
					stack.push(currentX);
				}
				currentX++;
			}

			while (stack.length > 0) {
				let poppedX = stack.pop();
				let rectSize = runCount[poppedX] * (currentX - poppedX);

				if (rectSize > maxSize) {
					maxSize = rectSize;
					startCoordinates = {x: poppedX, y: y - runCount[poppedX] + 1};
					endCoordinates = {x: currentX - 1, y: y};
				}
			}
		}

		return {
			x: startCoordinates.x,
			y: startCoordinates.y,
			width: endCoordinates.x - startCoordinates.x + 1,
			height: endCoordinates.y - startCoordinates.y + 1
		};
	}

	/**
	 * Determines if a given array is empty or not.
	 *
	 * @param {Array} arr - The array to check for emptiness.
	 * @return {boolean} - True if `arr` is empty, otherwise false.
	 */
	largesEmpty = findLargestEmptyRectangle();

	const updateBlocks = (arr, i, j, block, name) => {
		for (let x = i; x < i + block.height; x++) {
			for (let y = j; y < j + block.width; y++) {
				if(arr[x][y] !== 'f'){
					arr[x][y] = name.split('_')[1];
				}
			}
		}
	};

	const canPlaceBlock = (arr, i, j, block, name) => {
		if (i + block.height > arr.length || j + block.width > arr[0].length) {
			return false;
		}

		for (let x = i; x < i + block.height; x++) {
			for (let y = j; y < j + block.width; y++) {
				if (arr[x][y] !== 0 && arr[x][y] !== 'f') {
					return false;
				}
			}
		}

		updateBlocks(arr, i, j, block, name);
		return true;
	}

	const loopReplace = (rectangle, name) => {
		for(let i = 0; i <= _h - rectangle.height; i++){
			const breakLoop = Array.from({length: _l}, (_, j) => {
				if(j + rectangle.width <= _l && canPlaceBlock(virtualArray, i, j, rectangle, name)){
					if(empty(_l_h.x) && empty(_l_h.y)) { _l_h.x = j; _l_h.y = i}
					arrPosition.push({name, width: rectangle.width, height: rectangle.height, x: j, y: i})
					return true
				}
				return false
			}).some(Boolean);

			if(breakLoop) { break; }
		}
	}

	const updateEmptySpace = (largesEmpty, rectangle) => {
		if(largesEmpty.width === l && !empty(_l_h.width)){ _l_h.width = null }
		if(largesEmpty.height === h && !empty(_l_h.height)){ _l_h.height = null }

		if(largesEmpty.width < rectangle.width && (empty(_l_h.width) || !empty(_l_h.width))){
			tempDetail._l += rectangle.width - largesEmpty.width;
			_l_h.width = Math.ceil(tempDetail._l - l);
			return findEmptySpace(tempDetail, h, l, false)
		}
		else if(largesEmpty.height < rectangle.height && (empty(_l_h.height) || !empty(_l_h.height))){
			tempDetail._h += rectangle.height - largesEmpty.height;
			_l_h.height = Math.ceil(tempDetail._h - h);
			return findEmptySpace(tempDetail, h, l, false)

		}
		else{
			_l_h.height = _l_h.width = null
		}
	}

	if(arrPosition.length < 1){
		/**
		 * Checks if a given array is empty or not.
		 *
		 * @param {Array} arr - The array to be checked.
		 * @returns {boolean} - True if the array is empty, false otherwise.
		 */
		largesEmpty = findLargestEmptyRectangle();
		if (((largesEmpty.width >= 310 + PADDING && largesEmpty.height >= 100 + PADDING) ||
			(largesEmpty.width >= 100 + PADDING && largesEmpty.height >= 310 + PADDING))
			&& first) { return null; }
		else if(largesEmpty.height > 5 && largesEmpty.width > 5){
			if(largesEmpty.width > largesEmpty.height){
				loopReplace(rectangle_A, 'rectangle_A');
				loopReplace(rectangle_B, 'rectangle_B');
			}else{
				loopReplace(rectangle_A_rev, 'rectangle_A_rev');
				loopReplace(rectangle_B_rev, 'rectangle_B_rev');
			}
		}
	}


	if(arrPosition.length <= 1){
		/**
		 * Function to find and return the largest empty value in a given array.
		 *
		 * @param {number[]} inputArray - The array to search for the largest empty value.
		 * @returns {number} - The largest empty value or -1 if no empty value is found.
		 */
		largesEmpty = findLargestEmptyRectangle();
		if (((largesEmpty.width >= 310 + PADDING && largesEmpty.height >= 100 + PADDING) ||
			(largesEmpty.width >= 100 + PADDING && largesEmpty.height >= 310 + PADDING)) && first) { return null; }
		_l_h.largeX = largesEmpty.x;
		_l_h.largeY = largesEmpty.y;
		if(largesEmpty.height > 5 && largesEmpty.width > 5) {
			if (arrPosition.length === 1 && arrPosition[0].name === "rectangle_A") {
				updateEmptySpace(largesEmpty, rectangle_B);
			}

			if (arrPosition.length === 1 && arrPosition[0].name === "rectangle_B") {
				updateEmptySpace(largesEmpty, rectangle_A);
			}

			if (arrPosition.length === 1 && arrPosition[0].name === "rectangle_A_rev") {
				updateEmptySpace(largesEmpty, rectangle_B_rev);
			}

			if (arrPosition.length === 1 && arrPosition[0].name === "rectangle_B_rev") {
				updateEmptySpace(largesEmpty, rectangle_A_rev);
			}

			if (arrPosition.length === 0) {
				if (largesEmpty.width < rectangle_A.width) {
					updateEmptySpace(largesEmpty, rectangle_A_rev);
				} else {
					updateEmptySpace(largesEmpty, rectangle_A);
				}
			}
		}
	}

	cache[key] = {arrPosition, temp_l_h: { ..._l_h }};
}

export function getArrowSizeByDetailSize(defaultValue, detailH) {
	const sizeForBigDetails = 50
	if (detailH >= 2000) {
		return sizeForBigDetails
	}
	return defaultValue
}
export const handlePaste = (event, setFormData) => {
	const pastedData = event.clipboardData.getData('Text');
	if (pastedData.includes('*')) {
		event.preventDefault();
		// Remove '*' character from the pasted data
		const filteredData = pastedData.replace(/\*/g, '');
		// Manually insert the filtered data into the input field
		const { selectionStart, selectionEnd } = event.target;
		const newValue = event.target.value.substring(0, selectionStart) +
			filteredData +
			event.target.value.substring(selectionEnd);
		setFormData((prevFormData) => ({
			...prevFormData,
			name: newValue,
		}));
	}
};