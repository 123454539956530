import React, {useEffect, useState} from 'react';
import CDetail from "../../../model/CDetail";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import {useDispatch, useSelector} from "react-redux";

const ProjectDetail = ({ detail, used }) => {
	const dispatch = useDispatch()

	const addDetail = e => {
		e.preventDefault();
		if(detail.disabled) return;
		const _detail = new CDetail({
			name: detail.name,
			l: detail.l,
			h: detail.h,
			w: detail.w,
			detailId: detail.detailId,
			detailCId: detail.detailCId,
			contour3d: detail.contour3d,
			position: {x: 0, y: 0, z: 0},
			rotation: {x: 0, y: 0, z: 0},
			productId: detail.productId || 1,
			getEdgeData: detail.getEdgeData.bind(detail)
		});
		dispatch(constructor3dOperations.addCDetail(_detail))
			.then(() => _detail.create3DDetail())
	}

	return (
		<div className={`c-details__project-detail flex-justify-beetwen d-flex${detail.disabled ? ' disabled' : ''}`}>
			{detail.name}(всього: {detail.count})({detail.count - used})
			<a className="add-button" onClick={addDetail}>+</a>
		</div>
	)
}

export default ProjectDetail