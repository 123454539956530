const getConstruction = ({ project }) => {
  return project.project.construction
    ? project.project.construction.payload.construction
    : project.project.construction;
};

const getsceneModel = ({ project }) => {
  return project.project.sceneModel
    ? project.project.sceneModel.payload.sceneModel
    : project.project.sceneModel;
};

const getDetails = ({ project }) => {
  return project.project.construction && project.project.construction.payload
    ? project.project.construction.payload.construction.details
    : [];
};

const getMaterials = ({ project }) => {
  return project.project.construction && project.project.construction.payload
    ? project.project.construction.payload.construction._materials
    : [];
};

const getDetail = ({ project }) => {
  return project.project.detail
    ? project.project.detail.payload.detail
    : project.project.detail;
};
const getIsArrowMaterial = ({ project }) => {
  return project.project.IsArrowMaterial
      ? project.project.IsArrowMaterial.payload.IsArrowMaterial
      : project.project.IsArrowMaterial;
};
const getIsArrowEdge = ({ project }) => {
  return project.project.IsArrowEdge
      ? project.project.IsArrowEdge.payload.IsArrowEdge
      : project.project.IsArrowEdge;
};

const getDetailName = ({ project }) => {
  return project.project.detail
    ? project.project.detail.payload.detail.name
    : '';
};

const getTreatment = ({ project }) => {
  return project.project.Treatment
    ? project.project.Treatment.payload.Treatment
    : project.project.Treatment;
};

const getEdges = ({ project }) => {
  return project.project.construction && project.project.construction.payload
      ? project.project.construction.payload.construction._edges
      : [];
}

export default {
  getConstruction,
  getDetails,
  getDetail,
  getIsArrowMaterial,
  getIsArrowEdge,
  getMaterials,
  getEdges,
  getsceneModel,
  getTreatment,
  getDetailName
};
