import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import store from "redux/store";
import PrivateTemplate from "views/PrivateTemplate";
import img3 from "media/groove.png";
import img4 from "media/rabbet.png";
import img5 from "media/rectangle-circle.png";
import img6 from "media/corner.png";
import img7 from "media/hole.png";
import img8 from "media/curvedLine.png";
import img9 from "media/bevel.png";
import img10 from "media/tableProc.png";
import img11 from "media/templates.png";
import img12 from "media/templatesHandle.png";
import img13 from "media/Sampling.png";
import rotate_btn from "media/rotate_btn.png";
import rotate_false_btn from "media/rotate_false_btn.png";
import center_detail from "media/center-detail.png";
import camera_view_mode from "media/camera_view_mode.png";
import cameraView1 from 'media/face-platform.svg'
import cameraView2 from 'media/back-platform.svg'
import cameraView3 from 'media/left-platform.svg'
import cameraView4 from 'media/right-platform.svg'
import cameraView5 from 'media/bottom-platform.svg'
import cameraView6 from 'media/top-platform.svg'
import {debounce} from 'lodash';

import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import {projectOperations, projectSelectors} from "redux/project";
import {userSelectors} from "redux/user";
import CONSTANTS from "config/constants";
import {toast} from "react-toastify";
import "./style.scss";
import {empty, isset} from "../../helpers/helper";
import DefaultBtn from "../../components/UI/Buttons/DefaultBtn";
import Languages from "../../translation/Languages";
import ChosProcesses from "../../components/UI/ChosProcesses";
import {panelOperations, panelSelectors} from "redux/panel";
import Processing from "../../components/UI/Modal/Processing";
import ThreeScene from "../../components/3dPage";

import Loader from "../../components/UI/Loader";
import Project from "../../models/Project";
import {errorMessageOperations} from "redux/errors";
import {Tooltip} from "react-tooltip";

const Processes = () => {
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);
	const details = construction?.details
	const navigate = useNavigate();
	const detail = useSelector(projectSelectors.getDetail);
	const user = useSelector(userSelectors.User);


	//get detail id
	const { projectId, detailId } = useParams();

	const [processPanel, setProcessPanel] = useState(false)
	const [chosenProcesses, setChosenProcesses] = useState(false);
	const [selectedProcess, setSelectedProcess] = useState("");
	const isEditPanel = useSelector(panelSelectors.getPanelIsEditPanel);
	const panel = useSelector(panelSelectors.getPanelShowState)

	const treatment = useSelector(projectSelectors.getTreatment);
	const permissions = useSelector(userSelectors.permissions);
	const detailProject = useSelector(projectSelectors.getDetail);
	const [loader, setLoader] = useState(false)
	const [rotationEnabled, setRotationEnabled] = useState(true)

	const chooseDetail = () => {

		if(!empty(details)){
			const detailCurrent = construction.details.find(({ order }, index) => order ==  detailId)
			if(!empty(detailCurrent)){

				setDetail(detailCurrent)

			}else {
					dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('no-parts-found', true), type: 'warning', show: true }))
			}
			
				if(!empty(detail)){
					detail.fullRender();
				}
			setLoader(false)

		}else {
			dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('no-parts-found', true), type: 'warning', show: true }))
			setLoader(false)

		}
	}

	useEffect(() => {
		if (empty(user)){
			document.location=`${process.env.PERSONAL_ACCOUNT_URL}?previousUrl=${window.location.href}`
		}
		const currentProjectId = construction?.projectData?.id
		const shouldNotLoadProject = currentProjectId === Number(projectId)
		setLoader(true)
		if(!empty(projectId) && !shouldNotLoadProject && projectId != 0){
			 Project.loadProject(Number(projectId), dispatch)
				 .then((isLoad) => {
					 if(isLoad){
						 window.location.reload()
					 }else {
						 navigate("/")
					 }

			 })
		}
		else   {
			chooseDetail()
		}

		dispatch(
			panelOperations.switchStateForPanel({
				show: 'none'
			})
		)
		if(!empty(detail)){
			 detail.fullRender();
		}
		return () => toast.dismiss()

	}, [detail])

	const zPoint = (mWidth, mHeight) => {
		const maxSize = Math.max(mWidth, mHeight);
		let constantaForZoom
		if (mWidth - mHeight >= 500) {
			constantaForZoom = 160
		} else if ( mHeight - mWidth >= 500) {
			constantaForZoom = 500
		}
		else {
			constantaForZoom = 350
		}
		return maxSize / (2 * Math.tan((Math.PI * 40) / 360)) + constantaForZoom;
	};

	const centerDetail = () => {
		if(!empty(detail)) {
			const sceneModel = store.getState().project.project.sceneModel.payload.sceneModel;

			const l = detail.l;
			const h = detail.h;
			sceneModel.updateRenderSize();
			sceneModel.getCenter(zPoint(l, h));
		}
	};

	const centerDetailWithAnimations = () => {
		const sceneModel = store.getState().project.project.sceneModel.payload.sceneModel;

		const l = detail.l;
		const h = detail.h;
		sceneModel.getCenterWithAnimation(zPoint(l, h), 'front');
	};

	useEffect(() => {

		setTimeout(() => {
			centerDetail();
		}, 50)
	}, );

	useEffect(() => {
		centerDetail()
	}, [])

	const changeCameraHandler = (type) => {
		const sceneModel = store.getState().project.project.sceneModel.payload.sceneModel;

		const l = detail.l;
		const h = detail.h;
		sceneModel.getCenterWithAnimation(zPoint(l, h), type);
	}

	useEffect(() => {
		if (panel === 'procPanel') {
			setProcessPanel(true)
			setChosenProcesses(false)
			return
		}
		if (panel ==='chosProcesses') {
			setProcessPanel(false)
			setChosenProcesses(true)
			return
		}
		setSelectedProcess("");
		setChosenProcesses(false)
		setProcessPanel(false)
	}, [panel])

	const setDetail = (detail) => {
		dispatch(
			projectOperations.setDetail({
				detail: detail,
			})
		);
	}

	const changeDetail = (event) => {
		toast.dismiss()
		if (panel === 'procPanel') {
			return
		}
		const numberDetail = +event.target.value

		const item = details.find((detail) => detail.order === numberDetail );

		setDetail(item)

		navigate(`/processes/${projectId}/${numberDetail}`);


		if (!empty(item)) {
			dispatch(
				modalsOperations.switchStateForModal({
					show: false,
					type: null,
				})
			);
			dispatch(
				modalsOperations.showStateSelectedTreatments({
					show: false,
				})
			);
			dispatch(
				modalsOperations.showStateDetailInfo({
					show: false,
				})
			);
			dispatch(
				panelOperations.switchStateForPanel({
					show: 'none',
				})
			);

		}
	};
	const debouncedSetDetail = useRef(debounce((detail) => {
		setDetail(detail);
	}, 300)).current;

	const nextDetail = () => {
		toast.dismiss();
		if (panel === 'procPanel') {
			return;
		}
		const index = details.findIndex(el => el.id === detailProject.id);

		let nextNum = Number(detailId) + 1;

		const navigateFunc = (detailNum) => {
			navigate(`/processes/${projectId}/${detailNum}`);
		}


		if (nextNum <= details.length) {
			navigateFunc(nextNum);
			debouncedSetDetail(details[index + 1]);

		} else {
			navigateFunc(1);
			const firstDet =  details.find(({order}) => order === 1)
			debouncedSetDetail(firstDet);
		}
	};
	const prevDetail = () => {
		toast.dismiss();
		if (panel === 'procPanel') {
			return;
		}
		const index = details.findIndex(el => el.id === detailProject.id);

		let prevNum = Number(detailId) - 1;

		if (prevNum > 0) {
			navigate(`/processes/${projectId}/${prevNum}`);
			debouncedSetDetail(details[index - 1]);
		}else {
			const lastDetail = details.length;
			navigate(`/processes/${projectId}/${lastDetail}`);
			debouncedSetDetail(details[details.length]);
		}
	};


	const saveProject = async () => {
		detail.validate(detail)
			.then(() => navigate("/details"))
			.catch((e) => console.log(e))
	};

	const chosProcActiveHandler = () => {
		if (panel === 'procPanel') {
			return
		}
		if (panel === 'chosProcesses') {
			dispatch(
				panelOperations.switchStateForPanel({
					show: 'none',
				})
			);
		} else {
		dispatch(
			panelOperations.switchStateForPanel({
				show: 'chosProcesses',
			})
		);
		}
		setProcessPanel(false)
		setChosenProcesses((prevState) => !prevState)
		centerDetail()
	}

	const openProcess = (process, type) => {
		let value = null;
		if (process === "circle") {
			value = detail.addCircle();
		}
		if (process === "rectangle") {
			value = detail.addRectangle();
		}
		if (process === "groove") {
			value = detail.addGroove();
		}
		if (process === "rabbet") {
			value = detail.addRabbet();
		}
		if (process === "hole") {
			value = detail.addHole();
		}
		if (process === "corner") {
			value = detail.addCorner();
		}
		if (process === "mill") {
			value = detail.addMill();
		}
		if (process === "bevel") {
			value = detail.addBevel();
		}
		if (process === "cutoutSampling") {
			value = detail.addCutOutSampling();
		}
		if (process === "template") {
			value = detail.addTemplates({templateType: type});
		}
		if (process === "templateTableTop") {
			value = detail.addTemplates({templateType: "tableTop"});
		}
		if (process === "templateHandle") {
			value = detail.addTemplates({templateType: "handles"});
		}
		dispatch(
			projectOperations.setTreatment({
				Treatment: value,
			})
		);
		setChosenProcesses(false)
		dispatch(
			panelOperations.switchStateForPanel({
				show: 'procPanel',
			})
		);
		detail.render();
		setSelectedProcess(process);
		centerDetail()
	};

	const OpenModalDetail = () => {
		dispatch(
			modalsOperations.showStateDetailInfo({
				show: true,
			})
		);
	};

	const activeProcess = (value) => {
		// const result = selectedProcess ? selectedProcess : detail.type;
		return selectedProcess === value ? "active-process" : "";
	};

	const openProcessModal = (type) => {
		if (!isEditPanel && panel !== 'procPanel') {
			openProcess(type)
		}
		if (!isEditPanel && panel === 'procPanel') {
			if(isset(treatment)) {
				treatment?.remove();
			}

			openProcess(type)
		}
	}

	const getTreatmentType = (treatmentType) => {
		const types = {
			corner: "corners",
			groove: "grooves",
			rabbet: "rabbets",
			circle: "rectangles",
			rectangle: "rectangles",
			cutoutSampling: "cutouts",
			hole: "holes",
			mill: 'mills',
			bevel: 'bevels',
			template: 'templates',
			templatesTableTop: 'templatesTableTop',
			templatesHandle: 'templatesHandle'

		};
		return types[treatmentType];
	}

	const changeInfoPanel = () => {
		if (panel === 'chosProcesses'){
			return "calc(100% - 760px)"
		}
		// if (panel === 'procPanel') {
		// 	return 'calc(100% - 608px)'
		// }
		return "100%"
	}

	const getAvailableProcessing = () => {
		return Object.keys(detail.getAvailableProcessing());
	}

	const addProcessPanel = () => {
		if (!permissions.includes('KM_addProcessing')) return null;
		const images = {
			corner: img6,
			groove: img3,
			rabbet: img4,
			bevel: img9,
			rectangle: img5,
			cutoutSampling: img13,
			hole: img7,
			mill: img8,
			template: img11,
			templateTableTop: img10,
			templateHandle: img12
		}

		return <div className="processes__workspace-nav">
			{Object.keys(detail.getAvailableProcessing()).map(el => {
				return (<div
					key={el}
					title={Languages.getTranslation(`add_${el}_title`)}
					onClick={() => openProcessModal(el)}
					className={activeProcess(el)}
				>
					<img alt={Languages.getTranslation(`add_${el}_title`)} src={images[el]}/>
				</div>)
			})}
			</div>
	}

	const addRotateButtons = () => {
		if(!detail.getAvailableFeatures().includes("rotateDetail")) return null;
		return (<>
			<a className="processes__cameraViewBtn" title={Languages.getTranslation('rotate_detail_false', true)} onClick={e => {
				e.preventDefault()
				return rotateDetail(false)
			}}>
				<img alt={Languages.getTranslation('rotate_detail_false', true)} src={rotate_false_btn}/>
			</a>
			<a className="processes__cameraViewBtn" title={Languages.getTranslation('rotate_detail_true', true)} onClick={e => {
				e.preventDefault()
				return rotateDetail(true)
			}}>
				<img alt={Languages.getTranslation('rotate_detail_true', true)} src={rotate_btn}/>
			</a>
		</>)
	}

	const addCenterButton = () => {
		return (<>
			<a className="processes__rotate-btn" title={Languages.getTranslation('center-detail', true)} onClick={e => {
				e.preventDefault()
				return centerDetailWithAnimations()
			}}>
				<img alt={Languages.getTranslation('center-detail', true)} src={center_detail}/>
			</a>
		</>)
	}

	const addCameraViewButton = () => {
		return (<>
			<a className="processes__cameraViewBtn" title={`${Languages.getTranslation('cameraView', true)}:`} data-tooltip-id={`cameraView-tooltip`} onClick={e => {
				e.preventDefault()
				return centerDetailWithAnimations()
			}}>
				<img alt={Languages.getTranslation('center-detail', true)} src={cameraView1} width={20} height={20}/>
			</a>
		</>)
	}

  const rotateDetail = (direction = true) => {
      if(!rotationEnabled) return false
      setRotationEnabled(false)
      detail.rotate(direction)
          .then(() => {
              setRotationEnabled(true)
          })
  }

	return (
		<PrivateTemplate>
			{loader &&
			<div className="loaderCenter">
				 <Loader/>
			</div>
			}

			{!empty(detail) && (

				<div className="process-scene">
					<div className="processes">
						{/* <div className="processes__selectDetail">
            <div className="processes__selectDetail-content">
              <span className="processes__selectDetail-title">Деталь1</span>
              <i className="processes__selectDetail-icon"></i>
            </div>
          </div> */}

					<div className="processes__btns">
						{!permissions.includes("KM_disableSelectHandles") ? (
                        <>
						<select
							name="selectDetail"
							className="processes__selectDetail"
							value={detailProject?.order}
							onChange={changeDetail}
						>
							{details.length
								? [...details].sort((a, b) => a.order - b.order).map((item, key) => {
									return (
										<option key={item.order} value={item.order}>
											{`${key + 1}: ${item.name}  (${item.l}x${item.h})`}
										</option>
									);
								})
								: ""}
						</select>
						<div className="processes__btns-changePage">
							<button
								className="processes__btn-changePage"
								onClick={prevDetail}
							>
								<i className="processes__icon-up"></i>
							</button>
							<button
								className="processes__btn-changePage"
								onClick={nextDetail}
							>
								<i className="processes__icon-down"></i>
							</button>
						</div>
                           </>
						) : <div className="processes__btns-changePage"></div>}

						{addCameraViewButton()}
						{permissions.includes('KM_addRotateButtons') && addRotateButtons()}

						{
							<Tooltip
								key={`cameraView-tooltip`}
								className='productHelper-tooltip'
								id={`cameraView-tooltip`}
								place="bottom-start"
								variant='dark'
								zIndex={1000}
								clickable
							>
								<div className={"processes__camera"}>
									<div className="processes__camera-container">
										<div className="processes__camera-item" onClick={() => changeCameraHandler('front')} title={`${Languages.getTranslation('frontView', true)}`}>
											<img src={cameraView1} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
										<div className="processes__camera-item" onClick={() => changeCameraHandler('back')} title={`${Languages.getTranslation('backView', true)}`}>
											<img src={cameraView2} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
										<div className="processes__camera-item" onClick={() => changeCameraHandler('left')} title={`${Languages.getTranslation('leftView', true)}`}>
											<img src={cameraView3} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
									</div>
									<div className="processes__camera-container">
										<div className="processes__camera-item" onClick={() => changeCameraHandler('right')} title={`${Languages.getTranslation('rightView', true)}`}>
											<img src={cameraView4} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
										<div className="processes__camera-item" onClick={() => changeCameraHandler('top')} title={`${Languages.getTranslation('topView', true)}`}>
											<img src={cameraView6} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
										<div className="processes__camera-item" onClick={() => changeCameraHandler('bottom')} title={`${Languages.getTranslation('bottomView', true)}`}>
											<img src={cameraView5} alt={'cameraView1'} className='processes__camera-image'/>
										</div>
									</div>
								</div>
							</Tooltip>

						}
						<DefaultBtn title={Languages.getTranslation('details-param', true)} handler={OpenModalDetail}/>
					</div>
						<div className="processes__workspace">
							{addProcessPanel()}
							{processPanel && <Processing getTreatmentType={getTreatmentType}/>}

							<div className="processes__workspace-space" style={{width: changeInfoPanel()}}>
								<ThreeScene/>
							</div>
							<div
								className={chosenProcesses ? 'processes__workspace-chossproc_active' : 'processes__workspace-chossproc'}>
								<ChosProcesses/>
							</div>
							<div className="chossenProcesses__btn" onClick={chosProcActiveHandler}></div>
						</div>
						<div className="processes__footer">

							<div className="processes__colors">
								{CONSTANTS.colors.map((color, key) => {
									return (
										<div key={key} className="color-item">
											<div
												style={{background: color.color}}
												className="color"
											></div>
											<span className='text'>{color.sign}</span>
											<span className='text'>{color.name}</span>
										</div>
									);
								})}
							</div>
							<div className="processes__footer_variables">
							<span>
								{`${Languages.getTranslation('full-sizes', true)} dx = ${detail.l}, dy = ${detail.h}, dz = ${detail.w}. ${Languages.getTranslation('count', true)} = ${detail.count}`}
							</span>

							{ (!empty(detail.preCutting.top) ||
								!empty(detail.preCutting.bottom) ||
								!empty(detail.preCutting.right) ||
								!empty(detail.preCutting.left)) &&
								<span className="processes__footer_variables_second">
								{`${Languages.getTranslation('size-increase', true)}: 
								${!empty(detail.preCutting.right) ||
								!empty(detail.preCutting.left) ? 'dx + ' : ''} ${detail.preCutting.right || detail.preCutting.left || ''} 
								${!empty(detail.preCutting.right) || !empty(detail.preCutting.left) ? ',' : ''}
								${!empty(detail.preCutting.top) ||
								!empty(detail.preCutting.bottom) ? 'dy + ' : ''} ${detail.preCutting.top || detail.preCutting.bottom || ''}`}
							</span>}
						</div>
							{!permissions.includes('KM_disabledParamsDetail') && (
								<DefaultBtn title={Languages.getTranslation('save', true)} handler={saveProject}/>
							)}
					</div>
				</div>
			</div>
			)}
		</PrivateTemplate>
	);
};

export default Processes;
