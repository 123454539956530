import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";
import img1 from "../../../../media/addMaterial-test.png";
import {empty, isFractional, materialTitle} from "../../../../helpers/helper";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations, modalsSelectors} from "redux/modals";
import DefaultBtn from "../../Buttons/DefaultBtn";
import './style.scss'
import {projectSelectors} from "redux/project";
import RoundCheckBox from "../../Inputs/Input/RoundCheckBox";
import {toast} from "react-toastify";
import logo from '../../../../media/triangle.png';
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";

const _errors = new Errors([])

const ReplaceMaterial = () => {
	const dispatch = useDispatch()
	const selectedDetails = useSelector(modalsSelectors.getModalIsEdit);
	const construction = useSelector(projectSelectors.getConstruction);
	const [details, setDetails] = useState([])
	const [selectedMaterial, setSelectedMaterial] = useState(null)
	const [needMaterialThickness, setNeedMaterialThickness] = useState(null)
	const validate = selectedDetails.every(el => el.realW === selectedDetails[0].w)
	const thicknessSort = [...new Set(selectedDetails.map(el => el.realW))]
	const currentMaterials = construction.materials;
	let filteredMaterials = currentMaterials.filter(
		(material) => material.thickness === needMaterialThickness);

	useEffect(() => {
		setDetails(selectedDetails)
	}, [])

	useEffect(()=>{
		if(filteredMaterials.length === 1){
			setSelectedMaterial(filteredMaterials[0])
		}
	},[needMaterialThickness])

	const filterDetailHandler = (thickness) => {
		if (!thickness){
			setDetails([...selectedDetails])
			setNeedMaterialThickness(0)
			return
		}
		setDetails([...selectedDetails.filter(detail => detail.realW === thickness)])
		setNeedMaterialThickness(thickness)
	}

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const updateDetailsMaterial = (material) => {
		if (empty(selectedMaterial)) {
			_errors.setError(`${Languages.getTranslation("choose-one-material", true)}`, 'error', true)
			return
		}
		construction
			.replaceDetailsMaterial(details, material.index)
			.then(() => close())
			.then(() => dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation("replace-material-success", true), type: 'success', show: true })))
	};

	const setMaterial = (material) => {
		setSelectedMaterial(material);
	};

	return (
		<div className="replaceMaterial">
			<div className="replaceMaterial__header">
      <span className="replaceMaterial__header-title">{Languages.getTranslation("material-to-detail", true)}</span>
				<CloseBtn handler={close}/>
			</div>
			<div className="replaceMaterial__select">
				<div className="replaceMaterial__select-container">
					<img src={logo} alt="logo"/>
					<span className="replaceMaterial__text">
            {Languages.getTranslation('replace-every-detail-w', true)}
					</span>
				</div>
				<div className="replaceMaterial__select-container">
					<span className="replaceMaterial__text">
            {Languages.getTranslation('replace-on-w', true)}
					</span>
					<select name="select" className="replaceMaterial__select-item" defaultValue={''} onChange={(e)=>filterDetailHandler(+e.target.value)}>
						<option value=""></option>
						{thicknessSort.map((el)=> <option value={el}>{el}</option>)}
					</select>
				</div>
			</div>
			<div className="replaceMaterial__body">
				<div className="replaceMaterial__materials-list">
					{filteredMaterials.length
						? filteredMaterials.map((material, key) => (
							<div className="deleteMaterial__material-item" key={key}>
								<div className="material__container">
									<img src={img1} alt="image" className="material__img"/>
									<h3 className="material__name">
										{materialTitle(material)}
									</h3>
								</div>
								<div className="deleteMaterial__data-container">
									<div className="material__data">
                      <span className="material__data-item">
                        {Languages.getTranslation("article", true)}
                      </span>
										<span className="material__data-item-second">
                        {" "}
											{material.article}
                      </span>
									</div>
									<div className="material__data">
                      <span className="material__data-item">
                        {Languages.getTranslation("height", true)}
                      </span>
										<span className="material__data-item-second">
                        {" "}
											{material.l || material.height}
                      </span>
									</div>
									<div className="material__data">
                      <span className="material__data-item">
                        {Languages.getTranslation("width", true)}
                      </span>
										<span className="material__data-item-second">
                        {" "}
											{material.w || material.width}
                      </span>
									</div>
									<div className="material__data">
                      <span className="material__data-item">
                        {Languages.getTranslation("thickness", true)}
                      </span>
										<span className="material__data-item-second">
                        {" "}
											{material.thickness}
                      </span>
									</div>
									<div className="deleteMaterial__chosen-material">
										<RoundCheckBox value={material} setMaterial={setMaterial} selectedMaterial={[selectedMaterial]}/>
									</div>
								</div>
							</div>
						))
						: ""}
				</div>

				<div className="replaceMaterial__details-header">
					<div className="deleteMaterial__number">№</div>
					<div className="deleteMaterial__name">
						{Languages.getTranslation("name", true)}
					</div>
					<div className="deleteMaterial__container">
						<div className="deleteMaterial__item">
							{Languages.getTranslation("height", true)}
						</div>
						<div className="deleteMaterial__item">
							{Languages.getTranslation("width", true)}
						</div>
						<div className="deleteMaterial__item">
							{Languages.getTranslation("amount", true)}
						</div>
						{/*<div className="deleteMaterial__item">*/}
						{/*	{Languages.getTranslation("multiplicity", true)}*/}
						{/*</div>*/}
						<div className="deleteMaterial__item">
							{Languages.getTranslation("thickness", true)}
						</div>
					</div>
				</div>
				<div className={filteredMaterials.length ? "replaceMaterial__details-list" : 'list-without-materials'}>
					{details.length
						? details.map((detail, key) => (
							<div className="deleteMaterial__detail">
								<div className="deleteMaterial__number">{key + 1}</div>
								<div className="deleteMaterial__name">{detail.name}</div>
								<div className="deleteMaterial__container">
									<div className="deleteMaterial__item">{!isFractional(Number(detail.l)) ? Math.round(Number(detail.l)) : Number(detail.l).toFixed(1)}</div>
									<div className="deleteMaterial__item">{!isFractional(Number(detail.h)) ? Math.round(Number(detail.h)) : Number(detail.h).toFixed(1)}</div>
									<div className="deleteMaterial__item">{detail.count}</div>
									<div className="deleteMaterial__item">{detail.w}</div>
								</div>
							</div>
						))
						: ""}
				</div>
				<div className="replaceMaterial__btns">
					<DefaultBtn title={Languages.getTranslation('save', true)}
						handler={() => updateDetailsMaterial(selectedMaterial)}/>
				</div>
			</div>
		</div>
	);
}

export default ReplaceMaterial