import {modalsOperations} from "redux/modals";
import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import Languages from "../../../../translation/Languages";
import Draggable from "react-draggable";
import "./style.scss";
import CloseBtn from "components/UI/Buttons/CloseBtn";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import _Detail from "../../../../db/_Detail";
import {empty, handlePaste, validateDetail} from "helpers/helper";
import {errorMessageOperations} from "redux/errors";
import {projectOperations, projectSelectors} from "redux/project";
import DetailBody from "../DetailBody";
import cloneDeep from "lodash.clonedeep";
import {toast} from "react-toastify";
import {panelOperations} from "redux/panel";
import {tempDetailOperations} from "redux/tempDetail";
import {userSelectors} from "redux/user";

const detailDb = new _Detail();

const DetailInfo = ({show}) => {
	const dispatch = useDispatch()
	const construction = useSelector(projectSelectors.getConstruction);
	const detailR = useSelector(projectSelectors.getDetail);
	const {tempDetail} = useSelector(state => state.tempDetail.property)
	const permissions = useSelector(userSelectors.permissions);

	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const [selectedMaterial, setSelectedMaterial] = useState(currentMaterials[0]?.index);
	const [formData, setFormData] = useState({})

	useEffect(() => {
		if(tempDetail.multiplicity !== 'empty') {
			formData.multiplicity = tempDetail.multiplicity
		}
		return () => {
			dispatch(tempDetailOperations.addProperty(null))
		}
	}, [])

	useEffect(() => {
		if(detailR){
			detailDb.id = detailR.id;
		}
		setFormData({
			name: detailR?.name || "",
			material: detailR !== null ? construction.materials.find(m => m.index === detailR?.material) : 0,
			height: detailR?.h || "",
			heightError: false,
			width: detailR?.l || "",
			widthError: false,
			multiplicity: detailR?.multiplicity || null,
			amount: detailR?.count || 1,
			countError: false,
			isRotateTexture: detailR?.isRotateTexture,
			edges: detailR?.edges,
			productId: detailR?._productId,
			soft: {top: detailR?.soft?.top || false, bottom: detailR?.soft?.bottom || false },
			error_width_show: false,
			error_height_show: false,
		})
	}, [detailR, detailR?.edges?.top, detailR?.left, detailR?.edges?.right, detailR?.bottom])

	useEffect(() => {
		if(tempDetail.multiplicity !== 'empty'){
			formData.multiplicity = tempDetail.multiplicity
			if(tempDetail.multiplicity?.type === 6 || tempDetail?.multiplicity?.type === 8){
				setFormData({
					...formData,
					edges: {...formData.edges, right: null, left: null},

				});
			}
			if(tempDetail.multiplicity?.type === 7){
				setFormData({
					...formData,
					edges: {...formData.edges, bottom: null},

				});
			}
		}
	}, [tempDetail])


	const closeModal = (type = false) => {
		// dispatch(tempDetailOperations.addProperty('multiplicity', 'empty'))
		// type && dispatch(tempDetailOperations.addProperty('edges', 'empty'))
		setFormData({
			name: detailR?.name || "",
			material: detailR !== null ? construction.materials.find(m => m.index === detailR?.material) : 0,
			height: detailR?.h || "",
			heightError: false,
			width: detailR?.l || "",
			widthError: false,
			multiplicity: detailR?.multiplicity || null,
			amount: detailR?.count || 1,
			countError: false,
			isRotateTexture: detailR?.isRotateTexture,
			edges: detailR?.edges,
			productId: detailR?._productId,
			soft: {top: detailR?.soft?.top || false, bottom: detailR?.soft?.bottom || false }
		})
		dispatch(
			modalsOperations.showStateDetailInfo({
				show: false,
			})
		);
	}

	const changeTextureHandler = () => {
		if(['Постформинг', 'Постформінг', 'OSB'].includes(formData.material.type)){ return }
		setFormData({
			...formData,
			isRotateTexture: !formData.isRotateTexture
		})
	}

	const changeMultiplicityType = () => {
		dispatch(tempDetailOperations.addProperty('multiplicity', formData.multiplicity))
		dispatch(tempDetailOperations.addProperty('width', formData.width))
		dispatch(tempDetailOperations.addProperty('height', formData.height))
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "MULTIPLICITY",
			})
		);
		closeModal()
	}

	const handleChange = (event) => {
		const {name, value} = event.target;
		if (name === 'material') {
			setSelectedMaterial(Number(value))
		}
		if(name === 'productId'){
			setFormData({
				...formData,
				[name]: Number(value),
			});
			return;
		}
		if (name === 'height' || name === 'width') {
			const neededMaterial = formData.material
			setFormData({
				...formData,
				[name]: value,
			});
			const tempMat = cloneDeep(neededMaterial)
			const newName  = name === 'height' ? 'width' : 'height';
			if(formData.soft.top){tempMat[newName] -= 20}
			if(formData.soft.bottom){tempMat[newName] -= 20}
			let number = 0;
			if (validateDetail(value, tempMat, name)) {
				if(['Постформинг', 'Постформінг'].includes(neededMaterial.type)){
					if(empty(neededMaterial.double_rounding)
						&& formData.soft.top && newName === 'height'){ number = 20}

					if( name === 'width'){
						if(neededMaterial.sawdust){
							number = 26
						}else {
							number = 0
						}
					}

					if(!empty(neededMaterial.double_rounding)
						&& formData.soft.bottom) { number = 20 }

					if(!empty(neededMaterial.double_rounding)
						&& formData.soft.bottom && formData.soft.top) { number = 40 }
				}else if(!['Постформинг', 'Постформінг', 'OSB'].includes(neededMaterial.type)){
					if(neededMaterial.sawdust){
						number = 26
					}else {
						number = 0
					}
				}
				if(["Стеновая панель", 'Постформінг', 'Постформинг'].includes(neededMaterial.type) && name === 'height') { number = 0 }
				setFormData({
					...formData,
					[name]: Number(value) > neededMaterial[newName] - number ? neededMaterial[newName] - number : Number(value),

				})
				// detail[name === 'height' ? '_h' : '_l'] = Number(neededMaterial[newName] - number)

				Number(value) > neededMaterial[newName] - number && number > 0 && dispatch(errorMessageOperations.switchStateError(
					{
						message: `${Languages.getTranslation('same-sides-detail-material', true)} ${number} ${Languages.getTranslation('same-sides-detail-material-text-two')}`,
						type: 'warning',
						show: true
					}))

				return
			}else{
				if(!['OSB'].includes(neededMaterial.type)){
					if(neededMaterial.sawdust){
						number = 26
					}else {
						number = 0
					}
				}
				if(["Стеновая панель", 'Постформінг', 'Постформинг'].includes(neededMaterial.type) && name === 'height') { number = 0}
				setFormData({
					...formData,
					[name]: Number(value) > neededMaterial[newName] - number ? neededMaterial[newName] - number : value,

				})
				Number(value) > neededMaterial[newName] - number && number !== 0 && dispatch(errorMessageOperations.switchStateError(
					{
						message: `${Languages.getTranslation('same-sides-detail-material', true)} ${number} ${Languages.getTranslation('same-sides-detail-material-text-two')}`,
						type: 'warning',
						show: true
					}))
			}
			return
		}

		if(name === 'top' || name === 'bottom' || name === 'left' || name === 'right'){
			const val = value ? Number(value) : null;
			if (!empty(val)) {
				const edge = construction.edges.find((edge, index) => index === val);
				if (!empty(edge)) {
					if((name === 'top' || name === 'bottom')
						&& ['Постформинг', 'Постформінг'].includes(formData.material.type)){
						let show = false;
						if(edge.thickness < 1 && (formData.soft.top || formData.soft.bottom)) {
							if ((formData.edges.top === null || formData.edges.bottom === null)
								&& formData.height - edge.thickness + 1 > formData.height) {
								show = true
							} else if (name === 'bottom' && formData.height && formData.edges.top !== null) {
								if (formData.height - edge.thickness + 1 -
									construction.edges.find(edge => edge.index === formData.edges.top).thickness + 1 > formData.height) {
									show = true
								}
							}
						}
						show && showWarningSoft()
					}

					setFormData(prevState => ({...prevState, edges: {...prevState.edges, [name]: edge.index}}))
				} else {
					console.log("Error: Edge not found in scope");
				}
			} else {
				setFormData(prevState => ({...prevState, edges: {...prevState.edges, [name]: null}}))
			}
			return;
		}

		if(name === 'amount'){
			setFormData({
				...formData,
				[name]: Number(value),
			});
			return;
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

    const submitChanges = () => {
        if (
            formData.width === 0 || formData.width === "" ||
            formData.height === 0 || formData.height === "" ||
            formData.amount === 0 || formData.amount === ""
        ) {
            if (formData.height === 0 || formData.height === "") {
                setFormData(prevState => {
                    const temp = cloneDeep(prevState)
                    temp.heightError = true
                    return temp
                });
            }
            if (formData.width === 0 || formData.width === "") {
                setFormData(prevState => {
                    const temp = cloneDeep(prevState)
                    temp.widthError = true
                    return temp
                });
            }
            if (formData.amount === 0 || formData.amount === "") {
                setFormData(prevState => {
                    const temp = cloneDeep(prevState)
                    temp.countError = true
                    return temp
                });
            }
            return null;
        }

        setFormData(prevState => {
            const temp = cloneDeep(prevState)
            temp.heightError = false
            temp.widthError = false
            temp.countError = false
            return temp
        });

        detailR.name = formData.name;
        detailR.l = Number(formData.width);
        detailR.h = Number(formData.height);
        detailR.isRotateTexture = formData.isRotateTexture
        detailR.count = formData.amount
				detailR.productId = formData.productId
			construction.updateDetailData(detailR.id,
				{
					name: formData.name,
					count: Number(formData.amount),
					l: Number(formData.width),
					h: Number(formData.height),
					productId: formData.productId,
					isRotateTexture: formData.isRotateTexture,
					multiplicity: formData.multiplicity,
					soft: formData.soft,
					edges: formData.edges,
					isPostForming: detailR.isPostForming
				})
			.then((detail) => dispatch(projectOperations.setDetail({detail})))
			.then(() => {
				if (!empty(detailR.mills)) {
					const tempMills = [...detailR.mills]
					return new Promise((resolve, reject) => {
						function updateMills(mill) {
							if (!empty(mill) && mill.type !== 'tableCorner' && mill.type !== 'tableTop') {
								mill.updateDb()
									.then(() => updateMills(tempMills.shift()))
									.catch(e => reject(e))
							} else {
								return resolve()
							}
						}

						updateMills(tempMills.shift())
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempRabbets = detailR.rabbets
				if (!empty(tempRabbets)) {
					tempRabbets.forEach(item => {
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempCorners = detailR.corners
				if (!empty(tempCorners)) {
					tempCorners.forEach(item => {
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempRectangles = detailR.rectangles
				if (!empty(tempRectangles)) {
					tempRectangles.forEach(item => {
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempHoles = detailR.holes
				if (!empty(tempHoles)) {
					tempHoles.forEach(item => {
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempGrooves = detailR.grooves
				if (!empty(tempGrooves)) {
					tempGrooves.forEach(item => {
						if (detailR.l !== item.width) {
							if (item.r === 0 && item.direction === 'hor') {
								item.r = 2
							}
							item.addParams()
						}
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
				const tempMills = detailR.mills
				if (!empty(tempMills)) {
					tempMills.forEach(item => {
						item.formulaCalculationOnReSize()
					})
				} else {
					return Promise.resolve()
				}
			})
			.then(() => detailR.validate(detailR))
			.then(() => {
				dispatch(
					panelOperations.switchStateForPanel({
						show: null,
					})
				);
				setTimeout(() => {
					dispatch(
						panelOperations.switchStateForPanel({
							show: 'none',
						})
					);
				}, 10)

				dispatch(
					projectOperations.setDetail({
						detail: detailR,
					})
				);
				detailR.render();
				setTimeout(() => {
					closeModal(true)
				}, 10)
			})
			.catch(e => console.log(e));
		};

	const validSide = (side) => {
		const neededMaterial = construction._materials.find(
			(material) => material.index === formData.material.index);
		let number = 0;
		if(!formData.soft[side]) {
			let newHeight;
			if(side === "top" && neededMaterial?.double_rounding !== 1){
				newHeight = 20
			}
			if(side === "bottom" && neededMaterial?.double_rounding){
				if(formData.soft.top){
					newHeight = 40
				}else {
					newHeight = 20
				}
			}
			if(side === "top" && neededMaterial?.double_rounding){
				if(formData.soft.bottom){
					newHeight = 40
				}else {
					newHeight = 20
				}
			}
			if (validateDetail((formData.height + newHeight).toString(), neededMaterial, 'height')) {
				if (!formData.soft.top) { number = 20 }
				if (formData.soft.bottom && neededMaterial?.double_rounding === 1) {
					if(!formData.soft.top){
						number = 40
					}else {
						number = 20
					}
				}

				if (formData.soft.top && neededMaterial?.double_rounding === 1) {
					if(!formData.soft.bottom){
						number = 40
					}else {
						number = 20
					}
				}

				return { name: 'height', nameR: '_h', number: Number(neededMaterial['width'] - number)}
			}
			else return {name: null, number}

		}else return {name: false, number}
	}

	const changeSoft = (side) => {
		const { name, nameR, number } = validSide(side)
		if(name === 'height'){
			setFormData({
				...formData,
				soft: {...formData.soft, [side]: !formData.soft[side]},
				edges: {...formData.edges, [side]: !formData.soft[side] ? formData.edges[side] : null},
				[name]: number
			})
		}else{
			if(side === 'bottom' && formData.soft.top && formData.soft.bottom && formData.material.double_rounding === 1){
				setFormData({
					...formData,
					soft: { top: formData.soft.top, bottom: false},
					// soft: { top: false, bottom: false},
					// edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side], top: null},
					edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side]},
					[name]: number
				})
			}else {
				setFormData({
					...formData,
					soft: {...formData.soft, [side]: !formData.soft[side]},
					edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side]},
					[name]: number
				})
			}
		}

		// let detail = cloneDeep(detailR)
		// detail.edges[side] = !formData.soft[side] ? formData.edges[side] : null
		// if(side === 'bottom' && formData.soft.top && formData.soft.bottom && formData.material.double_rounding === 1){
		// 	detail.soft = { top: false, bottom: false}
		// 	detail.edges.top = null
		//
		// } else {
		// 	detail.soft[side] = !formData.soft[side]
		// }
		// if(name === 'height'){ detail[nameR] = number }

		// dispatch(projectOperations.setDetail({ detail }))
	}

	const showWarningSoft = () => {
		const message = Languages.getTranslation('detail-will-be-changed', true)
		toast.warn(message, {
			onClose: () => {},
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			toastId: message,
		})
	}

	const deleteErrors = (property) => {
		setFormData(prevState => {
			const temp = cloneDeep(prevState)
			temp[property] = false
			return temp
		});
	}

	return show ? (
		<Draggable bounds="parent" handle="strong" >
			<div className="detailInfo">
				<strong className="detailInfo__heading">
				  <span className="detailInfo__heading-title">
					{Languages.getTranslation("detail-params", true)}
				  </span>
					<CloseBtn handler={() => closeModal(true)}/>
				</strong>
					<DetailBody
						formData={formData}
						construction={construction}
						detail={detailR}
						disabled={true}
						changeTextureHandler={changeTextureHandler}
						changeMultiplicityType={changeMultiplicityType}
						handleChange={handleChange}
						handlePaste={(event) => handlePaste(event, setFormData)}
						materialSel={formData.material}
						changeSoft={changeSoft}
						deleteErrors={deleteErrors}
					/>
				{!permissions.includes('KM_disabledParamsDetail') && (
					<div className="detailInfo__actions">
						<DefaultBtn
							title={Languages.getTranslation("cancel2", true)}
							handler={closeModal}
						/>
						<DefaultBtn
							title={Languages.getTranslation("save", true)}
							handler={submitChanges}
						/>
					</div>
				)}
			</div>
		</Draggable>
	) : null;
};
// TODO рефактор redux убрать connect
const mapStateToProps = (state) => ({
	show: state.modals.modals.showDetailInfo,
	detail: state.project.project.detail
		? state.project.project.detail.payload.detail
		: null,
	construction: state.project.project.construction
		? state.project.project.construction.payload.construction
		: null,
});

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(
				modalsOperations.showStateDetailInfo({
					show: false,
				})
			);
		},
	};
};

export default connect(mapStateToProps)(DetailInfo);
