import CloseBtn from "components/UI/Buttons/CloseBtn";
import "./style.scss";
import Languages from "translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import {projectSelectors} from "redux/project";
import {useNavigate} from "react-router-dom";
import Project from '../../../../models/Project'
import {errorMessageOperations} from "redux/errors";

const NewProject = () => {
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const create = () => {
		construction.startNewProject()
			.then(() => {
				dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
				close()
			})
			.catch(error => console.log(error))
	}

	return (
		<div className="newProject">
			<div className="newProject__header">
        <span className="newProject__header-title">
          {Languages.getTranslation("new-project", true)}
        </span>
			</div>
			<div className="newProject__body">
				{Languages.getTranslation("new-project-text", true)}
			</div>
			<div className="newProject__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("newProject", true)}
					handler={create}
				/>

			</div>
		</div>
	);
};

export default NewProject;
