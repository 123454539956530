import React, {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {constructor3dSelectors} from "../../../../redux/constructor";
import HistoryClass from "../../../model/components/HistoryClass";

import Panel from "../../layuot/panel";
import {empty} from "../../../../helpers/helper";

import "./styles.scss";
import {current} from "@reduxjs/toolkit";

const History = () => {
	const historyState = useSelector(constructor3dSelectors.getHistory)
	const historyClass = new HistoryClass();
	const current = historyState.findIndex(el => el.isCurrent);



	if(empty(historyState)) return null;

	const rollBack = step => {
		historyClass.backToState(step, true)
	}

	const backTo = step => {
		historyClass.backToState(step)
	}

	const goToStep = i => {
		if(i !== current + 1) {
			backTo(i)
		} else {
			rollBack(i)
		}
	}
	const getControl = (i) => {
		if(historyState[i].changes.type === 'created') return null;
		if( i !== current + 1 && i <= current) {
			return <a className="remove-button" onClick={e => backTo(i)}>&#10226;</a>
		} else {
			return <a className="add-button" onClick={e => rollBack(i)}>&#10227;</a>
		}
	}

	const getItemCurrentClass = i => {
		if( i < current) {
			return ' history-item-prew'
		} else if(i > current) {
			return ' history-item-next'
		} else {
			return ' history-item-current'
		}
	}
	window.addEventListener('keyup', ev => {
		if (ev.key === "z" && ev.ctrlKey) {
			const current_step =  historyState.findIndex(el => el.isCurrent);
			goToStep(current_step);
		}
	})
	return (
		<Panel className="p__content history" title="History">
			{historyState.map((el, i) => {
				return (
					<div className={`history-item d-flex`} key={i}>
						<div className="d-flex flex-column">
							<strong className="history-item__detail">{el.detail.name}: </strong>
							<span className="history-item__description">{el.description}</span>
						</div>
						{getControl(i)}
					</div>)
			})}
		</Panel>
	)
}

export default History