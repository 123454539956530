import React from "react";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import img from "../../../../media/material-add-img-test.svg";
import detail_img from "../../../../media/detail_img.svg";
import '../../../../components/CreateProject/Materials/style.scss'
import "./style.scss";

const AddDetailsErrorModal = ({errorDetailsImport, closeErrorDetailsImport, title, allErrorsMessage = false, close}) => {

    return (
        <div className='modal'>
            <div className="modal-add-details_error">
                <h2 className="modal-add-details_error__title">{title}</h2>
                <div className="modal-add-details_error__header">
                    <div className="modal-add-details_error__info-container" style={{paddingTop: 10, paddingBottom: 7}}>
                        <div className="modal-add-details_error__header-item modal-add-details_error__number">№</div>
                        <div className="modal-add-details_error__header-item modal-add-details_error__name">
                            {Languages.getTranslation("name", true)}
                        </div>
                    </div>
                    <div className="modal-add-details_error__data-container">
                        <div style={{ marginLeft: '20px' }}
                             className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                            {Languages.getTranslation("height", true)}
                        </div>
                        <div className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                            {Languages.getTranslation("width", true)}
                        </div>
                        <div className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                            {Languages.getTranslation("amount", true)}
                        </div>
                    </div>
                </div>
                <div className="modal-add-details_error__body">
                    {errorDetailsImport.map((detail, key) => {
                            return (
                                <>
                                    <div key={key} id={key}
                                        className={`modal-add-details_error__body-item`}>
                                        <div className="modal-add-details_error__info-container">
                                            <span className=" modal-add-details_error__number">{detail.indexArr + 1}</span>
                                            <div className="modal-add-details_error__name">{detail[allErrorsMessage ? '_name' : 'name']}</div>
                                        </div>
                                        <div className="modal-add-details_error__data-container">
                                            <div className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_l' : 'l']}</div>
                                            <div className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_h' : 'h']}</div>
                                            <div className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_count' : 'count']}</div>
                                        </div>
                                    </div>
                                    {!allErrorsMessage && detail.messages.map(err =>  (
                                            <div className={`modal-add-details_error__body-itemError`}>
                                                {err.ua.split(',')[0].split('-')}</div>
                                        )
                                    )}
                                    {allErrorsMessage && detail.error.allErrorsMessage.map(err => {
                                        return (
                                            <div style={{ color: `var(--toastify-icon-color-${err.type})`}}  className={`modal-add-details_error__body-itemError`}>
                                                {err.message}</div>
                                        )
                                        }
                                    )}
                                </>
                            );
                        })}
                </div>
                <div className='modal-add-details_error__wraperBtn'>
                    <div className={`${allErrorsMessage ? 'modal-add-details_error__wraperBtn__btns' : 'modal-add-details_error__wraperBtn__btnOne'}`}>
                        {allErrorsMessage && <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>}
                        <DefaultBtn title={Languages.getTranslation('continue', true)} handler={closeErrorDetailsImport}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDetailsErrorModal;

