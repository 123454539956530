import {
	Raycaster,
	Vector2,
	Vector3,
	Scene,
	Box3,
	WebGLRenderer,
	PerspectiveCamera,
	AmbientLight,
	Quaternion,
	Euler, Group, AxesHelper, OrthographicCamera, Mesh, BoxGeometry, MeshBasicMaterial
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import Animations from "./Animations";
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import {empty} from "../../../helpers/helper";
import Base from "../components/Base";
import CONFIG from "../config";

const BaseClass = new Base();

export default class ConstructorSceneModel {
	rendered = 0;
	shouldRender = false;
	raycaster;
	mouse;
	clickMouse;
	selectObjectEvent;
	selectedObjects = [];
	dom;
	draggable;
	transform;
	transformed = false;
	selectedObject = null;
	transformTimeOut;
	connectedObjectsGroup;
	axesHelperPos;

	helperScene;
	helperCamera;
	helperRender;

	constructor(dom) {
		this.dom = dom;
		this.raycaster = new Raycaster();
		this.mouse = new Vector2();
		this.clickMouse = new Vector2();
		this.connectedObjectsGroup = null;
		this.init();
	}

	init() {
		const width = this.dom.clientWidth;
		const height = this.dom.clientHeight;

		this.scene = new Scene();
		//FPS gui
		// this.stats = new Stats()
		// document.body.appendChild(this.stats.dom)
		//Add Renderer
		this.renderer = new WebGLRenderer({
			antialias: true,
			powerPreference: "high-performance", //"high-performance", "low-power" or "default"
			precision: "highp", //highp", "mediump" or "lowp"
		});
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(width, height);
		this.renderer.setClearColor(0x000000, 0); // the default
		this.dom.appendChild(this.renderer.domElement);
		//add Camera
		this.camera = new PerspectiveCamera(40, width / height, 0.1, 100000);
		// this.camera = new OrthographicCamera( width / -2, width / 2, height / 2, height / -2, 1, 3000 );
		this.camera.position.z = 2000;
		this.camera.position.y = 0;
		this.camera.lookAt(new Vector3(0, 0, 0));
		//Camera Controls
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.addEventListener('change', e => {
			this.fixAxesHelper(e)
			this.renderScene()
		})
		this.addAxesHelper(width, height)
		this.scene.add(new AmbientLight("#f5f5f5", 1));

		this.renderScene();
		// this.start();
		this.setListeners();
	}

	addAxesHelper(width, height) {
		this.axesHelper = new AxesHelper( 100 );
		this.helperScene = new Scene();
		this.helperCamera = new PerspectiveCamera(40, 1, 0.1, 10000);
		this.helperScene.add(this.axesHelper);
		this.helperCamera.lookAt(new Vector3(0, 0, 0));
		this.axesHelper.position.set(0, 0, 0);
		this.helperCamera.position.z = 300;
		this.helperCamera.position.y = 0;

		this.helperRenderer = new WebGLRenderer({ alpha: true });
		this.helperRenderer.setSize(width / 8, width / 8);
		this.helperRenderer.domElement.classList.add('axes-scene')
		this.helperRenderer.domElement.style.position = 'absolute';
		this.helperRenderer.domElement.style.bottom = '10px';
		this.helperRenderer.domElement.style.left = CONFIG.leftPanelWidth + 'px';
		this.helperRenderer.domElement.style.zIndex = '100';
		this.dom.appendChild(this.helperRenderer.domElement);
		this.helperRenderer.render(this.helperScene, this.helperCamera)
	}

	fixAxesHelper(e) {
		this.axesHelper.quaternion.copy(this.camera.quaternion);
		this.helperRenderer.render(this.helperScene, this.helperCamera);
	}

	applyTransform(e) {

		if(this.transformTimeOut) {
			clearTimeout(this.transformTimeOut)
		}
		this.transformTimeOut = setTimeout(() => {

			console.log(this.transform.getMode(), this.transform.object);
		}, 100)
	}

	initTransform(mesh) {
		if(this.transformed) {
			this.removeTransform();
		}
		this.transformed = true;
		this.transform = new TransformControls( this.camera, this.renderer.domElement );
		const detail = BaseClass.getDetailFromMesh(mesh)
		const groupedDetail = BaseClass.getDetailsFromGroup(detail);
		if(!empty(groupedDetail)) {
			this.connectedObjectsGroup = new Group();
			groupedDetail.forEach(d => {
				this.connectedObjectsGroup.add(d.mesh);
			})
			this.scene.add( this.connectedObjectsGroup );
			this.transform.attach(this.connectedObjectsGroup )
		} else {
			const connectedDetails = BaseClass.getAllConnectedDetails(detail);
			if(!empty(connectedDetails)) {
				this.connectedObjectsGroup = new Group();
				connectedDetails.forEach(d => {
					this.connectedObjectsGroup.add(d.mesh);
				})
				this.connectedObjectsGroup.add(mesh)
				this.scene.add( this.connectedObjectsGroup );
				this.transform.attach(this.connectedObjectsGroup )
			} else {
				this.transform.attach(mesh)
			}
		}
		// this.transform.addEventListener('changed', e => {
		// 	this.renderScene()
		// })
		this.transform.addEventListener('dragging-changed', e => {
			this.controls.enabled = ! e.value;
		})
		this.transform.addEventListener('objectChange', e => {
			this.renderScene()
		})
		this.transform.setRotationSnap(Math.PI / 4)



		this.scene.add( this.transform );
		this.renderScene()

	}

	removeTransform() {
		if(!empty(this.connectedObjectsGroup?.children)) {
			while (!empty(this.connectedObjectsGroup?.children)) {
				const el = this.connectedObjectsGroup?.children[0]
				const detail = BaseClass.getDetailFromMesh(el)
				if(!empty(detail)) {
					detail.updatePosition();
				}

				this.scene.add(el);
			}
			this.connectedObjectsGroup.removeFromParent()
		} else {
			// this.dom.dispatchEvent( new CustomEvent('transformObject', {detail: this.transform.object}));
			const detail = BaseClass.getDetailFromMesh(this.transform.object)
			if(!empty(detail)) {
				detail.updatePosition();
			}
		}
		this.connectedObjectsGroup = null;
		this.transform.dispose();
		this.scene.remove(this.transform);
		this.transformed = false;
		this.renderScene()
	}

	setListeners() {
		window.addEventListener(
			"resize",
			() => {
				this.updateRenderSize();
			},
			false
		);
		window.addEventListener( 'keydown', ( event ) => {
			switch ( event.key ) {
				case 't':
				case 'е':
					this.transform.setMode( 'translate' );
					this.renderScene()
					break;
				case 'r':
				case 'к':
					this.transform.setMode( 'rotate' );
					this.renderScene()
					break;
				case 'Escape':
					if(this.transformed) {
						this.removeTransform()
					} else {
						this.dom.dispatchEvent( new CustomEvent('removeSelectedObjects', {detail: true}));
					}

					break
			}
		})
		this.dom.addEventListener('mousedown', (event) => {
			// console.log(event.ctrlKey)
			if(event.button === 0 && event.ctrlKey) {
				this.clickMouse.x = ((event.clientX - this.renderer.domElement.offsetLeft) / this.renderer.domElement.clientWidth) * 2 - 1;
				this.clickMouse.y = -((event.clientY - this.renderer.domElement.offsetTop) / this.renderer.domElement.clientHeight) * 2 + 1;
				this.clickMouse.clientX = event.clientX;
				this.clickMouse.clientY = event.clientY;
				this.selectObject()
			}
			if(event.button === 2){
				this.clickMouse.x = ((event.clientX - this.renderer.domElement.offsetLeft) / this.renderer.domElement.clientWidth) * 2 - 1;
				this.clickMouse.y = -((event.clientY - this.renderer.domElement.offsetTop) / this.renderer.domElement.clientHeight) * 2 + 1;
				this.clickMouse.clientX = event.clientX;
				this.clickMouse.clientY = event.clientY;
				this.editObject()
			}

		}, false);

		this.dom.addEventListener('mousemove', event => {
			this.mouse.x = ((event.clientX - this.renderer.domElement.offsetLeft) / this.renderer.domElement.clientWidth) * 2 - 1;
			this.mouse.y = -((event.clientY - this.renderer.domElement.offsetTop) / this.renderer.domElement.clientHeight) * 2 + 1;
		})


	}

	setSelectedObject(obj) {
		this.dom.dispatchEvent( new CustomEvent('selectObject', {detail: {
				selectedObject: obj
			}}));
	}

	setEditedObject(obj) {
		this.dom.dispatchEvent(
			new CustomEvent('editObject', {detail: obj})
		);
	}


	dispose() {
		this.stop();
		window.removeEventListener('resize', this.updateRenderSize);
		this.renderer.dispose();
		this.controls.dispose();
		if(!empty(this.renderer?.domElement?.parentNode)) {
			this.renderer?.domElement.parentNode.removeChild(this.renderer.domElement);
		}

	}

	clear() {
		while(this.scene.children.length > 0){
			this.scene.remove(this.scene.children[0]);
		}
		this.renderer.dispose()
		this.stop()
	}

	deleteElement(obj) {
		this.scene.remove(obj)
	}

	// updateRenderSize() {
	// 	if (!this.renderer?.domElement?.parentElement) {
	// 		console.error("cant calc size without parent dom element");
	// 		return;
	// 	}
	// 	if (this.camera && this.renderer?.domElement?.parentElement) {
	// 		const parentElement = this.renderer?.domElement?.parentElement;
	// 		let width = parentElement.clientWidth;
	// 		let height = parentElement.clientHeight;
	// 		const aspect = width / height;
	//
	// 		this.camera.aspect = aspect;
	//
	// 		this.camera.updateProjectionMatrix();
	// 		this.renderer.setSize(width, height);
	// 	}
	// }

	getCenter(zPoint) {
		this.controls.reset()
		this.camera.position.set(0.1, 9.06, zPoint);
		this.controls.update();
	}

	getCenterWithAnimation(zPoint) {
		this.shouldRender = true
		this.controls.reset()
		// Animations.smoothPositionForMesh(this.camera, {x: 0.1, y: 9.06, z: zPoint, duration: 1})
		this.controls.update();
	}

	// start() {
	// 	if (!this.frameId) {
	// 		this.animate();
	// 		// this.renderScene();
	// 		// this.frameId = requestAnimationFrame(this.animate);
	// 		this.controls.update();
	// 	}
	// }

	// stop() {
	// 	cancelAnimationFrame(this.frameId);
	// }

	renderScene = () => {
		if (this.renderer) {
			this.renderer.render(this.scene, this.camera);
			// this.renderer.render(this.axesScene, this.axesCamera);
		}

	};

	getClickedDetail(object) {
		if((object.isGroup || object.isMesh) && (object?.userData?.detailCId && !object?.parent?.userData?.productId || object?.userData?.productId)) {
			object.clickedCords = {x: this.clickMouse.clientX, y: this.clickMouse.clientY}
			return object
		} else if(!empty(object.parent)){
			return this.getClickedDetail(object.parent)
		} else {
			return null;
		}
	}

	selectObject() {
		this.raycaster.setFromCamera(this.clickMouse, this.camera)
		const found = this.raycaster.intersectObjects(this.scene.children)
		if(!empty(found)) {
			const _found = found[0];
			if(!_found?.object?.userData?.selection) {
				return
			}
			_found.clickedCords = {x: this.clickMouse.clientX, y: this.clickMouse.clientY}
			// const clickedDetail = this.getClickedDetail(_found.object);
			this.setSelectedObject(_found)

			// if(found.object.isMesh && found.object.userData.detailCId) {
			// 	this.setSelectedObject(found[0])
			// } else {
			//
			// }
			// // console.log(found)
			// // found.forEach(el => {
			// found[0].clickedCords = {x: this.clickMouse.clientX, y: this.clickMouse.clientY}
			//
			// // })
			// // this.setSelectedObject(found[0].object.)
			// if (found.length > 0 && found[0].object.userData.draggable) {
			// 	this.draggable = found[0].object
			// 	console.log(`found draggable object ${this.draggable.userData.name}`)
			// }
		} else {
			// this.scene.remove(this.transform)
		}
		this.renderScene()
	}

	editObject() {
		this.raycaster.setFromCamera(this.clickMouse, this.camera);
		// this.scene.children.forEach(el => el.updateMatrixWorld())
		const found = this.raycaster.intersectObjects(this.scene.children)
		if(!empty(found)) {
			const _found = {
				object: this.getClickedDetail(found[0].object),
				clickedCords: {x: this.clickMouse.clientX, y: this.clickMouse.clientY}
			}

			this.setEditedObject(_found);
			this.renderScene()
		} else {
			this.dom.dispatchEvent( new CustomEvent('removeSelectedObjects', {detail: null}));
		}
	}

	getObjectByUuid(uuid) {
		return this.scene.children.find(el => el.userData?.objectId && el.userData?.objectId === uuid);
	}

	/**
	 * Animates the models.
	 *
	 * @function animate
	 * @memberof global
	 * @instance
	 * @memberOf global
	 * @returns {void}
	 */
	// animate = () => {
	// 	//Animate Models Here
	// 	this.frameId = window.requestAnimationFrame(this.animate);
	// 	//ReDraw Scene with Camera and Scene Object
	//
	// 	if(this.shouldRender) {
	// 		this.renderScene();
	// 	}
	//
	// };


}