import Base from "./Base";
import _Constructor from "../../../db/_Constructor";
import HistoryClass from "./HistoryClass";
import CDetail from "../CDetail";
import {empty} from "../../../helpers/helper";
import _Details from "../../../db/_Details";
import store from "./../../../redux/store";

export default class CProject extends Base {
	historyClass = new HistoryClass();
	detailsDb = new _Details();

	constructor() {
		super();
		this.db.getDetails()
			.then(details => {
				if(!empty(details)) {
					const pDetails =  store.getState().project.project.construction.payload.construction._details
					return Promise.resolve({details, pDetails})
				} else {
					return Promise.resolve({details: [], pDetails: []});
				}
			})
			.then(data => {
				const { details, pDetails } = data;
				const groups = [];
        details.forEach((detail) => {
					if(detail.group?.group_id && empty(groups.find(group => group.group_id === detail.group?.group_id))) {
						groups.push(detail.group)
					}
					const projectDetail = pDetails.find(p => p.detailId === detail.detailId);
					const detailData = {
						...detail,
						h: projectDetail?.h,
						l: projectDetail?.l,
						w: projectDetail?.w,
						contour3d: projectDetail.contour3d,
						isLoad: true,
						shouldUpdate: this.checkIfDetailWasUpdated(detail, projectDetail)
					}
					const cDetail = new CDetail(detailData);
					cDetail.load()
	        store.dispatch({type: 'ADD_DETAIL', payload: cDetail})
        })
				groups.forEach(group => {
					store.dispatch({type: "ADD_GROUP", payload: group})
				})
				return Promise.resolve(groups)
			})
			.then(groups => {
				if (empty(groups)) return Promise.resolve();
				const details = this.getState('cDetails')
				groups.forEach(group => {
					const tmp_details = details.filter(detail => detail.group?.group_id === group.group_id)

					tmp_details.forEach(detail => {

					})
				});
				return Promise.resolve();
			})
			.then(() => this.db.getConnections())
			.then(connections => {
				connections.forEach(connection => {
					this.updateState({
						type: 'ADD_CONNECTION',
						payload: connection
					})
				})
				return Promise.resolve()
			})
			.then(() => {
				this.getState('scene').sceneClass.addDetails()
			})

	}

	checkIfDetailWasUpdated(detail, pDetail) {
		return detail.h !== pDetail.h || detail.l !== pDetail.l || detail.w !== pDetail.w
	}


}