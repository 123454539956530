import {useEffect, useRef, useState} from "react";
import {validationInput} from "../../../../../helpers/helper";
import {toast} from "react-toastify";
import Languages from "../../../../../translation/Languages";

const MaterialItem = ({material, materialTypes, deleteMaterial, idx, name}) => {
	const [materialData, setMaterialData] = useState({})
	const inputHeight = useRef()
	const inputWidth = useRef()
	const inputThickness = useRef()

	const getMaterialData = () => {
		const materialD = {
			height: material.height || '',
			width: material.width || '',
			thickness: material.thickness || '',
			name: material.name || name,
			type: material.type || 'ЛДСП'
		}
		return materialD
	}

	const handleChange = (event) => {
		let {name, value} = event.target;
		value = !isNaN(parseFloat(value))
			? parseFloat(value)
			: value;
		setMaterialData({
			...materialData,
			[name]: value,
		});
	};

	const changeMaterialProps = () => {
		const {height, width, thickness, name, type} = materialData
		material.height = height
		material.width = width
		material.thickness = thickness
		material.name = name
		material.type = type
	}

	const enterKeyDownHandler = (e, inputType) => {
		if (e.key === 'Enter'){
			if (e.target.value === '') {
				toast.error(Languages.getTranslation('enter-value', true), {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				})
				e.target.className = 'kronasMaterials__default-input kronasMaterials__error'
				return
			}
			e.target.className = 'kronasMaterials__default-input'
			inputType.current.focus()
		}
	}

	useEffect(() => {
		changeMaterialProps()
	}, [materialData])

	useEffect(() => {
		setMaterialData(getMaterialData());
	}, [material]);

	useEffect(() => {
		inputHeight.current.focus()
	}, [])

	return <div className="kronasMaterials__table-body-item" >
		<div className="kronasMaterials__data materialsKronas__height-width">
			<input type="text" className="kronasMaterials__default-input" value={materialData.height} name='height'
				ref={inputHeight} onChange={(e) => handleChange(e)} onInput={(e) => validationInput(e)}
				onKeyDown={(e) => enterKeyDownHandler(e, inputWidth)}
				autoComplete='off'
			/>
		</div>
		<div className="kronasMaterials__data materialsKronas__height-width">
			<input type="text" className="kronasMaterials__default-input" value={materialData.width} name='width'
				ref={inputWidth} onChange={(e) => handleChange(e)} onInput={(e) => validationInput(e)}
				onKeyDown={(e) => enterKeyDownHandler(e, inputThickness)}
				autoComplete='off'
			/>
		</div>
		<div className="kronasMaterials__data materialsKronas__sheet-thickness">
			<input type="text" className="kronasMaterials__default-input" value={materialData.thickness} name='thickness' ref={inputThickness}
			 onChange={(e) => handleChange(e)} onInput={(e) => validationInput(e)}
				autoComplete='off'
			/>
		</div>
		<div className="kronasMaterials__data materialsKronas__name">
			<input type="text" className="kronasMaterials__input kronasMaterials__input-name" value={materialData.name}
				name='name' onChange={(e) => handleChange(e)}
				autoComplete='off'
			/>
		</div>
		<div className="kronasMaterials__data materialsKronas__materialType">
			<select className="kronasMaterials__select" defaultValue={material.type} name='type'
				onChange={(e) => handleChange(e)}>
				{materialTypes.map((type) => (<option key={type} value={type}>{type}</option>))}
			</select>
		</div>
		<div className="kronasMaterials__btn">
			<button className="btn" onClick={() => deleteMaterial(material)}>
				<i className="btn__icon-delete"></i>
			</button>
		</div>
	</div>
}

export default MaterialItem