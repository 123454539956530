import Helpers from "../3D/Helpers";
import Languages from "../../translation/Languages";

export default class TableCorner {
	_elements;
	parent;
	constructor({
		            parent,
		            elements,
	            }) {
		this.parent = parent;
		this._elements = elements;

		this.addParams();
		this.initRequiredFields();
	}
	initRequiredFields() {
		this.parent.requiredFields = {
			r: {
				min: 80,
				max: Math.min(this.parent.detail.h, this.parent.detail.l) - 15
			},
		}
	}

	addParams() {
		this.parent.setFormField({
			name: 'angle', value: this.parent.angle, label: 'Кут', type: 'select', variables: [
				// {key: "left_bottom", value: "Лівий Нижній"},
				{key: "left_top", value: Languages.getTranslation('left_top', true)},
				{key: "right_top", value: Languages.getTranslation('right_top', true)},
				// {key: "right_bottom", value: "Правий Нижній"},
			], visible: true
		})

		this.parent.setFormField({name: 'edgeSide', type: 'select', visible: false})

		this.parent.setFormField({name: 'width', visible: false,})

		this.parent.setFormField({name: 'additionalSide', visible: false})
	}

	get paramsSorting() {
		const sortingArr = ['type', 'angle', 'comment']

		return 	this.parent.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	generateElements () {
		const elements = []
		const data = this.createCoordinatesForTableCorner()
		const {x1: x1Line, y1: y1Line, x2: x2Line, y2: y2Line } = data[this.parent.r][this.parent.angle].line1
		const {x1: x1Arc1, y1: y1Arc1, x2: x2Arc1, y2: y2Arc1, xc: xcArc1, yc: ycArc1} = data[this.parent.r][this.parent.angle].arc1
		const {x1: x1Arc2, y1: y1Arc2, x2: x2Arc2, y2: y2Arc2, xc: xcArc2, yc: ycArc2} = data[this.parent.r][this.parent.angle].arc2
		const {x1: x1Line2, y1: y1Line2, x2: x2Line2, y2: y2Line2 } = data[this.parent.r][this.parent.angle].line2

		switch (this.parent.angle) {
			case 'left_top':
				// if (this.parent.r === this.parent.detail.h) {
				elements.push(Helpers.createLine(x1Line, y1Line, x2Line, y2Line, this.parent.edge, this.getActiveElement()))
				// }
				elements.push(Helpers.createArc(x1Arc1, y1Arc1, x2Arc1, y2Arc1, this.parent.r, xcArc1, ycArc1, true, this.parent.edge, this.getActiveElement()))
				elements.push(Helpers.createArc(x1Arc2, y1Arc2, x2Arc2, y2Arc2, 25, xcArc2, ycArc2, false, this.parent.edge, this.getActiveElement()))
				elements.push(Helpers.createLine(x1Line2, y1Line2, x2Line2, y2Line2, this.parent.edge, this.getActiveElement()))
				break
			case 'right_top':
				elements.push(Helpers.createLine(x1Line, y1Line, x2Line, y2Line, this.parent.edge, this.getActiveElement()))
				elements.push(Helpers.createArc(x1Arc1, y1Arc1, x2Arc1, y2Arc1, 25, xcArc1, ycArc1, false, this.parent.edge, this.getActiveElement()))
				elements.push(Helpers.createArc(x1Arc2, y1Arc2, x2Arc2, y2Arc2, this.parent.r, xcArc2, ycArc2, true, this.parent.edge, this.getActiveElement()))
				// if (this.parent.r <= this.parent.detail.h) {
				elements.push(Helpers.createLine(x1Line2, y1Line2, x2Line2, y2Line2, this.parent.edge, this.getActiveElement()))
				// }
				break
		}
		return elements
	}

	//coordinates of every tableCorner type changing by radius
	createCoordinatesForTableCorner () {
		const detailH = this.parent.detail.h
		const detailL = this.parent.detail.l
		return {
			80: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 88
					},
					arc1: {
						x1: 0,
						y1: detailH - 88,
						x2: 64.499,
						y2: detailH - 9.516,
						xc: 80,
						yc: detailH - 88,
					},
					arc2: {
						x1: 64.499,
						y1: detailH - 9.516,
						x2: 77.332,
						y2: detailH - 2.668,
						xc: 59.655,
						yc: detailH + 15.01,
					},
					line2: {
						x1: 77.332,
						y1: detailH - 2.668,
						x2: 80,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 80,
						y1: detailH,
						x2: detailL - 77.332,
						y2: detailH - 2.668
					},
					arc1: {
						x1: detailL - 77.332,
						y1: detailH - 2.668,
						x2: detailL - 64.499,
						y2: detailH - 9.516,
						xc: detailL - 59.655,
						yc: detailH + 15.01,
					},
					arc2: {
						x1: detailL - 64.499,
						y1: detailH - 9.516,
						x2: detailL,
						y2: detailH - 88,
						xc: detailL - 80,
						yc: detailH - 88,
					},
					line2: {
						x1: detailL,
						y1: detailH - 88,
						x2: detailL,
						y2: 0
					},

				}
			},
			100: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 108
					},
					arc1: {
						x1: 0,
						y1: detailH - 108,
						x2: 84.033,
						y2: detailH - 9.283,
						xc: 100,
						yc: detailH - 108,
					},
					arc2: {
						x1: 84.033,
						y1: detailH - 9.283,
						x2: 97.719,
						y2: detailH - 2.281,
						xc: 80.041,
						yc: detailH + 15.396,
					},
					line2: {
						x1: 97.719,
						y1: detailH - 2.281,
						x2: 100,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 100,
						y1: detailH,
						x2: detailL - 97.718,
						y2: detailH - 2.281
					},
					arc1: {
						x1: detailL - 97.718,
						y1: detailH - 2.281,
						x2: detailL - 84.033,
						y2: detailH - 9.283,
						xc: detailL - 80.041,
						yc: detailH + 15.396,
					},
					arc2: {
						x1: detailL - 84.033,
						y1: detailH - 9.283,
						x2: detailL,
						y2: detailH - 108,
						xc: detailL - 100,
						yc: detailH - 108,
					},
					line2: {
						x1: detailL,
						y1: detailH - 108,
						x2: detailL,
						y2: 0
					},

				}
			},
			150: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 158
					},
					arc1: {
						x1: 0,
						y1: detailH - 158,
						x2: 133.339,
						y2: detailH - 8.92,
						xc: 150,
						yc: detailH - 158,
					},
					arc2: {
						x1: 133.339,
						y1: detailH - 8.92,
						x2: 148.24,
						y2: detailH - 1.75,
						xc: 130.562,
						yc: detailH + 15.917,
					},
					line2: {
						x1: 148.24,
						y1: detailH - 1.75,
						x2: 150,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 150,
						y1: detailH,
						x2: detailL - 148.239,
						y2: detailH - 1.76
					},
					arc1: {
						x1: detailL - 148.239,
						y1: detailH - 1.76,
						x2: detailL - 133.339,
						y2: detailH - 8.928,
						xc: detailL - 130.562,
						yc: detailH + 15.917,
					},
					arc2: {
						x1: detailL - 133.339,
						y1: detailH - 8.928,
						x2: detailL,
						y2: detailH - 158,
						xc: detailL - 150,
						yc: detailH - 158,
					},
					line2: {
						x1: detailL,
						y1: detailH - 158,
						x2: detailL,
						y2: 0
					},

				}
			},
			200: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 208
					},
					arc1: {
						x1: 0,
						y1: detailH - 208,
						x2: 182.957,
						y2: detailH - 8.727,
						xc: 200,
						yc: detailH - 208,
					},
					arc2: {
						x1: 182.957,
						y1: detailH - 8.727,
						x2: 198.504,
						y2: detailH - 1.496,
						xc: 180.826,
						yc: detailH + 16.182,
					},
					line2: {
						x1: 198.504,
						y1: detailH - 1.496,
						x2: 200,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 200,
						y1: detailH,
						x2: detailL - 198.504,
						y2: detailH - 1.496
					},
					arc1: {
						x1: detailL - 198.504,
						y1: detailH - 1.496,
						x2: detailL - 182.957,
						y2: detailH - 8.727,
						xc: detailL - 180.826,
						yc: detailH + 16.182,
					},
					arc2: {
						x1: detailL - 182.957,
						y1: detailH - 8.727,
						x2: detailL,
						y2: detailH - 208,
						xc: detailL - 200,
						yc: detailH - 208,
					},
					line2: {
						x1: detailL,
						y1: detailH - 208,
						x2: detailL,
						y2: 0
					},

				}
			},
			250: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 258
					},
					arc1: {
						x1: 0,
						y1: detailH - 258,
						x2: 232.715,
						y2: detailH - 8.598,
						xc: 250,
						yc: detailH - 258,
					},
					arc2: {
						x1: 232.715,
						y1: detailH - 8.598,
						x2: 248.664,
						y2: detailH - 1.336,
						xc: 230.987,
						yc: detailH + 16.342,
					},
					line2: {
						x1: 248.664,
						y1: detailH - 1.336,
						x2: 250,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 250,
						y1: detailH,
						x2: detailL - 248.664,
						y2: detailH - 1.336
					},
					arc1: {
						x1: detailL - 248.664,
						y1: detailH - 1.336,
						x2: detailL - 232.715,
						y2: detailH - 8.598,
						xc: detailL - 230.986,
						yc: detailH + 16.342,
					},
					arc2: {
						x1: detailL - 232.715,
						y1: detailH - 8.598,
						x2: detailL,
						y2: detailH - 258,
						xc: detailL - 250,
						yc: detailH - 258,
					},
					line2: {
						x1: detailL,
						y1: detailH - 258,
						x2: detailL,
						y2: 0
					},

				}
			},
			300: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 308
					},
					arc1: {
						x1: 0,
						y1: detailH - 308,
						x2: 282.549,
						y2: detailH - 8.508,
						xc: 300,
						yc: detailH - 308,
					},
					arc2: {
						x1: 282.549,
						y1: detailH - 8.508,
						x2: 298.772,
						y2: detailH - 1.228,
						xc: 281.094,
						yc: detailH + 16.45,
					},
					line2: {
						x1: 298.772,
						y1: detailH - 1.228,
						x2: 300,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 300,
						y1: detailH,
						x2: detailL - 298.772,
						y2: detailH - 1.228
					},
					arc1: {
						x1: detailL - 298.772,
						y1: detailH - 1.228,
						x2: detailL - 282.548,
						y2: detailH - 8.508,
						xc: detailL - 281.094,
						yc: detailH + 16.45,
					},
					arc2: {
						x1: detailL - 282.548,
						y1: detailH - 8.508,
						x2: detailL,
						y2: detailH - 308,
						xc: detailL - 300,
						yc: detailH - 308,
					},
					line2: {
						x1: detailL,
						y1: detailH - 308,
						x2: detailL,
						y2: 0
					},

				}
			},
			400: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 408
					},
					arc1: {
						x1: 0,
						y1: detailH - 408,
						x2: 382.334,
						y2: detailH - 8.39,
						xc: 400,
						yc: detailH - 408,
					},
					arc2: {
						x1: 382.334,
						y1: detailH - 8.39,
						x2: 398.908,
						y2: detailH - 1.092,
						xc: 381.32,
						yc: detailH + 16.585,
					},
					line2: {
						x1: 398.908,
						y1: detailH - 1.092,
						x2: 400,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 400,
						y1: detailH,
						x2: detailL - 398.908,
						y2: detailH - 1.092
					},
					arc1: {
						x1: detailL - 398.908,
						y1: detailH - 1.092,
						x2: detailL - 382.34,
						y2: detailH - 8.39,
						xc: detailL - 381.23,
						yc: detailH + 16.585,
					},
					arc2: {
						x1: detailL - 382.34,
						y1: detailH - 8.39,
						x2: detailL,
						y2: detailH - 408,
						xc: detailL - 400,
						yc: detailH - 408,
					},
					line2: {
						x1: detailL,
						y1: detailH - 408,
						x2: detailL,
						y2: 0
					},

				}
			},
			450: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 458
					},
					arc1: {
						x1: 0,
						y1: detailH - 458,
						x2: 432.261,
						y2: detailH - 8.35,
						xc: 450,
						yc: detailH - 458,
					},
					arc2: {
						x1: 432.261,
						y1: detailH - 8.35,
						x2: 448.953,
						y2: detailH - 1.047,
						xc: 431.276,
						yc: detailH + 16.631,
					},
					line2: {
						x1: 448.953,
						y1: detailH - 1.047,
						x2: 450,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 450,
						y1: detailH,
						x2: detailL - 448.953,
						y2: detailH - 1.047
					},
					arc1: {
						x1: detailL - 448.953,
						y1: detailH - 1.047,
						x2: detailL - 432.261,
						y2: detailH - 8.35,
						xc: detailL - 431.275,
						yc: detailH + 16.631,
					},
					arc2: {
						x1: detailL - 432.261,
						y1: detailH - 8.35,
						x2: detailL,
						y2: detailH - 458,
						xc: detailL - 450,
						yc: detailH - 458,
					},
					line2: {
						x1: detailL,
						y1: detailH - 458,
						x2: detailL,
						y2: 0
					},

				}
			},
			500: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 508
					},
					arc1: {
						x1: 0,
						y1: detailH - 508,
						x2: 482.202,
						y2: detailH - 8.317,
						xc: 500,
						yc: detailH - 508,
					},
					arc2: {
						x1: 482.202,
						y1: detailH - 8.317,
						x2: 498.99,
						y2: detailH - 1.01,
						xc: 481.312,
						yc: detailH + 16.667,
					},
					line2: {
						x1: 498.99,
						y1: detailH - 1.01,
						x2: 500,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 500,
						y1: detailH,
						x2: detailL - 498.99,
						y2: detailH - 1.01
					},
					arc1: {
						x1: detailL - 498.99,
						y1: detailH - 1.01,
						x2: detailL - 482.202,
						y2: detailH - 8.017,
						xc: detailL - 481.312,
						yc: detailH + 16.667,
					},
					arc2: {
						x1: detailL - 482.202,
						y1: detailH - 8.017,
						x2: detailL,
						y2: detailH - 508,
						xc: detailL - 500,
						yc: detailH - 508,
					},
					line2: {
						x1: detailL,
						y1: detailH - 508,
						x2: detailL,
						y2: 0
					},

				}
			},
			600: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 608
					},
					arc1: {
						x1: 0,
						y1: detailH - 608,
						x2: 582.112,
						y2: detailH - 8.267,
						xc: 600,
						yc: detailH - 608,
					},
					arc2: {
						x1: 582.112,
						y1: detailH - 8.267,
						x2: 599.045,
						y2: detailH - 0.955,
						xc: 581.367,
						yc: detailH + 16.722,
					},
					line2: {
						x1: 599.045,
						y1: detailH - 0.955,
						x2: 600,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 600,
						y1: detailH,
						x2: detailL - 599.044,
						y2: detailH - 0.955
					},
					arc1: {
						x1: detailL - 599.044,
						y1: detailH - 0.955,
						x2: detailL - 582.112,
						y2: detailH - 8.267,
						xc: detailL - 581.367,
						yc: detailH + 16.631,
					},
					arc2: {
						x1: detailL - 582.112,
						y1: detailH - 8.267,
						x2: detailL,
						y2: detailH - 608,
						xc: detailL - 600,
						yc: detailH - 608,
					},
					line2: {
						x1: detailL,
						y1: detailH - 608,
						x2: detailL,
						y2: 0
					},
				}
			},
			700: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 708
					},
					arc1: {
						x1: 0,
						y1: detailH - 708,
						x2: 682.047,
						y2: detailH - 8.23,
						xc: 700,
						yc: detailH - 708,
					},
					arc2: {
						x1: 682.047,
						y1: detailH - 8.23,
						x2: 699.084,
						y2: detailH - 0.916,
						xc: 681.406,
						yc: detailH + 16.762,
					},
					line2: {
						x1: 699.084,
						y1: detailH - 0.916,
						x2: 700,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 700,
						y1: detailH,
						x2: detailL - 699.084,
						y2: detailH - 0.916
					},
					arc1: {
						x1: detailL - 699.084,
						y1: detailH - 0.916,
						x2: detailL - 682.047,
						y2: detailH - 8.23,
						xc: detailL - 681.406,
						yc: detailH + 16.762,
					},
					arc2: {
						x1: detailL - 682.047,
						y1: detailH - 8.23,
						x2: detailL,
						y2: detailH - 708,
						xc: detailL - 700,
						yc: detailH - 708,
					},
					line2: {
						x1: detailL,
						y1: detailH - 708,
						x2: detailL,
						y2: 0
					},

				}
			},
			800: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 808
					},
					arc1: {
						x1: 0,
						y1: detailH - 808,
						x2: 781.998,
						y2: detailH - 8.202,
						xc: 800,
						yc: detailH - 808,
					},
					arc2: {
						x1: 781.998,
						y1: detailH - 8.202,
						x2: 799.114,
						y2: detailH - 0.886,
						xc: 781.436,
						yc: detailH + 16.791,
					},
					line2: {
						x1: 799.114,
						y1: detailH - 0.886,
						x2: 800,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 800,
						y1: detailH,
						x2: detailL - 799.113,
						y2: detailH - 0.886
					},
					arc1: {
						x1: detailL - 799.113,
						y1: detailH - 0.886,
						x2: detailL - 781.998,
						y2: detailH - 8.202,
						xc: detailL - 781.436,
						yc: detailH + 16.791,
					},
					arc2: {
						x1: detailL - 781.998,
						y1: detailH - 8.202,
						x2: detailL,
						y2: detailH - 808,
						xc: detailL - 800,
						yc: detailH - 808,
					},
					line2: {
						x1: detailL,
						y1: detailH - 808,
						x2: detailL,
						y2: 0
					},

				}
			},
			900: {
				left_top: {
					line1: {
						x1: 0,
						y1: 0,
						x2: 0,
						y2: detailH - 907.964
					},
					arc1: {
						x1: 0,
						y1: detailH - 907.964,
						x2: 881.995,
						y2: detailH - 8.202,
						xc: 900,
						yc: detailH - 907.964,
					},
					arc2: {
						x1: 881.995,
						y1: detailH - 8.202,
						x2: 899.173,
						y2: detailH - 0.827,
						xc: 881.495,
						yc: detailH + 16.851,
					},
					line2: {
						x1: 899.173,
						y1: detailH - 0.827,
						x2: 900,
						y2: detailH
					},
				},
				right_top: {
					line1: {
						x1: detailL - 900,
						y1: detailH,
						x2: detailL - 899.173,
						y2: detailH - 0.827
					},
					arc1: {
						x1: detailL - 899.173,
						y1: detailH - 0.827,
						x2: detailL - 881.995,
						y2: detailH - 8.144,
						xc: detailL - 881.495,
						yc: detailH + 16.851,
					},
					arc2: {
						x1: detailL - 881.995,
						y1: detailH - 8.144,
						x2: detailL,
						y2: detailH - 907.964,
						xc: detailL - 900,
						yc: detailH - 907.964,
					},
					line2: {
						x1: detailL,
						y1: detailH - 907.964,
						x2: detailL,
						y2: 0
					},

				}
			},
			}
	}

	get elements() {
		return this.generateElements()
	}

	// set elements(element) {
	// 	this._elements = element
	// }

	get cutOut() {
		return {
			side: 'front',
			type: 'tableCorner',
			subType: 'tableProc',
			depth: this.parent.detail.w,
			place: this.parent.edgeSide,
			width: this.parent.width,
			angle: this.parent.angle,
			color: this.parent.isActive ? Helpers.colorForActive : null,
			x_axis: this.parent.additionalSide,
			elements: this.elements,
		};
	}

	get realData() {
		return {
			r: Number(this.parent.r),
			additionalSide: this.parent.additionalSide,
			edgeSide: this.parent.angle.split('_')[0]
		}
	}

	getActiveElement() {
		return this.parent.isActive ? Helpers.colorForActive : null
	}

	addSizesArrows() {
		return
	}

	validate() {
		return Promise.resolve()
	}
}