import React, {useEffect, useState} from "react";
import AppRouter from "components/AppRouter";
import ThreeScene from "components/3dPage";
import {useDispatch, useSelector} from "react-redux";
import Project from "models/Project";
import Loader from "../UI/Loader";
import {modalsSelectors} from "redux/modals";
import {userSelectors} from "redux/user";
import Db from "../../api/Db";
import {empty} from "../../helpers/helper";
import NewProjectOrLoad from "../UI/Modal/NewProjectOrLoad";

const db = new Db();

const App = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState([])
  const id = new URLSearchParams(window.location.search).get('id')
  const action = new URLSearchParams(window.location.search).get('action')
  const modalState = useSelector(modalsSelectors.getModalShowState);
  const user = useSelector(userSelectors.User);

  useEffect(()=> {
    if (modalState){
      document.body.classList.add('disable-scroll')
    } else {
      document.body.classList.remove('disable-scroll')
    }
  })

  const checkLoadProject = async () => {
      const _id = id || null;
      return Project.loadProject(_id, dispatch)
  }

  useEffect(() => {
      if(action === 'create'){
          db.checkIfData().then(res => {
              setIsEmpty(res)
              setLoaded(true)
          });
      }else{
          checkLoadProject()
              .then(data => {
                  setLoaded(true);
                  setLoad(data);
              })
              .then(() => Project.validateProjectDetails())
              .catch(err => console.log(err))
      }
  },[])

  const render = () => {
      if(loaded) {
          return (<>
              <AppRouter load={load} user={user} />
              {!empty(isEmpty) && action === 'create' && <NewProjectOrLoad setIsEmpty={setIsEmpty} />}
          </>)
      } else {
          return <Loader main={true}/>
      }
  }

  return render();
};

export default App;
