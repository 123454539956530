import Db from "../api/Db";
import {empty} from "../helpers/helper";

export default class _Project extends Db {

	constructor() {
		super();
	}

	getProject(id = 1) {
		return this.getItem('project', id);
	}

	getProjectData() {
		return this.getProjectId()
			.then(id => {
				return this.getItem('project', id)
			})

	}

	getName() {
		return this.getProject()
			.then(project => Promise.resolve(project.name))
	}

	setName(name) {
		return this.update('project', 1, 'name', name)
	}
	getProducts() {
		return this.getProject()
			.then(project => Promise.resolve(project.products))
	}

	updateProject(id = 1, field, value){
		return this.update('project', id, field, value)
	}


	getProjectId() {
 		return this.getAllData('project')
			.then(data => {
				if(!empty(data) && !empty(data[0].id)) {
					return Promise.resolve(data[0].id)
				} else {
					return this.addProject()
						.then(data => Promise.resolve(data.id))
				}
			})
	}
}