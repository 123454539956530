const getPanelShowState = ({ panel }) => {
  return panel.panel.show;
};

const getPanelIsEditPanel = ({panel}) => {
  return panel.panel.isEditPanel || false
}

export default {
  getPanelShowState,
  getPanelIsEditPanel
};
