import React from 'react';
import './style.scss';

const MaterialResiduesItem = ({activeCheckBox, material, selectedMaterials, selectMaterialHandler}) => {
const { article, height, width, thickness, name, type, damage, count, mc_id} = material
    return (
      <div className="materialResiduesItem">
        <div className="materialResidues__checkBoxContainer">
          <div className={activeCheckBox("checkbox", selectedMaterials.includes(material))} onClick={() => selectMaterialHandler(material)}>
            <i className={activeCheckBox("icon", true)}></i>
          </div>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent ">{article}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{mc_id}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{height}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{width}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{thickness}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{count}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__material" title={name}>
          <div className="materialResiduesItem__textContent">{name}</div>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <span className="materialResiduesItem__textContent">{type}</span>
        </div>
        <div className="materialResidues__itemContainer materialResiduesItem__itemContainerForSmallRow">
          <div className="materialResidues__checkBox">
            <div className={activeCheckBox("checkbox", damage)} style={{cursor: "default"}}>
              <i className={activeCheckBox("icon", damage)}></i>
            </div>
          </div>
        </div>
      </div>
    );
}

export default MaterialResiduesItem;