import './style.scss';
import logoMain from '../../media/logoMain.svg'
import logoPersonal from '../../media/logo-personal.svg'
import logo3D from '../../media/logo-3d.svg'
import logoKr from '../../media/kronasComLogo.svg'
import logoKRONAS_MASTER_3D from '../../media/KRONAS_MASTER_3D.svg'
import logoLK from '../../media/logoLK.svg'
import Languages from "../../translation/Languages";
import {useSelector} from "react-redux";
import {userSelectors} from "redux/user";
import {empty} from "../../helpers/helper";

const KronasLinks = () => {
	const user = useSelector(userSelectors.User);

	const setProject = () => {
		if (!empty(user)){
			if (confirm(Languages.getTranslation('are-you-ok', true)) === true) {
				window.location.replace(`${process.env.PERSONAL_ACCOUNT_URL}/#/my-projects`);
			}
		}else{
			document.location=`${process.env.PERSONAL_ACCOUNT_URL}?previousUrl=${window.location.href}`
		}
	};

	return <ul className="logo__list">
		<li className="logo__item logo__item--personal">
			<a className="logo__link" href="/">
  		<span className="logo__icon">
              <img src={logo3D} width="18" height="18" alt="logo3D"
              />
            </span>
				<span className="logo__text">
						<img src={logoKRONAS_MASTER_3D} width="108" height="14" alt='logoKr'/>
					</span> </a>
		</li>
		<li className="logo__item logo__item--main">
			<a
				className="logo__link"
				href='https://kronas.com.ua'
				target="_blank"
			>
			<span className="logo__icon">
              <img
								src={logoMain}
								width="14"
								height="14"
								alt='logoMain'
							/>
            </span>
					<span className="logo__text">
						<img src={logoKr} width="98" height="14" alt='logoKr'/>
					</span>
				</a>
			</li>
			<li className="logo__item logo__item--master">
				<a className="logo__link" onClick={setProject}>
				<span className="logo__icon">
              <img
								src={logoPersonal}
								width="18"
								height="18"
								alt='logoPersonal'
							/>
            </span>
					<span className="logo__text">
						{Languages.getTranslation("personal")}
					</span> </a>
			</li>
	</ul>
}

export default KronasLinks