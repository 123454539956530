import ACTIONS from "./actions";

const setToken = ({ token }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.UserToken({ token: token }));
    } catch (e) {
      console.log(e);
    }
  };
};
const setUserInfo = ({ user }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.UserInfo({ user: user }));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  setToken,
  setUserInfo
};
