import './style.scss';
import Languages from "../../../translation/Languages";
import React, {useEffect, useState} from "react";
import ParamContainer from "./ParamContainer";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {empty} from "../../../helpers/helper";
import CloseBtn from "../Buttons/CloseBtn";
import {panelSelectors} from "redux/panel";
import {userSelectors} from "redux/user";

export const getProcesses = (detail) => {
	return [
		{
			name: Languages.getTranslation('corner', true),
			type: "corner",
			items: [detail?.corners],
		},
		{
			name: Languages.getTranslation('one-groove', true),
			type: "groove",
			items: [detail?.grooves],
		},
		{
			name: Languages.getTranslation('rabbet', true),
			type: "rabbet",
			items: [detail?.rabbets],
		},
		{
			name: Languages.getTranslation('circle-proc', true),
			type: "circle",
			items: [detail?.circles],
		},
		{
			name: Languages.getTranslation('rectangle-proc', true),
			type: "rectangle",
			items: [detail?.rectangles],
		},
		{
			name: Languages.getTranslation('uShape-proc', true),
			type: "uShape",
			items: [detail?.uShapes],
		},
		{
			name: Languages.getTranslation('add_hole_title', true),
			type: "hole",
			items: [detail?.holes],
		},
		{
			name: Languages.getTranslation('add_mill_title', true),
			type: "mill",
			items: [detail?.mills],
		},
		{
			name: Languages.getTranslation('template-proc', true),
			type: "mill",
			items: [detail?.templates],
		},
		{
			name: Languages.getTranslation('Milling', true),
			type: "cutOut",
			items: [detail?.cutouts],
		},
	]
}

export const dontHaveProc = (detail) => {
	if (empty(detail?.corners)
		&& empty(detail?.grooves)
		&& empty(detail?.rabbets)
		&& empty(detail?.circles)
		&& empty(detail?.rectangles)
		&& empty(detail?.uShapes)
		&& empty(detail?.holes)
		&& empty(detail?.bevels)
		&& empty(detail?.mills)
		&& empty(detail?.cutouts)
		&& empty(detail?.templates)) {
		return <div className="chosProcesses__dontProc">{Languages.getTranslation('dont-have-proc', true)}</div>
	}
}

const ChosProcesses = () => {
	const detail = useSelector(projectSelectors.getDetail) || null;
	const [currentDetail, setCurrentDetail] = useState(null);
	const [comment, setComment] = useState('')
	const [showComment, setShowComment] = useState(false)
	const [currentProc, setCurrentProc] = useState([])
	const panel = useSelector(panelSelectors.getPanelShowState)
	const [backgroundOverFlow, setBackgroundOverFlow] = useState(false)
	const permissions = useSelector(userSelectors.permissions);
	const rightValue = !permissions.includes("KM_disableSelectHandles") ? 0 : -140;
	// const rightValue = !permissions.includes("KM_disableSelectHandles") ? 0 : -140;

	const style = {
		right: `${rightValue}px`,
	};
	useEffect(() => {
		setCurrentDetail(detail);
	}, [detail, panel]);

    const getRectangles = () => {
		if(!empty(currentDetail)){
			return currentDetail?.rectangles.sort((a, b) => {
				if (a.subType < b.subType) {
					return -1;
				} else if (a.subType > b.subType) {
					return 1;
				} else {
					return 0;
				}
			});
		}
	}
	const createProc =() => {
		const defaultProc = [
			{
				name: Languages.getTranslation('corner', true),
				type: "corner",
				items: currentDetail?.corners,
			},
			{
				name: Languages.getTranslation('one-groove', true),
				type: "groove",
				items: currentDetail?.grooves,
			},
			{
				name: Languages.getTranslation('rabbet', true),
				type: "rabbet",
				items: currentDetail?.rabbets,
			},
			{
				name: Languages.getTranslation('Cutouts', true),
				type: "rectangle",
				items: getRectangles(),
			},
			{
				name: Languages.getTranslation('uShape-proc', true),
				type: "uShape",
				items: currentDetail?.uShapes,
			},
			{
				name: Languages.getTranslation('add_hole_title', true),
				type: "hole",
				items: currentDetail?.holes,
			},
			{
				name: Languages.getTranslation('add_mill_title', true),
				type: "mill",
				items: currentDetail?.mills,
			},
			{
				name: Languages.getTranslation('add_bevel_title', true),
				type: "bevel",
				items: currentDetail?.bevels,
			},
			{
				name: Languages.getTranslation('Milling-in', true),
				type: "cutOut",
				items: currentDetail?.cutouts,
			},
			{
				name: Languages.getTranslation('template-proc', true),
				type: "template",
				items: currentDetail?.templates,
			},
		]
		setCurrentProc(defaultProc)
	}

	useEffect(() => {
		createProc()
	}, [currentDetail, panel]);

	const createComment = (text) => {
		setComment(text)
		setShowComment(true)
	}

	const closeComment = () => {
		setShowComment(false)
	}

	return <div className="chosProcesses" style={style}>
		{showComment ? <div className="chosProcesses__comment-background">
			<div className="chosProcesses__comment-container">
				<div className="chosProcesses__comment-header">
					<span className="chosProcesses__comment-header-title">{Languages.getTranslation('coment', true)}</span>
					<CloseBtn handler={closeComment}/>
				</div>
				<div className="chosProcesses__comment-body">
					{comment}
				</div>
			</div>
		</div> : ""}
		<div className="chosProcesses__header">{Languages.getTranslation("chos-processes", true)}
		</div>
		<div className="chosProcesses__datas" style={{overflowY: showComment || backgroundOverFlow ? "hidden" : 'auto'}}>
			<div className="chosProcesses__body">
				{!empty(currentDetail)
					? currentProc.map((item, id) => {
						return (
							<ParamContainer
								type={item.type}
								idx={id}
								key={`container-${id}`}
								param={item}
								createComment={createComment}
								detail={currentDetail}
								createProc={createProc}
								setCurrentDetail={setCurrentDetail}
								setBackgroundOverFlow={setBackgroundOverFlow}
							/>
						);
					})
					: "Something wrong"}
				{dontHaveProc(currentDetail)}
			</div>
		</div>

	</div>
}

export default ChosProcesses;
