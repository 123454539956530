import React, {useEffect, useState} from "react";
import {materialTitle} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";

const CustomerWarehouseItem = ({chosenMaterials, material, setMaterial, materialTypes})=>{
const [selectedType, setSelectedType] = useState(material.type)
	useEffect(()=>{
		material.type = selectedType
	},[selectedType])

	const activeCheckBox = (type, material) => {
		if (type === "checkbox") {
			return chosenMaterials.includes(material)
				? "detailsTab__checkbox detailsTab__checkbox_active"
				: "detailsTab__checkbox";
		} else {
			return chosenMaterials.includes(material)
				? "checkbox__icon checkbox__icon_active"
				: "checkbox__icon";
		}
	};

const changeHandler = (e)=>{
	setSelectedType(e.target.value)
}

	function parseAndDisplayDate(time) {
	  if(!time) return "-"

		const date = new Date(time);
		const finaleDate = date.toLocaleDateString() + " " + date.toLocaleTimeString();
		return finaleDate
	}

	return <div className="customerWarehouse__table-item">
		<div className='customerWarehouse__container'>
			<div className="customerWarehouse__checkbox">
				<div
					className={activeCheckBox("checkbox", material)}
					onClick={()=>setMaterial(material)}
				>
					<i className={activeCheckBox("icon", material)}></i>
				</div>
			</div>
			<div className="customerWarehouse__data-content">
				<div className="customerWarehouse__data">{material.height}</div>
				<div className="customerWarehouse__data">{material.width}</div>
				<div className="customerWarehouse__data customerWarehouse__sheetThickness">{material.thickness}</div>
				<div className="customerWarehouse__data">{material.count}</div>
				<div className="customerWarehouse__data customerWarehouse__warehouse">{material.placeName}</div>
				<div className="customerWarehouse__data customerWarehouse__article">{material.article}</div>
				<div className="customerWarehouse__data customerWarehouse__name customerWarehouse__name-data-container" title={materialTitle(material)}>
					<span className='customerWarehouse__name-data'>
						{materialTitle(material)}
					</span>
				</div>
				<div className="customerWarehouse__data customerWarehouse__name">{parseAndDisplayDate(material.created_at)}</div>
			</div>
		</div>
		<div className="customerWarehouse__select-container">
			<div className="customerWarehouse__select-container-background">
				<select defaultValue={material.type} className='customerWarehouse__select' onChange={(e)=>changeHandler(e)}>
					{materialTypes.map(type=> <option value={type}>{Languages.getTranslation(type, true)}</option>)}
				</select>
			</div>
		</div>
	</div>
}

export default CustomerWarehouseItem