import Db from "api/Db";
import {empty} from "../helpers/helper";

export default class _Edges extends Db {
    errors = [];
    constructor() {
        super();
    }

    getEdgeDB() {
        return this.getIndex('edges')
    }

    addEdges(edges) {
        if(!edges || !edges.length) {
            return Promise.reject('');
        }

        const process = (edge) => {
            if(edge) {
                this.addEdge(edge)
                    .then(() => process(edges.shift()))
                    .catch(e => Promise.reject(e))
            } else {
                return Promise.resolve();
            }
        }
        return process(edges.shift());
    }

    addEdge(edge) {
        return new Promise((resolve, reject) => {
            if (!edge.hasOwnProperty('laser')){
            edge.laser = 0;
            }
            if(typeof edge !== 'object' || !edge.hasOwnProperty('article')) {
                return Promise.reject('incorrect edge')
            }
            if(!empty(edge.article)) {
                edge.article = edge.article.toString()
            }
            if (edge.hasOwnProperty('code')){
                edge.article = edge.code.toString()
                delete edge.code
            }
            if(empty(edge.width) && !empty(edge.w)) {
                edge.width = edge.w;
                delete edge.w;
            }
            if (edge.hasOwnProperty('t')){
                edge.thickness = edge.t
                delete edge.t
            }
            if (edge.hasOwnProperty('typename')){
                edge.type = edge.typename
                delete edge.typename
            }
            this.addItem('edges', edge)
                .then(() => resolve(edge))
                .catch(err => reject(err))

        })
    }

    getEdges() {
        return this.getAllData('edges');
    }

    getEdge(id) {
        return this.getDataByKey('edges', 'index', id);
    }

    getEdgeByArticle(article) {
        return this.getDataByKey('edges', 'article', article.toString())
    }

    deleteEdge(index) {
        if(empty(index)) {
            return Promise.reject({Error : 'Incorrect edge.index'})
        }
        return this.removeItem('edges', index);
    }
}