const ROUTER = {
  route: {
    DEFAULT: "/",
    NEW: "/new",
    NONE: "",
    MAIN: "/home",
    ANY: "*",
    THREE: "/3d",
    PROJECTS: '/projects',
    DETAILS: '/details',
    PROCESSES: '/processes/:projectId/:detailId',
    CALCULATE: '/calculate',
    IMPORT: '/import-project',
    SUPPORT: '/support',
    COST: '/cost',
    INFO: '/info',
    CONSTRUCTOR: '/constructor'
  },
};

export default ROUTER;
