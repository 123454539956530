import './style.scss'
import Languages from "../../../../translation/Languages";
import React from "react";
import img from "../../../../media/octahedron-cube-morph-PTDDH9qlHJ.png";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";

const ProductHelper = ({productId}) => {
  const construction = useSelector(projectSelectors.getConstruction);
  const product = construction.products.find(p => p.id === productId)


  return <div
    className="tooltip-productHelper">
    <img src={img} alt="PRODUCT" className='tooltip-productHelper__image'/>
    <div className="tooltip-productHelper__container">
      <span className="tooltip-productHelper__title">{Languages.getTranslation('product-name', true)}</span>
      <span className="tooltip-productHelper__name">{product?.name}</span>
    </div>
  </div>
}

export default ProductHelper;