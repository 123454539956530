import {
	BoxGeometry,
	BufferGeometry,
	CircleGeometry,
	EdgesGeometry,
	Line,
	LineBasicMaterial,
	LineDashedMaterial,
	LineSegments,
	Mesh,
	MeshBasicMaterial,
	MeshStandardMaterial,
	Shape,
	ShapeGeometry,
	Vector2,
	Vector3
} from "three";
import {empty} from "../../../helpers/helper";

export default class Helper {
	static ShapeGeometrySegments = 32;
	static materialColor = "#eeebed";
	static materialHoColor = "#575555";
	static contourLinesColor = "#A04382";
	static materialBackColor = "#a1a1a1";

	static addContourLines(geometry, color = null) {
		const edgesGeometry = new EdgesGeometry(geometry);
		const line = new LineSegments(edgesGeometry, this.getLineMaterial(color));
		line.userData = {
			selection: true,
			meshType: 'line'
		}
		return line
	}

	static getMaterial3D(color = null) {
		return new MeshStandardMaterial({
			color: color || this.materialColor,
			transparent: true,
			opacity: 0.8,
			side: 2
		})
	}

	static getLineMaterial(color = null) {
		return new LineBasicMaterial({
			color: color || this.contourLinesColor,
			linewidth: 1,
			linejoin: "miter",
			side: 2,
		})
	}

	static getArcGeometry(points, fromDepth, toDepth) {
		const vertices = [];
		const faces = [];

		for (let point of points) {
			vertices.push(new Vector3(point.x, point.y, fromDepth));
			vertices.push(new Vector3(point.x, point.y, toDepth));
		}

		let iteration = 0;
		for (let step = 0; step < vertices.length / 2; step++) {
			faces.push([iteration + 3, iteration + 1, iteration + 2]);
			iteration++;
			faces.push([iteration, iteration - 1, iteration + 1]);
			iteration++;
		}

		faces.pop();
		faces.pop();

		const geometry = new BufferGeometry();

		const pointsNew = [];

		for (let face of faces) {
			pointsNew.push(vertices[face[0]]);
			pointsNew.push(vertices[face[1]]);
			pointsNew.push(vertices[face[2]]);
		}

		geometry.setFromPoints(pointsNew);
		geometry.computeVertexNormals();
		return geometry;
	}

	static getLineMesh({x1, y1, x2, y2, w}, color = null, side = null, lines = false, userData = {}) {
		const edgeLength = Math.hypot((x2 - x1), (y2 - y1));
		const squareShape = new Shape();
		squareShape.moveTo(0, 0)
		squareShape.lineTo(0, edgeLength);
		squareShape.lineTo(w, edgeLength);
		squareShape.lineTo(w, 0);
		let squareShapeGeometry = new ShapeGeometry(squareShape, 4);
		squareShapeGeometry.rotateY(-Math.PI / 2);
		const mesh = new Mesh(squareShapeGeometry, this.getMaterial3D(color));
		if(lines) {
			const linesMesh = this.addContourLines(mesh.geometry, color);
			linesMesh.userData = {
				...linesMesh.userData,
				name: 'грань ' + side,
				meshSide: side
			}
			mesh.add(linesMesh)
		}
		return mesh;
	}

	static getArcMesh({x1, y1, x2, y2, xc, yc, r, dir, w}, color = null, side, lines = false) {
		const shape = new Shape()
		shape.moveTo(0, 0)
		let radStart = Math.atan2(yc - y1, xc - x1) - Math.PI;
		let radEnd = Math.atan2(yc - y2, xc - x2) - Math.PI;
		shape.absarc(xc, yc, r, radStart, radEnd, dir);
		const points = shape.getPoints(this.ShapeGeometrySegments);
		const mesh = new Mesh(this.getArcGeometry(points, w, 0), this.getMaterial3D(color));
		if(lines) {
			const linesMesh = this.addContourLines(mesh.geometry, color);
			linesMesh.userData = {
				...linesMesh.userData,
				name: 'грань ' + side,
				meshSide: side
			}
			mesh.add(linesMesh)
		}
		return mesh;
	}

	static getColorByThickEdge(thick) {
		if (thick <= 0.6) return "green";
		if (thick <= 0.8) return "orange";
		if (thick <= 1) return "lightblue";
		if (thick < 2) return "blue";
		if (thick === 2) return "purple";
	}

	static getDetailMesh(object) {
		const _object = object?.object ?? object;
		if(_object?.userData?.detailCId) {
			return _object
		} else if(_object?.parent) {
			return this.getDetailMesh(_object.parent)
		} else {
			return null;
		}
	}
}