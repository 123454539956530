import React, { useEffect, useRef, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { modalsOperations, modalsSelectors } from "redux/modals";
import html2canvas from 'html2canvas';

import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

import "./style.scss";
import Errors from "../../../../models/Errors";
import CloseBtn from "../../Buttons/CloseBtn";
import {Rectangle} from "./TemplateType";
import ModalXYCoordinate from "./modalXYCoordinate/ModalXYCoordinate";
import {empty} from "../../../../helpers/helper";
import Requests from "../../../../api/API";
import {errorMessageOperations} from "redux/errors";
import {projectSelectors} from "redux/project";
const _errors = new Errors([])
import Loader from "../../Loader";

const TransportCut = () => {
    const [valueX, setValueX] = useState(null)
    const [valueY, setValueY] = useState(null)
    const [previousId, setPreviousId] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedRectangleId, setSelectedRectangleId] = useState(null);
    const [isLoader, setIsLoader] = useState(false)
    const construction = useSelector(projectSelectors.getConstruction);

    const modalRef = useRef(null);
    const rectangleRefs = useRef([]);
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        1: { valueX: null, valueY: null, type: 1},
        2: { valueX: null, valueY: null, type: 2},
        3: { valueX: null, valueY: null, type: 3},
        4: { valueX: null, valueY: null, type: 4},
        5: { valueX: null, valueY: null, type: 5},
    });

    const close = () => {

        dispatch(modalsOperations.switchStateForModal({
            show: false, type: null,
        }));
    };
    const saveImage = (id) => {
        setImageData(null)
        setIsLoader(true)
        const node = document.getElementById(id);
        setTimeout(() => {
            html2canvas(node)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    setImageData(imgData);
                })
                .catch((error) => {
                    console.error('Something went wrong:', error);
                });
        }, 300)

    }
    const openModal = (id) => {

        setSelectedId(null);
        setValueX(null);
        setValueY(null);
        setSelectedRectangleId(id);

        setTimeout(() => {
            setSelectedId(id);
        }, 0);
    };


    useEffect(() => {
        if (selectedId !== null) {
            const selectedRectangle = rectangleRefs.current[selectedId];
            const rectangleRect = selectedRectangle.getBoundingClientRect();

            const modalEl = modalRef.current;
            if (modalEl) {
                if (selectedId === 5) {
                    modalEl.style.left = `${rectangleRect.left - modalEl.offsetWidth}px`;
                    modalEl.style.top = `${rectangleRect.top}px`;
                } else {
                    modalEl.style.left = `${rectangleRect.right}px`;
                    modalEl.style.top = `${rectangleRect.top}px`;
                }
            }
        }
    }, [selectedId])

    useEffect(() => {
        if(!empty(imageData)){
            setIsLoader(false)
        }
    },[imageData])
    const submit = async() =>{
        const firstNonEmptyObjectKey = Object.keys(values).find(key => Object.keys(values[key]).length !== 0);
        const firstNonEmptyObject = values[firstNonEmptyObjectKey];
        const idProject = construction?.projectData?.id
        const data = {
            x: firstNonEmptyObject.valueX,
            y: firstNonEmptyObject.valueY,
            img: imageData,
            type: firstNonEmptyObjectKey,
        }

        const nameProject = 'Транспортний різ';
        const project_folder_id = null;
        const cutType = 'Transport_cut';

        async function handleProjectAction(projectActionPromise, error) {
            const response = await projectActionPromise;
            if (response && response.data){
                dispatch(errorMessageOperations.switchStateError({
                    message: Languages.getTranslation("project-save", true),
                    type: 'success',
                    show: true,
                    autoHide: 'none',
                }));
                // TODO none auto hide
                setTimeout(() => {
                    window.location.replace(`${process.env.PERSONAL_ACCOUNT_URL}/#/my-projects`);
                }, 1000);
            } else {
                _errors.setError(error.message, 'error', true);
                close();
            }
        }

        if(idProject !== 0){
            const projectActionPromise = Requests.Projects.updateProject(idProject, nameProject, JSON.stringify(data),[], cutType);
            handleProjectAction(projectActionPromise);
        } else {
            const projectActionPromise = Requests.Projects.saveProject(nameProject, project_folder_id, JSON.stringify(data),[], cutType);
            handleProjectAction(projectActionPromise);
        }


    }
    const valuePasser = (rectangleId) => ({
        valueX: values[rectangleId]?.valueX || 0,
        valueY: values[rectangleId]?.valueY || 0,
    });


    const resetAllValuesExceptCurrent = (currentId) => {
        if(previousId !== currentId) {
            setValues(prevValues => {
                const newValues = {};
                for(let id in prevValues) {
                    newValues[id] = id === currentId ? prevValues[id] : {};
                }
                return newValues;
            });
        }
        setPreviousId(currentId);
    }
    return (
        <div className="transportCut">
            <div className="transportCut__header">
                <div>
                  <h3 className="transportCut__header-title">
                    {Languages.getTranslation("transport-cut", true)}
                  </h3>
                  <div className="transportCut__header-desc">
                    {Languages.getTranslation("sheet-materials-desc", true)}
                  </div>
                </div>
                <div>
                    <CloseBtn handler={close}/>

                </div>
            </div>
            <div className="transportCut__body">

                <div className="transportCut__body-first">
                    <div className="transportCut__body-first-title">
                        {Languages.getTranslation("sheet-materials", true)}
                    </div>

                    <div className="transportCut__body-greyLine"></div>
                    <div className="transportCut__body-first-body">

                        <div id={1} ref={el => rectangleRefs.current[1] = el} onClick={() => openModal(1)}>
                            <Rectangle
                                id={1}
                                x={0}
                                type={1}
                                {...valuePasser(1)}
                                selected={selectedRectangleId === 1}
                            />
                        </div>
                        <div id={2} ref={el => rectangleRefs.current[2] = el} onClick={() => openModal(2)}>
                            <Rectangle
                                selected={selectedRectangleId === 2}
                                id={2} y={0} type={2} {...valuePasser(2)}/>
                        </div>
                        <div id={3} ref={el => rectangleRefs.current[3] = el} onClick={() => openModal(3)}>
                            <Rectangle
                                selected={selectedRectangleId === 3}
                                id={3} x={0} y={0} type={3} {...valuePasser(3)}/>
                        </div>
                        <div id={4} ref={el => rectangleRefs.current[4] = el} onClick={() => openModal(4)}>
                            <Rectangle
                                selected={selectedRectangleId === 4}
                                id={4} x={0} y={0} type={4} {...valuePasser(4)}/>
                        </div>

                    </div>

                    <div className="coordinate-system">
                        <div className="x-axis">
                            <span className="x-label">X</span>
                        </div>
                        <div className="y-axis">
                            <span className="y-label">Y</span>
                        </div>
                    </div>
                </div>
                <div className="transportCut__body-line"></div>
                <div className="transportCut__body-second">
                    <div className="transportCut__body-first-title">
                    {Languages.getTranslation("table-top", true)}
                    </div>
                    <div className="transportCut__body-greyLine"></div>
                    <div className="transportCut__body-second-body">
                        <div id={5} ref={el => rectangleRefs.current[5] = el} onClick={() => openModal(5)}>
                            <Rectangle
                                selected={selectedRectangleId === 5}
                                id={5} x={0} y={0} type={1} {...valuePasser(5)}/>

                        </div>
                        <div className="transportCut__body-second-footer">
                            <DefaultBtn
                                handler={submit}
                                disabled={!imageData}
                                title={isLoader ?  <Loader style={{maxWidth: '25px', maxHeight: '25px'}} /> : Languages.getTranslation("make-order", true)}
                            />

                        </div>
                    </div>

                </div>

            </div>

            {!empty(selectedId) && (
                <div
                    className="transportCut__modal"
                    ref={modalRef}
                    style={{ position: 'absolute' }}
                >
                    <ModalXYCoordinate
                        id={selectedId}
                        setValues={setValues}
                        saveImage={saveImage}
                        valueX={values[selectedId].valueX}
                        valueY={values[selectedId].valueY}
                        resetAllValuesExceptCurrent={resetAllValuesExceptCurrent}
                        setValueY={(valueY) => setValues(values => ({...values, [selectedId]: { ...values[selectedId], valueY }}))}
                        setValueX={(valueX) => setValues(values => ({...values, [selectedId]: { ...values[selectedId], valueX }}))} handleClose={()=> setSelectedId(null)}/>
                </div>
            )}
        </div>
    );
};

export default TransportCut;
