
module.exports = [
  {   //1
    position({w}) { // thk, y, x
      return [0, 0, w]
    },
    rotation: [0, 0, 0],
    scale({h, l}) {
      return [l, h, 1]
    },
    userData: {
      side: 'front',
      type: 'Front',
    }
  },
  {   //6
    position({w}) {
      return [0, 0, -w]
    },
    rotation: [0, Math.PI, 0],
    scale({h,l}) {
      return [l, h, 1]
    },
    userData: {
      side: 'back',
      type: 'Back',
    },
  },
  {   //2
    position({l}) {
      return [-l, 0, 0]
    },
    rotation: [0, -Math.PI / 2, 0],
    scale({w,h}) {
      return [w, h, 1]
    },
    userData: {
      side: 'left',
      type: 'Edge',
    },
  },
  {   //3
    position({h}) {
      return [0, h, 0]
    },
    rotation: [-Math.PI / 2, 0, Math.PI / 2],
    scale({w,l}) {
      return [w, l, 1]
    },
    userData: {
      side: 'top',
      type: 'Edge',
    },
  },
  {   //4
    position({l}) {
      return [l, 0]
    },
    rotation: [0, Math.PI / 2, 0],
    scale({w,h}) {
      return [w, h, 1]
    },
    userData: {
      side: 'right',
      type: 'Edge',
    },
  },
  {   //5
    position({h}) {
      return [0, -h]
    },
    rotation: [Math.PI / 2, 0, Math.PI / 2],
    scale({w,l}) {
      return [w, l, 1]
    },
    userData: {
      side: 'bottom',
      type: 'Edge',
    },
  }
]
