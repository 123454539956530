import Helpers from "../3D/Helpers";
import {empty} from "../../helpers/helper";

export default class Partial {
	_elements;
	parent;
	id;
	edgeThickness = 0;
	detailEdgeLeftThickness = 0;
	detailEdgeBottomThickness = 0;
	direction = false;
	overallElements = [];
	_sidesCount = 0;
	startFrom = '';
	endFrom = '';
	touchedSides = [];
	arcDegCount = 6;

	constructor({parent, elements}) {
		this.parent = parent;
		this.parent._showArrow = false;
		this.elements = elements;
		this.parent.edgeSide = null
		if(!empty(this.parent.edge)) {
			this.edgeThickness = this.parent.detail.getEdgeData(this.parent.edge)?.thickness
		}
		if(!empty(this.parent.detail.edges.left)) {
			this.detailEdgeLeftThickness = this.parent.detail.getEdgeData(this.parent.detail.edges.left)?.thickness
		}
		if(!empty(this.parent.detail.edges.bottom)) {
			this.detailEdgeBottomThickness = this.parent.detail.getEdgeData(this.parent.detail.edges.bottom)?.thickness
		}
		// this.fixElementsDirection();
		this.generateElementsForPartial();
		this.getFullElements();
		this.detectSide()
		this.initRequiredFields()
	}



	initRequiredFields() {
		this.parent.requiredFields = {}
	}

	fixElementsDirection() {
		const getOrientation = (objects) => {
			let sum = 0;
			for (let i = 0; i < objects.length; i++) {
				let cur = { x: objects[i].x2, y: objects[i].y2 };
				let next = { x: objects[(i + 1) % objects.length].x1, y: objects[(i + 1) % objects.length].y1 };
				sum += (next.x - cur.x) * (next.y + cur.y);
			}
			return sum;
		}

		const elements = [...this.elements]
		if (getOrientation(elements) < 0) {
			this.elements = elements.reverse().map(obj => {
				let newObj = {...obj};

				// switch start and end points for any type of object
				let temp = { x1: newObj.x1, y1: newObj.y1 };
				newObj.x1 = newObj.x2;
				newObj.y1 = newObj.y2;
				newObj.x2 = temp.x1;
				newObj.y2 = temp.y1;

				// switch direction for arcs
				if (newObj.type === "arc") {
					newObj.dir = !newObj.dir;
				}

				return newObj;
			});
		}
	}

	get cutOut() {
		this.generateElementsForPartial()
		const width = this.widthCalc;
		const height = this.heightCalc;
		let sidesCount = 1;
		if (this.parent.edgeSide) {
			sidesCount = 1
		} else if (this.parent.angle) {
			sidesCount = 2
		} else {
			sidesCount = 3
		}
		if (this.touchedSides.length === 4) {
			sidesCount = 4
		}
		const elForRender = this.elements.map((el) => {
				let x1 = el.x1, x2 = el.x2, y1 = el.y1, y2 = el.y2, xc = el?.xc, yc = el?.yc
				if (this.parent.edgeSide === "right" || ['right_top', "right_bottom"].includes(this.parent.angle)) {
					x1 = el.x1 + this.detailEdgeLeftThickness + this.edgeThickness
					x2 = el.x2 + this.detailEdgeLeftThickness + this.edgeThickness
					if (!empty(el.xc)) {
						xc = el.xc + this.detailEdgeLeftThickness + this.edgeThickness
					}
				}
				if (this.parent.edgeSide !== "bottom" || ['left_top', "right_top"].includes(this.parent.angle)) {
					y1 = el.y1 + this.detailEdgeBottomThickness + this.edgeThickness
					y2 = el.y2 + this.detailEdgeBottomThickness + this.edgeThickness
					if (!empty(el.yc)) {
						yc = el.yc + this.detailEdgeBottomThickness + this.edgeThickness
					}
				}
				let newVal = {...el,
					x1,
					x2,
					y1,
					y2,
					edge: this.parent.edge
				}
				if (el.type === 'arc') {
					newVal = {
						...newVal,
						xc,
						yc
					}
				}
				return newVal
			})
		return {
			x: 0,
			y: 0,
			side: 'front',
			type: this.parent.type,
			subType: 'mill',
			depth: this.parent.detail.w,
			edge: this.parent.edge,
			startFrom: this.startFrom,
			endFrom: this.endFrom,
			place: this.parent.edgeSide || this.parent.angle,
			width: width,
			sidesCount,
			touchedSides: this.touchedSides,
			color: this.parent.isActive ? Helpers.colorForActive : null,
			height: height,
			elements: this.overallElements.map(el => ({...el, color: this.parent.isActive ? Helpers.colorForActive : null,}))
		}
	}

	get realData() {
		return {
			angle: this.parent.angle,
			height: this.heightCalc,
			width: this.widthCalc,
			overallElements: this.overallElements,
			elements: this.elements,
			touchedSides: this.touchedSides
		}
	}

	getFullElements() {
		const overallElements = [];

		this.elements.forEach(el => {
			const tmp_el = this.parent.detail.contour.find(c_el => c_el.id === el.id);
			if(tmp_el) {
				overallElements.push(tmp_el);
			}
		})

		this.overallElements = overallElements.map(el => {
			if (el.type === "arc") {
				return Helpers.createArc(el.x1, el.y1, el.x2, el.y2, el.r, el.xc, el.yc, el.dir, el.edge, this.parent.isActive ? Helpers.colorForActive : null, !empty(el.id) ? el?.id : null)
			}
			return Helpers.createLine(el.x1, el.y1, el.x2, el.y2, el.edge, this.parent.isActive ? Helpers.colorForActive : null, !empty(el.id) ? el?.id : null)
		})
	}

	generateElementsForPartial() {
			this.elements = this.elements.map(el =>{
				if (el.type === "arc") {
					return Helpers.createArc(el.x1, el.y1, el.x2, el.y2, el.r, el.xc, el.yc, el.dir, el.edge, this.parent.isActive ? Helpers.colorForActive : null, !empty(el.id) ? el?.id : null)
				}
				return Helpers.createLine(el.x1, el.y1, el.x2, el.y2, el.edge, this.parent.isActive ? Helpers.colorForActive : null, !empty(el.id) ? el?.id : null)
			})
	}

	get xCalc() {
		return Math.min(...this.elements.map(el => Math.min(el.x1, el.x2)))
	}

	get yCalc() {
		return Math.min(...this.elements.map(el => Math.min(el.y1, el.y2)))
	}

	calculateMaxY(el) {
		if(el.type === 'arc') {
			let startAngleRad = el.startAngleRad;
			let endAngleRad = el.endAngleRad;
			if (el.dir === false) {
				startAngleRad = el.endAngleRad;
				endAngleRad = el.startAngleRad;
			}
			// let steps, coor = 0;
			// if (el.dir) {
			// 	steps = el.endAngle - el.startAngle;
			// 	if(el.startAngle < 0 && el.x1 < el.x2) {
			// 		coor += 90;
			// 	}
			// 	if(el.endAngle < 0 && el.x1 < el.x2) {
			// 		coor += 90
			// 	}
			// } else {
			// 	steps = el.startAngle - el.endAngle;
			// }
			// steps = Math.abs(steps) + coor;
			const steps = Math.abs(endAngleRad - startAngleRad) * 180 / Math.PI;
			let maxY = el.yc + el.r * Math.sin(startAngleRad);
			const addCounter = steps < 10 ? 2 : steps < 30 ? 3 : 8;
			let angle;
			let y = 0;
			for (let i = 0; i <= steps; i += addCounter) {
				angle = startAngleRad - (i / 180) * Math.PI
				if(angle < -Math.PI) {
					angle = Math.abs(2 * Math.PI + angle)
				} else if(angle > Math.PI) {
					angle = -(2 * Math.PI - angle)
				}
				y = el.yc + el.r * Math.sin(angle);
				if (y > maxY) {
					maxY = y;
				}
			}
			return maxY;
		} else {
			return Math.max(el.y1, el.y2);
		}
	}

	calculateMinY(el) {
		if(el.type === 'arc') {
			let startAngleRad = el.startAngleRad;
			let endAngleRad = el.endAngleRad;
			if (el.dir === false) {
				startAngleRad = el.endAngleRad;
				endAngleRad = el.startAngleRad;
			}
			const steps = Math.abs(endAngleRad - startAngleRad) * 180 / Math.PI;
			let minY = el.yc + el.r * Math.sin(startAngleRad);
			let angle;
			let y = 0;
			const addCounter = steps < 10 ? 2 : steps < 30 ? 3 : 8;
			for (let i = 0; i <= steps; i += addCounter) {
				angle = startAngleRad - (i / 180) * Math.PI;
				if(angle < -Math.PI) {
					angle = Math.abs(2 * Math.PI + angle)
				} else if(angle > Math.PI) {
					angle = -(2 * Math.PI - angle)
				}
				y = el.yc + el.r * Math.sin(angle);
				if (y < minY) {
					minY = y;
				}
			}
			return minY;
		} else {
			return Math.min(el.y1, el.y2);
		}
	}

	calculateMaxX(el) {
		if(el.type === 'arc') {
			let startAngleRad = el.startAngleRad;
			let endAngleRad = el.endAngleRad;
			if (el.dir === false) {
				startAngleRad = el.endAngleRad;
				endAngleRad = el.startAngleRad;
			}
				const steps = Math.abs(endAngleRad - startAngleRad) * 180 / Math.PI;
				let maxX = el.xc + el.r * Math.cos(startAngleRad);

			let angle;
			const addCounter = steps < 10 ? 2 : steps < 30 ? 3 : 8;
			for (let i = 0; i <= steps; i += addCounter) {
				angle = startAngleRad - (i / 180) * Math.PI;
				if(angle < -Math.PI) {
					angle = Math.abs(2 * Math.PI + angle)
				} else if(angle > Math.PI) {
					angle = -(2 * Math.PI - angle)
				}
					let x = el.xc + el.r * Math.cos(angle);
					if (x > maxX) {
						maxX = x;
					}
				}
				return maxX;
			} else {
			return Math.max(el.x1, el.x2);
		}
	}

	calculateMinX(el) {
		if(el.type === 'arc') {
			let startAngleRad = el.startAngleRad;
			let endAngleRad = el.endAngleRad;
			if (el.dir === false) {
				startAngleRad = el.endAngleRad;
				endAngleRad = el.startAngleRad;
			}
			const steps = Math.abs(endAngleRad - startAngleRad) * 180 / Math.PI;

			// Initialize min X as start point of the arc
			let minX = el.xc + el.r * Math.cos(startAngleRad);
			let angle;
			const addCounter = steps < 10 ? 2 : steps < 30 ? 3 : 8;
			for (let i = 0; i <= steps; i+=addCounter) {
				angle = startAngleRad - (i / 180) * Math.PI;
				if(angle < -Math.PI) {
					angle = Math.abs(2 * Math.PI + angle)
				} else if(angle > Math.PI) {
					angle = -(2 * Math.PI - angle)
				}
				let x = el.xc + el.r * Math.cos(angle);
				if (x < minX) {
					minX = x;
				}
			}
			return minX;
		} else {
			return Math.min(el.x1, el.x2);
		}
	}

	checkIfPointYMiddle(y) {
		return y > 4 && this.parent.detail.h - y > 4
	}

	checkIfPointXMiddle(x) {
		return x > 4 && this.parent.detail.l - x > 4
	}

	detectSide() {
		const elements = !empty(this.overallElements) ? [...this.overallElements] : [...this.elements];
		if (empty(elements)) return false
		const lastIndex = elements.length - 1;
		const firstEl = elements[0],
			lastEl = elements[lastIndex];
		const minX = Math.min(...elements.map(el => this.calculateMinX(el)));
		const minY = Math.min(...elements.map(el => this.calculateMinY(el)));
		const maxX = Math.max(...elements.map(el => this.calculateMaxX(el)));
		const maxY = Math.max(...elements.map(el => this.calculateMaxY(el)));

		const firstElEnd = this.detectPointPos(firstEl)
		const firstElStart = this.detectPointPos(firstEl, false)
		const lastElStart = this.detectPointPos(lastEl, false)
		const lastElEnd = this.detectPointPos(lastEl)
		const startSide = this.detectStartSide(firstEl)
		const endSide = this.detectEndSide(lastEl)
		const sidesArr = [];
		let middleEl, middleElStart, middleElEnd;
		switch (elements.length) {
			case 1:
				switch (startSide) {
					case 1:
						switch (endSide) {
							case 2:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								break;
							case 3:
								if(!this.checkIfPointYMiddle(firstEl.y1) && !this.checkIfPointYMiddle(lastEl.y2)) {
									if(firstEl.y1 === lastEl.y2) {
										if (this.parent.detail.h - maxY <= 4) {
											if(this.parent.detail.h - firstEl.y1 > 4) {
												sidesArr[0] = 'left';
												sidesArr[1] = 'top';
												sidesArr[2] = 'right';
											} else {
												sidesArr[0] = 'top';
											}
										} else if(minY <= 4) {
											if(firstEl.y1 > 4) {
												sidesArr[0] = 'left';
												sidesArr[1] = 'bottom';
												sidesArr[2] = 'right';
											} else {
												sidesArr[0] = 'bottom';
											}
										}
									} else {
										if(firstEl.y1 > lastEl.y2) {
											sidesArr[0] = 'left';
											sidesArr[1] = 'top';
										} else {
											sidesArr[0] = 'top';
											sidesArr[1] = 'right';
										}
									}
								} else {
									sidesArr[0] = (firstEl.y1 < 4 || this.parent.detail.h - firstEl.y1 <= 4) && lastEl.y2 < 4 ? 'right' : 'left';
									sidesArr[1] = maxY - this.getDeltaY(true) <= 4 ? 'top' : 'bottom';
									if (this.parent.detail.l - maxX <= 4) {
										sidesArr[2] = 'right'
									}
								}

								break
							case 4:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 1:
								if(this.parent.detail.l - maxX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'right';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'left';
								}
								break
						}
						break
					case 2:
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								break
							case 3:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break
							case 4:
								sidesArr[1] = 'top';
								sidesArr[0] = maxX - this.getDeltaX(true) <= 4 ? 'right' : 'left';
								break
							case 2:
								if(minY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'bottom';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'top';
								}
								break
						}
						break
					case 3:
						switch (endSide) {
							case 2:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break;
							case 1:
								sidesArr[0] = (firstEl.y1 < 4 || this.parent.detail.h - firstEl.y1 <= 4) && lastEl.y2 > 4 ? 'left' : 'right';
								sidesArr[1] = minY <= 4 ? 'bottom' : 'top';
								break
							case 4:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								break
							case 3:
								if(minX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'left';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
								}
								break
						}
						break
					case 4:
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 3:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								break
							case 2:
								sidesArr[1] = 'top';
								sidesArr[0] = minX <= 4 ? 'right' : 'left';
								break
							case 4:
								if(this.parent.detail.h - maxY < 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'bottom';
								}
								break
						}
						break
				}
				break
			case 2:
				switch (startSide) {
					case 1:
						this.startFrom = 'left'
						switch (endSide) {
							case 2:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								this.endFrom = 'top'
								break;
							case 3:
								if(this.parent.detail.h - lastEl.y2 <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = [2, 3].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
								} else if(lastEl.y2 <= 4) {
									sidesArr[0] = 'right';
									sidesArr[1] = [2, 3].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = [2, 3].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
									sidesArr[2] = 'right';
								}
								break
							case 4:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 1:
								if(maxX - this.getDeltaX(true) <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'right';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'left';
								}
								break
						}
						break
					case 2:
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								break
							case 3:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break
							case 4:
								sidesArr[0] = 'top';
								sidesArr[1] = [4, 3].includes(this.detectPointPos(startSide, false)) ? 'right' : 'left';
								sidesArr[2] = 'bottom';
								break
							case 2:
								if(minY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'bottom';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'top';
								}
								break
						}
						break
					case 3:
						switch (endSide) {
							case 2:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break;
							case 1:
								if(this.parent.detail.h - lastEl.y2 <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = [2, 3].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
								} else if(lastEl.y2 <= 4) {
									sidesArr[0] = 'right';
									sidesArr[1] = [1, 2].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
								}else {
									sidesArr[0] = 'left';
									sidesArr[1] = [2, 3].includes(this.detectPointPos(startSide, false)) ? 'top' : 'bottom';
									sidesArr[2] = 'right';
								}
								break
							case 4:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								break
							case 3:
								if(minX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'left';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
								}
								break
						}
						break
					case 4:
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 3:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								break
							case 2:
								sidesArr[0] = 'top';
								sidesArr[1] = [4, 3].includes(this.detectPointPos(startSide, false)) ? 'right' : 'left';
								sidesArr[2] = 'bottom';
								break
							case 4:
								if(this.parent.detail.h - maxY < 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'bottom';
								}
								break
						}
						break
				}
				break;
			case 3:
				middleEl = elements[1];
				middleElStart = this.detectPointPos(middleEl, false);
				middleElEnd = this.detectPointPos(middleEl);
				switch (startSide) {
					case 1:
						switch (endSide) {
							case 3:
								if([1, 2, 3].includes(middleElStart) && [1, 2, 3].includes(middleElEnd)) {
									sidesArr[0] = this.parent.detail.h - firstElStart.y1 > 4 ? 'left' : 'right';
									sidesArr[1] = 'top';
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'bottom';
								}
								if(lastEl.y2 - this.getDeltaY(true) > 4 && lastEl.y2 > 4) {
									sidesArr[2] = 'right';
								}
								break
							case 2:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								break
							case 4:
								if (this.parent.detail.l - maxX <= 4 && this.parent.detail.h - maxY <= 4) {
									sidesArr[0] = 'left';
                                    sidesArr[1] = 'top';
                                    sidesArr[2] = 'right';
									sidesArr[3] = 'bottom';
								}
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 1:
								if(this.parent.detail.l - maxX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'right';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'left';
								}
								break
						}
						break
					case 2:
						this.startFrom = 'top'
						switch (endSide) {
							case 1:
								if (this.parent.detail.l - maxX <= 4 && minY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
									sidesArr[3] = 'bottom';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
								}
								this.endFrom = 'left'
								break
							case 3:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break
							case 4:
								if([1, 2].includes(middleElStart)) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								}
								break
							case 2:
								if(minY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'bottom';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'top';
								}
								break
						}
						break
					case 3:
						switch (endSide) {
							case 1:
								if([4, 2, 3].includes(middleElStart) && [4, 2, 3].includes(middleElEnd)) {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = 'bottom';
								}
								if(lastEl.y2 - this.getDeltaY(true) > 4 && lastEl.y2 > 4) {
									sidesArr[2] = 'left';
								}
								break
							case 2:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								break
							case 4:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								break
							case 3:
								if(minX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'left';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
								}
								break
						}
						break
					case 4:
						this.startFrom = 'bottom'
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								break
							case 3:
								if (!this.checkIfPointYMiddle(maxY)) {
									if(this.parent.detail.h - lastEl.y2 <= 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
										sidesArr[2] = 'top';
									} else {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
										sidesArr[2] = 'right';
										sidesArr[3] = 'bottom';
									}
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'bottom';
								}
								this.endFrom = 'right'
								break
							case 2:
								if([3, 4].includes(middleElStart)) {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								}
								break
							case 4:
								if(this.parent.detail.h - maxY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'bottom';
								}
								break
						}

				}
				break;
			default:
				middleEl = elements[Math.floor(this.elements.length / 2)];
				middleElStart = this.detectPointPos(middleEl, false);
				middleElEnd = this.detectPointPos(middleEl);
				switch (startSide) {
					case 1:
						this.startFrom = 'left'
						switch (endSide) {
							case 3:
								if (lastEl.y2 === firstEl.y1 && !this.checkIfPointYMiddle(lastEl.y2)) {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'top';
									} else {
										sidesArr[0] = 'bottom';
									}
								} else if(this.checkIfPointYMiddle(firstEl.y1) && this.checkIfPointYMiddle(lastEl.y2)) {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
										sidesArr[2] = 'right';
									} else {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
										sidesArr[2] = 'right';
									}
								} else if(this.checkIfPointYMiddle(firstEl.y1)){
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
									} else {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
									}
								} else {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'right';
										sidesArr[1] = 'top';
									} else {
										sidesArr[0] = 'right';
										sidesArr[1] = 'bottom';
									}
								}
								this.endFrom = 'right';
								break
							case 2:
								if (firstEl.y1 === lastEl.y2) {
									sidesArr[0] = 'top';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
								}
								this.endFrom = 'top';
								break
							case 4:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								this.endFrom = 'bottom';
								break
							case 1:
								if (this.parent.detail.l - maxX < 4 && this.parent.detail.h - firstEl.y1 < 4 && lastEl.y2 < 4) {
                                    sidesArr[0] = 'top';
                                    sidesArr[1] = 'right';
                                    sidesArr[2] = 'bottom';
								} else if(this.parent.detail.l - maxX < 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
									sidesArr[3] = 'bottom';
								} else {
									sidesArr[0] = 'left';
								}
								this.endFrom = 'left';
								break
						}
						break
					case 2:
						this.startFrom = 'top'
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'top';
								this.endFrom = 'left';
								break
							case 3:
								if (firstEl.y1 === lastEl.y2) {
									sidesArr[0] = 'top';
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
								}
								this.endFrom = 'right';
								break
							case 4:
								if([1, 2].includes(middleElStart)) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								}
								this.endFrom = 'bottom'
								break
							case 2:
								if (lastEl.y2 === firstEl.y1) {
									if(minY <=4 && (this.parent.detail.l - maxX <= 4 || minX <= 4)) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
										sidesArr[2] = 'right';
										sidesArr[3] = 'bottom';
									} else {
										sidesArr[0] = 'top';
									}
								} else {
									if(minY <= 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
										sidesArr[2] = 'right';
									} else {
										sidesArr[0] = 'top';
									}
								}
								this.endFrom = 'top'
								break
						}
						break
					case 3:
						this.startFrom = 'right'
						switch (endSide) {
							case 1:
								if (lastEl.y2 === firstEl.y1 && !this.checkIfPointYMiddle(lastEl.y2) && (this.parent.detail.h - maxY > 4 || minY > 4)) {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'top';
									} else {
										sidesArr[0] = 'bottom';
									}
								} else if(this.checkIfPointYMiddle(firstEl.y1) && this.checkIfPointYMiddle(lastEl.y2)) {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
										sidesArr[2] = 'right';
									} else {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
										sidesArr[2] = 'right';
									}
								} else if(this.checkIfPointYMiddle(firstEl.y1) || this.parent.detail.h - firstEl.y1 < 4){
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'right';
										sidesArr[1] = 'top';
									} else {
										sidesArr[0] = 'right';
										sidesArr[1] = 'bottom';
									}
								} else {
									if(this.parent.detail.h - maxY < 4) {
										sidesArr[0] = 'left';
										sidesArr[1] = 'top';
									} else {
										sidesArr[0] = 'left';
										sidesArr[1] = 'bottom';
									}
								}
								this.endFrom = 'left';
								break
							case 2:
								sidesArr[0] = 'right';
								sidesArr[1] = 'top';
								this.endFrom = 'top';
								break
							case 4:
								sidesArr[0] = 'right';
								sidesArr[1] = 'bottom';
								this.endFrom = 'bottom';
								break
							case 3:
								if(minX <= 4) {
									sidesArr[0] = 'top';
									sidesArr[1] = 'left';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'right';
								}
								this.endFrom = 'right';
								break
						}
						break
					case 4:
						this.startFrom = 'bottom'
						switch (endSide) {
							case 1:
								sidesArr[0] = 'left';
								sidesArr[1] = 'bottom';
								this.endFrom = 'left';
								break
							case 3:
								if (minX <= 4 && this.parent.detail.h - maxY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
									sidesArr[3] = 'bottom';
								} else {
									sidesArr[0] = 'right';
									sidesArr[1] = 'bottom';
								}
								this.endFrom = 'right';
								break
							case 2:
								if([3, 4].includes(middleElStart)) {
									sidesArr[0] = 'right';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								} else {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'bottom';
								}
								this.endFrom = 'top';
								break
							case 4:
								if(this.parent.detail.h - maxY <= 4 && minY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
									sidesArr[3] = 'bottom';
								} else if(this.parent.detail.h - maxY <= 4) {
									sidesArr[0] = 'left';
									sidesArr[1] = 'top';
									sidesArr[2] = 'right';
								} else {
									sidesArr[0] = 'bottom';
								}
								this.endFrom = 'bottom';
								break
						}

				}
				break;
			// default:
			// 	if(minX <= 4) {
			// 		if(minY <= 4) {
			// 			sidesArr[0] = 'left';
			// 			sidesArr[1] = 'bottom';
			// 		} else {
			// 			sidesArr[0] = 'left';
			// 			sidesArr[1] = 'top';
			// 		}
			// 	} else if(maxX - this.getDeltaX(true) <= 4 ) {
			// 		if(minY  <= 4) {
			// 			sidesArr[0] = 'right';
			// 			sidesArr[1] = 'bottom';
			// 		} else {
			// 			sidesArr[0] = 'right';
			// 			sidesArr[1] = 'top';
			// 		}
			// 	}
		}

		switch (sidesArr.length) {
			case 1:
				this.parent.edgeSide = sidesArr[0];
				this.parent.angle = null;
				break;
			case 2:
				this.parent.angle = sidesArr.join('_');
				break;
			default:
				this.parent.angle = null;
				this.parent.adgeSide = null;
		}
		this.touchedSides = [...sidesArr]
	}

	detectPointPos(el, isEnd = true) {
		const point = [];
		const x = isEnd ? el.x2 : el.x1;
		const y = isEnd ? el.y2 : el.y1;
		point[0] = x <= this.parent.detail.l / 2 ? 'left' : 'right';
		point[1] = y <= this.parent.detail.h / 2 ? 'bottom' : 'top';
		if(point[0] === 'left' && point[1] === 'bottom') return 1;
		if(point[0] === 'left' && point[1] === 'top') return 2;
		if(point[0] === 'right' && point[1] === 'top') return 3;
		if(point[0] === 'right' && point[1] === 'bottom') return 4;
	}

	detectStartSide(el) {
		if(el.x1 <= 4) return 1;
		if(this.parent.detail.l - el.x1 <= 4) return 3;
		if(el.y1 <= 4) return 4;
		if(this.parent.detail.h - el.y1 <= 4) return 2;
		return null
	}

	detectEndSide(el) {
		if(el.x2 <= 4) return 1;
		if(this.parent.detail.l - el.x2 <= 4) return 3;
		if(el.y2 <= 4) return 4;
		if(this.parent.detail.h - el.y2 <= 4) return 2;
		return null
	}

	checkElDirection(el) {
		if(el.type === "arc") return null;
		if(el.x1 === el.x2) return 'hor';
		if(el.y1 === el.y2) return 'ver';
		return null;
	}

	getDeltaX(max = false) {
		return max ? this.parent.detail.l - this.edgeThickness - this.detailEdgeLeftThickness : this.edgeThickness
	}

	getDeltaY(max = false) {
		return max ? this.parent.detail.h - this.edgeThickness - this.detailEdgeBottomThickness : this.edgeThickness
	}

	// get points() {
	// 	const lastIndex = this.elements.length - 1;
	// 	const firstEl = this.elements[0],
	// 		lastEl = this.elements[lastIndex]
	// 	if(firstEl.x1 === lastEl.x2) {
	// 		if(firstEl.x1 < 3) {
	// 			side = 'left'
	// 		} else {
	// 			side = 'right'
	// 		}
	// 	}
	// 	return [
	// 		{x: }
	// 	]
	// }

	get widthCalc() {
		if(this.elements.length === 3){
			if(!empty(this.parent.angle) && this.elements[1].type === 'arc'){
				const el = this.elements[1];
				return Math.max(el.x1, el.x2) - Math.min(el.x1, el.x2);
			}
		}
		return Math.max(...this.elements.map(el => Math.max(el.x1, el.x2))) - Math.min(...this.elements.map(el => Math.min(el.x1, el.x2)))
  }

  get heightCalc() {
	  if(this.elements.length === 3){
		  if(this.elements[1].type === 'arc'){
			  const el = this.elements[1];
			  return Math.max(el.y1, el.y2) - Math.min(el.y1, el.y2);
		  }
	  }
	  return Math.max(...this.elements.map(el => Math.max(el.y1, el.y2))) - Math.min(...this.elements.map(el => Math.min(el.y1, el.y2)))
  }

	get elements() {
      return this._elements;
  }

  set elements(value) {
      this._elements = value;
  }

	getNextSideForRotate(direction, side) {
		const sides = ['left', 'top', 'right', 'bottom'];
		const currentSide = sides.findIndex(el => el === side);
		if(currentSide === -1) return side;
		if(direction) {
			return currentSide === 3 ? sides[0] : sides[currentSide + 1]
		}
		return currentSide === 0 ? sides[3] : sides[currentSide - 1]
	}

	rotateDetail(direction, l, h) {
		if(!empty(this.parent.edgeSide)) {
			this.parent.edgeSide = this.parent.getNextEdgeSideForRotate(direction)
		}
		this.touchedSides = this.touchedSides.map(c => this.getNextSideForRotate(direction, c))
		if(!empty(this.parent.angle)) {
			const angles = ['left_bottom', 'left_top', 'right_top', 'right_bottom']
			const currAngleIndex = angles.findIndex(el => el === this.parent.angle)
			if(direction) {
				this.parent.angle = currAngleIndex === 3 ? angles[0] : angles[currAngleIndex + 1]
			} else {
				this.parent.angle = currAngleIndex === 0 ? angles[3] : angles[currAngleIndex - 1]
			}
		}
		this.elements = Helpers.rotateElementsArr(this.elements, l, h, direction);
		this.overallElements = Helpers.rotateElementsArr(this.overallElements, l, h, direction);
		// this.detectSide()
		return Promise.resolve();
	}
}