import { combineReducers } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'
import ACTIONS from './actions'

const initialState = {
	message: []
}

const errorMessage = createReducer(initialState, {
	[ACTIONS.ErrorMessageSwitch.type]: (state, val) => {
		if(val.payload.message.drop) {
			return initialState
		}
		if(val.payload.message.action) {
			return {...state, message: [...state.message.slice(0, -1)]}
		}
		return { ...state, message: [ ...state.message, val.payload.message] }
	}
})

export default combineReducers({
	errorMessage,
})