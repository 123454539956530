import keyMirror from "keymirror";

const actionType = {
  ADD_DETAIL: {},
  REMOVE_DETAIL: {},
  ADD_SELECTED_DETAIL: null,
  REMOVE_SELECTED_DETAIL: null,
  SET_SELECTED_DETAILS: [],
  SET_EDITED_OBJECT: null,
  SET_SCENE: null,
  ADD_PRODUCT: {},
  REMOVE_PRODUCT: null,
  ADD_SELECTED_OBJECT: null,
  REMOVE_SELECTED_OBJECT: null,
  ADD_HISTORY_STATE: null,
  SET_HISTORY_STATE: null,
  HISTORY_SET_CURRENT: null,
  GET_SHOWED_PROCESSING: null,
  SET_SHOWED_PROCESSING: null,
  ADD_CONNECTION: null,
  REMOVE_CONNECTION: null,
  SET_CONNECTIONS: null,
  SHOW_CONNECT_OPTIONS: null,
  CONNECT_VALUES: null,
  DROP_CONNECT_VALUES: null,
  ADD_GROUP: null,
  REMOVE_GROUP: null,
  ADD_DETAIL_GROUP: null,
  REMOVE_DETAIL_GROUP: null,
  LEFT_PANEL_OPENED: null,
  RIGHT_PANEL_OPENED: null,
  SET_CURRENT_CAMERA: null,
  ADD_TOP_PANEL_ITEM: null,
  REMOVE_TOP_PANEL_ITEM: null,
  TOGGLE_CONNECT_MODAL: null

  // setCSceneModel: null
};

export default keyMirror(actionType);
