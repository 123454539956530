import React, { useState, useEffect } from 'react';
import Panel from './../../layuot/panel';
import {useSelector, useDispatch} from "react-redux";
import { constructor3dSelectors, constructor3dOperations } from "../../../../redux/constructor";

import drill from "../../../../media/drill.svg";
import saw from "../../../../media/saw.svg";
import cutter from "../../../../media/cutter.svg";
import './style.scss'

const ChangeView = props => {
	const dispatch = useDispatch()
	const details = useSelector(constructor3dSelectors.getCDetails);
	const showProcessing = useSelector(constructor3dSelectors.showedProcessing);

	useEffect(() => {
		for(const [type, value] of Object.entries(showProcessing)) {
			details.forEach(el => el.changeView(value, type))
		}
	}, [showProcessing])

	const setShowProcessing = type => {
		dispatch(constructor3dOperations.setShowProcessing({[type]: !showProcessing[type]}))
	}

	return(
		<Panel className="p__content" title="Змінити вид">
			<div className="d-flex change-view">
				<a className={`change-view__control${showProcessing?.hole ? ' active' : ''}`} onClick={e => setShowProcessing('hole')}>
					<img src={drill}/>
				</a>
				<a className={`change-view__control${showProcessing?.saw ? ' active' : ''}`} onClick={e => setShowProcessing('saw')}>
					<img src={saw}/>
				</a>
				<a className={`change-view__control${showProcessing?.cutter ? ' active' : ''}`} onClick={e => setShowProcessing('cutter')}>
					<img src={cutter}/>
				</a>
			</div>
		</Panel>
	)
}

export default ChangeView;