import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";

const SubmitModal = ({setShowSubmitModal, setSelectedValue, selectType, submitModalText, setDeleteDetail, submitAction, isRemoveDetails = false, isRemoveProject = false}) => {

	const submit = () => {
		if(!isRemoveDetails){
			if (!isRemoveProject) {
				setSelectedValue(selectType)
			}
		}
		if(isRemoveDetails){
			submitAction()
		}
		if(isRemoveProject){
			submitAction()
		}
		setShowSubmitModal(false)
	}

	const close = ()=>{

		if (setDeleteDetail){
		setDeleteDetail(null)
		}

		setShowSubmitModal(false)
	}

	return <div className='modal'>
		<div className='submitModal'>
			<div className="newProject__body">
				{submitModalText}
			</div>
			<div className="newProject__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("submit", true)}
					handler={submit}
				/>

			</div>
		</div>
	</div>

}

export default SubmitModal