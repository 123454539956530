import "./style.scss";

const RadioButtonCustom = ({ id, checkId, checked }) => {

  return (
      <>
        <input
            onClick={() => checkId(id)}
            id={`radio-${id}`}
            className="radio-custom"
            name="radio-group"
            type="radio"
            checked={checked}
        />
        <label htmlFor={`radio-${id}`} className="radio-custom-label"></label>
      </>
  );
};

export default RadioButtonCustom;
