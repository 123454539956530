import Helpers from "../Helpers";
import {BufferGeometry, Group, Line, Mesh, Shape, ShapeGeometry, Vector2} from "three";
import {FontLoader} from "three/examples/jsm/loaders/FontLoader";
import fontFace from "../FontFace";
import {TextGeometry} from "three/addons/geometries/TextGeometry.js";
import {empty, getArrowSizeByDetailSize} from "../../../helpers/helper";
export default class SizeArrow {
	static addSizeArrow({_size, direction = 'hor', position = {x: 0, y: -50}, color= Helpers.contourLinesColor, helpers = false, fontSize = 8}) {
		const shape = new Shape();
		const size = Number(_size).toFixed(1).toString();
		switch (direction) {
			case 'hor':
				shape.moveTo(0, 0);
				shape.lineTo(5, 2);
				shape.lineTo(5, 0);
				shape.lineTo(_size - 5, 0);
				shape.lineTo(_size - 5, 2);
				shape.lineTo(_size, 0);
				shape.lineTo(_size - 5, -3);
				shape.lineTo(_size - 5, -1);
				shape.lineTo(5, -1);
				shape.lineTo(5, -3);
				break
			case 'ver':
				shape.moveTo(1, 0);
				shape.lineTo(-2, 5);
				shape.lineTo(0, 5);
				shape.lineTo(0, _size - 5);
				shape.lineTo(-2, _size - 5);
				shape.lineTo(1, _size);
				shape.lineTo(3, _size - 5);
				shape.lineTo(1, _size - 5);
				shape.lineTo(1, 5);
				shape.lineTo(3, 5);
				break
		}
		const geometry = new ShapeGeometry(shape, 4)
		const mesh = new Mesh(geometry, Helpers.getMaterial3D(color))
		mesh.position.x = position.x;
		mesh.position.y = position.y;
		mesh.position.z = 0;

		const textMesh = this.getTextMesh(size, fontSize, color);
		mesh.add(textMesh);

		const moveText = 6 * size.toString().length / 2
		// const mesh = textMesh;
		switch (direction) {
			case 'hor':
				textMesh.position.x = size / 2 + position.x - moveText;
				textMesh.position.y = position.y + 55;
				textMesh.position.z = 0;
				break
			case 'ver':
				textMesh.rotateZ(Math.PI / 2);
				textMesh.position.y = size / 2 - moveText
				textMesh.position.x = position.x - 5;
				textMesh.position.z = 0;
				break
		}
		return mesh;
	}

	static getTextMesh(text, fontSize, color = Helpers.contourLinesColor) {
		const font = new FontLoader().parse(fontFace);
		const textGeo = new TextGeometry(text, {
			font: font,
			size: fontSize,
			height: 1,
		})
		return new Mesh(textGeo, Helpers.getMaterial3D(color))
	}

	static getElementSizeArrow(x, y, radius = null, pos, detail, lineToDetail = false, zAxis = false, size = null) {
		const mesh = new Group();
		const l = detail.l, h = detail.h, z = detail.w / 2 + 2;
		const fontSize =  Helpers.getHelpersFontSize({l: detail.l, h: detail.h});
		let toValX,
			valX,
			toValY,
			valY,
			lineX,
			lineY,
			sizeValX, sizeValY;
		if(!empty(size)) {
			toValX = this.addSizeArrow({_size: l - x - size, fontSize});
			sizeValX = this.addSizeArrow({_size: size, color: Helpers.sizesLineColor, fontSize});
			valX = this.addSizeArrow({_size: x + size, fontSize});
			toValY = this.addSizeArrow({_size: detail.h - y - size, direction: 'ver', fontSize});
			sizeValY = this.addSizeArrow({_size: size, direction: 'ver', color: Helpers.sizesLineColor, fontSize});
			valY = this.addSizeArrow({_size: y + size, direction: 'ver', color: Helpers.sizesLineColor, fontSize});
			lineX = Helpers.getLine(lineToDetail ? 20 : x + 20);
			lineY = Helpers.getLine(lineToDetail ? 20 : y + getArrowSizeByDetailSize(20, h), 'ver');
		} else {
			toValX = this.addSizeArrow({_size: l - x, fontSize});
			valX = this.addSizeArrow({_size: x, color: Helpers.sizesLineColor, fontSize});
			toValY = this.addSizeArrow({_size: detail.h - y, direction: 'ver', fontSize});
			valY = this.addSizeArrow({_size: y, direction: 'ver', color: Helpers.sizesLineColor, fontSize});
			lineX = Helpers.getLine(lineToDetail ? 20 : x + 20);
			lineY = Helpers.getLine(lineToDetail ? 20 : y + getArrowSizeByDetailSize(20, h), 'ver');
		}

		switch (pos) {
			case "left_bottom":
				detail.showMainArrowXBottom(false);
				detail.showMainArrowYLeft(false);
				toValX.position.set(-(l / 2 - x), - h / 2 - getArrowSizeByDetailSize(20, h), z)
				valX.position.set(-l / 2, - h / 2 - getArrowSizeByDetailSize(20, h), z)
				toValY.position.set(-l / 2 - 20, -h / 2 + y, z)
				valY.position.set(-l / 2 - 20, -h / 2, z)
				if(!empty(size)) {
				}
				if(lineToDetail) {
					lineX.position.set(-l / 2 - 20, - h / 2 + y, z)
				} else {
					lineX.position.set(-l / 2 - 20, - h / 2 + y, z)
				}
				lineY.position.set(-l / 2 + x, -h / 2 - getArrowSizeByDetailSize(20, h), z)

				break
			case "left_top":
				detail.showMainArrowXBottom(true);
				detail.showMainArrowYLeft(false);
				toValX.position.set(-(l / 2 - x), h / 2 + 20, z)
				valX.position.set(-l / 2, h / 2 + 20, z)
				toValY.position.set(-l / 2 - 20, -h / 2, z)
				valY.position.set(-l / 2 - 20, -h / 2 + (h - y), z)
				if(lineToDetail) {
					lineY.position.set(-l / 2 + x, -h / 2 + h, z)
				} else {
					lineY.position.set(-l / 2 + x, -h / 2 + h - y, z)
				}
				lineX.position.set(-l / 2 - 20, - h / 2 + (h - y), z)

				break
			case "right_bottom":
				detail.showMainArrowYLeft(true);
				detail.showMainArrowXBottom(false);
				toValX.position.set(-l / 2, - h / 2 - 20, z)
				valX.position.set(-l / 2 + (l - x), - h / 2 - 20, z)
				toValY.position.set(l / 2 + 20, -h / 2 + y, z)
				valY.position.set(l / 2 + 20, -h / 2, z)
				if(lineToDetail) {
					lineX.position.set(-l / 2 + l, - h / 2 + y, z)
				} else {
					lineX.position.set(-l / 2 + (l - x), - h / 2 + y, z)
				}
				lineY.position.set(-l / 2 + (l - x), -h / 2 - 20, z)
				break
			case "right_top":
				detail.showMainArrowXBottom(true);
				detail.showMainArrowYLeft(true);
				toValX.position.set(-l / 2, h / 2 + 20, z)
				valX.position.set(-l / 2 + (l - x), h / 2 + 20, z)
				toValY.position.set(l / 2 + 20, -h / 2, z)
				valY.position.set(l / 2 + 20, -h / 2 + (h - y), z)
				if(lineToDetail) {
					lineX.position.set(-l / 2 + l, -h / 2 + h - y, z)
					lineY.position.set(-l / 2 + l - x , -h / 2 + h, z)
				} else {
					lineX.position.set(-l / 2 + (l - x), -h / 2 + h - y, z)
					lineY.position.set(-l / 2 + l - x , -h / 2 + (h - y), z)
				}

				break
		}

		if(!empty(radius)) {
			const radiusLine = this.getRadiusSize( - l /2 + radius.xc, - h / 2 + radius.yc, radius.r, Helpers.sizesLineColor, pos);
			radiusLine.position.z = z;

			switch (pos) {
				case "left_bottom":
					radiusLine.rotateZ(225 * (Math.PI / 180))
					break
				case "left_top":
					radiusLine.rotateZ(135 * (Math.PI / 180))
					break
				case "right_bottom":
					radiusLine.rotateZ(-45 * (Math.PI / 180))
					break
				case "right_top":
					radiusLine.rotateZ(45 * (Math.PI / 180))
					break
			}
			radiusLine.position.set(- l /2 + radius.xc, - h / 2 + radius.yc, z)
			mesh.add(radiusLine);
		}

		if(zAxis) {
			const zLine = Helpers.getLine(detail.w, Helpers.sizesLineColor)
			zLine.rotateY(Math.PI / 2)
			zLine.position.set(-l / 2 + x, - h / 2 + y, z);
			mesh.add(zLine)
		}

		if(x > 0) {
			mesh.add(valX)
			mesh.add(lineY)
		}
		if(y > 0) {
			mesh.add(valY)
			mesh.add(lineX)
		}
		if(x !== l) {
			mesh.add(toValX)
		}
		if(y !== h) {
			mesh.add(toValY)
		}
		if(!empty(size)) {
			mesh.add(sizeValX);
			mesh.add(sizeValY)
		}

		return mesh;
	}

	static getRadiusSize(xc, yc, r, color, pos) {
		const points = [];
		points.push( new Vector2( 0, 0 ) );
		points.push( new Vector2( r, 0 ) );
		const geometry = new BufferGeometry().setFromPoints( points );
		const mesh = new Line( geometry, Helpers.getLineMaterial(color) );
		const moveText = 6 * r.toString().length / 2;
		const textMesh = this.getTextMesh('R = ' + r, 8, color);
		textMesh.position.x = r / 2 - moveText;
		textMesh.position.y = 5;
		switch (pos) {
			case "left_bottom":
			case "left_top":
				textMesh.rotateZ(Math.PI)
				textMesh.position.x += moveText + 10;
				textMesh.position.y -= 10;
				break
			case "right_bottom":

				break
			case "right_top":

				break
		}
		mesh.add(textMesh);
		return mesh;
	}
}