import React from 'react';
import History from "../../components/History";
import './style.scss'

const RightPanel = () => {
	return (
		<div className='right-panel'>
			<History />
		</div>
	)
}

export default RightPanel;