import React, {useState, useEffect} from "react";
import {useDispatch} from 'react-redux';
import {inputsOperations} from "redux/inputs";
import {useSelector} from "react-redux";
import {inputsSelectors} from "redux/inputs";

const File = ({name, type}) => {
  const [file, setFile] = useState(null)
  const [accept, setAccept] = useState(false)
  const dispatch = useDispatch()
  const fileNameRedux = useSelector(inputsSelectors.getFileProjectState);

  useEffect(() => {
    file 
    && fileNameRedux.payload.file.name == file.name 
    && fileNameRedux.payload.file.type == type 
      ? setAccept(true) 
      : setAccept(false)
  }, [file,fileNameRedux])

  const getFile = (e) => {
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);

    if(filesArr[0] && filesArr[0].name) {
      setFile(filesArr[0])
      dispatch(inputsOperations.setFileProject({
        file: {name: filesArr[0].name, type: type}
      }))
    }
  }

  
  const removeFile = (e) => {
    e.preventDefault()
    setFile(null)
    dispatch(inputsOperations.setFileProject({
      file: {}
    }))
  }

  return (
    <>
      <label className={'file-upload ' + (!accept ? "activated" : "")}>
        <input accept={type} type="file" onChange={getFile} />
        {name}
        {
          file && accept
          ? (
            <>
              <div title={file.name} className="name">
                {file.name.length <= 11 ? file.name : file.name.substring(0, 11) + '...'}
                <button onClick={removeFile} className="remove"></button>
              </div>
            </>
          ) : ('') 
        }
      </label>
    </>
  )
}

export default File