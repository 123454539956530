import API from "config/api";
import {empty} from "../helpers/helper";

export default class Materials {
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;
  }

  async getMaterialsCurrent(code, filial=72) {
    return await this.axios
      .get(`${API.ROUTE.get_current_material()}/?code=${code}&filial=${filial}`, this.headers)
      .then((response) => {
        if(!empty(response.data)) {
          if(empty(response.data[0].languages) && empty(response.data[0].name)) {
            return Promise.reject({Error: 'incorrect Material'})
          } else {
            if(empty(response.data[0].languages)) {
              response.data.languages = {
                ru: response.data[0].name,
                ua: response.data[0].name
              }
            } else if(empty(response.data[0].languages.ua)) {
              response.data[0].languages.ua = response.data[0].languages.ru
            }
          }
          if(!empty(response.data[0].edges)) {
            response.data[0].edges.forEach((edge, i) => {
              if(empty(edge.languages) && empty(edge.name)) {
                return Promise.reject({Error: `incorrect Material Edge ${i}`})
              }
              if(empty(edge.languages) && !empty(edge.name)) {
                edge.languages = {
                  ru: edge.name,
                  ua: edge.name
                }
              } else if(empty(edge.languages.ua)) {
                edge.languages.ua = edge.languages.ru
              }
            })
          }
        }

        return Promise.resolve(response.data);
      })
      .catch(err => console.log(err));
  }
  async getSearchMaterials(code) {
    return await this.axios
        .get(`${API.ROUTE.get_search_materials()}/?find=${code}`, this.headers)
        .then((response) => {
          if(!empty(response.data)) {
            if(empty(response.data[0].languages) && empty(response.data[0].name)) {
              return Promise.reject({Error: 'incorrect Material'})
            } else {
              if(empty(response.data[0].languages)) {
                response.data.languages = {
                  ru: response.data[0].name,
                  ua: response.data[0].name
                }
              } else if(empty(response.data[0].languages.ua)) {
                response.data[0].languages.ua = response.data[0].languages.ru
              }
            }
          }
          return Promise.resolve(response.data);
        })
  }
  async getEdgesCurrent(code) {
    return await this.axios
        .get(`${API.ROUTE.get_current_edges()}/?find=${code}`, this.headers)
        .then((response) => {
          if(!empty(response.data)) {
            if(empty(response.data[0].languages) && empty(response.data[0].name)) {
              return Promise.reject({Error: 'incorrect Edjes'})
            } else {
              if(empty(response.data[0].languages)) {
                response.data.languages = {
                  ru: response.data[0].name,
                  ua: response.data[0].name
                }
              } else if(empty(response.data[0].languages.ua)) {
                response.data[0].languages.ua = response.data[0].languages.ru
              }
            }
          }
          return Promise.resolve(response.data);
        })
  }

  async getMaterials() {
    return await this.axios
      .get(API.ROUTE.get_materials(), this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // NotificationManager.warning(error.message, "Error");
      });
  }

  async getEdges() {
    return await this.axios
      .get(API.ROUTE.get_edges(), this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // NotificationManager.warning(error.message, "Error");
      });
  }

  async getWarehouseMaterials(phone){
    return await this.axios
      .get(`${API.ROUTE.get_warehouse_materials()}?phone=${phone}`, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
    //   .catch((error)=>{
    //   NotificationManager.warning(error.message, "Error");
    // })
  }

  async getMaterialsKronas(body){
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
    .post(`${API.ROUTE.get_kronas_materials()}`, body, this.headers)
    .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
    })
  }

  async reservingMaterials (body)  {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.reserving_materials()}`, body, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async deleteReservingMaterials (body)  {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.delete_reserved_materials()}`, body, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async checkKronasElement (body) {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.check_kronas_element()}`, body ,this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async checkKronasOneElement (id) {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }

    return await this.axios
      .get(`${API.ROUTE.check_kronas_one_element()}/${id}/show`, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }


}
