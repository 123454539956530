import { useState } from "react";
import "./style.scss";

const RoundCheckBox = (props) => {
  const roundCheckBoxClass =
    props.value === props.selectedMaterial[0]
      ? "roundCheckBox roundCheckBox_active"
      : "roundCheckBox";

  const changeProp = () => {
    if (props && props.value) {
      props.setMaterial(props.value);
    }
  };

  return (
    <div className={roundCheckBoxClass} onClick={() => changeProp()}></div>
  );
};

export default RoundCheckBox;
