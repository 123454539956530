import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import {empty} from "../../../../helpers/helper";
import ConnectDetails from "../../../model/components/ConnectDetails";

import './style.scss'

import Connection from "../Connection";

const Connections = ({productId}) => {
	const Connect = new ConnectDetails();
	const dispatch = useDispatch();
	const connections = useSelector(constructor3dSelectors.getConnections).filter(c => c.productId === productId)
	const [isOpen, setIsOpen] = useState(false);

	const editConnection = (connectionId) => {
		const data = Connect.getData(connectionId);
		if(empty(data)) return;
		dispatch(constructor3dOperations.addSelectedObject({object: data.mainObject}))
				.then(() => dispatch(constructor3dOperations.addSelectedObject({object: data.targetObject})))
				.then(() => {
					data.mainDetailClass.selectObject(data.mainObject.uuid);
					data.targetDetailClass.selectObject(data.targetObject.uuid);
					return dispatch(constructor3dOperations.setConnectionValues({...data.connection.offset, connectionId}))
				})
				.then(() => dispatch(constructor3dOperations.setConnectionOptions(true)))

	}

	const deleteConnection = (connectionId) => {
		Connect.removeConnection(connectionId)
	}

	const restoreConnections = () => {
		Connect.restoreConnectedDetails()
	}

	if(empty(connections)) return null;

	return (
		<div className="block connections">
			<div className="block__header connections__header icon-before-connections">
				<span className={'block__header-title connections__header-title'}>Прив’язки {connections.length}</span>
				<a className={`block__header-control icon-button-arrow-ver ${isOpen ? 'hide' : 'show'}`}
					onClick={e => setIsOpen(!isOpen)}>{isOpen ? '-' : '+'}</a>
			</div>
			<div className={`block__body ${isOpen ? '' : 'hidden'}`}>
				{connections.map((el) => (<Connection key={el.connectionId} connection={el} editConnection={editConnection} deleteConnection={deleteConnection} />))}
				<a onClick={restoreConnections}>restore</a>
			</div>
		</div>
	)
}

export default Connections;