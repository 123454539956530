import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Languages from "../../../../translation/Languages";
import './style.scss';
import Input from './../UI/Input';
import Modal from './../../layuot/Modal';
import {constructor3dOperations, constructor3dSelectors} from "../../../../redux/constructor";
import ConnectDetails from "../../../model/components/ConnectDetails";

const ConnectDetailsOptions = (props) => {
	const values = useSelector(constructor3dSelectors.getConnectValues);
	const isOpen = useSelector(constructor3dSelectors.connectModalOpened);
	const selectedObjects = useSelector(constructor3dSelectors.getSelectedObjects);
	const [mainObject, setMainObject] = useState(selectedObjects[0] || null)
	const connect = new ConnectDetails(selectedObjects);
	const dispatch = useDispatch();
	const [cOffset, setCOffset] = useState({});

	useEffect(() => {
		setCOffset(values)
	}, []);

	useEffect(() => {
		setCOffset(values)
	}, [values]);

	useEffect(() => {
		setMainObject(selectedObjects[0])
	}, [selectedObjects]);

	const handleChange = data => {
		const { name, value } = data;
		setCOffset({...cOffset, [name]: value});
	}

	const handleCoincidentObjects = () => {
		const _offset = {};
		for(const [prop, value] of Object.entries(cOffset)) {
			if(prop !== 'connectionId' && prop !== 'productId') {
				_offset[prop] = value;
			}
		}
		connect.connectObjects({
			mainObject: mainObject.object,
			targetObject: selectedObjects.find(el => el.object.uuid !== mainObject.object.uuid)?.object,
			offset: _offset,
			type:'coincident',
			connectionId: cOffset.connectionId
		})
		dispatch(constructor3dOperations.setConnectionValues(_offset))
			.then(() => dispatch(constructor3dOperations.setConnectionOptions(false)))

	}

	const closeModal = () => {
		dispatch(constructor3dOperations.toggleConnectModal())
	}

	const getControls = () => {
		return (<button onClick={handleCoincidentObjects}>ok</button>)
	}

	return (
		<Modal show={isOpen} hide={closeModal} title={'conn'} controls={getControls()}>
			{Object.keys(cOffset).map(name => {
				return name !== 'connectionId' ?
					<Input name={name} title={Languages.getTranslation(`c_${name}`)} value={cOffset[name]} onChange={handleChange} key={name} />
					: null
			})}
		</Modal>
	)

}

export default ConnectDetailsOptions