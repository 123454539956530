import './style.scss';
import {useEffect, useState} from "react";
import { useSelector} from "react-redux";
import { projectSelectors} from "redux/project";
import {empty} from "../../../helpers/helper";
import {userSelectors} from "redux/user";

const TextureAndCutItem = ({ detailItem, id, type = "texture", materialType }) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const permissions = useSelector(userSelectors.permissions);

    const [item, setItem] = useState(null)
    useEffect(() => {
        setItem(detailItem)
    }, [detailItem])
    const changeItem = () => {
        setItem(prevItem => {
            const newItem = !prevItem;
            if(type === 'texture'){
                construction.updateDetail(id,  'isRotateTexture', newItem)

            }else{
                construction.updateDetail(id, 'isCutting', newItem);
            }
            return newItem;
        });
    };
    return (
        <>
        {!empty(item) && (
        <div className="detailsTab__data-item">
            <div aria-disabled={['Постформінг', 'Постформинг', 'OSB'].includes(materialType) || permissions.includes("KM_disabledParamsDetail")}
                onClick={() => {
                    if(!['Постформінг', 'Постформинг', 'OSB'].includes(materialType)) {
                        changeItem()
                    }
                }}
                className={item ? "detailsTab__texture detailsTab__texture_active pointer" : "detailsTab__texture pointer"}>
                <i
                    className={item ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"}></i>
            </div>
        </div>
        )
        }
        </>
    )
};
export default TextureAndCutItem