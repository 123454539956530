import React, {useEffect, useState} from "react";
import "./../../../Modal/Types/style.scss";
import {empty} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";

const Select = ({ param, updateParams, treatmentType, treatment }) => {

  useEffect(() => {

  }, [param.isError])

  const update = (e) => {
    treatment.checkRequiredField(param.name, e.target.value)
    // if(!error) {
      param.onChange(e.target.value);
      // updateParams()
    // }
    updateParams()
  };
  const showCaption = () => {
    if(empty(param.isError)) return null;
    return (<span className="param__error-caption">{param.isError}</span>)
  }

  return (
    <>
      <select
          value={param.value}
          className={"param__default-select"}
          onChange={(e) => update(e)}
          name={param.id}
          id={param.id}
          disabled={param?.disabled || false}
      >
          {param.variables
              && param.variables.map((option, key) => {
                      return (
                          <>
                              <option key={key} selected={key === 0} value={option.key}>
                                  {option.value}
                              </option>
                              {(treatmentType === "groove" || treatmentType === "hole")
                                  && option.key === 'back' && <hr key={key}/>}
                          </>
                      )
                  }
              )}
      </select>
      {/*{showCaption()}*/}
    </>
  );
};

export default Select;
