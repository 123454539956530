import React, { useState } from 'react';

const Input = props => {
	const {value, type='text', onChange, onBlur, name='input', title} = props;
	const [val, setVal] = useState(value);

	const handleChange = e => {
		setVal(e.target.value);
		if(onChange && typeof onChange === 'function') {
			onChange({name, value: e.target.value});
		}
	}

	const handleBlur = (e) => {
		if(onBlur && typeof onBlur === 'function') {
			onBlur({name, value: val});
		}
	}

	return (
		<label>
			{title}
			<input type={type} value={val} name={name} onBlur={handleBlur} onChange={handleChange}/>
		</label>

	);
}

export default Input;