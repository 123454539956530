import { createAction } from "@reduxjs/toolkit";
import actionType from "./actionType";

const UserToken = createAction(actionType.token);
const UserInfo = createAction(actionType.user);

export default {
  UserToken,
  UserInfo
};
