import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations, modalsSelectors} from "redux/modals";

import Languages from "../../../../translation/Languages";
import CloseBtn from "components/UI/Buttons/CloseBtn";
import "./style.scss";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {tempDetailOperations} from "redux/tempDetail";
import {empty} from "../../../../helpers/helper";
import CustomSelect from "../../Select";
import {projectSelectors} from "redux/project";

 const initialDirectionCuts =[
	 {value: 0, label: '0 - Довільне'},
	 {value: 1, label: '1 - По довжині'},
	 {value: 2, label: '2 - По ширині'},
	 {value: 3, label: '3 - По довжині переважно'},
	 {value: 4, label: '4 - По ширині переважно'},
 ]
const sortingLines =[
	{value: 0, label: '0 - По спаданню'},
	{value: 1, label: '1 - По зростанню'},
	{value: 2, label: '2 - Вузькі в центр'},
	{value: 3, label: '3 - Не сортувати'},
]

const CuttingParams = () => {
	const material = useSelector(modalsSelectors.getModalIsEdit);
	const [isHardStripes, setIsHardStripes] = useState(false);
	const [currentMaterial, setCurrentMaterial] = useState(null);
	const [valueInitialCut, setValueInitialCut] = useState(null)
	const [valueSortingLine, setSortingLine] = useState(null)
	const construction = useSelector(projectSelectors.getConstruction);
	const dispatch = useDispatch();


	const hardStripesClass = isHardStripes
		? "quickInputTable__edges-mode quickInputTable__edges-mode_active"
		: "quickInputTable__edges-mode";
	const hardStripeItemClass = isHardStripes
		? "quickInputTable__edges-mode-item quickInputTable__edges-mode-item_active"
		: "quickInputTable__edges-mode-item";

	useEffect(() => {
		construction.getMaterialCur(material.article).then((mat) => {
			setCurrentMaterial(mat)
		})

	}, [])
	useEffect(() => {
		if(!empty(currentMaterial)){

		if(!empty(currentMaterial?.CsComplexBand)){
			setIsHardStripes(currentMaterial.CsComplexBand)
		}
		if(!empty(currentMaterial?.CsDirectCut)){
			const findInitialCut = initialDirectionCuts.find(({value}) =>value === currentMaterial.CsDirectCut)
			setValueInitialCut(findInitialCut)
		} else {
			if(['OSB', 'ЛДСП', 'МДФ','HDF','Compact-плита'].includes(currentMaterial.type)  && (currentMaterial.height <= 3750 && material.width <= 3750)){
				setValueInitialCut({value: 3, label: '3 - По довжині переважно'})
			}else if(['Постформінг', 'Постформинг', 'Стеновая панель', 'Стінова панель'].includes(currentMaterial.type) || currentMaterial.height > 3750 ||material.width > 3750){
				setValueInitialCut({value: 4, label: '4 - По ширині переважно'})

			}else {
				setValueInitialCut({value: 0, label: '0 - Довільне'})

			}

		}
		if(!empty(currentMaterial?.CsSortBands)){
			const findSortingLine = sortingLines.find(({value}) =>value === currentMaterial.CsSortBands)
			setSortingLine(findSortingLine)
		}else {
			setSortingLine({value: 0, label: '0 - По спаданню'})

		}
		}

	}, [currentMaterial]);
	const close = () => {
		dispatch(tempDetailOperations.addProperty(null))
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const stripeModeHandler = () => {
		setIsHardStripes((prevState) => !prevState);

	};


	const submit = () => {
		construction.updateMaterial(currentMaterial.index, 'CsComplexBand', isHardStripes)
		construction.updateMaterial(currentMaterial.index, 'CsDirectCut', valueInitialCut?.value)
		construction.updateMaterial(currentMaterial.index, 'CsSortBands', valueSortingLine?.value)
		close()
	}
	return (
		<>
		{!empty(currentMaterial) && (

		<div className="cuttingParams">
			<div className="cuttingParams__header">
				<span className="cuttingParams__header-title">{Languages.getTranslation("cutting-params", true)}</span>
				<CloseBtn handler={close}/>
			</div>
			<div className="cuttingParams__body">
				<div className="cuttingParams__body-strips">
					<div className="cuttingParams__body-item cuttingParams__body-strips-title">
						{Languages.getTranslation("use-of-complex-stripes", true)}

					</div>
					<div className="cuttingParams__body-item cuttingParams__body-strips-body">
						<span>{Languages.getTranslation("simple-stripes", true)}</span>
						<div className={hardStripesClass} onClick={stripeModeHandler}>
							<div className={hardStripeItemClass}></div>
						</div>
					</div>


				</div>
				<div className="cuttingParams__body-strips">
					<div className="cuttingParams__body-item cuttingParams__body-strips-title">
						{Languages.getTranslation("initial-direction-of-cuts", true)}

					</div>
					{
						!empty(valueInitialCut) && (
							<div className="cuttingParams__body-item cuttingParams__body-strips-select">
								<CustomSelect
									value={valueInitialCut}
									defaultValue={valueInitialCut}
									placeholder={Languages.getTranslation("select-action", true)}
									options={initialDirectionCuts}
									handleChange={(selectedOption) => setValueInitialCut(selectedOption)}
								/>
							</div>
						)
					}


				</div>
				<div className="cuttingParams__body-strips">
					<div className="cuttingParams__body-item cuttingParams__body-strips-title">
						{Languages.getTranslation("initial-direction-of-cuts", true)}

					</div>
					{
						!empty(valueSortingLine) && (
							<div className="cuttingParams__body-item cuttingParams__body-strips-select">
								<CustomSelect
									value={valueSortingLine}
									defaultValue={valueSortingLine}
									placeholder={Languages.getTranslation("select-action", true)}
									options={sortingLines}
									handleChange={(selectedOption) => setSortingLine(selectedOption)}
								/>
							</div>
						)
					}


				</div>
			</div>

			<div className="cuttingParams__actions">

				<DefaultBtn
					title={Languages.getTranslation("cancel2", true)}
					className="defaultBtn5"
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation('save', true)}
					className="defaultBtn5"
					handler={() =>submit()}
				/>
			</div>
		</div>
	)}
		</>
	);
};

export default CuttingParams;
