import React, {useEffect, useRef, useState} from 'react';
import ContourSVGComponent from "../components/UI/Svg/index";
import Contour from "../components/UI/SvgSketch";
import {empty} from "../helpers/helper";

const IntersectionSVG = ({detail}) => {
  const observerTarget = useRef();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting){
        setVisible(true);
        observer.disconnect();
      }
    });

    observer.observe(observerTarget.current);

    return () => observer.disconnect();
  }, []);
  return (
      <div ref={observerTarget}>
        {isVisible ? (
            <>
                <ContourSVGComponent detail={detail}  rects={detail.getCutoutsData()} holes={detail.holes} />
                {/*<Contour detail={detail}  rects={detail.getCutoutsData()} holes={detail.holes} />*/}
            </>
            )
          :
            null
        }
      </div>
  );
};

export default IntersectionSVG;