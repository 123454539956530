import "./style.scss";

import React from 'react';
import Select, { components } from "react-select";

const { Option } = components;

const CustomOption = (props) => {
    return (
        <Option {...props}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {props.data.img && <img src={props.data.img} style={{ marginRight: 8 }} alt={props.data.label} />}
                {props.data.label}
            </div>
        </Option>
    );
};
const CustomSelect = ({ options,defaultValue, handleChange, placeholder, customStyles, disabled, withImages }) => {
    const defaultStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#451d54' : 'black',
            backgroundColor: state.isFocused ? '#EFE3EC' : null,
            padding: 10,
            fontSize: '16px',
            cursor: "pointer"
        }),
        control: () => ({
            width: '100%',
            display: 'flex',
            background: "#F0F0F0",
            cursor: "pointer"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '16px',
            cursor: 'pointer'
        }),

        placeholder: (provided) => ({
            ...provided,
            fontSize: '16px',
            cursor: "pointer"

        })
    }
    const combinedStyles = { ...defaultStyles, ...customStyles }
    const components = withImages ? {Option: CustomOption} : undefined;
    return (
        <Select
            styles={combinedStyles}
            options={options}
            onChange={handleChange}
            isSearchable={false}
            maxMenuHeight={900}
            defaultValue={defaultValue}
            placeholder={placeholder}
            isDisabled={disabled}
            components={components}
        />
    );
}

export default CustomSelect;
