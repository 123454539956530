import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialState = {
  show: false,
};

const hideAll = createReducer(initialState, {
  [ACTIONS.HideAllStateSwitch.type]: (state, val) => {
    return { ...state, show: val.payload.show };
  },
});

export default combineReducers({
  hideAll,
});
