import ACTIONS from './actions'

const addProperty = (name, property) => {
    return async (dispatch) => {
        try{
            dispatch(ACTIONS.TempDetail({ name, property }))
        }catch(error){
            console.log(error)
        }
    }
}

const propertyNull = (name, property = null) => {
    return async (dispatch) => {
        try{
            dispatch(ACTIONS.TempDetail({ name, property }))
        }catch(error){
            console.log(error)
        }
    }
}

const defaultState = (name = null, property = null) => {
    return async (dispatch) => {
        try{
            dispatch(ACTIONS.TempDetail({ name, property }))
        }catch(error){
            console.log(error)
        }
    }
}

const setTempProject = (project) => {
    return async (dispatch) => {
        try{
            dispatch(ACTIONS.TempProject({ project }))
        }catch(error){
            console.log(error)
        }
    }
}

const setTempDetails = (details) => {
    return async (dispatch) => {
        try{
            dispatch(ACTIONS.TempDetails({ details }))
        }catch(error){
            console.log(error)
        }
    }
}

export default {
    addProperty,
    propertyNull,
    defaultState,
    setTempProject,
    setTempDetails
}