import Helpers from "../3D/Helpers";
import SizeArrow from "../3D/components/SizeArrow";

export default class BasisCutOut {
  cutout
  _side
  _depth
  _x
  _type
  _width
  _height
  _x_axis
  _y_axis
  detail_h
  detail_l
  fullDepth = false
    constructor(cutout) {
      this.cutout = cutout;
      this._side = cutout._side
      this._depth = cutout._depth
      this._x = cutout._x
      this._y = cutout._y
      this._type = cutout._type
      this._width = cutout._width
      this.fullDepth = cutout.fullDepth
      this._height = cutout._height
      this._elements = cutout.elements
      this._edgeSide = cutout._edgeSide
      this._x_axis = cutout._x_axis
      this._y_axis = cutout._y_axis
      this.detail_l = cutout.detail.l
      this.detail_h = cutout.detail.h
    }

  initRequiredFields() {
    this.cutout.requiredFields = {
      x: {
        max: this.cutout.detail.l - this.width,
        min: 0
      },
      y: {
        max: this.cutout.detail.h - this.height,
        min: 0
      },
      depth: {
        min: 1,
        max: this.cutout.getMaterialDepth()
      },
    }
  }

  addParams() {
    // this.cutout.setFormField({name: 'type', value: this.type, type: 'number', label: 'Тип обробки'})
    this.cutout.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)'})
    this.cutout.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)'})
    this.cutout.setFormField({name:'side', value: this.side, label: 'сторона', type: 'select', variables: [
        { key: "front", value: "Лицьова" },
        { key: "back", value: "Тильна" }
      ], visible: true})
    this.cutout.setFormField({
      name: 'depth', value: this.depth, type: 'number', label: 'глибина'
    })

  }

  get paramsSorting() {
    const sortingArr = ['side', 'y', 'x', 'depth', 'comment']

    return this.cutout.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get cutOut() {
    return {
      color: this.cutout.getMeshColor(),
      height: this.height,
      width: this.width,
      elements: this.elements,
      x: this.xCalc,
      y: this.yCalc,
      type: 'cutout',
      subType: this.cutout.subType,
      edge: this.cutout.edge,
      depth: this.depth,
      id: this.cutout.id,
      fullDepth: this.cutout.fullDepth,
      side: this.side
    }
  }

  get realData() {
    return {
      height: this.height,
      width: this.width,
      elements: this.elements,
      type: 'cutout',
      x: this.xCalc,
      y: this.yCalc,
      edge: this.cutout.edge,
      depth: this.depth,
      comment: this.cutout.comment,
      id: this.cutout.id,
      fullDepth: this.cutout.fullDepth,
      side: this.side,
      dataForConstructor: this.cutout.dataForConstructor
    }
  }

  addSizesArrows() {
    if(!this.cutout.isActive) return false;
    const pos = "left_bottom"
    return SizeArrow.getElementSizeArrow(
      this.xCalc,
      this.yCalc,
      null,
      pos,
      this.cutout.detail
    )
  }

  /*Getters and Setters*/
  get x(){
    return this._x;
  }

  set x (x){
      if(!this.cutout.checkRequiredField('x', Number(x))) {
        this._x = Math.abs(Number(x));
        this.cutout.setFormField({name: 'x', value: this.x})
      } else {
        this.cutout.showErrors();
      }
    // this.initRequiredFields()
    this.initRequiredFields()
    this.cutout.renderDetail();

  }

  get y(){
    return this._y;
  }

  set y(y){
      if(!this.cutout.checkRequiredField('y', Number(y))) {
        this._y = Math.abs(Number(y));
        this.cutout.setFormField({name: 'y', value: this.y})
      } else {
        this.cutout.showErrors();
      }
    this.initRequiredFields()
    this.cutout.renderDetail()

    // this.initRequiredFields()
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get xCalc() {
    if (this.x_axis === "right") {
      return this.cutout.detail.l - this.x - this.width
    } else {
      return this.x
    }
  }

  get yCalc() {
    if (this.y_axis === "top") {
      return this.cutout.detail.h - this.y - this.height;
    } else {
      return this.y;
    }
  }

  get elements() {
    return this._elements;
  }

  set elements(value) {
    this._elements = value;
  }

  get side() {
    return this._side;
  }

  set side(side) {
    this._side = side;
    this.cutout.setFormField({name: 'side', value: this.side})
    this.cutout.renderDetail()
  }

  get height() {
    return this._height;
  }

  set height(value) {
    this._height = value;
  }

  get edgeSide() {
    return this._edgeSide;
  }

  set edgeSide(edgeSide) {
    this._edgeSide = edgeSide;
  }

  get depth() {
    return this._depth;
  }

  set depth(depth) {
    this._depth = Math.abs(Number(depth))
    this.cutout.setFormField({name: 'depth', value: this.depth})
    this.cutout.initRequiredFields()
    this.cutout.renderDetail()
  }

  get width() {
    return this._width;
  }

  set width(value) {
    this._width = value;
  }

  get y_axis() {
    return this._y_axis;
  }

  get x_axis() {
    return this._x_axis;
  }

  set x_axis(x_axis) {
    this._x_axis = x_axis;
    this.cutout.setFormField({name: 'x_axis', value: this.x_axis})
    this.cutout.renderDetail()
  }

  set y_axis(y_axis) {
    this._y_axis = y_axis;
    this.cutout.setFormField({name: 'y_axis', value: this.y_axis})
    this.cutout.renderDetail()
  }

  /*Features*/
  rotateDetail(direction, l, h) {
    let x, y, height, x_axis, y_axis, width, cx, cy;
    [x, y] = [this.y, this.x];
    [height, width] = [this.width, this.height];
    if (direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
    }
    // // console.log(this.x_axis, this.y_axis, this.y, this.x, x_axis,y_axis, y, x)
    this._x_axis = x_axis;
    this._y_axis = y_axis;

    [this._x, this._y] = [x, y];

    this.elements = Helpers.rotateElementsArr(this.elements, this.width, this.height, direction);
    [this.width, this.height] = [width, height];
    // return Promise.resolve()
    return this.cutout.updateDb();
  }

}