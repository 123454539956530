import {empty} from "../../helpers/helper";
import {Group} from "three";
import MultiplicityPart from "./components/MultiplicityPart";
import Helpers from "./Helpers";

export default class Multiplicity {
	detail;
	_type= 1;
	_material;
	_w;
	_parts = [];
	_cutOuts;
	_holes;
	sidePartWidth = 120;
	_mesh = null;
	shapes = [];
	edgeShapes = {};
	side = 'back';
	holes = null;
	_width = 0;
	_height = 0;
	edge = null;

	constructor(detail) {
		this.detail = detail
		if(!empty(this.detail.multiplicity) && !empty(this.detail.multiplicity.type) ) {
			this.w = detail.getMaterialThickness(detail.multiplicity.material);
			this.type = detail.multiplicity.type;
			this.edge = detail.parent.edges.find(el => el.index === detail.multiplicity.edge) ?? null;
			this.init()
		}
	}

	init() {
		this.detail.w = this.detail.getMaterialThickness(this.detail.material) + this.w;
		switch (this.type) {
			case 3:
				this.width = this.detail.l - this.sidePartWidth * 2;
				this.height = this.detail.h - this.sidePartWidth * 2
				this.parts = [{
					x1: this.getX(this.sidePartWidth), y1: this.getY(this.sidePartWidth),
					x2: this.getX(this.sidePartWidth), y2: this.getY(this.sidePartWidth + this.height),
					x3: this.getX(this.sidePartWidth + this.width), y3: this.getY(this.sidePartWidth + this.height),
					x4: this.getX(this.sidePartWidth + this.width), y4: this.getY(this.sidePartWidth)
				}]
				break;
			case 4:
				this.width = (this.detail.l - this.sidePartWidth * 3) / 2;
				this.height = this.detail.h - this.sidePartWidth * 2
				this.parts = [
					{
						x1: this.getX(this.sidePartWidth), y1: this.getY(this.sidePartWidth),
						x2: this.getX(this.sidePartWidth), y2: this.getY(this.sidePartWidth + this.height),
						x3: this.getX(this.sidePartWidth + this.width), y3: this.getY(this.sidePartWidth + this.height),
						x4: this.getX(this.sidePartWidth + this.width), y4: this.getY(this.sidePartWidth)
					},
					{
						x1: this.getX(this.sidePartWidth * 2 + this.width), y1: this.getY(this.sidePartWidth),
						x2: this.getX(this.sidePartWidth * 2 + this.width), y2: this.getY(this.sidePartWidth + this.height),
						x3: this.getX(this.sidePartWidth * 2 + this.width * 2), y3: this.getY(this.sidePartWidth + this.height),
						x4: this.getX(this.sidePartWidth * 2 + this.width * 2), y4: this.getY(this.sidePartWidth)
					}
				]
				break;
			case 5:
				this.width = this.detail.l - this.sidePartWidth * 2;
				this.height = (this.detail.h - this.sidePartWidth * 3) / 2;
				this.parts = [
					{
						x1: this.getX(this.sidePartWidth), y1: this.getY(this.sidePartWidth),
						x2: this.getX(this.sidePartWidth), y2: this.getY(this.sidePartWidth + this.height),
						x3: this.getX(this.sidePartWidth + this.width), y3: this.getY(this.sidePartWidth + this.height),
						x4: this.getX(this.sidePartWidth + this.width), y4: this.getY(this.sidePartWidth)
					},
					{
						x1: this.getX(this.sidePartWidth), y1: this.getY(this.sidePartWidth * 2 + this.height),
						x2: this.getX(this.sidePartWidth), y2: this.getY(this.sidePartWidth * 2 + this.height * 2),
						x3: this.getX(this.sidePartWidth + this.width), y3: this.getY(this.sidePartWidth * 2 + this.height * 2),
						x4: this.getX(this.sidePartWidth + this.width), y4: this.getY(this.sidePartWidth * 2 + this.height)
					}
				]
				break;
			case 6:
				this.width = this.detail.l;
				this.height = this.detail.h - this.sidePartWidth;
				this.parts = [{
					x1: this.getX(0), y1: this.getY(0),
					x2: this.getX(0), y2: this.getY(this.height),
					x3: this.getX(this.width), y3: this.getY(this.height),
					x4: this.getX(this.width), y4: this.getY(0)
				}]
				this.edgeShapes.left = [{x: 0, y: 0}, {x: 0, y: this.height}]
				this.edgeShapes.right = [{x: 0, y: 0}, {x: 0, y: this.height}]
				this.edgeShapes.bottom = [{x: 0, y: 0}, {x: this.width, y: 0}]
				break;
			case 7:
				this.width = this.detail.l - this.sidePartWidth * 2;
				this.height = this.detail.h - this.sidePartWidth;
				this.parts = [{
					x1: this.getX(this.sidePartWidth), y1: this.getY(0),
					x2: this.getX(this.sidePartWidth), y2: this.getY(this.height),
					x3: this.getX(this.sidePartWidth + this.width), y3: this.getY(this.height),
					x4: this.getX(this.sidePartWidth + this.width), y4: this.getY(0)
				}];
				this.edgeShapes.bottom = [{x: this.sidePartWidth, y: 0}, {x: this.detail.l - this.sidePartWidth, y: 0}];
				break
			case 8:
				this.width = this.detail.l;
				this.height = this.detail.h - this.sidePartWidth;
				this.parts = [{
					x1: this.getX(0), y1: this.getY(this.sidePartWidth),
					x2: this.getX(0), y2: this.getY(this.sidePartWidth + this.height),
					x3: this.getX(this.width), y3: this.getY(this.sidePartWidth + this.height),
					x4: this.getX(this.width), y4: this.getY(this.sidePartWidth)
				}]
				this.edgeShapes.left = [{x: 0, y: this.sidePartWidth}, {x: 0, y: this.height + this.sidePartWidth}]
				this.edgeShapes.right = [{x: 0, y: this.sidePartWidth}, {x: 0, y: this.height + this.sidePartWidth}]
				this.edgeShapes.top = [{x: 0, y: 0}, {x: this.width, y: 0}]
				break;
		}
	}

	getMesh() {
		const meshes = [];
		this.parts.forEach(el => {
			const part = new MultiplicityPart({
				...el,
				mainW: this.detail.w,
				cutOuts: this.cutOuts.createFaceShape(el),
				holes: this.detail.holes.filter(hole =>
					Helpers.rectanglesAreNestedLB(
						Helpers.getHoleRectangle(hole.x - this.detail.l / 2, hole.y - this.detail.h / 2, hole.diam / 2),
						el) && (hole.depth >= this.w || hole.side === 'back')
				),
				w: this.w,
				type: this.type,
				edge: this.edge,
				detailL: this.detail.l,
				detailH: this.detail.h
			})
			meshes.push(part.create())
			this.shapes.push(...part.ownShapes);
			this.holes = part.getEdgeHoles()
		})
		return meshes
	}

	getX(value) {
		return value - this.detail.l / 2;
	}

	getY(value) {
		return value - this.detail.h / 2;
	}

	get type() {
		return this._type;
	}
	set type(type) {
		this._type = Number(type);
	}

	get w() {
		return this._w;
	}
	set w(w) {
		this._w = Number(w)
	}

	get parts() {
		return this._parts;
	}

	set parts(parts) {
		this._parts = parts;
	}

	set cutOuts(cutOuts) {
		this._cutOuts = cutOuts;
	}

	get cutOuts() {
		return this._cutOuts;
	}

	set height(height) {
		this._height = Number(height)
	}

	get height() {
		return this._height;
	}

	set width(width) {
		this._width = Number(width);
	}

	get width() {
		return this._width;
	}

	getRealParts() {
		return this.parts.map(part => {
			const partNew = {};
			for(let i in part) {
				let ind = i
				if(i.indexOf('1') !== -1) {
					ind = i.replace('1', '4')
				} else if(i.indexOf('2') !== -1) {
					ind = i.replace('2', '1')
				} else if(i.indexOf('3') !== -1) {
					ind = i.replace('3', '2')
				} else if(i.indexOf('4') !== -1) {
					ind = i.replace('4', '3')
				}
				if(i.indexOf('x') !== -1) {
					partNew[ind] = part[i] + this.detail.l / 2
				} else {
					partNew[ind] = part[i] + this.detail.h / 2
				}
			}
			return partNew
		})
	}
}