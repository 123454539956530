import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import Base from "../../../model/components/Base";
import ResizeProduct from "../../../model/components/ResizeProduct";

import Panel from "../../layuot/panel";
import {empty} from "../../../../helpers/helper";
import Helper from "../../../model/3d/Helper";
import UsedDetail from "../UsedDetail";
import Connections from "../Connections";
import Modal from "../../layuot/Modal";
import {v4 as uuidv4} from "uuid";
import './style.scss'


const UsedDetails = ({productId, title}) => {
	const usedDetails = useSelector(constructor3dSelectors.getCDetails);
	const details = usedDetails.filter(el => el.productId === productId);
	const selectedObjects = useSelector(constructor3dSelectors.getSelectedObjects)
	const BaseClass = new Base();
	const ResizeProductClass = new ResizeProduct();
	const dispatch = useDispatch();
	// const selectedDetails = useSelector(constructor3dSelectors.getSelectedDetails)
	const [optionsVisible, setOptionsVisible] = useState(false)
	// const [selectedDetails, setSelectedDetails] = useState([])
	const [detailsGroups, setDetailsGroup] = useState([])
	const [sizeModal, setSizeModal] = useState(false);
	const [size, setSize] = useState(null);

	const getDetailsGroups = () => {
		const tmp_obj = {};
		details.forEach(detail => {
			if(!empty(detail.group?.group_id)) {
				if(empty(tmp_obj[detail.group.group_id])) {
					tmp_obj[detail.group.group_id] = {
						name: detail.group.name,
						group_id: detail.group.group_id,
						details: []
					};
				}
				tmp_obj[detail.group.group_id].details.push(detail);
			} else {
				if(empty(tmp_obj?.noGroup)) {
					tmp_obj.noGroup = []
				}
				tmp_obj.noGroup.push(detail)
			}
		})
		return Object.values(tmp_obj)
	}

	useEffect(() => {
		// setSelectedDetails(usedDetails.filter(detail => detail.selected))
		setDetailsGroup(getDetailsGroups())
	}, [usedDetails])

	useEffect(() => {

	}, [detailsGroups]);

	if(empty(details)) return null;

	const lockProduct = () => {
		BaseClass.lockProduct(productId);
	}

	const toggleOptions = () => {
		setOptionsVisible(!optionsVisible)
	}

	const removeFromGroup = detail => {
		detail.removeFromGroup()
	}

	const mergeDetails = (selectedDetails) => {
		const name = prompt('Назва Об’єкту');
		if(!empty(name)) {
			const group = {
				group_id: uuidv4(),
				name
			}
			selectedDetails.forEach(detail => detail.addToGroup(group))
		}
	}

	const changeProductSize = () => {
		const { size} = BaseClass.getProductSize(productId);
		setSize({
			x: Number(size.x.toFixed(2)),
			y: Number(size.y.toFixed(2)),
			z: Number(size.z.toFixed(2))});
		setSizeModal(true)
	}

	const saveProductSize = () => {
		ResizeProductClass.resizeProduct(productId, size);
	}

	const getChangeSizeControls = () => {
		return (
			<>
				<button onClick={saveProductSize}>save</button>
			</>
		)
	}

	const getEditSizeModal = () => {
		if(empty(size) || !sizeModal) return null;
		return (
      <Modal
	      title={'change-size'}
	      show={true}
	      hide={() => setSizeModal(false)}
	      controls={getChangeSizeControls()}
      >
            <div className="modal__input-group">
              <label htmlFor="size-x">X:</label>
              <input
                type="number"
                id="size-x"
                value={size.x}
                onChange={(e) => setSize({ ...size, x: Number(e.target.value) })}
              />
            </div>
            <div className="modal__input-group">
              <label htmlFor="size-y">Y:</label>
              <input
                type="number"
                id="size-y"
                value={size.y}
                onChange={(e) => setSize({ ...size, y: Number(e.target.value) })}
              />
            </div>
            <div className="modal__input-group">
              <label htmlFor="size-z">Z:</label>
              <input
                type="number"
                id="size-z"
                value={size.z}
                onChange={(e) => setSize({ ...size, z: Number(e.target.value) })}
              />
            </div>
      </Modal>
    )
	}

	const renderOptions = () => {
		if(!optionsVisible) return null;
		const optionsArray = [];
		const selectedDetails = details.filter(detail => detail.selected);
		optionsArray.push({
			type: 'action',
			handler: () => changeProductSize(),
			text: 'changeSize'
		})
		if(selectedDetails.length >= 2) {
			optionsArray.push({
				type: 'action',
				handler: () => mergeDetails(selectedDetails),
				text: 'group'
			})
		}
		return (
			<div className={'tooltip'}>
				{ optionsArray.map(el => <a onClick={el.handler}>{el.text}</a>) }
			</div>
		)
	}

	const getProductOptions = () => {
		return (
			<>
				<button className={'icon-button-burger'} onClick={toggleOptions}>...</button>
				{renderOptions()}
			</>
		)
	}


	return (
		<Panel className='used-details p__content' icon={'box'} lock={false} options={getProductOptions}  title={title}>
			{detailsGroups.map((el, i) => {
				if(!empty(el.details)) {
					return (
						<div className={'used-details__group'} key={i}>
							<p className={'used-details__group-title'}>{el.name}</p>
							{el.details.map(detail => <UsedDetail key={detail.detailCId} removeFromGroup={removeFromGroup} detailCId={detail.detailCId}  />)}
						</div>
					)
				} else {
					return el.map(detail => <UsedDetail key={detail.detailCId} detailCId={detail.detailCId}  />)
				}
			})}
			<Connections productId={productId} />
			{getEditSizeModal()}
		</Panel>
	)
}

export default UsedDetails