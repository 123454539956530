import React from "react";

const AppContainer = ({children}) => {
  return (
    <>
      {children}
    </>
  )
}

export default AppContainer