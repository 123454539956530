import React, {useEffect, useState} from "react";
import {edgeTitle, materialTitle} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import './style.scss';
import img from "../../../../../media/material-test.png";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";

const ItemHelper = ({itemHelper, edges, detail, materials, leftPoint, yPoint}) => {
	const construction = useSelector(projectSelectors.getConstruction);
	const [neededDetail, setNeededDetail] = useState(detail);

	useEffect(() => {
		const searchedDetail = construction.details.find((el) => el.id === detail.id)
		if(searchedDetail){
			setNeededDetail(searchedDetail)
		} else {
			setNeededDetail(detail)
		}
	},[detail])

	const edgeTitleForHelper = (edgeId) => {
		if (edgeId !== null) {
			const edge = edges.find((el) => el.index === edgeId)
			return edgeTitle(edge)
		}
		return Languages.getTranslation('none', true)
	}

	const materialTitleChange = () => {
		if (isNaN(neededDetail.material)) {
			return materialTitle(neededDetail.material)
		}
		const material = materials.find((el) => el.index === neededDetail.material)
		return materialTitle(material)
	}

	const detailItemHelperHandler = () => {
		if (itemHelper.current){
			itemHelper.current.className = 'itemHelper itemHelper_active'
		}
	}

	const detailItemHelperOffHandler = () => {
		if (itemHelper.current){
			itemHelper.current.className = 'itemHelper'
		}
	}

	return <div className="itemHelper" ref={itemHelper} style={{top: yPoint - 10, left: leftPoint}}
	onMouseEnter={detailItemHelperHandler}
		onMouseLeave={detailItemHelperOffHandler}
	>
		<div className="itemHelper__material-container">
			<div className="itemHelper__material-header">
				<div className="itemHelper__text itemHelper__material-title" >{Languages.getTranslation('material', true)}:</div>
				<span className="itemHelper__material-title itemHelper__text ">№{detail.material.index + 1 || detail.material + 1}</span>
			</div>
			<div className="itemHelper__material-name">
				<img src={img} alt="material"/>
				<span className="itemHelper__text2">{`Матеріал: ${materialTitleChange()}`}</span>
			</div>
		</div>
		<div className="itemHelper__edges">
			<div className="itemHelper__edge">
				<div className="itemHelper__edge-container">
					<span className="itemHelper__edge-container-img">
						<i className=" icon__triangle-up"></i>
					</span>
				{/*<span className="itemHelper__text">Крайка знизу:</span>*/}
				<span className="itemHelper__text2">{edgeTitleForHelper(neededDetail.edges.top)}</span>
				</div>
			</div>
			<div className="itemHelper__edge">
				<div className="itemHelper__edge-container">
					<span className="itemHelper__edge-container-img">
						<i className="icon__triangle-down"></i>
					</span>
				{/*<span className="itemHelper__text">Крайка зверху:</span>*/}
				<span className="itemHelper__text2">{edgeTitleForHelper(neededDetail.edges.bottom)}</span>
				</div>
			</div>
			<div className="itemHelper__edge">
				<div className="itemHelper__edge-container">
					<span className="itemHelper__edge-container-img">
						<i className="icon__triangle-left"></i>
					</span>
				{/*<span className="itemHelper__text">Крайка справа:</span>*/}
				<span className="itemHelper__text2">{edgeTitleForHelper(neededDetail.edges.left)}</span>
				</div>
			</div>
			<div className="itemHelper__edge">
				<div className="itemHelper__edge-container">
					<span className="itemHelper__edge-container-img">
						<i className="icon__triangle-right"></i>
					</span>
				{/*<span className="itemHelper__text">Крайка зліва:</span>*/}
				<span className="itemHelper__text2">{edgeTitleForHelper(neededDetail.edges.right)}</span>
				</div>
			</div>
		</div>
	</div>
}

export default ItemHelper