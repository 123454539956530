import Helpers from "../3D/Helpers";
import Languages from "../../translation/Languages";
import {empty} from "../../helpers/helper";

export default class TableTop {
	_elements;
	parent;
	_detailH;
	_detailL;
	constructor({
		            parent,
		            elements,
		            detailL,
		            detailH,
	            }) {
		this.parent = parent;
		this._detailH = detailH
		this._detailL = detailL
		this._elements = elements;

		this.addParams();
		this.initRequiredFields();
	}
	initRequiredFields() {
		this.parent.requiredFields = {

		}
	}
	addParams() {
		const material = this.parent.detail.parent.materials.find(el => el.index === this.parent.detail.material)
		this.parent.setFormField({ name: 'angle', value: this.parent.angle, visible: false })
		if(material.double_rounding) {
		this.parent.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('tip', true), value: this.parent.edgeSide, visible: true, variables: [
				{ key: "left", value: Languages.getTranslation('left1', true) },
				{ key: "top", value: Languages.getTranslation('top1', true) },
				{ key: "right", value: Languages.getTranslation('right1', true) },
				{ key: "bottom", value: Languages.getTranslation('bottom1', true) }
			]})
		} else {
			this.parent.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('tip', true), value: this.parent.edgeSide, visible: true, variables: [
					{ key: "left", value: Languages.getTranslation('left1', true) },
					{ key: "top", value: Languages.getTranslation('top1', true) },
					{ key: "right", value: Languages.getTranslation('right1', true) },
				]})
		}
		this.parent.setFormField({name: 'width', visible: false, value: this.parent.width, label: Languages.getTranslation('height', true), type: 'number'})

		this.parent.setFormField({name: 'additionalSide', label: `${Languages.getTranslation('side-to-exist', true)} х`, visible: false, type: 'select', variables: [
				{ key: "left", value: Languages.getTranslation('left1', true) },
				{ key: "right", value: Languages.getTranslation('right1', true) },
			], value: this.parent.additionalSide })
	}

	get paramsSorting() {
		const sortingArr = ['type', 'edgeSide', 'width', 'additionalSide', 'comment']

		return 	this.parent.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	generateElements () {
		const elements = []
		const width = this.parent.width
		switch (this.parent.edgeSide) {
			case 'left':
				elements.push(Helpers.createLine(0, 0, 0, this.parent.detail.h - 36.6, null, this.getActiveElement()))
				elements.push(Helpers.createArc(0, this.parent.detail.h - 36.6, 11.7, this.parent.detail.h - 8.3, 40, 40, this.parent.detail.h - 36.6, true, null, this.getActiveElement()))
				elements.push(Helpers.createLine(11.7, this.parent.detail.h - 8.3, 20, this.parent.detail.h, null, this.getActiveElement()))
				break
			case 'right':
				elements.push(Helpers.createLine(this.parent.detail.l - 20, this.parent.detail.h, this.parent.detail.l - 11.7, this.parent.detail.h - 8.3, null, this.getActiveElement()))
				elements.push(Helpers.createArc( this.parent.detail.l - 11.7, this.parent.detail.h - 8.3, this.parent.detail.l, this.parent.detail.h - 36.6,40, this.parent.detail.l - 40, this.parent.detail.h - 36.6,true, null, this.getActiveElement()))
				elements.push(Helpers.createLine(this.parent.detail.l, this.parent.detail.h - 36.6, this.parent.detail.l, 0, null, this.getActiveElement()))
				break
			case 'top':
				if (this.parent.additionalSide === 'left') {
					elements.push(Helpers.createLine(0, this.parent.detail.h - 20, width - 36.6, this.parent.detail.h - 20, null, this.getActiveElement()))
					elements.push(Helpers.createArc( width - 36.6, this.parent.detail.h - 20, width - 8.3, this.parent.detail.h - 8.3,40, width - 36.6, this.parent.detail.h + 20, false, null, this.getActiveElement()))
					elements.push(Helpers.createLine(width - 8.3, this.parent.detail.h - 8.3, width, this.parent.detail.h, null, this.getActiveElement()))
				} else {
					elements.push(Helpers.createLine(this.parent.detail.l - width, this.parent.detail.h, this.parent.detail.l - width + 8.3, this.parent.detail.h - 8.3, null, this.getActiveElement()))
					elements.push(Helpers.createArc( this.parent.detail.l - width + 8.3, this.parent.detail.h - 8.3, this.parent.detail.l - width + 36.6, this.parent.detail.h - 20,40, this.parent.detail.l - width + 36.6, this.parent.detail.h + 20, false, null, this.getActiveElement()))
					elements.push(Helpers.createLine( this.parent.detail.l - width + 36.6, this.parent.detail.h - 20, this.parent.detail.l, this.parent.detail.h - 20, null, this.getActiveElement()))
				}
				break
			case 'bottom':
				if (this.parent.additionalSide === 'left') {
					elements.push(Helpers.createLine(width, 0,  width - 8.3, 8.3, null, this.getActiveElement()))
					elements.push(Helpers.createArc( width - 8.3, 8.3, width - 36.6, 20,40, width - 36.6, -20, false, null, this.getActiveElement()))
					elements.push(Helpers.createLine(width - 36.6, 20,  0, 20, null, this.getActiveElement()))

				} else {
					elements.push(Helpers.createLine(this.parent.detail.l, 20,  this.parent.detail.l - width + 36.6, 20, null, this.getActiveElement()))
					elements.push(Helpers.createArc( this.parent.detail.l - width + 36.6, 20, this.parent.detail.l - width + 8.3, 8.3,40, this.parent.detail.l - width + 36.6, -20, false, null, this.getActiveElement()))
					elements.push(Helpers.createLine(this.parent.detail.l - width + 8.3, 8.3,  this.parent.detail.l - width, 0, null, this.getActiveElement()))
				}
				break
		}
		return elements;
	}

	get elements() {
		return this.generateElements()
	}

	// set elements(element) {
	// 	this._elements = element
	// }

	get cutOut() {
		// this.generateElements()
		return {
			side: 'front',
			type: 'tableTop',
			subType: 'tableProc',
			depth: this.parent.detail.w,
			place: this.parent.edgeSide,
			width: this.parent.width,
			color: this.parent.isActive ? Helpers.colorForActive : null,
			x_axis: this.parent.additionalSide,
			elements: this.elements,
		};
	}

	get realData() {
		this.checkSideForWidth()
		return {
			place: this.parent.edgeSide,
			additionalSide: this.parent.additionalSide,
			width: this.parent.width,
			angle: null
		}
	}

	checkSideForWidth() {
		if (['left', 'right'].includes(this.parent.edgeSide)) {
			this.parent.width = this.parent.detail.h
		}
	}

	getActiveElement() {
		return this.parent.isActive ? Helpers.colorForActive : null
	}

	addSizesArrows() {
		return
	}

	get detailH() {
		return this._detailH;
	}

	get detailL() {
		return this._detailL;
	}

	validate() {
		// if (this.parent.width < 219) {
		// 	this.parent.detail.error.setError(`${Languages.getTranslation('change-width', true)}`, 'error', true);
		// 	return Promise.reject('change-width')
		// }
		return Promise.resolve()
	}
}