import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import ahtung from '../../../../media/ahtuung.png'

const AhtungModal = ({setShowSubmitModal, setSelectedValue, selectType, submitModalText, setDeleteDetail}) => {

    const submit = () => {
        setSelectedValue(selectType)
        setShowSubmitModal(false)
    }

    const close = ()=>{

        if (setDeleteDetail){
            setDeleteDetail(null)
        }

        setShowSubmitModal(false)
    }

    return <div className='modal'>
        <div className='ahtungModal'>
            <img src={ahtung} alt="ahtung"/>
            <div className="ahtungModal__body">
                <div className="ahtungModal__body-decription">
                    <b className="ahtungModal__body-decription-title">
                        {Languages.getTranslation('warning', true)}</b>
                    <div className="ahtungModal__body-decription-body">
                        <span>{Languages.getTranslation('ahtungModal_text', true)}</span>
                        <div className="ahtungModal__body-decription-body_text">{Languages.getTranslation('ahtungModal_text_second', true)}</div>

                        <ul className='ahtungModal__list'>
                            <li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-one', true)}</li>
                            <li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-two', true)}</li>
                            <li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-three', true)}</li>
                            <li className='ahtungModal__list-item'>{Languages.getTranslation('ahtung-text-four', true)}</li>
                        </ul>
                        {submitModalText}
                        <br/>
                        <div className='mt-5'>
                            <b className='textPurpl'>{Languages.getTranslation('Substitution-of-material-in-the-project', true)}</b>
                            {Languages.getTranslation('and', false)}
                            <b className='textPurpl'>{Languages.getTranslation('Replacing-the-edge-in-the-project', true)}</b>
                            <br/>
                            {Languages.getTranslation('designer-Kronas-Master-3D', true)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="ahtungModal__actions">
                <DefaultBtn
                    title={Languages.getTranslation("cancel", true)}
                    handler={close}
                />
                <DefaultBtn
                    title={Languages.getTranslation("submit", true)}
                    handler={submit}
                />

            </div>
        </div>
    </div>

}

export default AhtungModal