import React, { useEffect, useState } from "react";
import './style.scss';

const Panel = ({children, className, icon = 'none', lock, title, options}) => {
	const [panelOpen, setPanelOpen] = useState(true);



	return (
		<div className={`panel block panel-${className}`}>
			<header className={`panel__header block__header icon-before-${icon}`}>
				<span className={'block__header-title'}>{title}</span>
				{lock ? <a className='lock' onClick={lock}>lock</a>: null }
				{options ? options() : null}
				<a className={`block__header-control icon-button-arrow-ver ${panelOpen ? 'hide' : 'show'}`} onClick={e => setPanelOpen(!panelOpen)}>{panelOpen ? '-' : '+'}</a>
			</header>
			<div className={`panel__body block__body ${className}${panelOpen ? ' open' : ' hidden'}`}>
				{children}
			</div>
			<div className={`panel__footer`}>

			</div>
		</div>
	)
}

export default Panel