import Db from "../api/Db";
import {empty} from "../helpers/helper";
import history from "../constructor/view/components/History";

export default class _Constructor extends Db {

	addDetail(detail) {
		return this.addItem('cDetails', detail)
	}

	deleteDetail(detailCId) {
		return this.removeItem('cDetails', detailCId)
	}

	getDetails() {
		return this.getAllData('cDetails')
	}

	updateDetail(detail, type) {
		return this.updateByKey('cDetails', detail.detailCId, type, detail[type])
	}

	addHistoryItem(item) {
		return this.addItem('cHistory', item)
	}

	setHistory(history) {
		// return this.setData('cHistory
	}

	getHistory() {
		return this.getAllData('cHistory')
	}

	deleteHistoryItem(id) {
		return this.removeItem('cHistory', id)
	}

	addConnection(connection) {
		return this.addItem('cConnections', connection)
	}

	removeConnection(connectionId) {
		return this.removeItem('cConnections', connectionId)
	}

	updateConnection(connection) {
		return this.updateItem('cConnections', connection )
	}

	getConnections() {
		return this.getAllData('cConnections')
	}

}