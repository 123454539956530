import {toast} from "react-toastify";

export const toastError = (text) => {
  toast.error(text, {
    position: "bottom-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    toastId: text,
  })
}

export const toastWarning = (text) => {
  toast.warn(text, {
    position: "top-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    toastId: text,
  })
}

export const toastSuccess = (text) => {
  toast.success(text, {
    position: "bottom-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    toastId: text,
  })
}