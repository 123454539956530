import {empty, isNumber} from "../helpers/helper";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {v4 as uuidv4} from "uuid";
import Languages from "../translation/Languages";
import store from "redux/store";

export default class Corner extends Processing {
  _angle;
  _type;
  _edge;
  _subType = 'corner';
  _isActive = false;
  _isErrorText = "";
  contourId

  constructor({
    dataForConstructor = {},
    angle = null,
    type = "",
    x = 10,
    y = 10,
    edge = null,
    detail,
    r = 10,
    id,
    comment = "",
    isErrorText = '',
    additionalInfo = '',
    isInit = false,
    contourId = null,
                isTemplate = null
  }) {
    super({x, y, r, comment, detail, id, dataForConstructor,isErrorText, additionalInfo, isInit, isTemplate})
    this._angle = angle || this.getAnglesValue()[0]?.key;
    this._type = type;
    this.contourId = contourId
    this._edge = this.detail.parent.edges.find(_edge => _edge.index === edge);
    this._isErrorText = isErrorText;
    this._dataForConstructor = dataForConstructor
    this.addParams();
    this.initRequiredFields();

  }

  initRequiredFields () {
    this.requiredFields = {
      r: {
        max: Math.min(this.detail.l, this.detail.h),
        min: this.type === 'radius' ? 3 : 0
      },
      x: {
        max: this.detail.l,
        min: this.type === 'line' ? 1 : 0
      },
      y: {
        max: this.detail.h,
        min: this.type === 'line' ? 1 : 0
      },
      type: true
    }
  }

  addParams() {
    const { materials } = store.getState().project.project.construction.payload.construction;
    const detailMaterial = materials.find(el => el.index === this.detail.material)
    let types = [
      { key: "", value: Languages.getTranslation('Not-backwards', true)},
      { key: "radius", value: Languages.getTranslation('radius', true) },
      { key: "line", value: Languages.getTranslation('slice', true) },
    ]
    if(detailMaterial.double_rounding && !this.detail.soft.top && !this.detail.soft.bottom){
      types = [
        { key: "", value:  Languages.getTranslation('Not-backwards', true) },
        { key: "line", value: Languages.getTranslation('slice', true) },
      ]
    }
    let f_value = ''
    this.setFormField({name:'angle', value: this.angle, label: Languages.getTranslation('corner', true), type: 'select', variables:
          this.getAnglesValue(this.type),
     visible: true})
    this.setFormField({name: 'type', value: this.type, label: Languages.getTranslation('type-of-proces', true), type: 'select',variables: types})

    f_value = this._dataForConstructor.hasOwnProperty('f_x') ? this._dataForConstructor['f_x'] : '';
    this.setFormField({name: 'x', label: 'x (px)', value: this.x, type: 'number', visible: this.type === "line", additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_y') ? this._dataForConstructor['f_y'] : '';
    this.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)', visible: this.type === "line", additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_r') ? this._dataForConstructor['f_r'] : '';
    this.setFormField({name: 'r', value: this.r, type: 'number', label: `${Languages.getTranslation('radius', true)} (pr)`, visible: this.type === "radius", additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value} ]})
  }

  get x(){
    return this._x
  }

  set x(x){
    if(typeof x === 'number'){
      if(!this.checkRequiredField('x', x)) {
        this._x = x;
        this.setFormField({name: 'x', value: this.x, type: 'number', visible: this.type === "line", additionalParam : [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''} ]});
        this.updateDataForConstructor({name: 'f_x', value: ''})
        this.updateDataForConstructor({name: 'x', value: this.x})
        this.renderDetail()
      } else {
        this.showErrors();
      }
    } else {
      if(!empty(x)) {
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('px')
            && x.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(x))) {
          // const calculation = Number(Number(this.formulaCalculation(x)).toFixed(1));
          // // if (calculation >= 0) {
          const _x = Number(Number(this.formulaCalculation(x)).toFixed(1));
          if(!this.checkRequiredField('x', _x)) {
            this._x = _x;
            this.setFormField({
              name: 'x', value: this.x, type: 'number', visible: this.type === "line", additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: x}]
            });
            this.updateDataForConstructor({name: 'f_x', value: x})
            this.renderDetail()
          } else {
            this.showToastError('cannot-be-negative')
          }
        }
      }else {
        this.updateDataForConstructor({name: 'f_x', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('px')){
      this.y = this._dataForConstructor['f_y']
    }
  }

  get y(){
    return this._y
  }

  set y(y){
    if(typeof y === 'number'){
      if(!this.checkRequiredField('y', y)) {
        this._y = y;
        this.setFormField({
          name: 'y', value: this.y, type: 'number', visible: this.type === "line", additionalParam: [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''}]
        });
        this.updateDataForConstructor({name: 'f_y', value: ''})
        this.updateDataForConstructor({name: 'y', value: this.y})
        this.renderDetail();
      } else {
        this.showErrors();
      }
    } else {
      if(!empty(y)){
        if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('py')
            && y.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(y))) {
          const _y = Number(Number(this.formulaCalculation(y)).toFixed(1));
          if(!this.checkRequiredField('y', _y)) {
            this._y = _y;
            this.setFormField({
              name: 'y', value: this.y, type: 'number', visible: this.type === "line", additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: y}]
            });
            this.updateDataForConstructor({name: 'f_y', value: y})
            this.renderDetail();
          } else {
            this.showErrors()
          }
        }
      }else {
        this.updateDataForConstructor({name: 'f_y', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('py')){
      this.x = this._dataForConstructor['f_x']
    }
  }

  get r(){
    return this._r
  }

  set r (r){
    if(typeof r === 'number'){
      if(!this.checkRequiredField('r', r)) {
        this._r = r;
        this.setFormField({
          name: 'r', value: this.r, type: 'number', visible: this.type === "radius", additionalParam: [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''}]
        })
        this.updateDataForConstructor({name: 'f_r', value: ''})
        this.renderDetail()
      } else {
        this.showErrors()
      }
    } else {
      if(!empty(r)){
        const _r = Number(Number(this.formulaCalculation(r)).toFixed(1));
        if(!this.checkRequiredField('r', _r)) {
          this._r = _r;
          this.setFormField({ name: 'r', value: this.r, type: 'number', visible: this.type === "radius", additionalParam: [
              {name: 'formula', label: Languages.getTranslation('formula', true), value: r}]
          })
          this.updateDataForConstructor({name: 'f_r', value: r})
          this.renderDetail()
        }else{
          this.showErrors()
        }
      }else{
        this.updateDataForConstructor({name: 'f_r', value: ''})
      }
    }
    this.fixMultiplicity();
    this.renderDetail();
  }

  get paramsSorting() {
    const sortingArr = ['type', 'angle', 'r', 'x', 'y', 'comment']

    return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get dataForValidate() {
    return {
      id: this.id,
      angle: this.angle,
      x: this.x < 0 ? 0 : this.x,
      y: this.y < 0 ? 0 : this.y,
      r: this.r,
      type: this.type,
      edge: empty(this.edge) ? null : this.getEdgeArrayIndex(this.edge.index),
      subtype: "corner",
    };
  }

  get isErrorText() {
    return this._isErrorText;
  }

  set isErrorText(isErrorText) {
    this._isErrorText = isErrorText;
  }

  get subtype(){
    return this._subType
  }

  get objectEdge() {
    if (this.edge)
      return (
        this.detail.parent.edges.find(edge => edge.index === this.edge.index) ||
        null
      );
    return null;
  }

  addSizesArrows() {
    if(!this.isActive) return false;
    let x = this.x;
    let y = this.y;
    let radius = null;
    if(this.type === "radius") {
      x = this.r;
      y = this.r;
      let xc, yc;
      switch (this.angle) {
        case 'left_bottom':
          xc = this.r;
          yc = this.r;
          break
        case 'left_top':
          xc = this.r;
          yc = this.detail.h - this.r;
          break
        case 'right_top':
          xc = this.detail.l - this.r;
          yc = this.detail.h - this.r;
          break
        case 'right_bottom':
          xc = this.detail.l - this.r;
          yc = this.r;
          break
      }
      radius = {xc, yc, r: this.r};
    }
    return SizeArrow.getElementSizeArrow(
      x,
      y,
      radius,
      this.angle,
      this.detail,
      true
    )
  }

  updateDb(remove=false) {
    const dbProcessingName = this.getProcessingDbIndex(this.subType);
    let data = {};
    if(remove) {
      data.id = this.id;
      data.isDelete = true;
    } else {
      data = {...this.realData, dataForConstructor: this.getDataForConstructor()}
    }

    return this.detail.updateProcessing(dbProcessingName, data)
      .then(() => {
        if(this.type === 'radius') {
          this.detail.edges[this.angle.split('_')[0]] = !remove ? this.edgeIndex : null;
          this.detail.edges[this.angle.split('_')[1]] = !remove ? this.edgeIndex : null;
        } else{
          const angleArr = this.angle?.split('_');
          let detailEdges = {
            ...this.detail.edges
          }
          if(!empty(angleArr)){
            angleArr.forEach(side => {
              if(['left', 'right'].includes(side) && this.y === this.detail.h){
                detailEdges[side] = this.edgeIndex
              }
              if(['top', 'bottom'].includes(side) && this.x === this.detail.l){

                detailEdges[side] = this.edgeIndex
              }
            })
          }
          this.detail.edges = {...detailEdges}
        }
      })
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
    if(this.type === 'radius') {
      if (this.detail.isPostForming && ['left_top', 'right_top'].includes(this.angle)) this.angle = 'left_bottom'
      this.x = 10;
      this.y = 10;
      const anglesValue = this.getAnglesValue(this.type);
      // this.angle = anglesValue[0].key
      this.setFormField({name:'angle', value: this.angle, variables: anglesValue});
      this.setFormField({name: 'x',value: this.x, visible: false})
      this.setFormField({name: 'y',value: this.y, visible: false})
      this.setFormField({name: 'r',value: this.r, visible: true})
    } else if (this.type === 'line') {
      const anglesValue = this.getAnglesValue();
      // this.angle = anglesValue[0].key
      this.r = 10;
      this.setFormField({name:'angle', value: this.angle, variables: anglesValue});
      this.setFormField({name: 'x', value: this.x, visible: true})
      this.setFormField({name: 'y', value: this.y, visible: true})
      this.setFormField({name: 'r', value: this.r, visible: false})
    } else {
      this.r = 10;
      this.x = 10;
      this.y = 10;
      this.setFormField({name: 'x', value: this.x, visible: false})
      this.setFormField({name: 'y', value: this.y, visible: false})
      this.setFormField({name: 'r', value: this.r, visible: false})
    }
    this.initRequiredFields()
    this.updateDataForConstructor({name: 'type', value: this.type})
    this.setFormField({name: 'type', value: this.type})
    this.renderDetail()
  }

  // validate() {
  //   if(empty(this.type)) {
  //     this.detail.error.setError(`${Languages.getTranslation('Add-type', true)}`, 'error', true);
  //     return Promise.reject('Add-type')
  //   } else if(this.type === "radius"){
  //     if(this.r < 10){
  //       this.detail.error.setError(`${Languages.getTranslation('radius-cannot-be', true)} ${this.r}`, 'error', true);
  //       return Promise.reject('radius-cannot-be-0')
  //     }
  //     else if(this.r > this.detail.h || this.r > this.detail.l){
  //       this.detail.error.setError(`${Languages.getTranslation('radius-cannot-be-greater-than-width-or-height', true)}`, 'error', true);
  //       return Promise.reject('radius-cannot-be-greater-than-width-or-height')
  //     }
  //   } else if(this.type === "line") {
  //     if (this.x < 10) {
  //       this.detail.error.setError(`${Languages.getTranslation('x-cannot-be', true)} ${this.x}`, 'error', true);
  //       return Promise.reject('x-cannot-be')
  //     }
  //     if (this.y < 10) {
  //       this.detail.error.setError(`${Languages.getTranslation('y-cannot-be', true)} ${this.y}`, 'error', true);
  //       return Promise.reject('y-cannot-be')
  //     }
  //   }
  //   return Promise.resolve()
  // }

  createCopy() {
    const processing = {...this.realData, dataForConstructor: {...this.getDataForConstructor()}};
    processing.id = this.genId();
    const createdProcessing = this.detail[this.getProcessingClass(this.subType)](processing);
    createdProcessing.updateDb();
    this.setFormField({name:'angle', values: this.angle, variables:
          this.getAnglesValue()})
    return Promise.resolve(createdProcessing)
  }

  get angle() {
    return this._angle;
  }

  set angle(angle) {
    this._angle = angle;
    this.setFormField({name:'angle', value: angle})
    // this.updateDataForConstructor({name: 'angle', value: angle})
    this.renderDetail()
  }

  // clone() {
  //   return new Corner({...this.data, parent: this.parent});
  // }

  get edge() {
    if(!empty(this._edge)) {
      if(isNumber(this._edge)) {
        return this.detail.parent.edges.find(el => el.index === this._edge)
      } else {
        return this._edge;
      }
    }
    return null;

  }

  set edge(edge) {
    // if(isNumber(edge)) {
    //   this._edge = this.detail.parent.edges.find(el => el.index === edge)
    // } else {
    // }
    this._edge = edge;
    this.renderDetail();
  }

  get realData() {
    return {
      id: this.id,
      angle: this.angle,
      x: this.x,
      y: this.y,
      type: this.type,
      r: this.r,
      edge: this.edgeIndex,
      contourId: this.contourId,
      comment: this.comment,
      isTemplate: this.isTemplate
    };
  }

  rotateDetail(direction) {
    let x, y, angle;
    const angles = ['left_bottom', 'left_top', 'right_top', 'right_bottom']
    const currAngleIndex = angles.findIndex(el => el === this.angle)
    if(direction) {
      angle = currAngleIndex === 3 ? angles[0] : angles[currAngleIndex + 1]
    } else {
      angle = currAngleIndex === 0 ? angles[3] : angles[currAngleIndex - 1]
    }

    [x , y] = [this.y, this.x];

    this.x = x;
    this.y = y;
    this.angle = angle;
    return this.updateDb();
  }
}
