import React, { useEffect, useState } from "react";
import './style.scss';
import {div} from "three/examples/jsm/nodes/math/OperatorNode";

export default function Modal({children, title, controls, show, hide}) {

	const toggle = () => {
		hide()
	}

	if(!show) return null;

	return (
      <div className="modal">
	      <div className="modal__content">
		      <div className="modal__title">
			      {title}
			      <a className={'close-button'} onClick={toggle}>×</a>
		      </div>
		      <div className="modal__main">
			      {children}
		      </div>
		      <div className="modal__footer">
			      {controls}
		      </div>
				</div>
      </div>
  );
}