import "./style.scss";


const ToggleSwitch = ({ labels, selectedLabel, onSelect }) => {
    const handleToggle = () => {
        const nextLabel = selectedLabel === labels[0] ? labels[1] : labels[0];
        onSelect(nextLabel);
      };
    
      return (
        <div className="toggle">
          {labels.map((label) => (
            <div
              key={label}
              className={`toggle__label ${label === selectedLabel ? 'selected' : ''}`}
              onClick={handleToggle}
            >
              {label}
            </div>
          ))}
        </div>
      );
    }
     

export default ToggleSwitch;
