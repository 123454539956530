import { createAction } from "@reduxjs/toolkit";
import actionType from "./actionType";

const ConstructionProject = createAction(actionType.Construction);
const setDetail = createAction(actionType.Detail);
const setIsArrowMaterial = createAction(actionType.IsArrowMaterial);
const setIsArrowEdge = createAction(actionType.IsArrowEdge);
const setTreatment = createAction(actionType.Treatment);
const setsceneModel = createAction(actionType.sceneModel);
const errorProject = createAction(actionType.ErrorProject);

export default {
  ConstructionProject,
  setDetail,
  setsceneModel,
  setTreatment,
  errorProject,
  setIsArrowMaterial,
  setIsArrowEdge,
};
