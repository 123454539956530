import './style.scss';
import PrivateTemplate from "../../views/PrivateTemplate";
import DefaultBtn from "../../components/UI/Buttons/DefaultBtn";
import Languages from "../../translation/Languages";
import ImportBlockModel from "../../components/UI/ImportBlockModel";
import AhtungModal from "../../components/UI/Modal/AhtungModal";
import React, {useEffect, useId, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import {projectSelectors} from "redux/project";
import Db from "../../api/Db";
import {panelOperations} from "redux/panel";
import {useNavigate} from "react-router-dom";
import {empty} from "../../helpers/helper";
import Helpers from "../../models/3D/Helpers";
import {v4 as uuidv4} from "uuid";
import Loader from "../../components/UI/Loader";
import axios from "axios";
import API from "../../api/API";
import {tempDetailOperations} from "redux/tempDetail";
import AddDetailsModal from "../../components/UI/Modal/AddDetailsModal";
import AddDetailsErrorModal from "../../components/UI/Modal/AddDetailsErrorModal";
import {toast} from "react-toastify";
import AhtungModalVersionVersion from "../../components/UI/Modal/AhtungModalVersion";
import AhtungModalVersion from "../../components/UI/Modal/AhtungModalVersion";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import {userSelectors} from "redux/user";

const db = new Db();

const ImportProject = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const construction = useSelector(projectSelectors.getConstruction);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [showAddDetails, setShowAddDetails] = useState(false)
    const [showAddDetailsError, setShowAddDetailsError] = useState({open: false, list: []})
    const [bazisVersion, setBazisVersion] = useState({min: 0, last: 0, isShow: false, currentVersion: 0, date: ''})
    const user = useSelector(userSelectors.User);

    const [loader, setLoader] = useState(false);
    const inputRef = useRef()
    const inputRef2 = useRef()
    const inputRef3 = useRef()
    useUpdateTranslations();

    const FORMATS_BASIS = '.kronas, .project, .json, .xml';
    const FORMATS_KRONAS_OLD = '.kronas, .project, .json, .xml';
    const FORMATS_GIBLAB = '.kronas, .project, .json, .xml';
    const FORMATS_EXCEL = '.xlsx, .xls'
    const FORMATS_PRO100 = '.txt'

    const getVersion = () => {
        // bazis
        axios.post(`${process.env.PROJECT_API}/converter/version`, {name: 'bazis'},
            {
                headers: {
                    'Authorization': `Bearer ${API.getToken()}`,
                },
            }
        ).then(data => {
            setBazisVersion(prev => ({...prev, min: data.data.min, last: data.data.max, date: data.data.updated_at}))
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        toast.dismiss()
        getVersion()

        dispatch(
            modalsOperations.switchStateForModal({
                show: false,
                type: "",
            })
        );
        dispatch(
            panelOperations.switchStateForPanel({
                show: 'none'
            })
        )
    }, [])

    useEffect(() => {
        if (selectedValue.includes("import_project")) {
            inputRef.current.click()
            // setSelectedValue('')
            return
        }
        if (selectedValue.includes('import_xlsx')) {
            inputRef2.current.click()
            setSelectedValue('')
            return
        }
        if (selectedValue.includes('import_pro100')) {
            inputRef3.current.click()
            // setSelectedValue('')
            return
        }
    }, [selectedValue])

    const changeInput = async (event) => {
        setLoader(true)
        const formData = new FormData()
        formData.append('file', event.target.files[0]);

        await axios.post(`${process.env.PROJECT_API}/excel/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${API.getToken()}`
            },
        }).then(response => {
            dispatch(tempDetailOperations.setTempProject(response.data.data))
            setShowAddDetails(true)
            setLoader(false)
        })
    }

    const uploadFileHandler = async (event) => {
    const errorDetailsImport = []
    setLoader(true)
		let isOpen = false, currentVer = 0, typeFile = event.target.files[0].type;

        const reader = new FileReader();
        const fileRead = new Promise((resolve, reject) => {
            if(typeFile === 'application/json') {
                reader.onload = (event) => {
                    try {
                        const json = JSON.parse(event.target.result);
                        if(json.hasOwnProperty('creator') && json.creator === 'bazis'){
                            if (Number(json.version) < bazisVersion.min) {
                                isOpen = true
                                currentVer = Number(json.version)
                                resolve(null);
                            }else{
                                resolve(json.version);
                            }
                        }
                        resolve();
                    } catch (error) {
                        console.error('Ошибка при преобразовании содержимого файла в JSON:', error);
                        reject(error);
                    }
                };
            }else{
                resolve();
            }
        });

		reader.readAsText(event.target.files[0]);

        fileRead
			.then(() => {
				if (isOpen){
					setBazisVersion(prev => ({
						...prev,
						currentVersion: currentVer,
						isShow: isOpen
					}))
					return Promise.reject('');
				} else {
					return Promise.resolve()
				}
			})
			.then(() => {
            const formData = new FormData()
            formData.append('file', event.target.files[0]);

            axios.post(`${process.env.CVRT_KRONAS}/api/${selectedValue.includes('import_pro100') ? 'convert-file-pro100' : 'convert-file'}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }).then(data => {
                setSelectedValue('')
                const req = data.data.data
                if (typeof req.errors === 'object') {
                    return Promise.reject(req.errors)
                }
                req.details.forEach((item, indexArr) => {
                    item.rects.filter(el => el.type === 'Groove').forEach(el =>{
                        if (!el.hasOwnProperty('additionalCutter')) {
                            el.additionalCutter = true
                        }
                    })
                    item.corners.forEach(corner => {
                        corner.contourId = !empty(corner?.quant)  ? corner.id : null
                    })
                    if (!empty(item.messages)) {
                        errorDetailsImport.push({...item, indexArr})
                    }
                })
                const dataFromCreated = {
                    ...req.constructor.created,
                    clientId: user.id
                }
                return construction.startNewProject(dataFromCreated)
                    .then(() => {
                        if (!empty(req.products)) {
                            if (typeof req.products === 'object') {
                                return Promise.all(Object.values(req.products).map(pr => construction.createProduct(pr)))
                            } else {
                                return Promise.all(req.products.map(pr => construction.createProduct(pr)))
                            }

                        } else {
                            return construction.createProduct({name: 'product'})
                        }

                    })
                    .then(() => construction.setProjectMaterials([...req.materials]))
                    .then(() => construction.setProjectEdges([...req.edges]))
                    .then(() => {
                        // const details = Helpers.prepareDetails(data.details)
                        return construction.addProjectDetails([...req.details])
                    })

                    .then(() => {
                        if (errorDetailsImport.length > 0) {
                            setLoader(false)
                            setShowAddDetailsError({open: true, list: errorDetailsImport})
                        }
                        return Promise.resolve(req)
                    })
                    .then(() => {
                        if (errorDetailsImport.length === 0) {
                            navigate('/details');
                            window.location.reload();
                        }
                        return Promise.resolve()
                    })
                    .catch(err =>
                        dispatch(
                            modalsOperations.switchStateForModal({
                                show: true,
                                type: "IMPORT_ERROR_MODAL",
                                isEdit: err
                            })
                        ));
            })
        })
			.catch(error => {
				setLoader(false)
				console.log(error)
			})
    }

    const importProject = (type) => {
        // if (empty(construction.details) && empty(construction.materials) && empty(construction.edges)) {
        // setSelectedValue('import_project')
        // return
        // }
        setSelectedType(`${type} ${uuidv4()}`)
        setShowSubmitModal(true)
    }

    const closeErrorDetailsImport = () => {
        setShowAddDetailsError({open: false, list: []})
        navigate('/details')
        window.location.reload()
    }

    return <PrivateTemplate>
        {loader ? <div className="projects" style={{height: 'calc(100vh - 80px)'}}>
            <div className='projects__center'><Loader/></div>
        </div> : <div className="importPage">
            <div className="importPage__layout">
                <div className="importPage__main">
                    <div className="importPage__block">
                        <div className="importPage__block_sub_block">
                            <h3 className="importPage__title">{Languages.getTranslation('import-basis', true)}</h3>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.9918 0.941942C9.26425 0.470035 9.94539 0.470037 10.2178 0.941944L19.1138 16.3501C19.3862 16.822 19.0456 17.4119 18.5007 17.4119H0.708916C0.164004 17.4119 -0.176564 16.822 0.0958917 16.3501L8.9918 0.941942Z"
                                    fill="#EB5757"/>
                                <path
                                    d="M8.60338 12.8389L8.45947 7.12818L10.5398 7.14312L10.3139 12.8511L8.60338 12.8389ZM8.52724 15.3926L8.53969 13.659L10.3658 13.6721L10.3533 15.4058L8.52724 15.3926Z"
                                    fill="#FCFCFC"/>
                            </svg>

                            <h3 style={{paddingLeft: '10px'}} className="importPage__title_update">{Languages.getTranslation("update-convertor").replace("{{version}}", bazisVersion.last).replace("{{date}}", bazisVersion.date)}</h3>
                        </div>
                        <div className="importPage__container">
                            <div className="importPage__info-block">
                                <DefaultBtn title={Languages.getTranslation('import-project', true)}
                                            handler={() => importProject('import_project')}/>
                                <div className="project-calc__import-project" style={{width: '55%'}}>
                                    <input type="file" id="project-calc__import"
                                           className='project-calc__import-project'
                                           accept={FORMATS_BASIS}
                                           onChange={(e) => uploadFileHandler((e))}
                                           ref={inputRef}
                                    />
                                    <div className='error_text'>{Languages.getTranslation('error-text-import', true)}
                                    </div>
                                    {/*<label htmlFor="project-calc__import">{Languages.getTranslation('import-project', true)}</label>*/}
                                </div>
                            </div>
                            <div className="importPage__info-block1">
                                <div className="importPage__info-block-link">
                                    <div className="icon__link-cotainer">
                                        <a
                                            href="https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=bazis_converter.zip&download=1"
                                            className="importPage__link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                    <span
                                        className="importPage__info-block-text">{Languages.getTranslation('first-link-import-basis')}</span>
                                </div>
                                <div className="importPage__info-block-link">
                                    <div className="icon__link-cotainer">
                                        <a
                                            href="https://cvrt.kronas.com.ua/api/v1/storage/public/download?filename=bazis_materials.zip&download=1"
                                            className="importPage__link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                    <span
                                        className="importPage__info-block-text">{Languages.getTranslation('second-link-import-basis')}</span>
                                </div>
                                <div className="importPage__icon-help-container">
                                    <div className="importPage__help-text">
                                        <p><b>{Languages.getTranslation('help-text-import-basis1')}</b></p>
                                        <p>{Languages.getTranslation('help-text-import-basis2')}</p>
                                        <p><b>{Languages.getTranslation('help-text-import-basis3')}</b></p>
                                        <p>{Languages.getTranslation('help-text-import-basis4')}</p>
                                        <p>{Languages.getTranslation('help-text-import-basis5')}</p>
                                    </div>
                                    <i className="importPage__help"></i>
                                </div>
                                <div className="importPage__icon-help-container2">
                                    <div className="importPage__help-text2">
                                        <p><b>{Languages.getTranslation('help-text-import-basis6')}</b></p>
                                        <p>{Languages.getTranslation('help-text-import-basis7')}</p>
                                        <p>{Languages.getTranslation('help-text-import-basis8')}</p>
                                    </div>
                                    <i className="importPage__help2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ImportBlockModel title={Languages.getTranslation('import-project-kronas-past', true)}
                                      text={Languages.getTranslation('text-kronas-past ', true)}
                                      formats={FORMATS_KRONAS_OLD} uploadFileHandler={uploadFileHandler}
                                      importProject={importProject} inputRef={inputRef} selectedType={selectedType}/>
                    <ImportBlockModel title={Languages.getTranslation('import-gibLab', true)}
                                      text={Languages.getTranslation('text-giblab', true)}
                                      formats={FORMATS_GIBLAB} uploadFileHandler={uploadFileHandler}
                                      importProject={importProject} inputRef={inputRef} selectedType={selectedType}/>
                    {/*<ImportBlockModel title={Languages.getTranslation('import-astra',true)} disabled={true}/>*/}
                    <div className="importPage__block">
                        <h3 className="importPage__title">{Languages.getTranslation('import-Exel', true)}</h3>
                        <div className="importPage__container">
                            <div className="importPage__info-block">
                                <DefaultBtn title={Languages.getTranslation('import-file', true)}
                                            handler={() => importProject('import_xlsx')}/>
                                <div className="project-calc__import-project">
                                    <input type="file" id="project-calc__import"
                                           className='project-calc__import-project'
                                           accept={FORMATS_EXCEL}
                                           onChange={(e) => changeInput((e))}
                                           ref={inputRef2}
                                    />
                                    {/*<label htmlFor="project-calc__import">{Languages.getTranslation('import-project', true)}</label>*/}
                                </div>
                            </div>
                            <div className="importPage__info-block1">
                                <div className="importPage__info-block-link">
                                    <div className="icon__link-cotainer">
                                        <a
                                            href={Languages.getTranslation('link-excel-xlsx', true)}
                                            className="importPage__link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                    <div className='inputExelModal__container'>
                                        <div
                                            className='inputExelModal__body-wrapper-text'>{Languages.getTranslation('download-template-xlsx', true)}</div>
                                        <a className='inputExelModal__body-wrapper_link'
                                           href={Languages.getTranslation('link-excel-xlsx', true)}
                                           download>xlsx</a>
                                    </div>
                                </div>
                                <div className="importPage__info-block-link">
                                    <div className="icon__link-cotainer">
                                        <a
                                            href={Languages.getTranslation('link-excel-xls', true)}
                                            className="importPage__link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                    <div className='inputExelModal__container'>
                                        <div
                                            className='inputExelModal__body-wrapper-text'>{Languages.getTranslation('download-template-xlsx', true)}</div>
                                        <a className='inputExelModal__body-wrapper_link'
                                           href={Languages.getTranslation('link-excel-xls', true)}
                                           download>xls</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="importPage__block">
                        <h3 className="importPage__title">{Languages.getTranslation('importPro100', true)}</h3>
                        <div className="importPage__container">
                            <div className="importPage__info-block">
                                <DefaultBtn title={Languages.getTranslation('import-project', true)}
                                            handler={() => importProject('import_pro100')}/>
                                <div className="project-calc__import-project">
                                    <input type="file" id="project-calc__import"
                                           className='project-calc__import-project'
                                           accept={FORMATS_PRO100}
                                           onChange={(e) => uploadFileHandler((e))}
                                           ref={inputRef3}
                                    />
                                </div>
                            </div>
                            <div className="importPage__info-block1">
                                <div className="importPage__info-block-link">
                                    <p
                                        className="importPage__info-block-text_pro100">{Languages.getTranslation('pro100-title', true)}</p>
                                </div>
                                <div className="importPage__icon-help-container">
                                    <div className="importPage__help-text-bottom" style={{bottom: "15px"}}>
                                        <p><b>{Languages.getTranslation('pro100-title2', true)}</b></p>
                                        <p>{Languages.getTranslation('pro100-text', true)}</p>
                                        <p><b>{Languages.getTranslation('pro100-title3', true)}</b></p>
                                        <ul>
                                            <li>{Languages.getTranslation('one-block-pro100', true)}</li>
                                            <li>{Languages.getTranslation('two-block-pro100', true)}</li>
                                        </ul>
                                    </div>
                                    <i className="importPage__help"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {showAddDetails && <AddDetailsModal close={() => {
                dispatch(tempDetailOperations.setTempProject({}))
                setShowAddDetails(false)
                navigate('/')
            }
            }/>}
            {bazisVersion.isShow && <AhtungModalVersion
                version={bazisVersion}
                close={() => setBazisVersion(prev => ({...prev, isShow: false}))}
            />}
            {showSubmitModal && <AhtungModal setShowSubmitModal={setShowSubmitModal}
                                             submitModalText={Languages.getTranslation('import-project-accept', true)}
                                             setSelectedValue={setSelectedValue} selectType={selectedType}/>}
        </div>}
        {showAddDetailsError.open && <AddDetailsErrorModal
            title={Languages.getTranslation('error-after-import', true)}
            errorDetailsImport={showAddDetailsError.list}
            closeErrorDetailsImport={closeErrorDetailsImport}/>}
    </PrivateTemplate>
}

export default ImportProject
