import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialState = {
  show: 'none',
  isEditPanel: false
};

const panel = createReducer(initialState, {
  [ACTIONS.PanelStateSwitch.type]: (state, val) => {
    return { ...state, show: val.payload.show, isEditPanel: val.payload.isEditPanel || false };
  },
});

export default combineReducers({
  panel,
});
