import Auth from "./Auth";
import Materials from "./Materials";
import Projects from './Projects'
import Detail from "./Detail";
import axios from "axios";
import {empty, isset} from "../helpers/helper";
import YoutubeV3API from "./Youtube";

const Requests = {
  async init() {
    const token = this.getCookie('token');
    const id = new URLSearchParams(window.location.search).get('id')
    // if(isset(token)) {
      // this.setCookie('token', token);
      // localStorage.setItem("token", token);
    // }

    this.api = axios.create();
    this.token = this.getToken();
    this.headers = this.getHeaders();
    
    this.Auth = new Auth(this.api, this.token, this.headers);
    this.Materials = new Materials(this.api, this.token, this.headers);
    this.Projects = new Projects(this.api, this.token, this.headers);
    this.Detail = new Detail(this.api, this.token, this.headers);
    this.YoutubeV3API = new YoutubeV3API(this.api);
    
    const user = await this.Auth.GetUserInfo()
    return !empty(user) ? user.id : null;
  },

  getToken() {
    return this.getCookie('token') || false;
    // return localStorage.getItem("token") ? localStorage.getItem("token") : null;
  },

  getHeaders() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getToken(),
        appkey: "Qp7zsfwvagomA82jjsyvId56vDWGkBPRBP9H2LxP"
      },
    };
  },

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift().replace('%7C', '|');
    } else {
      return null;
    }
  },

  // setCookie(name, val) {
  //   const dArray = document.location.host.split('.');
  //   const domain = dArray.length > 2 ? document.location.host.split('.').splice(-2).join('.') : document.location.host ;
  //   document.cookie = `${name}=${val}; domain=.${domain}; path="/"`
  // }

};

export default Requests;
