import React, { useState } from 'react'
import PrivateTemplate from "../../views/PrivateTemplate";
import DefaultBtn from "../../components/UI/Buttons/DefaultBtn";
import ToggleSwitch from "../../components/UI/ToggleSwitch"
import './style.scss';
import img from '../../media/supportIcon.svg'
import questionsAndAnswers from './questions.json';
import SupportQuestion from 'components/UI/SupportQuestion';
import QARequestModal from "../../components/UI/Modal/QARequest";
import ModalSuccess from 'components/UI/Modal/ModalSuccess';
import Languages from 'translation/Languages';
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";

const Support = () => {
	const [selectedLabel, setSelectedLabel] = useState(Languages.getTranslation('rare-questions', true));
	const [openItemIndex, setOpenItemIndex] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	useUpdateTranslations();

	const toggleItem = (index) => {
		setOpenItemIndex((prevIndex) => (prevIndex === index ? null : index));
	};
	//will be added in the future to the array -- Обробка ЛДСП, МДФ
	const toggleOptions = [Languages.getTranslation('rare-questions', true)];

	const handleLabelChange = (label) => {
		setSelectedLabel(label);
	};

	const closeModal = () => {
		setIsModalOpen(false)
	}
	const openModal = () => {
		setIsModalOpen(true)
	}
	const closeSuccessModal = () => {
		setIsSuccessModalOpen(false)
	}
	const openSuccessModal = () => {
		setIsSuccessModalOpen(true)
	}


	return <PrivateTemplate>
		<section className='supportPage'>
			<div className='supportPage__header'>
				<span className='supportPage__header-title'>{Languages.getTranslation('KRONAS3D-support-page', true)}</span>
				<img src={img} alt="kronas3D" />
			</div>
			<div className='supportPage__body'>
				<div className='supportPage__body-form'>
					<span className='supportPage__body-title'>{Languages.getTranslation('Request-KRONAS3D-support-page', true)}</span>
					<div className='supportPage__body-title'>
						<DefaultBtn title={Languages.getTranslation("make-request", true)} handler={openModal} />
					</div>
				</div>
				<div className="supportPage__questions">
					<div className="supportPage__questions-toggle">
						<ToggleSwitch
							labels={toggleOptions}
							selectedLabel={selectedLabel}
							onSelect={handleLabelChange}
						/>
					</div>
					<div className="supportPage__line" />
					<br />
					<div className="supportPage__questions-List">
						{questionsAndAnswers.questions.map(({ title, answer }, index) => {
							return (
								<SupportQuestion
									key={index}
									title={title[Languages.lang]}
									answer={answer[Languages.lang]}
									isOpen={index === openItemIndex}
									toggleItem={() => toggleItem(index)} />
							)
						})}
					</div>
				</div>
			</div>
		</section>
		{isModalOpen && <QARequestModal closeModal={closeModal} openSuccessModal={openSuccessModal} />}
		{isSuccessModalOpen && <ModalSuccess text="Ваш запит успішно надіслано!" buttonText="Ок" close={closeSuccessModal} />}
	</PrivateTemplate>
}
export default Support
